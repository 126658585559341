export const ui = {
    en: {
        'all_payment_types':'all payment types',
        'all_payment_types_description': 'Experience hassle-free transactions with Bitcoin, Ethereum, and more. Pay securely through the trusted Cryptomus gateway for instant access to DipSway\'s features.\n',
        'extra':'Extra',

        'different_ways_to_swap': 'Different Ways to Swap',

        'coin_swap_description': 'Optimize your {crypto} to {quote} trades with our Coin Swap Paths finder. Compare multiple {crypto}/{quote} conversion routes instantly, visualize complex swaps, and secure the best exchange rates for your {crypto} to {quote} conversions. Perfect for both novice and expert crypto traders seeking to maximize their {crypto} to {quote} trades.',

        'description_calculator_pt_1': 'This calculator provides a conversion rate for',
        'description_calculator_pt_2': 'to',
        'description_calculator_pt_3': 'The conversion rate is based on the market rate in the last 24h.',
        // "from a >> to << b"
        'to': 'to',
        'converter': 'Converter',
        'crypto_prices_and_market_data': 'Crypto Prices & Market Data',
        'converter_site:desc': 'Convert between cryptocurrencies and fiat currencies with our easy-to-use converter tool. Get the latest exchange rates and market data for your trades.',
        'callout_calculator': 'AI powered Crypto Trading Bots',
        'info_on_price': 'Price info',
        'live_data': 'Live data',
        'supported_exchanges': 'Supported Exchanges',
        'prices_of': 'Prices of',
        'on_different_exchanges': 'on different crypto exchanges',
        'converted_to': 'Converted to',
        'on_different_amounts': 'on different amounts',
        'up_of': 'Up of',
        'dropped_of': 'Dropped of',
        'respect_yesterday': 'respect yesterday',
        'close_price': 'Close price',
        'price_variation': 'Price variation',
        'vwap': 'Average price',
        'volume_last_24h': 'Volume 24h',

        "exchange": "Exchange",
        "price": "Price",
        "volume": "Volume",
        "quote_volume": "Quote Volume",
        "percentage": "Percentage",

        "asset": "Asset",
        "today_at": "Today at",

        "calculator_title": "Calculator from {crypto} to {quote}",
        "crypto_label": "Crypto",
        "currency_label": "Quote",
        "enter_amount": "Insert the amount",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Do trading on {crypto}",

        "current_price_info": "The current {crypto} price is {price}, which is {change24h} change in the past 24 hours and {change7d} in the past 7 days.",
        "circulating_supply_info": "With a circulating supply of 21M, {crypto}'s live market cap is {marketCap}.",
        "trading_volume_info": "The 24-hour trading volume is {volume}.",
        "conversion_rate_calculation": "The conversion rates are calculated using the Live {crypto} Price Index along with other digital asset price indices.",
        "exchange_rate_update": "The {crypto}/{quote} exchange rate is updated in real-time.",
        "follow_dipsway_info": "Follow DipSway's crypto converter and calculator for 24/7 live updates of the exchange rates between crypto and fiat currencies, including {crypto}, {currencies}, and more.",

        'search_for_crypto': 'Search for a crypto currency',
        'see_more': 'See more',
        'crypto_prices_title': 'Supported Cryptocurrency Prices Last 24h Change',
        'see_current_crypto_market_trend': 'See the current cryptocurrency market trend',
        'dipsway': 'DipSway',
        'home_page': 'home page',
        'blogs.related_posts': 'Related Posts',
        'blogs.automate_your_trading': 'Automate your trading with DipSway AI today.',
        'blogs.automate_your_trading_description': 'DipSway uses a combination of 121+ technical indicators, and 17+ pattern detectors to give you an unfair advantage.',
        'btn.start_7_day_free_trial': 'Start 7-day free trial',
        'blogs.tags': 'Tags',
        'blogs.description_site': 'Crypto tutorials, feature releases, product comparisons, and more! Find content from the DipSway team here on the DipSway blog.',
        'blogs.title_site': 'DipSway Blog',
        'blogs.callout': 'Introducing version 4.13',
        'blogs.description_extended': 'Tutorials, feature releases, product comparisons, and more!\nFind content from the DipSway team here on the DipSway blog.',
        'blogs.go_to_article': 'Go to article',
        'skip_to_content': 'Skip to content',
        'calculate_your_earnings_and_start_free_trial': 'Calculate your earnings & Start 7-day free trial',

        'privacy_policy': 'Privacy Policy',
        'terms_of_service': 'Terms of Service',
        'refund_policy': 'Refund Policy',
        'affiliate_policy': 'Affiliate Policy',
        'blog': 'Blog',

        'pricing': 'Pricing',
        'resources': 'Resources',
        'company': 'Company',
        'support': 'Support',
        'affiliate_program': 'Affiliate Program',
        'about_us': 'About Us',
        'customer_success_agent': 'Customer Success Agent',
        'dipsway_ai_optimization': 'DipSway AI optimization',
        'live_back_tests': 'Live backtests',
        'live_back_tests_performances': 'DipSway live backtests & performance',
        'historical_performance_data': 'Historical performance data',
        'dipsway_earning_calculator': 'DipSway earnings calculator',
        'performance': 'Performance',
        'common:footer.disclaimer': 'DipSway provides software only. Any references to trading, exchange, transfer, or wallet services, etc. are references to services provided by third-party service providers.',
        'no-token:footer.disclaimer': 'DipSway does not sell, offer, facilitate, or provide access to any DeFi token or cryptocurrency on any blockchain. We are solely a software provider.',

        'index:hero.title': 'DipSway - Zero configuration, automated AI crypto trading bots',
        'index:hero.description': 'Experience seamless crypto trading with our AI bots. Launch your Crypto Trading AI bot for 100% automated trades, available 24/7 on a simple interface.',

        'header:product': 'Product',
        'header:product.feature.header': 'What can your capital earn using DipSway?',
        'header:product.feature.description': `Calculate your earnings based on DipSway's backtest history.`,
        'header:product.feature.cta': 'Calculate your earnings',

        'header:product.links.spot_ai_trading_bot': 'Spot AI Trading Bot',
        'header:learn': 'Learn',
        'header:learn.links.ai_trading_optimization': 'AI + Crypto Trading',

        'welcome:headline': 'Zero configuration, automated AI crypto trading bots',
        'welcome:subheadline': 'Connect to your exchange, turn autopilot on and you’re done.',
        'start_7_days_no_credit_card': 'Start 7 day free trial - No deposit required.',


        '404:title_site': '404 - DipSway | AI Powered Crypto Trading Bot',
        '404:description_site': 'Find your path back to crypto trading with our AI bot. Launch your Crypto Trading AI bot for 100% automated trades, available 24/7 on a simple interface.',
        '404:page_not_found': 'Page not found',
        '404:page_not_found_message': `Looks like you found a ... broken link! Please let DipSway support know \nwhich page took you here!`,
        '404:go_home': 'Go home',
        '404:open_dashboard': 'Open dashboard',


        'affiliate_program_policy:title_site': "DipSway Affiliate Program Policy",
        'affiliate_program_policy:description_site': "DipSway Affiliate Program Policy",


        'crypto_bot_calculator:title_site': "Crypto trading bot earnings calculator",
        'crypto_bot_calculator:description_site': "Full profitability analysis based on DipSways historical data, factoring in things such as exchange fees, coin performances, and simulating how DipSway bots would trade with your capital size.",
        'crypto_bot_calculator:label': 'Crypto trading bot earnings calculator',
        'crypto_bot_calculator:simulate_based_on': 'Simulate based on past performance',
        'crypto_bot_calculator:calculate_your_earnings': 'Calculate your earnings',
        'crypto_bot_calculator:description': 'Full profitability analysis based on DipSways historical data, factoring in things such as exchange fees, coin performances, and simulating how DipSway bots would trade with your capital size.',


        'featured_on': 'Featured On',
        '4_clicks_to_start': '4-clicks to start up',
        'no_knowledge_required': 'Zero configuration, zero knowledge required',
        'split_content_description': 'DipSway is truly zero config. connect your exchange → turn your bot on →\n' +
            '      and monitor it’s performance. The best performing configurations are\n' +
            '      applied by default.',
        'learn_about_dipsway_ai': 'Learn about DipSway AI',

        'trained_for_down_trends': 'Trained to detect downtrends',
        'be_safe_on_down_trends': 'Be safe, even on downtrends',
        'autopilot_content_headline_1': 'In before the pump, out before the drop,',
        'autopilot_content_headline_2': 'on autopilot',
        'autopilot_description': 'DipSway analyzes markets and places trades while you’re busy with your\n' +
            '      day-to-day commitments, and keeps you in the loop with status updates.',
        'downtrends_description': 'DipSways AI is trained to identify downtrends and before things get out of\n' +
            '      hand, will get you out',


        'see_how_dipsway_works': 'See how DipSway works',

        'free_trial_action:label': 'Ready when you are.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Safety',
        'safety_description': 'Keep your funds safe in your exchange, your safety is priority for us.',

        'ai_trading_optimization_description': 'Our proprietary AI model trained to recognize opportunities.',

        '3months': '3 months',
        'total_profits_within_label': 'Total profits across all deployed DipSway bots in the last',

        'dipsway_uses_combination_of': 'DipSway uses a combination of',
        'and': 'and',
        'technical_indicators': 'technical indicators',
        'pattern_detectors': 'pattern detectors',
        'detect_next_move': 'to detect the markets next move.',

        'are_you_influencer': 'Are you an influencer? Partner with us',
        'influencer_description': 'Influencers who work with us get setup a custom onboarding landing page\n' +
            '      tailored to their userbase’s interests.',

        'get_in_touch': 'Get in touch',

        'spot_ai_bot:title_site': 'Spot AI Crypto Trading Bot | 100% Automated Trades',
        'spot_ai_bot:description_site': 'Turn on your Crypto Trading Bot and enjoy 100% automated trades. Launch your bot today for a running 24/7 bot. Enjoy convenience and maximize your profits.',

        'spot_ai_bot:24_7_automated_trading': '24/7 automated trading, near-instant trade placement',
        'spot_ai_bot:algo_crypto_by_ml': 'AI Algorithmic Crypto Trading, driven by Machine Learning',

        'spot_ai_bot:desc': 'The best trading bot in the industry, using AI to analyze the market and\n' +
            'make trading decisions at machine-like speed.',

        'million': 'million',

        'avg_monthly_volume_moved_by_bots': 'Average monthly volume moved by DipSway bots.',
        'dipsway_has_more_than_350_users': 'DipSway has more than 350 users worldwide.',
        'dipsway_supports_315_coins': 'DipSway supports trading for over 315+ currencies.',

        'ai_powered': "AI Powered",
        'trading_strategies_powered_by_ai': "The trading strategies are powered by AI, using a combination of technical analysis, fundamental analysis.",

        'geolocation_advantage': "Geolocation Advantage",
        'geolocation_advantage_desc': "The servers hosting the bot are located in the same geographical location as the exchange, to ensure the lowest latency possible (avg. ~932μs delay).",

        'high_frequency_trading': 'High Frequency Trading',
        'high_frequency_trading_desc': 'Thanks to a multi-tenancy architecture, the trading strategies are executed with a granularity of candlesticks of 1 second.',

        'advanced_risk_management': 'Advanced Risk Management',
        'advanced_risk_management_desc': 'The bot is able to manage investments in multiple currencies, and to automatically hedge the risk of the portfolio.',

        'optimization_overfitting_avoidance': 'Optimization & Overfitting Avoidance',
        'optimization_overfitting_avoidance_desc': 'The bot is able to optimize the parameters of the trading strategies, and to avoid overfitting using a AI-genetic algorithm and a custom Walk-Forward Analysis.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'Every 24h, we run backtests of all the trading strategies, and we re-calculate the weights used by AI using an "Online Learning" approach.',
        'how_do_dipsways_strategies_work': "How do DipSway's strategies work?",
        'no_one_wants_to_invest_in_black_box': "No-one wants to invest in a black box. That’s why we’ve created an easy way to follow behind the scenes of DipSway's AI Bot - DipSway merges 112+ indicator & strategies to give you an advantage in the fast-changing world of crypto.",
        'learn_more_about_dipsway_ai': 'Learn more about DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Autopilot buys, sharp as a knife',
        'autopilot_buys_sharp_as_knife_desc': 'Most crypto markets move sideways and big price swings don\'t last long. Thanks to a Machine Learning decision process, the AI bot is built to execute sharp buy orders that will continuously keep you profitable in sideways markets and take advantage when those big dips occur.',
        'other_strategies_dont_work': 'Other strategies out there don\'t work because they are static and cannot evolve with market changes.',

        'autopilot_sells_and_youre_in_profit': 'Autopilot sells, and you’re in profit.',

        'dont_let_downturns_catch_you_off_guard': 'Don\'t let downturns catch you off guard.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ indicators and every metric you need in one platform.',

        'your_bot_runs_in_cloud': 'Your bot runs in the Cloud, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Our bot is a cloud-based trading bot that runs 24/7, so you don\'t have to worry going offline! It\'s always connected to the market, analyzing the the best timing to buy and sell.\n',

        'unfair_advantage': 'to give you an unfair advantage.',
        'and_many_more': 'And many more...',

        'pricing:title_site': 'DipSway Pricing',
        'pricing:description_site': "Affordable Crypto Trading AI Bot | Launch Your Bot Today | Enjoy 24/7 Automated Trading",
        'pricing:supercharge_text': 'Supercharge your trading with DipSway AI',

        'performance:title_site': 'DipSway Performance History & Backtests',
        'performance:description_site': "Experience live backtests with our Crypto Trading AI Bot. Launch your bot today for a running 24/7 bot. Enjoy convenience and maximize your profits.",
        'performance:label': "DipSway Performance History & Backtests",

        'connect_to_exchange': 'Connect to your exchange, turn autopilot on and you’re done.',
        'calculate_your_earnings': 'Calculate your earnings',

        'trading_optimization:title_site': "DipSway AI Trading Optimization",
        'trading_optimization:description_site': "Experience AI Trading Optimization with our Crypto Trading AI Bot. Launch your bot today for a running 24/7 bot. Enjoy convenience and maximize your profits.",
        'trading_optimization:label': "AI Trading Optimization",
        'trading_optimization:breakdown': 'Breakdown: DipSway’s AI Crypto Trading',

        'trading_optimization:desc': 'DipSway\'s powerful AI bot dynamically adapts to the rapid market changes.',
        'trading_optimization:steps': 'All of this is done through a series of steps:',

        'behind_scenes:behind_the_scenes': 'What\'s behind the scenes?',

        'behind_scenes:indicators': 'Some indicators show a potential buy signal (green), while others are showing a potential sell signal (gray).',
        'behind_scenes:neural_network': 'The DipSway Neural Network takes all of these indicators into account and makes a decision based on the overall picture, producing a single buy or sell signal.',

        'affiliate_program:title_site': 'Cryptocurrency bot affiliate program',
        'affiliate_program:description_site': "Join our Affiliate Program. Enjoy 30% recurring commission on friend invites.",
        'affiliate_program:label': "Affiliate Program",
        'affiliate_program:desc': "Earn 30% commission on every referral, recurring",
        'affiliate_program:desc2': "Connect to your exchange, turn autopilot on and you’re done.",
        'affiliate_program:get_your_affiliate_link': 'Get your affiliate link',

        'conference': 'Conference',

        'about:title_site': "About DipSway",
        'about:our_journey': 'Our journey',
        'about:our_journey_desc': 'To provide high-quality, profitable AI crypto trading bots for traders of\n' +
            'any level growing their investment wallet and enhancing their daily\n' +
            'performances.',
        'about:just_getting_started': 'And we’re just getting started.',

        'about:description_site': "Experience AI Trading Optimization with our Crypto Trading AI Bot. Launch your bot today for a running 24/7 bot. Enjoy convenience and maximize your profits.",
        'about:label': 'About',
        'about:behind_scenes': 'Behind the scenes',
        'founder': 'Founder',

        'about:founder1_msg': 'DipSway was born from a vision to revolutionize crypto trading through cutting-edge AI. As software engineers and crypto enthusiasts, we saw an opportunity to create something truly innovative - a trading bot that thinks like a pro trader but operates at superhuman speed.\nThanks to our strong technical expertise, our breakthrough came when we developed an AI that doesn\'t just follow preset rules, but adapts in real-time to market dynamics.',
        'about:founder2_msg': 'This isn\'t your average trading bot; it\'s a sophisticated system that analyzes vast amounts of data, recognizes complex patterns, and makes split-second decisions with remarkable accuracy.\nWhat sets DipSway apart is our unique approach to AI learning. It\'s like having a team of expert traders working for you 24/7, but faster and more efficient.\nOur mission is to make advanced crypto trading accessible to everyone, from seasoned pros to curious newcomers.',

        'about:join_team': 'Join the DipSway team',
        'about:join_team_desc': 'We’re looking for crypto-enthusiasts who are passionate about finance,\n' +
            'trading and technology. Ready to change the way the world invests in\n' +
            'crypto?',

        'read_post': 'Read post',

        'about:development_starts': "Development starts",
        'about:development_starts_desc': "One day we were getting notifications from the Coinbase mobile app of the recent price of Bitcoin like 'Bitcoin is up 5% today'. We thought it would be cool to have a bot that would operate BEFORE such notifications. We searched the web and couldn't find anything easy to use, that runs in the cloud. So we decided to build it ourselves.",

        'about:first_bot_version': "First bot version",
        'about:first_bot_version_desc': "First simulation of Algo-Trading strategy for Bitcoin and Ethereum, we started to include more crypto assets and more strategies. We started to see the potential of our bot and we decided to make it public.",

        'about:simple_strategy_not_enough': 'A "simple strategy" was not enough',
        'about:simple_strategy_not_enough_desc': "We built and trained a neural network that would learn from the market and make predictions. The results showed a very high accuracy.",

        'about:live_bot_release': "Live bot release",
        'about:live_bot_release_desc': "We built and trained a neural network that would learn from the market and make predictions. The results showed a very high accuracy.",

        'about:our_transparency_value': "Our transparency value",
        'about:our_transparency_value_desc': "Live backtests and Live performances were released to the public. We want transparency to be a key differentiator between us and other companies who show no evidence of their results.",

        'about:infrastructure_revolution': "Infrastructure Revolution",
        'about:infrastructure_revolution_desc': "2023 was a successful year as more traders adopted our platform. We increased our engagement with traders, striving to understand what everyone truly needed in a bot platform. Given this growing user base, we recognized the importance of making DipSway fail-proof at an engineering level. Before introducing exciting new updates, features, tools, and bots, we prioritized ensuring our systems were running 24/7 without issues and that performance and quality were top-notch.",

        'about:new_exchanges': "New exchanges",
        'about:new_exchanges_desc': "In February, we significantly expanded our platform by integrating major exchanges like Coinbase Advanced, BingX, and Bitmart. We also partnered with KuCoin and Bybit, becoming an official third-party application on both. These additions and partnerships greatly increased our users' trading options and market access, validating our platform's reliability and marking a major milestone in our growth.",

        'about:rotating_proxy': "Rotating Proxy and new hot features",
        'about:rotating_proxy_desc': "In a significant upgrade to our infrastructure, we implemented 20 additional IPs and expanded our database replicas from 2 to 7, enhancing bot responsiveness to new candles across exchanges. This improvement positions our bots competitively in the market for speed and reliability. We also integrated ﻿Crypto(.)com further expanding our supported exchanges and trading options for users. A notable new feature is the Bot Leaderboard, which allows users to check out bot performances and copy the best performing bot settings. These enhancements collectively strengthen our platform's capabilities, offering users more robust, efficient, and diverse trading opportunities.",

        'about:multiple_quote_currencies': "Multiple quote currencies",
        'about:multiple_quote_currencies_desc': "We've introduced multi-currency support, enabling bots to trade with various quote pairs beyond USDT. This enhancement offers users greater flexibility, accommodating different exchange structures and geographic regulations. By allowing trading across multiple currencies, we've empowered users to optimize their strategies according to specific needs and market conditions, improving their overall trading experience.",

        'about:new_admin_dashboard': "New admin dashboard",
        'about:new_admin_dashboard_desc': "Our new platform release marks a significant leap forward in user experience and functionality. It enables faster development and delivery of new features, giving us greater control over bot troubleshooting to ensure elite-level support. We've strengthened the communication between interface and backend, guaranteeing data accuracy and reliable functionality. While maintaining our commitment to a minimalistic, clean, and easy-to-navigate design, this also enriched the platform with comprehensive data and analytics. This balance empowers users with in-depth insights without compromising on simplicity, allowing for more informed decision-making in a user-friendly environment.",

        'about:autopilot_tradingview': "Autopilot & TradingView signal automation",
        'about:autopilot_tradingview_desc': "The Autopilot Bot, our revolutionary AI-driven trading solution. This zero-configuration enhancement to our AI bots leverages historic data, neural network computing, and LIVE data from all DipSway bots to continuously optimize trading strategies. It automatically selects top-performing assets and adjusts configurations based on real-time market parameters, offering users a hands-off, data-driven trading experience that adapts instantly to market conditions.",

        'about:ai_futures_bot': "AI Futures bot",
        'about:ai_futures_bot_desc': "With the introduction of the in-house SL & TP system (Trailing operations will be available soon), all users now have 100% success rate on their custom settings for their bot. The in-house system allowed us to release the first generation of an authentic AI Futures bot.",

        'about:new_features': "New features",
        'about:new_features_desc': "We are working on a new feature that will allow users to create their own strategies and share them with the community.",

        'proven_to_perform': 'Proven to perform',

        'see_how_dipsway_averages': 'See how DipSwayers are averaging using the power of DipSway bots.',
        'last': 'Last',
        'months_compound_roi': 'Months Compound ROI',
        'total_return_based_on_last': 'Total Return based on last',
        'show_as_multiplier_values': 'Show as multiplier values',
        'show_as_percentage_values': 'Show as percentage values',
        'join': 'Join',
        'stars': 'Stars',

        'faq': 'Frequently Asked Questions',
        'faq_desc': 'Find your answers here. If you have a question that\'s not covered in the FAQ,\n' +
            'please feel free to reach out to us.',
        'faq_sub_desc': 'Could’t find the answer you were looking for?',
        'contact_support': 'Contact support',

        'view_supported_platform': 'View Supported Platforms',

        'simulate_based_on_past_performances': 'Simulate based on past performance',
        'calculate_your_earnings_desc': 'Full profitability analysis based on DipSways historical data, factoring in things such as exchange fees, coin performances, and simulating how DipSway bots would trade with your capital size.',

        'go_to_tweet': 'Go to tweet',
        'view_on': 'View on',

        'security': 'Security',
        'safe_and_secure': 'Safe & secure',
        'safe_and_secure_desc': 'Your funds stay in your exchange, and we only ask for minimal\n' +
            'permissions to execute trades for you.',

        'follow_dipsway_development': 'Follow DipSways development & meet the community',
        'join_us_on_telegram': 'Join us on Telegram',
        'here_when_you_need_help': 'Here when you need help',
        'dipsway_customer_care': 'DipSway Customer Care',

        'for_us_top_10_percent': 'For us the top 10% is the standard for DipSwayers. You’re free to\n' +
            'experiment with different configurations, but when you need help\n' +
            'optimizing we’re there.',

        'join_the_telegram_community': 'Join the telegram community',

        'made': 'made',
        'profit_on': 'profit on',
        'a_single_trade': 'a single trade',

        'dont_go_back_to_manual_trading': 'Don’t go back to manual trading,',

        'get_in_before_the_pump': 'get in before the pump',
        'get_out_before_the_drop': 'get out before the drop',

        'on_autopilot': 'On autopilot',

        'start_your_dipsway_bot': 'Start your DipSway bot in 4 clicks',

        'safe_for_you': 'Safe for you, by design',
        'safe_for_you_desc': `With DipSway your funds are safe in your crypto exchange, and we ask you
to trust us only as much as it’s needed for the bots to run.`,

        'ip_whitelisting': 'IP whitelisting',
        'ip_whitelisting_desc': 'Only DipSway controlled IP addresses can operate on your account, even if your API key has been stolen, bad actors can’t execute trades.',

        'restricted_access': 'Restricted access',
        'restricted_access_desc': 'DipSway only requests the simplest permissions to monitor & execute\n' +
            'trades on your behalf.',

        'bright_green_performances': 'Bright green performance',
        'on_capital': 'On capital',
        'win_rate': 'win rate',
        'average_trade': 'average trade',

        'see_dipsway_live_backtests': 'See DipSway live backtests & performance',
        'find_and_download': 'Find and download historical performance data, and live backtests.',

        'available_at_no_extra_cost': 'Available at no extra cost',

        'full_autopilot_mode': 'Full Autopilot mode',
        'full_autopilot_mode_desc': 'With DipSway Autopilot, not only are your trades automated, but\n' +
            'DipSway also chooses the best crypto currency to trade at that moment\n' +
            'based on market conditions.',

        'calculate_your_earnings_with_autopilot': 'Calculate your earnings with Autopilot',
        'high_performance': 'High performance',
        'live_backtests_historical_performance': 'Live backtests, historical performance.',
        'backtests_performance': 'Backtests & performance',
        'extendable_via_tradingview': 'Extendable via TradingView',
        'use_power_tradingview': 'Use the power of TradingView to configure DipSway further.',
        'coming_soon': 'Coming soon...',
        '3_green_trades_in_30_days': '3 green trades in 30 days or money back guaranteed',
        'need_help_choosing': 'Need help choosing?',
        'use_dipsway_earnings_calculator': 'Use the DipSway earnings calculator, learn how much you can earn and get a plan recommendation.',
        'launch_earnings_calculator': 'Launch earnings calculator',
        'popular': 'Popular',
        'recommended_for_you': 'Recommended for you',
        'no_deposit_required': 'No deposit required',
        'top_10_of_users': "Top 10% of users",
        'all_users': "All users",
        'downtrend_page:desc1': 'Operation: Emergency position exit. Downtrend identified.',
        'downtrend_page:sell_order_fulfilled': 'Sell order for 12 BTC fulfilled.',
        'performance_history': 'Performance History',
        'trained_to_recognize_opportunities': 'Trained to recognize opportunities',
        'live_backtests_performances': 'Live Backtests performances',
        'see_how_our_strategies_performed': 'See how our strategies have performed in the past with high quality live backtests.',
        'backtests_from': 'Backtests from',
        'past_6_months': 'past 6 months',
        'collapse': 'Collapse',
        'expand': 'Expand',
        'view_report': 'View report',
        'knowledge:title1': 'High-quality Historical Data',
        'knowledge:description1': 'We ensure that the live-candlesticks that are used are of the highest quality.',
        'knowledge:title2': 'Fees & Delays included',
        'knowledge:description2': 'Maker and taker fees of 0.1% are applied to each trade. Slippage of 0.1% and a delay of few minutes are applied to each trade.',
        'knowledge:title3': 'Overfitting Avoidance',
        'knowledge:description3': 'DipSway passively monitors accounts performing bad and recommends actions.',
        'operation': 'Operation',
        'buy_the_dip': 'Buy the dip',
        'sell_the_high': 'Sell the high',
        'bought': 'Bought',
        'watching_market': 'watching market',
        'sell_order_for': 'Sell order for',
        'fulfilled': 'fulfilled',
        'profit_made': 'Profit made',
        'calculation_results': 'Calculation results',
        'calculating': 'Calculating',
        'total_expected_profit_at_the_end_of': 'Total expected profit at the end of',
        'period_based_off_of_historical_backtest_performances': 'period based off of historical backtest performances',
        'trades': 'trades',
        'day': 'day',
        'profit': 'profit',
        'recommended_plan_for_you': 'Recommended plan for you',
        'go_to': 'Go to',
        'free_trial_available': 'Free Trial Available',
        'runs_at': 'Runs at',
        'learn_how_we_calculate_your_earnings': 'Learn how we calculate you earnings',
        'click_to_open_deepdive': 'Click to open deepdive where you can choose from other exchanges we support.',
        'your_capital': 'Your Capital',
        'currency': 'Currency',
        'enabled': 'Enabled',
        'set_to': 'set to',
        'coin_count': 'coin count',
        'disabled': 'Disabled',
        'auto': 'Auto',
        'selected_coins': 'Selected coins',
        'loading_coins': 'Loading coins',
        'login': 'Login',

        'trading_with': 'Trading with',
    },

    es: {
        'all_payment_types': 'todos los tipos de pago',
        'all_payment_types_description': 'Experimenta transacciones sin problemas con Bitcoin, Ethereum y más. Paga de forma segura a través de la puerta de enlace Cryptomus de confianza para acceder instantáneamente a las funciones de DipSway.\n',
        'extra': 'Extra',

        'no-token:footer.disclaimer': 'DipSway no vende, ofrece, facilita ni proporciona acceso a ningún token DeFi o criptomoneda en ninguna blockchain. Somos únicamente un proveedor de software.',

        "different_ways_to_swap": "Diferentes Formas de Intercambiar",

        'coin_swap_description': 'Optimiza tus intercambios de {crypto} a {quote} con nuestro buscador de Rutas de Intercambio de Monedas. Compara instantáneamente múltiples rutas de conversión de {crypto}/{quote}, visualiza intercambios complejos y asegura las mejores tasas de cambio para tus conversiones de {crypto} a {quote}. Perfecto tanto para comerciantes de criptomonedas novatos como expertos que buscan maximizar sus operaciones de {crypto} a {quote}.',

        "current_price_info": "El precio actual de {crypto} es {price}, lo que representa un cambio de {change24h} en las últimas 24 horas y {change7d} en los últimos 7 días.",
        "circulating_supply_info": "Con una oferta circulante de 21M, la capitalización de mercado en vivo de {crypto} es {marketCap}.",
        "trading_volume_info": "El volumen de trading en 24 horas es {volume}.",
        "conversion_rate_calculation": "Las tasas de conversión se calculan utilizando el Índice de Precios en Vivo de {crypto} junto con otros índices de precios de activos digitales.",
        "exchange_rate_update": "La tasa de cambio de {crypto}/{quote} se actualiza en tiempo real.",
        "follow_dipsway_info": "Sigue el conversor y calculadora de criptomonedas de DipSway para actualizaciones en vivo 24/7 de las tasas de cambio entre criptomonedas y monedas fiduciarias, incluyendo {crypto}, {currencies}, y más.",

        "description_calculator_pt_1": "Esta calculadora proporciona una tasa de conversión para",
        "description_calculator_pt_2": "a",
        "description_calculator_pt_3": "La tasa de conversión se basa en el tipo de cambio del mercado en las últimas 24 horas.",
        "to": "a",
        "converter": "Conversor",
        "crypto_prices_and_market_data": "Precios de Criptomonedas y Datos de Mercado",
        "converter_site:desc": "Convierte entre criptomonedas y monedas fiduciarias con nuestra herramienta de conversión fácil de usar. Obtén las últimas tasas de cambio y datos de mercado para tus transacciones.",
        "callout_calculator": "Bots de Trading de Criptomonedas impulsados por IA",
        "info_on_price": "Información de precio",
        "live_data": "Datos en vivo",
        "supported_exchanges": "Exchanges soportados",
        "prices_of": "Precios de",
        "on_different_exchanges": "en diferentes exchanges de criptomonedas",
        "converted_to": "Convertido a",
        "on_different_amounts": "en diferentes cantidades",
        "up_of": "Subida de",
        "dropped_of": "Bajada de",
        "respect_yesterday": "respecto a ayer",
        "close_price": "Precio de cierre",
        "price_variation": "Variación de precio",
        "vwap": "Precio promedio",
        "volume_last_24h": "Volumen 24h",

        "exchange": "Exchange",
        "price": "Precio",
        "volume": "Volumen",
        "quote_volume": "Volumen de cotización",
        "percentage": "Porcentaje",

        "asset": "Activo",
        "today_at": "Hoy a las",

        "calculator_title": "Calculadora de {crypto} a {quote}",
        "crypto_label": "Cripto",
        "currency_label": "Cotización",
        "enter_amount": "Inserte la cantidad",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Haz trading en {crypto}",

        'search_for_crypto': 'Buscar criptomoneda',
        'crypto_prices_title': 'Precios de criptomonedas compatibles Cambio en las últimas 24 horas',
        'see_current_crypto_market_trend': 'Ver la tendencia actual del mercado de criptomonedas',

        'see_more': 'Ver más',
        'privacy_policy': 'Política de privacidad',
        'terms_of_service': 'Términos de servicio',
        'refund_policy': 'Política de reembolso',
        'affiliate_policy': 'Política de afiliados',
        'blog': 'Blog',

        'pricing': 'Precios',
        'resources': 'Recursos',
        'company': 'Empresa',
        'support': 'Soporte',
        'affiliate_program': 'Programa de afiliados',
        'about_us': 'Sobre nosotros',
        'customer_success_agent': 'Agente de éxito del cliente',
        'dipsway_ai_optimization': 'Optimización de DipSway AI',
        'live_back_tests': 'Pruebas retrospectivas en vivo',
        'live_back_tests_performances': 'Pruebas retrospectivas y rendimiento en vivo de DipSway',
        'historical_performance_data': 'Datos de rendimiento histórico',
        'dipsway_earning_calculator': 'Calculadora de ganancias de DipSway',
        'performance': 'Rendimiento',
        'common:footer.disclaimer': 'DipSway proporciona solo software. Cualquier referencia a servicios de trading, intercambio, transferencia o billetera, etc., son referencias a servicios proporcionados por proveedores de servicios de terceros.',

        'index:hero.title': 'DipSway - Bots de trading de criptomonedas con IA en piloto automático, sin configuración',
        'index:hero.description': 'Experimenta el trading de criptomonedas sin problemas con nuestros bots de IA. Lanza tu bot de trading de criptomonedas con IA para operaciones 100% automatizadas, disponible 24/7 en una interfaz simple.',

        'header:product': 'Producto',
        'header:product.feature.header': '¿Qué puede ganar tu capital usando DipSway?',
        'header:product.feature.description': 'Calcula tus ganancias basadas en el historial de pruebas retrospectivas de DipSway.',
        'header:product.feature.cta': 'Calcula tus ganancias',

        'header:product.links.spot_ai_trading_bot': 'Bot de trading de IA al contado',
        'header:learn': 'Aprender',
        'header:learn.links.ai_trading_optimization': 'IA + Trading de criptomonedas',

        'welcome:headline': 'Bots de trading de criptomonedas con IA en piloto automático, sin configuración',
        'welcome:subheadline': 'Conéctate a tu exchange, activa el piloto automático y listo.',
        'start_7_days_no_credit_card': 'Comienza la prueba gratuita de 7 días - No se requiere depósito.',

        '404:title_site': '404 - DipSway | Bot de trading de criptomonedas con IA',
        '404:description_site': 'Encuentra tu camino de vuelta al trading de criptomonedas con nuestro bot de IA. Lanza tu bot de trading de criptomonedas con IA para operaciones 100% automatizadas, disponible 24/7 en una interfaz simple.',
        '404:page_not_found': 'Página no encontrada',
        '404:page_not_found_message': 'Parece que has encontrado un... ¡enlace roto! Por favor, informa al soporte de DipSway\nqué página te trajo aquí.',
        '404:go_home': 'Ir a inicio',
        '404:open_dashboard': 'Abrir panel',

        'affiliate_program_policy:title_site': "Política del programa de afiliados de DipSway",
        'affiliate_program_policy:description_site': "Política del programa de afiliados de DipSway",

        'crypto_bot_calculator:title_site': "Calculadora de ganancias del bot de trading de criptomonedas",
        'crypto_bot_calculator:description_site': "Análisis de rentabilidad completo basado en datos históricos de DipSway, teniendo en cuenta factores como las tarifas de intercambio, el rendimiento de las monedas y simulando cómo los bots de DipSway operarían con tu tamaño de capital.",
        'crypto_bot_calculator:label': 'Calculadora de ganancias del bot de trading de criptomonedas',
        'crypto_bot_calculator:simulate_based_on': 'Simular basado en el rendimiento pasado',
        'crypto_bot_calculator:calculate_your_earnings': 'Calcula tus ganancias',
        'crypto_bot_calculator:description': 'Análisis de rentabilidad completo basado en datos históricos de DipSway, teniendo en cuenta factores como las tarifas de intercambio, el rendimiento de las monedas y simulando cómo los bots de DipSway operarían con tu tamaño de capital.',

        'featured_on': 'Destacado en',
        '4_clicks_to_start': '4 clics para empezar',
        'no_knowledge_required': 'Cero configuración, cero conocimiento requerido',
        'split_content_description': 'DipSway es realmente de cero configuración. conecta tu exchange → activa tu bot →\n' +
            '      y monitorea su rendimiento. Las mejores configuraciones de rendimiento se\n' +
            '      aplican por defecto.',
        'learn_about_dipsway_ai': 'Aprende sobre DipSway AI',

        'trained_for_down_trends': 'Entrenado para detectar tendencias bajistas',
        'be_safe_on_down_trends': 'Mantente seguro, incluso en tendencias bajistas',
        'autopilot_content_headline_1': 'Dentro antes del bombeo, fuera antes de la caída,',
        'autopilot_content_headline_2': 'en piloto automático',
        'autopilot_description': 'DipSway analiza los mercados y realiza operaciones mientras estás ocupado con tus\n' +
            '      compromisos diarios, y te mantiene informado con actualizaciones de estado.',
        'downtrends_description': 'La IA de DipSway está entrenada para identificar tendencias bajistas y antes de que las cosas se salgan de\n' +
            '      control, te sacará',

        'see_how_dipsway_works': 'Mira cómo funciona DipSway',

        'free_trial_action:label': 'Listo cuando tú lo estés.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Seguridad',
        'safety_description': 'Mantén tus fondos seguros en tu exchange, tu seguridad es nuestra prioridad.',

        'ai_trading_optimization_description': 'Nuestro modelo de IA patentado entrenado para reconocer oportunidades.',

        '3months': '3 meses',
        'total_profits_within_label': 'Ganancias totales en todos los bots DipSway implementados en los últimos',

        'dipsway_uses_combination_of': 'DipSway utiliza una combinación de',
        'and': 'y',
        'technical_indicators': 'indicadores técnicos',
        'pattern_detectors': 'detectores de patrones',
        'detect_next_move': 'para detectar el próximo movimiento del mercado.',

        'are_you_influencer': '¿Eres un influencer? Asociate con nosotros',
        'influencer_description': 'Los influencers que trabajan con nosotros obtienen una página de inicio personalizada\n' +
            '      adaptada a los intereses de su base de usuarios.',

        'get_in_touch': 'Ponte en contacto',

        'spot_ai_bot:title_site': 'Bot de trading de IA al contado | Operaciones 100% automatizadas',
        'spot_ai_bot:description_site': 'Activa tu bot de trading de criptomonedas y disfruta de operaciones 100% automatizadas. Lanza tu bot hoy para tener un bot funcionando 24/7. Disfruta de la comodidad y maximiza tus ganancias.',

        'spot_ai_bot:24_7_automated_trading': 'Trading automatizado 24/7, colocación de operaciones casi instantánea',
        'spot_ai_bot:algo_crypto_by_ml': 'Trading algorítmico de criptomonedas con IA, impulsado por aprendizaje automático',

        'spot_ai_bot:desc': 'El mejor bot de trading de la industria, utilizando IA para analizar el mercado y\n' +
            'tomar decisiones de trading a velocidad de máquina.',

        'million': 'millón',

        'avg_monthly_volume_moved_by_bots': 'Volumen mensual promedio movido por los bots de DipSway.',
        'dipsway_has_more_than_350_users': 'DipSway tiene más de 350 usuarios en todo el mundo.',
        'dipsway_supports_315_coins': 'DipSway admite el trading de más de 315+ monedas.',

        'ai_powered': "Impulsado por IA",
        'trading_strategies_powered_by_ai': "Las estrategias de trading están impulsadas por IA, utilizando una combinación de análisis técnico y análisis fundamental.",

        'geolocation_advantage': "Ventaja de geolocalización",
        'geolocation_advantage_desc': "Los servidores que alojan el bot están ubicados en la misma ubicación geográfica que el exchange, para garantizar la menor latencia posible (retraso promedio de ~932μs).",

        'high_frequency_trading': 'Trading de alta frecuencia',
        'high_frequency_trading_desc': 'Gracias a una arquitectura multi-inquilino, las estrategias de trading se ejecutan con una granularidad de velas de 1 segundo.',

        'advanced_risk_management': 'Gestión avanzada de riesgos',
        'advanced_risk_management_desc': 'El bot es capaz de gestionar inversiones en múltiples monedas y de cubrir automáticamente el riesgo de la cartera.',

        'optimization_overfitting_avoidance': 'Optimización y evitación de sobreajuste',
        'optimization_overfitting_avoidance_desc': 'El bot es capaz de optimizar los parámetros de las estrategias de trading y de evitar el sobreajuste utilizando un algoritmo genético de IA y un análisis de avance personalizado.',

        'backtesting': 'Pruebas retrospectivas',
        'backtesting_desc': 'Cada 24 horas, realizamos pruebas retrospectivas de todas las estrategias de trading y recalculamos los pesos utilizados por la IA utilizando un enfoque de "Aprendizaje en línea".',
        'how_do_dipsways_strategies_work': "¿Cómo funcionan las estrategias de DipSway?",
        'no_one_wants_to_invest_in_black_box': "Nadie quiere invertir en una caja negra. Por eso hemos creado una forma fácil de seguir detrás de escena del bot de IA de DipSway - DipSway fusiona más de 112 indicadores y estrategias para darte una ventaja en el mundo rápidamente cambiante de las criptomonedas.",
        'learn_more_about_dipsway_ai': 'Aprende más sobre DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Compras en piloto automático, afiladas como un cuchillo',
        'autopilot_buys_sharp_as_knife_desc': 'La mayoría de los mercados de criptomonedas se mueven lateralmente y las grandes oscilaciones de precios no duran mucho. Gracias a un proceso de decisión de aprendizaje automático, el bot de IA está diseñado para ejecutar órdenes de compra precisas que te mantendrán constantemente rentable en mercados laterales y aprovecharán cuando se produzcan esas grandes caídas.',
        'other_strategies_dont_work': 'Otras estrategias por ahí no funcionan porque son estáticas y no pueden evolucionar con los cambios del mercado.',

        'autopilot_sells_and_youre_in_profit': 'Ventas en piloto automático, y estás en beneficio.',

        'dont_let_downturns_catch_you_off_guard': 'No dejes que las caídas te agarren desprevenido.',
        'dont_let_downturns_catch_you_off_guard_desc': 'Más de 121 indicadores y todas las métricas que necesitas en una plataforma.',

        'your_bot_runs_in_cloud': 'Tu bot se ejecuta en la nube, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Nuestro bot es un bot de trading basado en la nube que funciona 24/7, ¡así que no tienes que preocuparte por quedarte sin conexión! Siempre está conectado al mercado, analizando el mejor momento para comprar y vender.\n',

        'unfair_advantage': 'para darte una ventaja injusta.',
        'and_many_more': 'Y muchos más...',

        'pricing:title_site': 'Precios de DipSway',
        'pricing:description_site': "Bot de trading de criptomonedas con IA asequible | Lanza tu bot hoy | Disfruta del trading automatizado 24/7",
        'pricing:supercharge_text': 'Potencia tu trading con DipSway AI',

        'performance:title_site': 'Historial de rendimiento y pruebas retrospectivas de DipSway',
        'performance:description_site': "Experimenta pruebas retrospectivas en vivo con nuestro bot de trading de criptomonedas con IA. Lanza tu bot hoy para tener un bot funcionando 24/7. Disfruta de la comodidad y maximiza tus ganancias.",
        'performance:label': "Historial de rendimiento y pruebas retrospectivas de DipSway",

        'connect_to_exchange': 'Conéctate a tu exchange, activa el piloto automático y listo.',
        'calculate_your_earnings': 'Calcula tus ganancias',

        'trading_optimization:title_site': "Optimización de trading con IA de DipSway",
        'trading_optimization:description_site': "Experimenta la optimización de trading con IA con nuestro bot de trading de criptomonedas. Lanza tu bot hoy para tener un bot funcionando 24/7. Disfruta de la comodidad y maximiza tus ganancias.",
        'trading_optimization:label': "Optimización de trading con IA",
        'trading_optimization:breakdown': 'Desglose: Trading de criptomonedas con IA de DipSway',

        'trading_optimization:desc': 'El poderoso bot de IA de DipSway se adapta dinámicamente a los rápidos cambios del mercado.',
        'trading_optimization:steps': 'Todo esto se hace a través de una serie de pasos:',

        'behind_scenes:behind_the_scenes': '¿Qué hay detrás de escena?',

        'behind_scenes:indicators': 'Algunos indicadores muestran una señal de compra potencial (verde), mientras que otros muestran una señal de venta potencial (gris).',
        'behind_scenes:neural_network': 'La Red Neuronal de DipSway tiene en cuenta todos estos indicadores y toma una decisión basada en el panorama general, produciendo una única señal de compra o venta.',

        'affiliate_program:title_site': 'Programa de afiliados de bot de criptomonedas',
        'affiliate_program:description_site': "Únete a nuestro Programa de Afiliados. Disfruta de una comisión recurrente del 30% en invitaciones de amigos.",
        'affiliate_program:label': "Programa de Afiliados",
        'affiliate_program:desc': "Gana un 30% de comisión en cada referido, de forma recurrente",
        'affiliate_program:desc2': "Conéctate a tu exchange, activa el piloto automático y listo.",
        'affiliate_program:get_your_affiliate_link': 'Obtén tu enlace de afiliado',

        'conference': 'Conferencia',

        'about:title_site': "Acerca de DipSway",
        'about:our_journey': 'Nuestro viaje',
        'about:our_journey_desc': 'Proporcionar bots de trading de criptomonedas con IA de alta calidad y rentables para\n' +
            'traders de cualquier nivel que quieran hacer crecer su cartera de inversiones y mejorar su\n' +
            'rendimiento diario.',
        'about:just_getting_started': 'Y esto es solo el comienzo.',

        'about:description_site': "Experimenta la optimización de trading con IA con nuestro bot de trading de criptomonedas. Lanza tu bot hoy para tener un bot funcionando 24/7. Disfruta de la comodidad y maximiza tus ganancias.",
        'about:label': 'Acerca de',
        'about:behind_scenes': 'Detrás de escena',
        'founder': 'Fundador',

        'about:founder1_msg': 'DipSway nació de una visión para revolucionar el trading de criptomonedas a través de IA de vanguardia. Como ingenieros de software y entusiastas de las criptomonedas, vimos una oportunidad para crear algo verdaderamente innovador - un bot de trading que piensa como un trader profesional pero opera a velocidad sobrehumana.\nGracias a nuestra sólida experiencia técnica, nuestro gran avance llegó cuando desarrollamos una IA que no solo sigue reglas preestablecidas, sino que se adapta en tiempo real a la dinámica del mercado.',
        'about:founder2_msg': 'Este no es tu bot de trading promedio; es un sistema sofisticado que analiza grandes cantidades de datos, reconoce patrones complejos y toma decisiones en fracciones de segundo con una precisión notable.\nLo que distingue a DipSway es nuestro enfoque único para el aprendizaje de IA. Es como tener un equipo de traders expertos trabajando para ti 24/7, pero más rápido y más eficiente.\nNuestra misión es hacer que el trading avanzado de criptomonedas sea accesible para todos, desde profesionales experimentados hasta curiosos principiantes.',

        'about:join_team': 'Únete al equipo de DipSway',
        'about:join_team_desc': 'Buscamos entusiastas de las criptomonedas que sean apasionados por las finanzas,\n' +
            'el trading y la tecnología. ¿Listo para cambiar la forma en que el mundo invierte en\n' +
            'criptomonedas?',

        'read_post': 'Leer publicación',

        'about:development_starts': "Comienza el desarrollo",
        'about:development_starts_desc': "Un día estábamos recibiendo notificaciones de la aplicación móvil de Coinbase sobre el precio reciente de Bitcoin como 'Bitcoin ha subido un 5% hoy'. Pensamos que sería genial tener un bot que operara ANTES de tales notificaciones. Buscamos en la web y no pudimos encontrar nada fácil de usar que se ejecutara en la nube. Así que decidimos construirlo nosotros mismos.",

        'about:first_bot_version': "Primera versión del bot",
        'about:first_bot_version_desc': "Primera simulación de estrategia de trading algorítmico para Bitcoin y Ethereum, comenzamos a incluir más activos criptográficos y más estrategias. Empezamos a ver el potencial de nuestro bot y decidimos hacerlo público.",

        'about:simple_strategy_not_enough': 'Una "estrategia simple" no era suficiente',
        'about:simple_strategy_not_enough_desc': "Construimos y entrenamos una red neuronal que aprendería del mercado y haría predicciones. Los resultados mostraron una precisión muy alta.",

        'about:live_bot_release': "Lanzamiento del bot en vivo",
        'about:live_bot_release_desc': "Construimos y entrenamos una red neuronal que aprendería del mercado y haría predicciones. Los resultados mostraron una precisión muy alta.",

        'about:our_transparency_value': "Nuestro valor de transparencia",
        'about:our_transparency_value_desc': "Las pruebas retrospectivas en vivo y los rendimientos en vivo se hicieron públicos. Queremos que la transparencia sea un diferenciador clave entre nosotros y otras empresas que no muestran evidencia de sus resultados.",

        'about:infrastructure_revolution': "Revolución de infraestructura",
        'about:infrastructure_revolution_desc': "2023 fue un año exitoso ya que más traders adoptaron nuestra plataforma. Aumentamos nuestro compromiso con los traders, esforzándonos por entender lo que todos realmente necesitaban en una plataforma de bots. Dado este creciente número de usuarios, reconocimos la importancia de hacer que DipSway fuera a prueba de fallos a nivel de ingeniería. Antes de introducir emocionantes nuevas actualizaciones, características, herramientas y bots, priorizamos asegurar que nuestros sistemas funcionaran 24/7 sin problemas y que el rendimiento y la calidad fueran de primera clase.",

        'about:new_exchanges': "Nuevos exchanges",
        'about:new_exchanges_desc': "En febrero, expandimos significativamente nuestra plataforma integrando grandes exchanges como Coinbase Advanced, BingX y Bitmart. También nos asociamos con KuCoin y Bybit, convirtiéndonos en una aplicación oficial de terceros en ambos. Estas adiciones y asociaciones aumentaron enormemente las opciones de trading y el acceso al mercado de nuestros usuarios, validando la fiabilidad de nuestra plataforma y marcando un hito importante en nuestro crecimiento.",

        'about:rotating_proxy': "Proxy rotativo y nuevas características destacadas",
        'about:rotating_proxy_desc': "En una mejora significativa de nuestra infraestructura, implementamos 20 IPs adicionales y expandimos nuestras réplicas de base de datos de 2 a 7, mejorando la capacidad de respuesta de los bots a nuevas velas en los exchanges. Esta mejora posiciona a nuestros bots de manera competitiva en el mercado en cuanto a velocidad y fiabilidad. También integramos ﻿Crypto(.)com, ampliando aún más nuestros exchanges compatibles y opciones de trading para los usuarios. Una nueva característica notable es el Leaderboard de Bots, que permite a los usuarios ver el rendimiento de los bots y copiar la configuración de los bots con mejor rendimiento. Estas mejoras fortalecen colectivamente las capacidades de nuestra plataforma, ofreciendo a los usuarios oportunidades de trading más robustas, eficientes y diversas.",

        'about:multiple_quote_currencies': "Múltiples monedas de cotización",
        'about:multiple_quote_currencies_desc': "Hemos introducido soporte para múltiples monedas, permitiendo a los bots operar con varios pares de cotización más allá de USDT. Esta mejora ofrece a los usuarios mayor flexibilidad, acomodando diferentes estructuras de exchange y regulaciones geográficas. Al permitir el trading en múltiples monedas, hemos capacitado a los usuarios para optimizar sus estrategias de acuerdo con necesidades específicas y condiciones de mercado, mejorando su experiencia general de trading.",

        'about:new_admin_dashboard': "Nuevo panel de administración",
        'about:new_admin_dashboard_desc': "El lanzamiento de nuestra nueva plataforma marca un salto significativo en la experiencia del usuario y la funcionalidad. Permite un desarrollo y entrega más rápidos de nuevas características, dándonos mayor control sobre la resolución de problemas de los bots para garantizar un soporte de nivel elite. Hemos fortalecido la comunicación entre la interfaz y el backend, garantizando la precisión de los datos y una funcionalidad confiable. Mientras mantenemos nuestro compromiso con un diseño minimalista, limpio y fácil de navegar, también enriquecimos la plataforma con datos y análisis completos. Este equilibrio empodera a los usuarios con información detallada sin comprometer la simplicidad, permitiendo una toma de decisiones más informada en un entorno amigable para el usuario.",

        'about:autopilot_tradingview': "Piloto automático y automatización de señales de TradingView",
        'about:autopilot_tradingview_desc': "El Bot de Piloto Automático, nuestra revolucionaria solución de trading impulsada por IA. Esta mejora de configuración cero para nuestros bots de IA aprovecha datos históricos, computación de redes neuronales y datos EN VIVO de todos los bots de DipSway para optimizar continuamente las estrategias de trading. Selecciona automáticamente los activos de mejor rendimiento y ajusta las configuraciones basándose en parámetros de mercado en tiempo real, ofreciendo a los usuarios una experiencia de trading sin intervención y basada en datos que se adapta instantáneamente a las condiciones del mercado.",

        'about:ai_futures_bot': "Bot de futuros con IA",
        'about:ai_futures_bot_desc': "Con la introducción del sistema interno de SL y TP (Las operaciones de seguimiento estarán disponibles pronto), todos los usuarios ahora tienen una tasa de éxito del 100% en sus configuraciones personalizadas para su bot. El sistema interno nos permitió lanzar la primera generación de un auténtico bot de futuros con IA.",

        'about:new_features': "Nuevas características",
        'about:new_features_desc': "Estamos trabajando en una nueva característica que permitirá a los usuarios crear sus propias estrategias y compartirlas con la comunidad.",

        'proven_to_perform': 'Probado para rendir',

        'see_how_dipsway_averages': 'Mira cómo los usuarios de DipSway están promediando usando el poder de los bots de DipSway.',
        'last': 'Últimos',
        'months_compound_roi': 'Meses de ROI compuesto',
        'total_return_based_on_last': 'Retorno total basado en los últimos',
        'show_as_multiplier_values': 'Mostrar como valores multiplicadores',
        'show_as_percentage_values': 'Mostrar como valores porcentuales',
        'join': 'Unirse',
        'stars': 'Estrellas',

        'faq': 'Preguntas frecuentes',
        'faq_desc': 'Encuentra tus respuestas aquí. Si tienes una pregunta que no está cubierta en las FAQ,\n' +
            'no dudes en contactarnos.',
        'faq_sub_desc': '¿No encontraste la respuesta que buscabas?',
        'contact_support': 'Contactar soporte',

        'view_supported_platform': 'Ver plataformas compatibles',

        'simulate_based_on_past_performances': 'Simular basado en rendimiento pasado',
        'calculate_your_earnings_desc': 'Análisis de rentabilidad completo basado en datos históricos de DipSway, teniendo en cuenta factores como las tarifas de intercambio, el rendimiento de las monedas y simulando cómo los bots de DipSway operarían con tu tamaño de capital.',

        'go_to_tweet': 'Ir al tweet',
        'view_on': 'Ver en',

        'security': 'Seguridad',
        'safe_and_secure': 'Seguro y protegido',
        'safe_and_secure_desc': 'Tus fondos permanecen en tu exchange, y solo solicitamos\n' +
            'permisos mínimos para ejecutar operaciones por ti.',

        'follow_dipsway_development': 'Sigue el desarrollo de DipSway y conoce a la comunidad',
        'join_us_on_telegram': 'Únete a nosotros en Telegram',
        'here_when_you_need_help': 'Aquí cuando necesitas ayuda',
        'dipsway_customer_care': 'Atención al cliente de DipSway',

        'for_us_top_10_percent': 'Para nosotros, el top 10% es el estándar para los usuarios de DipSway. Eres libre de\n' +
            'experimentar con diferentes configuraciones, pero cuando necesites ayuda\n' +
            'para optimizar, estaremos ahí.',

        'join_the_telegram_community': 'Únete a la comunidad de Telegram',

        'made': 'hizo',
        'profit_on': 'de beneficio en',
        'a_single_trade': 'una sola operación',

        'dont_go_back_to_manual_trading': 'No vuelvas al trading manual,',

        'get_in_before_the_pump': 'entra antes del bombeo',
        'get_out_before_the_drop': 'sal antes de la caída',

        'on_autopilot': 'En piloto automático',

        'start_your_dipsway_bot': 'Inicia tu bot de DipSway en 4 clics',

        'safe_for_you': 'Seguro para ti, por diseño',
        'safe_for_you_desc': 'Con DipSway tus fondos están seguros en tu exchange de criptomonedas, y te pedimos\n' +
            'que confíes en nosotros solo lo necesario para que los bots funcionen.',

        'ip_whitelisting': 'Lista blanca de IP',
        'ip_whitelisting_desc': 'Solo las direcciones IP controladas por DipSway pueden operar en tu cuenta, incluso si tu clave API ha sido robada, los actores maliciosos no pueden ejecutar operaciones.',

        'restricted_access': 'Acceso restringido',
        'restricted_access_desc': 'DipSway solo solicita los permisos más simples para monitorear y ejecutar\n' +
            'operaciones en tu nombre.',

        'bright_green_performances': 'Rendimientos verde brillante',
        'on_capital': 'Sobre capital',
        'win_rate': 'tasa de éxito',
        'average_trade': 'operación promedio',

        'see_dipsway_live_backtests': 'Ver pruebas retrospectivas en vivo y rendimiento de DipSway',
        'find_and_download': 'Encuentra y descarga datos de rendimiento histórico y pruebas retrospectivas en vivo.',

        'available_at_no_extra_cost': 'Disponible sin costo adicional',

        'full_autopilot_mode': 'Modo piloto automático completo',
        'full_autopilot_mode_desc': 'Con el Piloto Automático de DipSway, no solo tus operaciones son automatizadas, sino que\n' +
            'DipSway también elige la mejor criptomoneda para operar en ese momento\n' +
            'basándose en las condiciones del mercado.',

        'calculate_your_earnings_with_autopilot': 'Calcula tus ganancias con Piloto Automático',
        'high_performance': 'Alto rendimiento',
        'live_backtests_historical_performance': 'Pruebas retrospectivas en vivo, rendimiento histórico.',
        'backtests_performance': 'Pruebas retrospectivas y rendimiento',
        'extendable_via_tradingview': 'Extensible a través de TradingView',
        'use_power_tradingview': 'Usa el poder de TradingView para configurar DipSway aún más.',
        'coming_soon': 'Próximamente...',
        '3_green_trades_in_30_days': '3 operaciones en verde en 30 días o te devolvemos el dinero',
        'need_help_choosing': '¿Necesitas ayuda para elegir?',
        'use_dipsway_earnings_calculator': 'Usa la calculadora de ganancias de DipSway, aprende cuánto puedes ganar y obtén una recomendación de plan.',
        'launch_earnings_calculator': 'Lanzar calculadora de ganancias',
        'popular': 'Popular',
        'recommended_for_you': 'Recomendado para ti',
        'no_deposit_required': 'No se requiere depósito',
        'top_10_of_users': "Top 10% de usuarios",
        'all_users': "Todos los usuarios",
        'downtrend_page:desc1': 'Operación: Salida de emergencia de posición. Tendencia bajista identificada.',
        'downtrend_page:sell_order_fulfilled': 'Orden de venta de 12 BTC cumplida.',
        'performance_history': 'Historial de rendimiento',
        'trained_to_recognize_opportunities': 'Entrenado para reconocer oportunidades',
        'live_backtests_performances': 'Rendimientos de pruebas retrospectivas en vivo',
        'see_how_our_strategies_performed': 'Mira cómo han rendido nuestras estrategias en el pasado con pruebas retrospectivas en vivo de alta calidad.',
        'backtests_from': 'Pruebas retrospectivas de los',
        'past_6_months': 'últimos 6 meses',
        'collapse': 'Colapsar',
        'expand': 'Expandir',
        'view_report': 'Ver informe',
        'knowledge:title1': 'Datos históricos de alta calidad',
        'knowledge:description1': 'Nos aseguramos de que las velas en vivo que se utilizan sean de la más alta calidad.',
        'knowledge:title2': 'Tarifas y retrasos incluidos',
        'knowledge:description2': 'Se aplican tarifas de maker y taker del 0.1% a cada operación. Se aplica un deslizamiento del 0.1% y un retraso de unos minutos a cada operación.',
        'knowledge:title3': 'Evitación de sobreajuste',
        'knowledge:description3': 'DipSway monitorea pasivamente las cuentas con mal rendimiento y recomienda acciones.',
        'operation': 'Operación',
        'buy_the_dip': 'Comprar en la caída',
        'sell_the_high': 'Vender en el máximo',
        'bought': 'Comprado',
        'watching_market': 'observando el mercado',
        'sell_order_for': 'Orden de venta por',
        'fulfilled': 'cumplida',
        'profit_made': 'Beneficio obtenido',
        'calculation_results': 'Resultados del cálculo',
        'calculating': 'Calculando',
        'total_expected_profit_at_the_end_of': 'Beneficio total esperado al final del',
        'period_based_off_of_historical_backtest_performances': 'período basado en rendimientos históricos de pruebas retrospectivas',
        'trades': 'operaciones',
        'day': 'día',
        'profit': 'beneficio',
        'recommended_plan_for_you': 'Plan recomendado para ti',
        'go_to': 'Ir a',
        'free_trial_available': 'Prueba gratuita disponible',
        'runs_at': 'Se ejecuta a',
        'learn_how_we_calculate_your_earnings': 'Aprende cómo calculamos tus ganancias',
        'click_to_open_deepdive': 'Haz clic para abrir el análisis detallado donde puedes elegir entre otros exchanges que admitimos.',
        'your_capital': 'Tu capital',
        'currency': 'Moneda',
        'enabled': 'Habilitado',
        'set_to': 'establecido en',
        'coin_count': 'cantidad de monedas',
        'disabled': 'Deshabilitado',
        'auto': 'Auto',
        'selected_coins': 'Monedas seleccionadas',
        'loading_coins': 'Cargando monedas',
        'login': 'Login',
        'trading_with': 'Operando con',
    },

    ko: {

        'all_payment_types': '모든 결제 유형',
        'all_payment_types_description': 'Bitcoin, Ethereum 등을 통한 거래를 경험해보세요. Cryptomus 게이트웨이를 통해 안전하게 지불하여 DipSway의 기능에 즉시 액세스하세요.',
        'extra': '추가',

        'no-token:footer.disclaimer': 'DipSway는 어떤 블록체인에서도 DeFi 토큰이나 암호화폐를 판매, 제공, 촉진하거나 접근을 제공하지 않습니다. 우리는 오직 소프트웨어 제공업체일 뿐입니다.',

        "different_ways_to_swap": "교환하는 다양한 방법",

        'coin_swap_description': '코인 스왑 경로 파인더로 {crypto}에서 {quote}로의 거래를 최적화하세요. 다양한 {crypto}/{quote} 전환 경로를 즉시 비교하고, 복잡한 스왑을 시각화하며, {crypto}에서 {quote}로의 전환에 대한 최고의 환율을 확보하세요. {crypto}에서 {quote}로의 거래를 극대화하려는 초보자와 전문 암호화폐 트레이더 모두에게 완벽합니다.',

        "current_price_info": "현재 {crypto} 가격은 {price}이며, 이는 지난 24시간 동안 {change24h}, 지난 7일 동안 {change7d}의 변동을 보였습니다.",
        "circulating_supply_info": "유통량이 21M인 상태에서 {crypto}의 실시간 시가총액은 {marketCap}입니다.",
        "trading_volume_info": "24시간 거래량은 {volume}입니다.",
        "conversion_rate_calculation": "환율은 {crypto} 실시간 가격 지수와 다른 디지털 자산 가격 지수를 사용하여 계산됩니다.",
        "exchange_rate_update": "{crypto}/{quote} 환율은 실시간으로 업데이트됩니다.",
        "follow_dipsway_info": "DipSway의 암호화폐 변환기와 계산기를 통해 {crypto}, {currencies} 등을 포함한 암호화폐와 법정화폐 간의 환율을 24/7 실시간으로 업데이트받으세요.",

        "description_calculator_pt_1": "이 계산기는 다음에 대한 환율을 제공합니다:",
        "description_calculator_pt_2": "에서",
        "description_calculator_pt_3": "환율은 지난 24시간 동안의 시장 환율을 기반으로 합니다.",
        "to": "에서",
        "converter": "변환기",
        "crypto_prices_and_market_data": "암호화폐 가격 및 시장 데이터",
        "converter_site:desc": "사용하기 쉬운 변환 도구로 암호화폐와 법정화폐 간 변환이 가능합니다. 거래에 필요한 최신 환율과 시장 데이터를 확인하세요.",
        "callout_calculator": "AI 기반 암호화폐 트레이딩 봇",
        "info_on_price": "가격 정보",
        "live_data": "실시간 데이터",

        "prices_of": "가격",
        "on_different_exchanges": "다양한 암호화폐 거래소에서",
        "converted_to": "변환됨",
        "on_different_amounts": "다양한 금액에 대해",
        "up_of": "상승",
        "dropped_of": "하락",
        "respect_yesterday": "어제 대비",
        "close_price": "종가",
        "price_variation": "가격 변동",
        "vwap": "평균 가격",
        "volume_last_24h": "24시간 거래량",

        "exchange": "거래소",
        "price": "가격",
        "volume": "거래량",
        "quote_volume": "호가 거래량",
        "percentage": "백분율",

        "asset": "자산",
        "today_at": "오늘",

        "calculator_title": "{crypto}에서 {quote}로 계산기",
        "crypto_label": "암호화폐",
        "currency_label": "호가",
        "enter_amount": "금액을 입력하세요",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "{crypto} 거래하기",

        'search_for_crypto': '암호화폐 검색',
        'crypto_prices_title': '지원되는 암호화폐 가격 지난 24시간 변동',
        'see_current_crypto_market_trend': '현재 암호화폐 시장 트렌드를 확인하세요.',

        'see_more': '더보기',
        'dipsway': 'DipSway',
        'home_page': '홈페이지',
        'blogs.related_posts': '관련 게시물',
        'blogs.automate_your_trading': 'DipSway AI로 오늘 당신의 거래를 자동화하세요.',
        'blogs.automate_your_trading_description': 'DipSway는 121개 이상의 기술적 지표와 17개 이상의 패턴 감지기를 조합하여 당신에게 불공정한 이점을 제공합니다.',
        'btn.start_7_day_free_trial': '7일 무료 체험 시작하기',
        'blogs.tags': '태그',
        'blogs.description_site': '암호화폐 튜토리얼, 기능 출시, 제품 비교 등! DipSway 블로그에서 DipSway 팀의 컨텐츠를 찾아보세요.',
        'blogs.title_site': 'DipSway 블로그',
        'blogs.callout': '버전 4.13 소개',
        'blogs.description_extended': '튜토리얼, 기능 출시, 제품 비교 등!\nDipSway 블로그에서 DipSway 팀의 컨텐츠를 찾아보세요.',
        'blogs.go_to_article': '기사로 이동',
        'skip_to_content': '컨텐츠로 건너뛰기',
        'calculate_your_earnings_and_start_free_trial': '수익을 계산하고 7일 무료 체험 시작하기',

        'privacy_policy': '개인정보 처리방침',
        'terms_of_service': '서비스 이용약관',
        'refund_policy': '환불 정책',
        'affiliate_policy': '제휴 정책',
        'blog': '블로그',
        'supported_exchanges': '지원되는 거래소',
        'pricing': '가격',
        'resources': '리소스',
        'company': '회사',
        'support': '지원',
        'affiliate_program': '제휴 프로그램',
        'about_us': '회사 소개',
        'customer_success_agent': '고객 성공 에이전트',
        'dipsway_ai_optimization': 'DipSway AI 최적화',
        'live_back_tests': '실시간 백테스트',
        'live_back_tests_performances': 'DipSway 실시간 백테스트 & 성과',
        'historical_performance_data': '과거 성과 데이터',
        'dipsway_earning_calculator': 'DipSway 수익 계산기',
        'performance': '성과',
        'common:footer.disclaimer': 'DipSway는 소프트웨어만 제공합니다. 거래, 교환, 이체 또는 지갑 서비스 등에 대한 언급은 제3자 서비스 제공업체가 제공하는 서비스에 대한 언급입니다.',

        'index:hero.title': 'DipSway - 설정이 필요 없는 완전 자동 AI 암호화폐 거래 봇',
        'index:hero.description': '우리의 AI 봇으로 원활한 암호화폐 거래를 경험하세요. 간단한 인터페이스에서 24/7 이용 가능한 100% 자동화된 거래를 위한 암호화폐 거래 AI 봇을 시작하세요.',

        'header:product': '제품',
        'header:product.feature.header': 'DipSway를 사용하여 당신의 자본은 얼마나 벌 수 있을까요?',
        'header:product.feature.description': 'DipSway의 백테스트 기록을 기반으로 수익을 계산해보세요.',
        'header:product.feature.cta': '수익 계산하기',

        'header:product.links.spot_ai_trading_bot': '현물 AI 거래 봇',
        'header:learn': '학습',
        'header:learn.links.ai_trading_optimization': 'AI + 암호화폐 거래',

        'welcome:headline': '설정이 필요 없는 완전 자동 AI 암호화폐 거래 봇',
        'welcome:subheadline': '거래소에 연결하고 자동 조종을 켜면 끝입니다.',
        'start_7_days_no_credit_card': '7일 무료 체험 시작하기 - 예치금 필요 없음.',

        '404:title_site': '404 - DipSway | AI 기반 암호화폐 거래 봇',
        '404:description_site': '우리의 AI 봇으로 암호화폐 거래로 돌아가세요. 간단한 인터페이스에서 24/7 이용 가능한 100% 자동화된 거래를 위한 암호화폐 거래 AI 봇을 시작하세요.',
        '404:page_not_found': '페이지를 찾을 수 없습니다',
        '404:page_not_found_message': '깨진 링크를 발견하셨군요! 어떤 페이지에서 이곳으로 오셨는지\nDipSway 지원팀에 알려주세요!',
        '404:go_home': '홈으로 가기',
        '404:open_dashboard': '대시보드 열기',

        'affiliate_program_policy:title_site': "DipSway 제휴 프로그램 정책",
        'affiliate_program_policy:description_site': "DipSway 제휴 프로그램 정책",

        'crypto_bot_calculator:title_site': "암호화폐 거래 봇 수익 계산기",
        'crypto_bot_calculator:description_site': "거래소 수수료, 코인 성과 등을 고려하고 DipSway 봇이 당신의 자본 규모로 어떻게 거래할지 시뮬레이션하여 DipSway의 과거 데이터를 기반으로 한 완전한 수익성 분석.",
        'crypto_bot_calculator:label': '암호화폐 거래 봇 수익 계산기',
        'crypto_bot_calculator:simulate_based_on': '과거 성과를 기반으로 시뮬레이션',
        'crypto_bot_calculator:calculate_your_earnings': '수익 계산하기',
        'crypto_bot_calculator:description': '거래소 수수료, 코인 성과 등을 고려하고 DipSway 봇이 당신의 자본 규모로 어떻게 거래할지 시뮬레이션하여 DipSway의 과거 데이터를 기반으로 한 완전한 수익성 분석.',

        'featured_on': '이곳에 소개되었습니다',
        '4_clicks_to_start': '4번의 클릭으로 시작',
        'no_knowledge_required': '설정 필요 없음, 지식 필요 없음',
        'split_content_description': 'DipSway는 정말로 설정이 필요 없습니다. 거래소에 연결 → 봇 켜기 →\n' +
            '      성과 모니터링. 최고의 성과 구성이\n' +
            '      기본적으로 적용됩니다.',
        'learn_about_dipsway_ai': 'DipSway AI에 대해 알아보기',

        'trained_for_down_trends': '하락세 감지를 위해 훈련됨',
        'be_safe_on_down_trends': '하락세에서도 안전하게',
        'autopilot_content_headline_1': '펌프 전에 진입하고, 하락 전에 나가세요,',
        'autopilot_content_headline_2': '자동 조종으로',
        'autopilot_description': 'DipSway는 당신이 일상적인 업무로 바쁜 동안 시장을 분석하고 거래를 실행하며,\n' +
            '      상태 업데이트로 당신에게 계속 정보를 제공합니다.',
        'downtrends_description': 'DipSway의 AI는 하락세를 식별하도록 훈련되어 있으며 상황이 통제를 벗어나기 전에\n' +
            '      당신을 빼낼 것입니다',

        'see_how_dipsway_works': 'DipSway가 어떻게 작동하는지 보기',

        'free_trial_action:label': '준비되면 시작하세요.',

        'dipsway_ai': 'DipSway AI',

        'safety': '안전',
        'safety_description': '당신의 자금을 거래소에 안전하게 보관하세요, 당신의 안전이 우리의 우선순위입니다.',

        'ai_trading_optimization_description': '기회를 인식하도록 훈련된 우리의 독점 AI 모델.',

        '3months': '3개월',
        'total_profits_within_label': '지난 기간 동안 모든 배포된 DipSway 봇의 총 수익',

        'dipsway_uses_combination_of': 'DipSway는 다음의 조합을 사용합니다',
        'and': '그리고',
        'technical_indicators': '기술적 지표',
        'pattern_detectors': '패턴 감지기',
        'detect_next_move': '시장의 다음 움직임을 감지하기 위해.',

        'are_you_influencer': '인플루언서이신가요? 우리와 파트너십을 맺어보세요',
        'influencer_description': '우리와 협력하는 인플루언서들은 자신의 사용자 기반의 관심사에 맞춘\n' +
            '      맞춤형 온보딩 랜딩 페이지를 설정받습니다.',

        'get_in_touch': '연락하기',

        'spot_ai_bot:title_site': '현물 AI 암호화폐 거래 봇 | 100% 자동화된 거래',
        'spot_ai_bot:description_site': '암호화폐 거래 봇을 켜고 100% 자동화된 거래를 즐기세요. 오늘 24/7 실행되는 봇을 시작하세요. 편리함을 즐기고 수익을 극대화하세요.',

        'spot_ai_bot:24_7_automated_trading': '24/7 자동화된 거래, 거의 즉각적인 거래 실행',
        'spot_ai_bot:algo_crypto_by_ml': '머신러닝이 주도하는 AI 알고리즘 암호화폐 거래',

        'spot_ai_bot:desc': '업계 최고의 거래 봇으로, AI를 사용하여 시장을 분석하고\n' +
            '기계와 같은 속도로 거래 결정을 내립니다.',

        'million': '백만',

        'avg_monthly_volume_moved_by_bots': 'DipSway 봇이 이동시키는 월평균 거래량.',
        'dipsway_has_more_than_350_users': 'DipSway는 전 세계적으로 350명 이상의 사용자를 보유하고 있습니다.',
        'dipsway_supports_315_coins': 'DipSway는 315개 이상의 통화에 대한 거래를 지원합니다.',

        'ai_powered': "AI 기반",
        'trading_strategies_powered_by_ai': "거래 전략은 기술적 분석, 기본적 분석의 조합을 사용하는 AI에 의해 구동됩니다.",

        'geolocation_advantage': "지리적 위치 이점",
        'geolocation_advantage_desc': "봇을 호스팅하는 서버는 거래소와 같은 지리적 위치에 있어 가능한 가장 낮은 지연 시간을 보장합니다 (평균 ~932μs 지연).",

        'high_frequency_trading': '고빈도 거래',
        'high_frequency_trading_desc': '다중 테넌시 아키텍처 덕분에 거래 전략은 1초 단위의 캔들스틱 세분성으로 실행됩니다.',

        'advanced_risk_management': '고급 위험 관리',
        'advanced_risk_management_desc': '봇은 여러 통화의 투자를 관리하고 포트폴리오의 위험을 자동으로 헤지할 수 있습니다.',

        'optimization_overfitting_avoidance': '최적화 & 과적합 방지',
        'optimization_overfitting_avoidance_desc': '봇은 AI-유전 알고리즘과 맞춤형 Walk-Forward 분석을 사용하여 거래 전략의 매개변수를 최적화하고 과적합을 방지할 수 있습니다.',

        'backtesting': '백테스팅',
        'backtesting_desc': '매 24시간마다 모든 거래 전략에 대한 백테스트를 실행하고, "온라인 학습" 접근 방식을 사용하여 AI가 사용하는 가중치를 재계산합니다.',
        'how_do_dipsways_strategies_work': "DipSway의 전략은 어떻게 작동하나요?",
        'no_one_wants_to_invest_in_black_box': "누구도 블랙박스에 투자하고 싶어 하지 않습니다. 그래서 우리는 DipSway의 AI 봇의 뒤에서 일어나는 일을 쉽게 따라갈 수 있는 방법을 만들었습니다 - DipSway는 112개 이상의 지표와 전략을 병합하여 빠르게 변화하는 암호화폐 세계에서 당신에게 이점을 제공합니다.",
        'learn_more_about_dipsway_ai': 'DipSway AI에 대해 더 알아보기',

        'autopilot_buys_sharp_as_knife': '칼날처럼 날카로운 자동 구매',
        'autopilot_buys_sharp_as_knife_desc': '대부분의 암호화폐 시장은 횡보하며 큰 가격 변동은 오래 지속되지 않습니다. 머신 러닝 결정 프로세스 덕분에, AI 봇은 횡보 시장에서 지속적으로 수익을 내고 큰 하락이 발생할 때 이를 활용할 수 있는 날카로운 매수 주문을 실행하도록 설계되었습니다.',
        'other_strategies_dont_work': '다른 전략들은 정적이고 시장 변화에 따라 진화할 수 없기 때문에 작동하지 않습니다.',

        'autopilot_sells_and_youre_in_profit': '자동 판매로 당신은 이익을 얻습니다.',

        'dont_let_downturns_catch_you_off_guard': '하락세에 방심하지 마세요.',
        'dont_let_downturns_catch_you_off_guard_desc': '121개 이상의 지표와 필요한 모든 메트릭을 하나의 플랫폼에서.',

        'your_bot_runs_in_cloud': '당신의 봇은 클라우드에서 24/7 실행됩니다.',
        'your_bot_runs_in_cloud_desc': '우리의 봇은 24/7 실행되는 클라우드 기반 거래 봇이므로 오프라인 걱정을 할 필요가 없습니다! 항상 시장에 연결되어 있으며, 매수와 매도의 최적의 타이밍을 분석합니다.\n',

        'unfair_advantage': '당신에게 불공정한 이점을 제공하기 위해.',
        'and_many_more': '그리고 더 많은 것들...',

        'pricing:title_site': 'DipSway 가격',
        'pricing:description_site': "저렴한 암호화폐 거래 AI 봇 | 오늘 당신의 봇을 시작하세요 | 24/7 자동화된 거래를 즐기세요",
        'pricing:supercharge_text': 'DipSway AI로 당신의 거래를 가속화하세요',

        'performance:title_site': 'DipSway 성과 기록 & 백테스트',
        'performance:description_site': "우리의 암호화폐 거래 AI 봇으로 실시간 백테스트를 경험해보세요. 오늘 24/7 실행되는 봇을 시작하세요. 편리함을 즐기고 수익을 극대화하세요.",
        'performance:label': "DipSway 성과 기록 & 백테스트",

        'connect_to_exchange': '거래소에 연결하고 자동 조종을 켜면 끝입니다.',
        'calculate_your_earnings': '수익 계산하기',

        'trading_optimization:title_site': "DipSway AI 거래 최적화",
        'trading_optimization:description_site': "우리의 암호화폐 거래 AI 봇으로 AI 거래 최적화를 경험해보세요. 오늘 24/7 실행되는 봇을 시작하세요. 편리함을 즐기고 수익을 극대화하세요.",
        'trading_optimization:label': "AI 거래 최적화",
        'trading_optimization:breakdown': '분석: DipSway의 AI 암호화폐 거래',

        'trading_optimization:desc': 'DipSway의 강력한 AI 봇은 빠른 시장 변화에 동적으로 적응합니다.',
        'trading_optimization:steps': '이 모든 것은 일련의 단계를 통해 이루어집니다:',

        'behind_scenes:behind_the_scenes': '무대 뒤에서는 무엇이 일어나나요?',

        'behind_scenes:indicators': '일부 지표는 잠재적인 매수 신호(녹색)를 보여주는 반면, 다른 지표는 잠재적인 매도 신호(회색)를 보여줍니다.',
        'behind_scenes:neural_network': 'DipSway 신경망은 이 모든 지표를 고려하여 전체적인 그림을 기반으로 결정을 내리고, 단일 매수 또는 매도 신호를 생성합니다.',

        'affiliate_program:title_site': '암호화폐 봇 제휴 프로그램',
        'affiliate_program:description_site': "우리의 제휴 프로그램에 참여하세요. 친구 초대로 30%의 반복 수수료를 즐기세요.",
        'affiliate_program:label': "제휴 프로그램",
        'affiliate_program:desc': "모든 추천에 대해 30%의 반복 수수료를 받으세요",
        'affiliate_program:desc2': "거래소에 연결하고 자동 조종을 켜면 끝입니다.",
        'affiliate_program:get_your_affiliate_link': '제휴 링크 받기',

        'conference': '컨퍼런스',

        'about:title_site': "DipSway 소개",
        'about:our_journey': '우리의 여정',
        'about:our_journey_desc': '모든 수준의 트레이더를 위해 투자 지갑을 성장시키고 일일 성과를 향상시키는\n' +
            '고품질, 수익성 있는 AI 암호화폐 거래 봇을 제공하는 것.',
        'about:just_getting_started': '그리고 우리는 이제 막 시작했습니다.',

        'about:description_site': "우리의 암호화폐 거래 AI 봇으로 AI 거래 최적화를 경험해보세요. 오늘 24/7 실행되는 봇을 시작하세요. 편리함을 즐기고 수익을 극대화하세요.",
        'about:label': '소개',
        'about:behind_scenes': '무대 뒤',
        'founder': '창립자',

        'about:founder1_msg': 'DipSway는 최첨단 AI를 통해 암호화폐 거래를 혁신하려는 비전에서 탄생했습니다. 소프트웨어 엔지니어이자 암호화폐 열정가로서, 우리는 정말로 혁신적인 무언가를 만들 기회를 보았습니다 - 프로 트레이더처럼 생각하지만 초인적인 속도로 작동하는 거래 봇입니다.\n우리의 강력한 기술 전문성 덕분에, 우리의 돌파구는 미리 설정된 규칙을 따르는 것이 아니라 시장 역학에 실시간으로 적응하는 AI를 개발했을 때 왔습니다.',
        'about:founder2_msg': '이것은 평범한 거래 봇이 아닙니다; 이는 방대한 양의 데이터를 분석하고, 복잡한 패턴을 인식하며, 놀라운 정확도로 순식간에 결정을 내리는 정교한 시스템입니다.\nDipSway를 차별화하는 것은 AI 학습에 대한 우리의 독특한 접근 방식입니다. 이는 24/7로 당신을 위해 일하는 전문 트레이더 팀을 갖는 것과 같지만, 더 빠르고 더 효율적입니다.\n우리의 미션은 경험 많은 전문가부터 호기심 많은 초보자까지 모든 사람이 고급 암호화폐 거래를 접할 수 있게 하는 것입니다.',

        'about:join_team': 'DipSway 팀에 합류하세요',
        'about:join_team_desc': '우리는 금융, 거래, 기술에 열정적인 암호화폐 열정가를 찾고 있습니다.\n' +
            '세계가 암호화폐에 투자하는 방식을 바꿀 준비가 되셨나요?',

        'read_post': '게시물 읽기',

        'about:development_starts': "개발 시작",
        'about:development_starts_desc': "어느 날 우리는 Coinbase 모바일 앱에서 '비트코인이 오늘 5% 상승했습니다'와 같은 비트코인의 최근 가격에 대한 알림을 받고 있었습니다. 우리는 이런 알림이 오기 전에 작동하는 봇이 있으면 좋겠다고 생각했습니다. 웹을 검색했지만 사용하기 쉽고 클라우드에서 실행되는 것을 찾을 수 없었습니다. 그래서 우리가 직접 만들기로 결정했습니다.",

        'about:first_bot_version': "첫 번째 봇 버전",
        'about:first_bot_version_desc': "비트코인과 이더리움에 대한 알고리즘 거래 전략의 첫 시뮬레이션, 우리는 더 많은 암호화폐 자산과 더 많은 전략을 포함하기 시작했습니다. 우리는 봇의 잠재력을 보기 시작했고 이를 공개하기로 결정했습니다.",

        'about:simple_strategy_not_enough': '"단순한 전략"으로는 충분하지 않았습니다',
        'about:simple_strategy_not_enough_desc': "우리는 시장에서 학습하고 예측을 만들 수 있는 신경망을 구축하고 훈련시켰습니다. 결과는 매우 높은 정확도를 보여주었습니다.",

        'about:live_bot_release': "라이브 봇 출시",
        'about:live_bot_release_desc': "우리는 시장에서 학습하고 예측을 만들 수 있는 신경망을 구축하고 훈련시켰습니다. 결과는 매우 높은 정확도를 보여주었습니다.",

        'about:our_transparency_value': "우리의 투명성 가치",
        'about:our_transparency_value_desc': "실시간 백테스트와 실시간 성과가 대중에게 공개되었습니다. 우리는 투명성이 결과에 대한 증거를 보여주지 않는 다른 회사들과 우리를 구별하는 핵심 차별화 요소가 되기를 원합니다.",

        'about:infrastructure_revolution': "인프라 혁명",
        'about:infrastructure_revolution_desc': "2023년은 더 많은 트레이더들이 우리 플랫폼을 채택하면서 성공적인 해였습니다. 우리는 트레이더들과의 소통을 늘리며 모든 사람이 봇 플랫폼에서 진정으로 필요로 하는 것이 무엇인지 이해하려 노력했습니다. 이렇게 늘어나는 사용자 기반을 고려할 때, 우리는 DipSway를 엔지니어링 수준에서 오류 방지 시스템으로 만드는 것의 중요성을 인식했습니다. 흥미진진한 새로운 업데이트, 기능, 도구, 봇을 소개하기 전에, 우리는 시스템이 문제 없이 24/7 실행되고 성능과 품질이 최고 수준임을 보장하는 것을 우선시했습니다.",

        'about:new_exchanges': "새로운 거래소",

        'about:new_exchanges_desc': "2월에 우리는 Coinbase Advanced, BingX, Bitmart와 같은 주요 거래소를 통합함으로써 플랫폼을 크게 확장했습니다. 또한 KuCoin 및 Bybit와 제휴를 맺어 두 거래소의 공식 제3자 애플리케이션이 되었습니다. 이러한 추가와 제휴는 사용자의 거래 옵션과 시장 접근성을 크게 증가시켰고, 우리 플랫폼의 신뢰성을 입증하며 성장의 주요 이정표를 세웠습니다.",

        'about:rotating_proxy': "회전 프록시 및 새로운 핵심 기능",
        'about:rotating_proxy_desc': "우리의 인프라를 크게 업그레이드하여 20개의 추가 IP를 구현하고 데이터베이스 복제본을 2개에서 7개로 확장했으며, 이를 통해 거래소 전반에 걸쳐 새로운 캔들에 대한 봇의 응답성을 향상시켰습니다. 이러한 개선으로 우리 봇은 속도와 신뢰성 면에서 시장에서 경쟁력 있는 위치를 차지하게 되었습니다. 또한 ﻿Crypto(.)com을 통합하여 지원되는 거래소와 사용자의 거래 옵션을 더욱 확장했습니다. 주목할 만한 새로운 기능으로는 봇 리더보드가 있어, 사용자들이 봇 성과를 확인하고 최고 성과를 내는 봇 설정을 복사할 수 있게 되었습니다. 이러한 개선사항들은 전체적으로 우리 플랫폼의 역량을 강화하여 사용자에게 더욱 강력하고 효율적이며 다양한 거래 기회를 제공합니다.",

        'about:multiple_quote_currencies': "다중 견적 통화",
        'about:multiple_quote_currencies_desc': "우리는 다중 통화 지원을 도입하여 봇이 USDT 외의 다양한 견적 쌍으로 거래할 수 있게 되었습니다. 이 개선으로 사용자들은 더 큰 유연성을 얻게 되어 다양한 거래소 구조와 지리적 규제에 대응할 수 있게 되었습니다. 여러 통화로의 거래를 허용함으로써, 우리는 사용자들이 특정 요구사항과 시장 조건에 따라 전략을 최적화할 수 있게 하여 전반적인 거래 경험을 개선했습니다.",

        'about:new_admin_dashboard': "새로운 관리자 대시보드",
        'about:new_admin_dashboard_desc': "우리의 새로운 플랫폼 출시는 사용자 경험과 기능성에서 큰 발전을 이루었습니다. 이를 통해 새로운 기능의 더 빠른 개발과 배포가 가능해졌고, 최고 수준의 지원을 보장하기 위해 봇 문제 해결에 대한 더 큰 통제력을 갖게 되었습니다. 인터페이스와 백엔드 간의 통신을 강화하여 데이터 정확성과 신뢰할 수 있는 기능성을 보장했습니다. 미니멀리스트적이고 깔끔하며 탐색하기 쉬운 디자인에 대한 우리의 약속을 유지하면서도, 종합적인 데이터와 분석으로 플랫폼을 풍부하게 만들었습니다. 이러한 균형은 사용자 친화적인 환경에서 더 정보에 입각한 의사 결정을 할 수 있도록 사용자에게 심층적인 인사이트를 제공하면서도 단순성을 손상시키지 않습니다.",

        'about:autopilot_tradingview': "자동 조종 & TradingView 신호 자동화",
        'about:autopilot_tradingview_desc': "자동 조종 봇, 우리의 혁명적인 AI 기반 거래 솔루션입니다. 이 제로 구성 AI 봇 강화는 과거 데이터, 신경망 컴퓨팅, 모든 DipSway 봇의 실시간 데이터를 활용하여 거래 전략을 지속적으로 최적화합니다. 실시간 시장 매개변수를 기반으로 최고 성능의 자산을 자동으로 선택하고 구성을 조정하여 사용자에게 시장 조건에 즉시 적응하는 핸즈오프, 데이터 기반 거래 경험을 제공합니다.",

        'about:ai_futures_bot': "AI 선물 봇",
        'about:ai_futures_bot_desc': "사내 SL & TP 시스템 도입(트레일링 작업은 곧 제공 예정)으로 모든 사용자는 이제 자신의 봇에 대한 맞춤 설정에서 100% 성공률을 달성할 수 있습니다. 사내 시스템을 통해 우리는 진정한 AI 선물 봇의 첫 세대를 출시할 수 있었습니다.",

        'about:new_features': "새로운 기능",
        'about:new_features_desc': "우리는 사용자가 자신만의 전략을 만들고 커뮤니티와 공유할 수 있는 새로운 기능을 개발 중입니다.",

        'proven_to_perform': '성과가 입증됨',

        'see_how_dipsway_averages': 'DipSway 봇의 힘을 사용하여 DipSway 사용자들이 어떻게 평균을 내고 있는지 확인하세요.',
        'last': '지난',
        'months_compound_roi': '개월 복리 ROI',
        'total_return_based_on_last': '지난 기간 기준 총 수익률',
        'show_as_multiplier_values': '곱셈 값으로 표시',
        'show_as_percentage_values': '백분율 값으로 표시',
        'join': '참여',
        'stars': '별',

        'faq': '자주 묻는 질문',
        'faq_desc': '여기에서 답변을 찾으세요. FAQ에서 다루지 않은 질문이 있다면\n' +
            '언제든지 문의해 주세요.',
        'faq_sub_desc': '찾고 계신 답변을 찾지 못하셨나요?',
        'contact_support': '고객 지원 문의',

        'view_supported_platform': '지원되는 플랫폼 보기',

        'simulate_based_on_past_performances': '과거 성과를 기반으로 시뮬레이션',
        'calculate_your_earnings_desc': '거래소 수수료, 코인 성과 등을 고려하고 DipSway 봇이 당신의 자본 규모로 어떻게 거래할지 시뮬레이션하여 DipSway의 과거 데이터를 기반으로 한 완전한 수익성 분석.',

        'go_to_tweet': '트윗으로 이동',
        'view_on': '에서 보기',

        'security': '보안',
        'safe_and_secure': '안전하고 보안됨',
        'safe_and_secure_desc': '당신의 자금은 거래소에 남아 있으며, 우리는 당신을 위해\n' +
            '거래를 실행하는데 필요한 최소한의 권한만 요청합니다.',

        'follow_dipsway_development': 'DipSway의 개발을 팔로우하고 커뮤니티를 만나보세요',
        'join_us_on_telegram': '텔레그램에서 우리와 함께하세요',
        'here_when_you_need_help': '도움이 필요할 때 여기 있습니다',
        'dipsway_customer_care': 'DipSway 고객 관리',

        'for_us_top_10_percent': '우리에게 상위 10%는 DipSway 사용자들의 표준입니다. 다양한 구성을 실험해볼 수 있지만\n' +
            '최적화에 도움이 필요할 때 우리가 있습니다.',

        'join_the_telegram_community': '텔레그램 커뮤니티에 참여하세요',

        'made': '만든',
        'profit_on': '의 수익을',
        'a_single_trade': '단일 거래에서',

        'dont_go_back_to_manual_trading': '수동 거래로 돌아가지 마세요,',

        'get_in_before_the_pump': '펌프 전에 진입하고',
        'get_out_before_the_drop': '하락 전에 나가세요',

        'on_autopilot': '자동 조종으로',

        'start_your_dipsway_bot': '4번의 클릭으로 DipSway 봇 시작하기',

        'safe_for_you': '설계상 당신을 위해 안전합니다',
        'safe_for_you_desc': 'DipSway를 사용하면 당신의 자금은 암호화폐 거래소에 안전하게 보관되며,\n' +
            '우리는 봇 실행에 필요한 만큼만 당신의 신뢰를 요청합니다.',

        'ip_whitelisting': 'IP 화이트리스팅',
        'ip_whitelisting_desc': 'DipSway가 제어하는 IP 주소만 당신의 계정에서 작업할 수 있으며, API 키가 도난당했더라도 악의적인 행위자가 거래를 실행할 수 없습니다.',

        'restricted_access': '제한된 접근',
        'restricted_access_desc': 'DipSway는 당신을 대신하여 모니터링하고 거래를 실행하는데\n' +
            '가장 단순한 권한만 요청합니다.',

        'bright_green_performances': '밝은 녹색 성과',
        'on_capital': '자본에 대해',
        'win_rate': '승률',
        'average_trade': '평균 거래',

        'see_dipsway_live_backtests': 'DipSway 실시간 백테스트 & 성과 보기',
        'find_and_download': '과거 성과 데이터와 실시간 백테스트를 찾아 다운로드하세요.',

        'available_at_no_extra_cost': '추가 비용 없이 이용 가능',

        'full_autopilot_mode': '완전 자동 조종 모드',
        'full_autopilot_mode_desc': 'DipSway 자동 조종을 사용하면 거래가 자동화될 뿐만 아니라\n' +
            'DipSway가 시장 상황에 따라 그 순간 가장 좋은 암호화폐를\n' +
            '선택합니다.',

        'calculate_your_earnings_with_autopilot': '자동 조종으로 수익 계산하기',
        'high_performance': '고성능',
        'live_backtests_historical_performance': '실시간 백테스트, 과거 성과.',
        'backtests_performance': '백테스트 & 성과',
        'extendable_via_tradingview': 'TradingView를 통해 확장 가능',
        'use_power_tradingview': 'TradingView의 힘을 사용하여 DipSway를 더욱 구성하세요.',
        'coming_soon': '곧 출시 예정...',
        '3_green_trades_in_30_days': '30일 동안 3번의 수익 거래 또는 환불 보장',
        'need_help_choosing': '선택에 도움이 필요하신가요?',
        'use_dipsway_earnings_calculator': 'DipSway 수익 계산기를 사용하여 얼마나 벌 수 있는지 알아보고 플랜 추천을 받으세요.',
        'launch_earnings_calculator': '수익 계산기 시작하기',
        'popular': '인기',
        'recommended_for_you': '당신을 위한 추천',
        'no_deposit_required': '예치금 필요 없음',
        'top_10_of_users': "상위 10% 사용자",
        'all_users': "모든 사용자",
        'downtrend_page:desc1': '작업: 비상 포지션 종료. 하락세 감지됨.',
        'downtrend_page:sell_order_fulfilled': '12 BTC에 대한 매도 주문 체결.',
        'performance_history': '성과 기록',
        'trained_to_recognize_opportunities': '기회를 인식하도록 훈련됨',
        'live_backtests_performances': '실시간 백테스트 성과',
        'see_how_our_strategies_performed': '고품질 실시간 백테스트로 우리의 전략이 과거에 어떻게 수행되었는지 확인하세요.',
        'backtests_from': '다음 기간의 백테스트',
        'past_6_months': '지난 6개월',
        'collapse': '접기',
        'expand': '펼치기',
        'view_report': '보고서 보기',
        'knowledge:title1': '고품질 과거 데이터',
        'knowledge:description1': '우리는 사용되는 실시간 캔들스틱이 최고 품질임을 보장합니다.',
        'knowledge:title2': '수수료 & 지연 포함',
        'knowledge:description2': '각 거래에 0.1%의 메이커 및 테이커 수수료가 적용됩니다. 각 거래에 0.1%의 슬리피지와 몇 분의 지연이 적용됩니다.',
        'knowledge:title3': '과적합 방지',
        'knowledge:description3': 'DipSway는 성과가 좋지 않은 계정을 수동적으로 모니터링하고 조치를 권장합니다.',
        'operation': '작업',
        'buy_the_dip': '하락 시 매수',
        'sell_the_high': '고점에서 매도',
        'bought': '매수함',
        'watching_market': '시장 관찰 중',
        'sell_order_for': '에 대한 매도 주문',
        'fulfilled': '체결됨',
        'profit_made': '실현 이익',
        'calculation_results': '계산 결과',
        'calculating': '계산 중',
        'total_expected_profit_at_the_end_of': '다음 기간 종료 시 예상 총 이익',
        'period_based_off_of_historical_backtest_performances': '과거 백테스트 성과를 기반으로 한 기간',
        'trades': '거래',
        'day': '일',
        'profit': '이익',
        'recommended_plan_for_you': '당신을 위한 추천 플랜',
        'go_to': '이동',
        'free_trial_available': '무료 체험 가능',
        'runs_at': '실행 속도',
        'learn_how_we_calculate_your_earnings': '우리가 당신의 수익을 어떻게 계산하는지 알아보세요',
        'click_to_open_deepdive': '클릭하여 우리가 지원하는 다른 거래소 중에서 선택할 수 있는 심층 분석을 열어보세요.',
        'your_capital': '당신의 자본',
        'currency': '통화',
        'enabled': '활성화됨',
        'set_to': '설정됨',
        'coin_count': '코인 수',
        'disabled': '비활성화됨',
        'auto': '자동',
        'selected_coins': '선택된 코인',
        'loading_coins': '코인 로딩 중',
        'login': '로그인',

        'trading_with': '거래 중',

    },

    fr: {

        'all_payment_types':'tous les types de paiement',
        'all_payment_types_description': 'Expérience des transactions sans tracas avec Bitcoin, Ethereum et plus encore. Payez en toute sécurité via la passerelle Cryptomus de confiance pour un accès instantané aux fonctionnalités de DipSway.\n',
        'extra':'Extra',

        'no-token:footer.disclaimer': 'DipSway ne vend pas, n\'offre pas, ne facilite pas et ne fournit pas d\'accès à des jetons DeFi ou à des cryptomonnaies sur une blockchain quelconque. Nous sommes uniquement un fournisseur de logiciels.',

        "different_ways_to_swap": "Différentes Façons d'Échanger",

        'coin_swap_description': 'Optimisez vos échanges de {crypto} vers {quote} avec notre outil de recherche de chemins de swap de coins. Comparez instantanément plusieurs itinéraires de conversion {crypto}/{quote}, visualisez des échanges complexes et obtenez les meilleurs taux de change pour vos conversions de {crypto} vers {quote}. Parfait pour les traders de cryptomonnaies novices et experts cherchant à maximiser leurs échanges de {crypto} vers {quote}.',

        "current_price_info": "Le prix actuel de {crypto} est de {price}, ce qui représente une variation de {change24h} au cours des dernières 24 heures et de {change7d} au cours des 7 derniers jours.",
        "circulating_supply_info": "Avec une offre en circulation de 21M, la capitalisation boursière en direct de {crypto} est de {marketCap}.",
        "trading_volume_info": "Le volume de trading sur 24 heures est de {volume}.",
        "conversion_rate_calculation": "Les taux de conversion sont calculés en utilisant l'indice de prix en direct de {crypto} ainsi que d'autres indices de prix d'actifs numériques.",
        "exchange_rate_update": "Le taux de change {crypto}/{quote} est mis à jour en temps réel.",
        "follow_dipsway_info": "Suivez le convertisseur et la calculatrice crypto de DipSway pour des mises à jour en direct 24/7 des taux de change entre les cryptomonnaies et les devises fiduciaires, y compris {crypto}, {currencies}, et plus encore.",

        "description_calculator_pt_1": "Cette calculatrice fournit un taux de conversion pour",
        "description_calculator_pt_2": "vers",
        "description_calculator_pt_3": "Le taux de conversion est basé sur le taux du marché des dernières 24h.",
        "to": "vers",
        "converter": "Convertisseur",
        "crypto_prices_and_market_data": "Prix des cryptos et données de marché",
        "converter_site:desc": "Convertissez entre les cryptomonnaies et les devises fiduciaires avec notre outil de conversion facile à utiliser. Obtenez les derniers taux de change et données de marché pour vos transactions.",
        "callout_calculator": "Bots de trading crypto alimentés par l'IA",
        "info_on_price": "Informations sur les prix",
        "live_data": "Données en direct",

        "prices_of": "Prix de",
        "on_different_exchanges": "sur différentes plateformes d'échange crypto",
        "converted_to": "Converti en",
        "on_different_amounts": "pour différents montants",
        "up_of": "Hausse de",
        "dropped_of": "Baisse de",
        "respect_yesterday": "par rapport à hier",
        "close_price": "Prix de clôture",
        "price_variation": "Variation de prix",
        "vwap": "Prix moyen",
        "volume_last_24h": "Volume sur 24h",
        "exchange": "Plateforme d'échange",
        "price": "Prix",
        "volume": "Volume",
        "quote_volume": "Volume de cotation",
        "percentage": "Pourcentage",
        "asset": "Actif",
        "today_at": "Aujourd'hui à",
        "calculator_title": "Calculatrice de {crypto} à {quote}",
        "crypto_label": "Crypto",
        "currency_label": "Devise",
        "enter_amount": "Saisissez le montant",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Trader sur {crypto}",

        'search_for_crypto': 'Rechercher une crypto',
        'crypto_prices_title': 'Prix des cryptomonnaies prises en charge Changement des dernières 24 heures',
        'see_current_crypto_market_trend': 'Voir la tendance actuelle du marché des cryptos',

        'see_more': 'Voir plus',
        'dipsway': 'DipSway',
        'home_page': 'page d\'accueil',
        'blogs.related_posts': 'Articles connexes',
        'blogs.automate_your_trading': 'Automatisez votre trading avec DipSway AI aujourd\'hui.',
        'blogs.automate_your_trading_description': 'DipSway utilise une combinaison de plus de 121 indicateurs techniques et plus de 17 détecteurs de motifs pour vous donner un avantage déloyal.',
        'btn.start_7_day_free_trial': 'Commencer l\'essai gratuit de 7 jours',
        'blogs.tags': 'Tags',
        'blogs.description_site': 'Tutoriels crypto, lancements de fonctionnalités, comparaisons de produits et plus encore ! Trouvez du contenu de l\'équipe DipSway ici sur le blog DipSway.',
        'blogs.title_site': 'Blog DipSway',
        'blogs.callout': 'Présentation de la version 4.13',
        'blogs.description_extended': 'Tutoriels, lancements de fonctionnalités, comparaisons de produits et plus encore !\nTrouvez du contenu de l\'équipe DipSway ici sur le blog DipSway.',
        'blogs.go_to_article': 'Aller à l\'article',
        'skip_to_content': 'Passer au contenu',
        'calculate_your_earnings_and_start_free_trial': 'Calculez vos gains et commencez l\'essai gratuit de 7 jours',

        'privacy_policy': 'Politique de confidentialité',
        'terms_of_service': 'Conditions d\'utilisation',
        'refund_policy': 'Politique de remboursement',
        'affiliate_policy': 'Politique d\'affiliation',
        'blog': 'Blog',
        'supported_exchanges': 'Échanges pris en charge',
        'pricing': 'Tarification',
        'resources': 'Ressources',
        'company': 'Entreprise',
        'support': 'Support',
        'affiliate_program': 'Programme d\'affiliation',
        'about_us': 'À propos de nous',
        'customer_success_agent': 'Agent de réussite client',
        'dipsway_ai_optimization': 'Optimisation DipSway AI',
        'live_back_tests': 'Tests en direct',
        'live_back_tests_performances': 'Tests en direct et performances DipSway',
        'historical_performance_data': 'Données de performance historiques',
        'dipsway_earning_calculator': 'Calculateur de gains DipSway',
        'performance': 'Performance',
        'common:footer.disclaimer': 'DipSway fournit uniquement des logiciels. Toute référence aux services de trading, d\'échange, de transfert ou de portefeuille, etc. sont des références aux services fournis par des fournisseurs de services tiers.',

        'index:hero.title': 'DipSway - Bots de trading crypto IA en pilote automatique sans configuration',
        'index:hero.description': 'Découvrez le trading de crypto sans effort avec nos bots IA. Lancez votre bot de trading crypto IA pour des transactions 100% automatisées, disponible 24/7 sur une interface simple.',

        'header:product': 'Produit',
        'header:product.feature.header': 'Que peut gagner votre capital en utilisant DipSway ?',
        'header:product.feature.description': 'Calculez vos gains basés sur l\'historique des backtests de DipSway.',
        'header:product.feature.cta': 'Calculez vos gains',

        'header:product.links.spot_ai_trading_bot': 'Bot de trading IA Spot',
        'header:learn': 'Apprendre',
        'header:learn.links.ai_trading_optimization': 'IA + Trading Crypto',

        'welcome:headline': 'Bots de trading crypto IA en pilote automatique sans configuration',
        'welcome:subheadline': 'Connectez-vous à votre échange, activez le pilote automatique et c\'est tout.',
        'start_7_days_no_credit_card': 'Commencez l\'essai gratuit de 7 jours - Aucun dépôt requis.',

        '404:title_site': '404 - DipSway | Bot de trading crypto alimenté par l\'IA',
        '404:description_site': 'Retrouvez votre chemin vers le trading crypto avec notre bot IA. Lancez votre bot de trading crypto IA pour des transactions 100% automatisées, disponible 24/7 sur une interface simple.',
        '404:page_not_found': 'Page non trouvée',
        '404:page_not_found_message': 'On dirait que vous avez trouvé un... lien cassé ! Veuillez informer le support DipSway\nde la page qui vous a amené ici !',
        '404:go_home': 'Aller à l\'accueil',
        '404:open_dashboard': 'Ouvrir le tableau de bord',

        'affiliate_program_policy:title_site': "Politique du programme d'affiliation DipSway",
        'affiliate_program_policy:description_site': "Politique du programme d'affiliation DipSway",

        'crypto_bot_calculator:title_site': "Calculateur de gains du bot de trading crypto",
        'crypto_bot_calculator:description_site': "Analyse complète de la rentabilité basée sur les données historiques de DipSway, prenant en compte des éléments tels que les frais d'échange, les performances des pièces, et simulant comment les bots DipSway négocieraient avec votre taille de capital.",
        'crypto_bot_calculator:label': 'Calculateur de gains du bot de trading crypto',
        'crypto_bot_calculator:simulate_based_on': 'Simuler sur la base des performances passées',
        'crypto_bot_calculator:calculate_your_earnings': 'Calculez vos gains',
        'crypto_bot_calculator:description': 'Analyse complète de la rentabilité basée sur les données historiques de DipSway, prenant en compte des éléments tels que les frais d\'échange, les performances des pièces, et simulant comment les bots DipSway négocieraient avec votre taille de capital.',

        'featured_on': 'Présenté sur',
        '4_clicks_to_start': '4 clics pour commencer',
        'no_knowledge_required': 'Zéro configuration, zéro connaissance requise',
        'split_content_description': 'DipSway est vraiment sans configuration. connectez votre échange → activez votre bot →\n' +
            '      et surveillez ses performances. Les meilleures configurations de performance sont\n' +
            '      appliquées par défaut.',
        'learn_about_dipsway_ai': 'En savoir plus sur DipSway AI',

        'trained_for_down_trends': 'Formé pour détecter les tendances baissières',
        'be_safe_on_down_trends': 'Soyez en sécurité, même lors des tendances baissières',
        'autopilot_content_headline_1': 'Entrez avant la hausse, sortez avant la baisse,',
        'autopilot_content_headline_2': 'en pilote automatique',
        'autopilot_content_description': 'DipSway analyse les marchés et place des trades pendant que vous êtes occupé avec vos\n' +
            '      engagements quotidiens, et vous tient informé avec des mises à jour de statut.',
        'downtrends_description': 'L\'IA de DipSway est formée pour identifier les tendances baissières et avant que les choses ne deviennent incontrôlables,\n' +
            '      elle vous fera sortir',

        'see_how_dipsway_works': 'Voir comment fonctionne DipSway',

        'free_trial_action:label': 'Prêt quand vous l\'êtes.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Sécurité',
        'safety_description': 'Gardez vos fonds en sécurité dans votre échange, votre sécurité est notre priorité.',

        'ai_trading_optimization_description': 'Notre modèle AI propriétaire formé pour reconnaître les opportunités.',

        '3months': '3 mois',
        'total_profits_within_label': 'Profits totaux sur tous les bots DipSway déployés au cours des derniers',

        'dipsway_uses_combination_of': 'DipSway utilise une combinaison de',
        'and': 'et',
        'technical_indicators': 'indicateurs techniques',
        'pattern_detectors': 'détecteurs de motifs',
        'detect_next_move': 'pour détecter le prochain mouvement du marché.',

        'are_you_influencer': 'Êtes-vous un influenceur ? Collaborez avec nous',
        'influencer_description': 'Les influenceurs qui travaillent avec nous obtiennent une page d\'atterrissage d\'intégration personnalisée\n' +
            '      adaptée aux intérêts de leur base d\'utilisateurs.',

        'get_in_touch': 'Contactez-nous',

        'spot_ai_bot:title_site': 'Bot de trading IA Spot | Transactions 100% automatisées',
        'spot_ai_bot:description_site': 'Activez votre bot de trading crypto et profitez de transactions 100% automatisées. Lancez votre bot aujourd\'hui pour un bot fonctionnant 24/7. Profitez de la commodité et maximisez vos profits.',

        'spot_ai_bot:24_7_automated_trading': 'Trading automatisé 24/7, placement de trade quasi instantané',
        'spot_ai_bot:algo_crypto_by_ml': 'Trading algorithmique de crypto par IA, piloté par l\'apprentissage automatique',

        'spot_ai_bot:desc': 'Le meilleur bot de trading de l\'industrie, utilisant l\'IA pour analyser le marché et\n' +
            'prendre des décisions de trading à une vitesse proche de celle d\'une machine.',

        'million': 'million',

        'avg_monthly_volume_moved_by_bots': 'Volume mensuel moyen déplacé par les bots DipSway.',
        'dipsway_has_more_than_350_users': 'DipSway compte plus de 350 utilisateurs dans le monde.',
        'dipsway_supports_315_coins': 'DipSway prend en charge le trading de plus de 315+ devises.',

        'ai_powered': "Alimenté par l\'IA",
        'trading_strategies_powered_by_ai': "Les stratégies de trading sont alimentées par l\'IA, utilisant une combinaison d\'analyse technique et d\'analyse fondamentale.",

        'geolocation_advantage': "Avantage de géolocalisation",
        'geolocation_advantage_desc': "Les serveurs hébergeant le bot sont situés dans la même zone géographique que l\'échange, pour assurer la latence la plus faible possible (délai moyen de ~932μs).",

        'high_frequency_trading': 'Trading haute fréquence',
        'high_frequency_trading_desc': 'Grâce à une architecture multi-tenant, les stratégies de trading sont exécutées avec une granularité de bougies d\'une seconde.',

        'advanced_risk_management': 'Gestion avancée des risques',
        'advanced_risk_management_desc': 'Le bot est capable de gérer les investissements dans plusieurs devises et de couvrir automatiquement le risque du portefeuille.',

        'optimization_overfitting_avoidance': 'Optimisation & Évitement du surajustement',
        'optimization_overfitting_avoidance_desc': 'Le bot est capable d\'optimiser les paramètres des stratégies de trading et d\'éviter le surajustement en utilisant un algorithme génétique IA et une analyse Walk-Forward personnalisée.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'Toutes les 24h, nous effectuons des backtests de toutes les stratégies de trading, et nous recalculons les poids utilisés par l\'IA en utilisant une approche d\'apprentissage en ligne.',
        'how_do_dipsways_strategies_work': "Comment fonctionnent les stratégies de DipSway ?",
        'no_one_wants_to_invest_in_black_box': "Personne ne veut investir dans une boîte noire. C\'est pourquoi nous avons créé un moyen simple de suivre les coulisses du bot IA de DipSway - DipSway fusionne plus de 112 indicateurs et stratégies pour vous donner un avantage dans le monde en rapide évolution des cryptos.",
        'learn_more_about_dipsway_ai': 'En savoir plus sur DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Achats en pilote automatique, tranchants comme un couteau',
        'autopilot_buys_sharp_as_knife_desc': 'La plupart des marchés crypto évoluent latéralement et les grandes variations de prix ne durent pas longtemps. Grâce à un processus de décision par apprentissage automatique, le bot IA est conçu pour exécuter des ordres d\'achat précis qui vous maintiendront constamment rentable sur les marchés latéraux et profiteront des grandes baisses lorsqu\'elles se produisent.',
        'other_strategies_dont_work': 'Les autres stratégies existantes ne fonctionnent pas car elles sont statiques et ne peuvent pas évoluer avec les changements du marché.',

        'autopilot_sells_and_youre_in_profit': 'Ventes en pilote automatique, et vous êtes en profit.',

        'dont_let_downturns_catch_you_off_guard': 'Ne vous laissez pas surprendre par les baisses.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ indicateurs et toutes les métriques dont vous avez besoin sur une seule plateforme.',

        'your_bot_runs_in_cloud': 'Votre bot fonctionne dans le Cloud, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Notre bot est un bot de trading basé sur le cloud qui fonctionne 24/7, donc vous n\'avez pas à vous soucier d\'être hors ligne ! Il est toujours connecté au marché, analysant le meilleur moment pour acheter et vendre.\n',

        'unfair_advantage': 'pour vous donner un avantage déloyal.',
        'and_many_more': 'Et bien plus encore...',

        'pricing:title_site': 'Tarification DipSway',
        'pricing:description_site': "Bot de trading crypto IA abordable | Lancez votre bot aujourd'hui | Profitez du trading automatisé 24/7",
        'pricing:supercharge_text': 'Boostez votre trading avec DipSway AI',

        'performance:title_site': 'Historique des performances et backtests de DipSway',
        'performance:description_site': "Expérimentez des backtests en direct avec notre bot de trading crypto IA. Lancez votre bot aujourd'hui pour un bot fonctionnant 24/7. Profitez de la commodité et maximisez vos profits.",
        'performance:label': "Historique des performances et backtests de DipSway",

        'connect_to_exchange': 'Connectez-vous à votre échange, activez le pilote automatique et c\'est tout.',
        'calculate_your_earnings': 'Calculez vos gains',

        'trading_optimization:title_site': "Optimisation du trading IA DipSway",
        'trading_optimization:description_site': "Expérimentez l'optimisation du trading IA avec notre bot de trading crypto. Lancez votre bot aujourd'hui pour un bot fonctionnant 24/7. Profitez de la commodité et maximisez vos profits.",
        'trading_optimization:label': "Optimisation du trading IA",
        'trading_optimization:breakdown': 'Analyse : Trading crypto IA de DipSway',

        'trading_optimization:desc': 'Le puissant bot IA de DipSway s\'adapte dynamiquement aux changements rapides du marché.',
        'trading_optimization:steps': 'Tout cela se fait à travers une série d\'étapes :',

        'behind_scenes:behind_the_scenes': 'Que se passe-t-il en coulisses ?',

        'behind_scenes:indicators': 'Certains indicateurs montrent un signal d\'achat potentiel (vert), tandis que d\'autres montrent un signal de vente potentiel (gris).',
        'behind_scenes:neural_network': 'Le réseau neuronal de DipSway prend en compte tous ces indicateurs et prend une décision basée sur l\'image globale, produisant un seul signal d\'achat ou de vente.',

        'affiliate_program:title_site': 'Programme d\'affiliation de bot crypto',
        'affiliate_program:description_site': "Rejoignez notre programme d\'affiliation. Profitez d\'une commission récurrente de 30% sur les invitations d\'amis.",
        'affiliate_program:label': "Programme d\'affiliation",
        'affiliate_program:desc': "Gagnez 30% de commission sur chaque parrainage, de manière récurrente",
        'affiliate_program:desc2': "Connectez-vous à votre échange, activez le pilote automatique et c\'est tout.",
        'affiliate_program:get_your_affiliate_link': 'Obtenez votre lien d\'affiliation',

        'conference': 'Conférence',

        'about:title_site': "À propos de DipSway",
        'about:our_journey': 'Notre parcours',
        'about:our_journey_desc': 'Fournir des bots de trading crypto IA de haute qualité et rentables pour les traders\n' +
            'de tous niveaux qui font croître leur portefeuille d\'investissement et améliorent leurs\n' +
            'performances quotidiennes.',
        'about:just_getting_started': 'Et nous ne faisons que commencer.',

        'about:description_site': "Expérimentez l'optimisation du trading IA avec notre bot de trading crypto. Lancez votre bot aujourd'hui pour un bot fonctionnant 24/7. Profitez de la commodité et maximisez vos profits.",
        'about:label': 'À propos',
        'about:behind_scenes': 'En coulisses',
        'founder': 'Fondateur',

        'about:founder1_msg': 'DipSway est né d\'une vision visant à révolutionner le trading de crypto grâce à une IA de pointe. En tant qu\'ingénieurs logiciels et passionnés de crypto, nous avons vu une opportunité de créer quelque chose de vraiment innovant - un bot de trading qui pense comme un trader professionnel mais opère à une vitesse surhumaine.\nGrâce à notre solide expertise technique, notre percée est venue lorsque nous avons développé une IA qui ne suit pas simplement des règles prédéfinies, mais s\'adapte en temps réel à la dynamique du marché.',
        'about:founder2_msg': 'Ce n\'est pas votre bot de trading moyen ; c\'est un système sophistiqué qui analyse de vastes quantités de données, reconnaît des motifs complexes et prend des décisions en une fraction de seconde avec une précision remarquable.\nCe qui distingue DipSway, c\'est notre approche unique de l\'apprentissage de l\'IA. C\'est comme avoir une équipe de traders experts travaillant pour vous 24/7, mais plus rapide et plus efficace.\nNotre mission est de rendre le trading de crypto avancé accessible à tous, des pros chevronnés aux nouveaux venus curieux.',

        'about:join_team': 'Rejoignez l\'équipe DipSway',
        'about:join_team_desc': 'Nous recherchons des passionnés de crypto qui sont passionnés par la finance,\n' +
            'le trading et la technologie. Prêt à changer la façon dont le monde investit dans\n' +
            'les cryptos ?',

        'read_post': 'Lire l\'article',

        'about:development_starts': "Le développement commence",
        'about:development_starts_desc': "Un jour, nous recevions des notifications de l'application mobile Coinbase sur le prix récent du Bitcoin comme 'Le Bitcoin est en hausse de 5% aujourd'hui'. Nous avons pensé qu'il serait cool d'avoir un bot qui opérerait AVANT de telles notifications. Nous avons cherché sur le web et n'avons rien trouvé de facile à utiliser qui fonctionne dans le cloud. Alors nous avons décidé de le construire nous-mêmes.",

        'about:first_bot_version': "Première version du bot",
        'about:first_bot_version_desc': "Première simulation de stratégie de trading algorithmique pour Bitcoin et Ethereum, nous avons commencé à inclure plus d'actifs crypto et plus de stratégies. Nous avons commencé à voir le potentiel de notre bot et nous avons décidé de le rendre public.",

        'about:simple_strategy_not_enough': 'Une "stratégie simple" n\'était pas suffisante',
        'about:simple_strategy_not_enough_desc': "Nous avons construit et entraîné un réseau neuronal qui apprendrait du marché et ferait des prédictions. Les résultats ont montré une très haute précision.",

        'about:live_bot_release': "Lancement du bot en direct",
        'about:live_bot_release_desc': "Nous avons construit et entraîné un réseau neuronal qui apprendrait du marché et ferait des prédictions. Les résultats ont montré une très haute précision.",

        'about:our_transparency_value': "Notre valeur de transparence",
        'about:our_transparency_value_desc': "Les backtests en direct et les performances en direct ont été rendus publics. Nous voulons que la transparence soit un différenciateur clé entre nous et d'autres entreprises qui ne montrent aucune preuve de leurs résultats.",

        'about:infrastructure_revolution': "Révolution de l'infrastructure",
        'about:infrastructure_revolution_desc': "2023 a été une année réussie car plus de traders ont adopté notre plateforme. Nous avons augmenté notre engagement envers les traders, en nous efforçant de comprendre ce dont tout le monde avait vraiment besoin dans une plateforme de bot. Compte tenu de cette base d'utilisateurs croissante, nous avons reconnu l'importance de rendre DipSway infaillible au niveau de l'ingénierie. Avant d'introduire de nouvelles mises à jour, fonctionnalités, outils et bots passionnants, nous avons priorisé l'assurance que nos systèmes fonctionnaient 24/7 sans problème et que les performances et la qualité étaient de premier ordre.",

        'about:new_exchanges': "Nouveaux échanges",
        'about:new_exchanges_desc': "En février, nous avons considérablement élargi notre plateforme en intégrant des échanges majeurs comme Coinbase Advanced, BingX et Bitmart. Nous avons également établi des partenariats avec KuCoin et Bybit, devenant une application tierce officielle sur les deux. Ces ajouts et partenariats ont grandement augmenté les options de trading et l'accès au marché de nos utilisateurs, validant la fiabilité de notre plateforme et marquant une étape majeure dans notre croissance.",

        'about:rotating_proxy': "Proxy rotatif et nouvelles fonctionnalités phares",
        'about:rotating_proxy_desc': "Dans une mise à niveau significative de notre infrastructure, nous avons implémenté 20 IP supplémentaires et étendu nos répliques de base de données de 2 à 7, améliorant la réactivité des bots aux nouvelles bougies sur les échanges. Cette amélioration positionne nos bots de manière compétitive sur le marché en termes de vitesse et de fiabilité. Nous avons également intégré ﻿Crypto(.)com, élargissant encore nos échanges supportés et les options de trading pour les utilisateurs. Une nouvelle fonctionnalité notable est le Tableau de bord des Bots, qui permet aux utilisateurs de vérifier les performances des bots et de copier les paramètres des bots les plus performants. Ces améliorations renforcent collectivement les capacités de notre plateforme, offrant aux utilisateurs des opportunités de trading plus robustes, efficaces et diverses.",

        'about:multiple_quote_currencies': "Multiples devises de cotation",
        'about:multiple_quote_currencies_desc': "Nous avons introduit le support multi-devises, permettant aux bots de trader avec diverses paires de cotation au-delà de l'USDT. Cette amélioration offre aux utilisateurs une plus grande flexibilité, s'adaptant à différentes structures d'échange et réglementations géographiques. En permettant le trading à travers plusieurs devises, nous avons permis aux utilisateurs d'optimiser leurs stratégies selon des besoins spécifiques et des conditions de marché, améliorant leur expérience globale de trading.",

        'about:new_admin_dashboard': "Nouveau tableau de bord d'administration",
        'about:new_admin_dashboard_desc': "Le lancement de notre nouvelle plateforme marque un bond significatif en termes d'expérience utilisateur et de fonctionnalité. Il permet un développement et une livraison plus rapides de nouvelles fonctionnalités, nous donnant un plus grand contrôle sur le dépannage des bots pour assurer un support de niveau élite. Nous avons renforcé la communication entre l'interface et le backend, garantissant la précision des données et une fonctionnalité fiable. Tout en maintenant notre engagement envers un design minimaliste, propre et facile à naviguer, nous avons également enrichi la plateforme avec des données et des analyses complètes. Cet équilibre permet aux utilisateurs d'avoir des insights approfondis sans compromettre la simplicité, permettant une prise de décision plus éclairée dans un environnement convivial.",

        'about:autopilot_tradingview': "Pilote automatique & automatisation des signaux TradingView",
        'about:autopilot_tradingview_desc': "Le Bot Pilote Automatique, notre solution de trading révolutionnaire alimentée par l'IA. Cette amélioration sans configuration de nos bots IA exploite les données historiques, le calcul de réseau neuronal et les données EN DIRECT de tous les bots DipSway pour optimiser continuellement les stratégies de trading. Il sélectionne automatiquement les actifs les plus performants et ajuste les configurations en fonction des paramètres de marché en temps réel, offrant aux utilisateurs une expérience de trading mains libres et basée sur les données qui s'adapte instantanément aux conditions du marché.",

        'about:ai_futures_bot': "Bot de futures IA",
        'about:ai_futures_bot_desc': "Avec l'introduction du système interne SL & TP (les opérations de trailing seront bientôt disponibles), tous les utilisateurs ont maintenant un taux de réussite de 100% sur leurs paramètres personnalisés pour leur bot. Le système interne nous a permis de lancer la première génération d'un authentique bot de futures IA.",

        'about:new_features': "Nouvelles fonctionnalités",
        'about:new_features_desc': "Nous travaillons sur une nouvelle fonctionnalité qui permettra aux utilisateurs de créer leurs propres stratégies et de les partager avec la communauté.",

        'proven_to_perform': 'Prouvé pour performer',

        'see_how_dipsway_averages': 'Voyez comment les utilisateurs de DipSway font en moyenne en utilisant la puissance des bots DipSway.',
        'last': 'Derniers',
        'months_compound_roi': 'Mois ROI composé',
        'total_return_based_on_last': 'Rendement total basé sur les derniers',
        'show_as_multiplier_values': 'Afficher comme valeurs multiplicatrices',
        'show_as_percentage_values': 'Afficher comme valeurs en pourcentage',
        'join': 'Rejoindre',
        'stars': 'Étoiles',

        'faq': 'Foire aux questions',
        'faq_desc': 'Trouvez vos réponses ici. Si vous avez une question qui n\'est pas couverte dans la FAQ,\n' +
            'n\'hésitez pas à nous contacter.',
        'faq_sub_desc': 'Vous n\'avez pas trouvé la réponse que vous cherchiez ?',
        'contact_support': 'Contacter le support',

        'view_supported_platform': 'Voir les plateformes supportées',

        'simulate_based_on_past_performances': 'Simuler sur la base des performances passées',
        'calculate_your_earnings_desc': 'Analyse complète de la rentabilité basée sur les données historiques de DipSway, prenant en compte des éléments tels que les frais d\'échange, les performances des pièces, et simulant comment les bots DipSway négocieraient avec votre taille de capital.',

        'go_to_tweet': 'Aller au tweet',
        'view_on': 'Voir sur',

        'security': 'Sécurité',
        'safe_and_secure': 'Sûr et sécurisé',
        'safe_and_secure_desc': 'Vos fonds restent dans votre échange, et nous ne demandons que des\n' +
            'permissions minimales pour exécuter des trades pour vous.',

        'follow_dipsway_development': 'Suivez le développement de DipSway et rencontrez la communauté',
        'join_us_on_telegram': 'Rejoignez-nous sur Telegram',
        'here_when_you_need_help': 'Ici quand vous avez besoin d\'aide',
        'dipsway_customer_care': 'Service client DipSway',

        'for_us_top_10_percent': 'Pour nous, le top 10% est la norme pour les utilisateurs de DipSway. Vous êtes libre\n' +
            'd\'expérimenter avec différentes configurations, mais quand vous avez besoin d\'aide\n' +
            'pour optimiser, nous sommes là.',

        'join_the_telegram_community': 'Rejoignez la communauté Telegram',

        'made': 'a réalisé',
        'profit_on': 'de profit sur',
        'a_single_trade': 'une seule transaction',

        'dont_go_back_to_manual_trading': 'Ne revenez pas au trading manuel,',

        'get_in_before_the_pump': 'entrez avant la hausse',
        'get_out_before_the_drop': 'sortez avant la baisse',

        'on_autopilot': 'En pilote automatique',

        'start_your_dipsway_bot': 'Démarrez votre bot DipSway en 4 clics',

        'safe_for_you': 'Sûr pour vous, par conception',
        'safe_for_you_desc': 'Avec DipSway, vos fonds sont en sécurité dans votre échange de crypto, et nous vous\n' +
            'demandons de nous faire confiance uniquement dans la mesure nécessaire pour que les bots fonctionnent.',

        'ip_whitelisting': 'Liste blanche d\'IP',
        'ip_whitelisting_desc': 'Seules les adresses IP contrôlées par DipSway peuvent opérer sur votre compte, même si votre clé API a été volée, les acteurs malveillants ne peuvent pas exécuter de transactions.',

        'restricted_access': 'Accès restreint',
        'restricted_access_desc': 'DipSway ne demande que les permissions les plus simples pour surveiller et exécuter\n' +
            'des transactions en votre nom.',

        'bright_green_performances': 'Performances vert vif',
        'on_capital': 'Sur capital',
        'win_rate': 'taux de réussite',
        'average_trade': 'trade moyen',

        'see_dipsway_live_backtests': 'Voir les backtests en direct et les performances de DipSway',
        'find_and_download': 'Trouvez et téléchargez des données de performance historiques et des backtests en direct.',

        'available_at_no_extra_cost': 'Disponible sans frais supplémentaires',

        'full_autopilot_mode': 'Mode pilote automatique complet',
        'full_autopilot_mode_desc': 'Avec le pilote automatique DipSway, non seulement vos trades sont automatisés, mais\n' +
            'DipSway choisit également la meilleure crypto-monnaie à trader à ce moment-là\n' +
            'en fonction des conditions du marché.',

        'calculate_your_earnings_with_autopilot': 'Calculez vos gains avec le pilote automatique',
        'high_performance': 'Haute performance',
        'live_backtests_historical_performance': 'Backtests en direct, performance historique.',
        'backtests_performance': 'Backtests & performance',
        'extendable_via_tradingview': 'Extensible via TradingView',
        'use_power_tradingview': 'Utilisez la puissance de TradingView pour configurer davantage DipSway.',
        'coming_soon': 'Bientôt disponible...',
        '3_green_trades_in_30_days': '3 trades gagnants en 30 jours ou remboursement garanti',
        'need_help_choosing': 'Besoin d\'aide pour choisir ?',
        'use_dipsway_earnings_calculator': 'Utilisez le calculateur de gains DipSway, découvrez combien vous pouvez gagner et obtenez une recommandation de plan.',
        'launch_earnings_calculator': 'Lancer le calculateur de gains',
        'popular': 'Populaire',
        'recommended_for_you': 'Recommandé pour vous',
        'no_deposit_required': 'Aucun dépôt requis',
        'top_10_of_users': "Top 10% des utilisateurs",
        'all_users': "Tous les utilisateurs",
        'downtrend_page:desc1': 'Opération : Sortie de position d\'urgence. Tendance baissière identifiée.',
        'downtrend_page:sell_order_fulfilled': 'Ordre de vente pour 12 BTC exécuté.',
        'performance_history': 'Historique des performances',
        'trained_to_recognize_opportunities': 'Entraîné à reconnaître les opportunités',
        'live_backtests_performances': 'Performances des backtests en direct',
        'see_how_our_strategies_performed': 'Voyez comment nos stratégies ont performé dans le passé avec des backtests en direct de haute qualité.',
        'backtests_from': 'Backtests des',
        'past_6_months': '6 derniers mois',
        'collapse': 'Réduire',
        'expand': 'Développer',
        'view_report': 'Voir le rapport',
        'knowledge:title1': 'Données historiques de haute qualité',
        'knowledge:description1': 'Nous nous assurons que les bougies en direct utilisées sont de la plus haute qualité.',
        'knowledge:title2': 'Frais & Délais inclus',
        'knowledge:description2': 'Des frais maker et taker de 0,1% sont appliqués à chaque trade. Un slippage de 0,1% et un délai de quelques minutes sont appliqués à chaque trade.',
        'knowledge:title3': 'Évitement du surajustement',
        'knowledge:description3': 'DipSway surveille passivement les comptes peu performants et recommande des actions.',
        'operation': 'Opération',
        'buy_the_dip': 'Acheter la baisse',
        'sell_the_high': 'Vendre au plus haut',
        'bought': 'Acheté',
        'watching_market': 'surveillance du marché',
        'sell_order_for': 'Ordre de vente pour',
        'fulfilled': 'exécuté',
        'profit_made': 'Profit réalisé',
        'calculation_results': 'Résultats du calcul',
        'calculating': 'Calcul en cours',
        'total_expected_profit_at_the_end_of': 'Profit total attendu à la fin de',
        'period_based_off_of_historical_backtest_performances': 'période basée sur les performances historiques des backtests',
        'trades': 'trades',
        'day': 'jour',
        'profit': 'profit',
        'recommended_plan_for_you': 'Plan recommandé pour vous',
        'go_to': 'Aller à',
        'free_trial_available': 'Essai gratuit disponible',
        'runs_at': 'Fonctionne à',
        'learn_how_we_calculate_your_earnings': 'Apprenez comment nous calculons vos gains',
        'click_to_open_deepdive': 'Cliquez pour ouvrir l\'analyse approfondie où vous pouvez choisir parmi d\'autres échanges que nous prenons en charge.',
        'your_capital': 'Votre capital',
        'currency': 'Devise',
        'enabled': 'Activé',
        'set_to': 'réglé à',
        'coin_count': 'nombre de pièces',
        'disabled': 'Désactivé',
        'auto': 'Auto',
        'selected_coins': 'Pièces sélectionnées',
        'loading_coins': 'Chargement des pièces',
        'login': 'Connexion',

        'trading_with': 'Trading avec',

    },

    tr: {


        'all_payment_types': 'tüm ödeme türleri',
        'all_payment_types_description': 'Bitcoin, Ethereum ve daha fazlası ile sorunsuz işlemler yapın. Güvenilir Cryptomus ağ geçidi aracılığıyla güvenli bir şekilde ödeyerek DipSway\'ın özelliklerine anında erişin.',
        'extra': 'Ekstra',

        'no-token:footer.disclaimer': 'DipSway, herhangi bir blok zincirinde herhangi bir DeFi token\'ı veya kripto para birimini satmaz, teklif etmez, kolaylaştırmaz veya bunlara erişim sağlamaz. Biz yalnızca bir yazılım sağlayıcısıyız.',
        "different_ways_to_swap": "Takas Etmenin Farklı Yolları",

        'coin_swap_description': 'Coin Swap Paths bulucumuz ile {crypto} - {quote} işlemlerinizi optimize edin. Birden fazla {crypto}/{quote} dönüşüm rotasını anında karşılaştırın, karmaşık takasları görselleştirin ve {crypto} - {quote} dönüşümleriniz için en iyi döviz kurlarını güvence altına alın. {crypto} - {quote} işlemlerini en üst düzeye çıkarmak isteyen hem acemi hem de uzman kripto yatırımcıları için mükemmel.',

        "current_price_info": "Mevcut {crypto} fiyatı {price} olup, bu son 24 saatte {change24h} ve son 7 günde {change7d} değişim göstermiştir.",
        "circulating_supply_info": "21M dolaşımdaki arz ile {crypto}'nun canlı piyasa değeri {marketCap}'dir.",
        "trading_volume_info": "24 saatlik işlem hacmi {volume}'dir.",
        "conversion_rate_calculation": "Dönüşüm oranları, Canlı {crypto} Fiyat Endeksi ve diğer dijital varlık fiyat endeksleri kullanılarak hesaplanmaktadır.",
        "exchange_rate_update": "{crypto}/{quote} döviz kuru gerçek zamanlı olarak güncellenmektedir.",
        "follow_dipsway_info": "Kripto ve fiat para birimleri arasındaki döviz kurlarının 7/24 canlı güncellemeleri için DipSway'in kripto dönüştürücüsünü ve hesap makinesini takip edin, {crypto}, {currencies} ve daha fazlasını içerir.",

        "description_calculator_pt_1": "Bu hesap makinesi şu dönüşüm oranını sağlar:",
        "description_calculator_pt_2": "için",
        "description_calculator_pt_3": "Dönüşüm oranı son 24 saatteki piyasa oranına dayanmaktadır.",
        "to": "için",
        "converter": "Dönüştürücü",
        "crypto_prices_and_market_data": "Kripto Fiyatları ve Piyasa Verileri",
        "converter_site:desc": "Kullanımı kolay dönüştürücü aracımızla kripto paralar ve fiat para birimleri arasında dönüşüm yapın. İşlemleriniz için en son döviz kurlarını ve piyasa verilerini alın.",
        "callout_calculator": "AI destekli Kripto Ticaret Botları",
        "info_on_price": "Fiyat bilgisi",
        "live_data": "Canlı veri",

        "prices_of": "Fiyatları",
        "on_different_exchanges": "farklı kripto borsalarında",
        "converted_to": "Dönüştürüldü",
        "on_different_amounts": "farklı miktarlarda",
        "up_of": "Yükseliş",
        "dropped_of": "Düşüş",
        "respect_yesterday": "dünkine göre",
        "close_price": "Kapanış fiyatı",
        "price_variation": "Fiyat değişimi",
        "vwap": "Ortalama fiyat",
        "volume_last_24h": "24 saatlik hacim",
        "exchange": "Borsa",
        "price": "Fiyat",
        "volume": "Hacim",
        "quote_volume": "Teklif Hacmi",
        "percentage": "Yüzde",
        "asset": "Varlık",
        "today_at": "Bugün saat",
        "calculator_title": "{crypto}'dan {quote}'a Hesap Makinesi",
        "crypto_label": "Kripto",
        "currency_label": "Teklif",
        "enter_amount": "Miktarı girin",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "{crypto} üzerinde işlem yapın",

        'search_for_crypto': 'Kripto ara',
        'crypto_prices_title': 'Desteklenen Kripto Para Birimleri Fiyatları Son 24 Saat Değişimi',
        'see_current_crypto_market_trend': 'Mevcut kripto piyasası trendini gör',

        'see_more': 'Daha fazla gör',
        'dipsway': 'DipSway',
        'home_page': 'ana sayfa',
        'blogs.related_posts': 'İlgili Gönderiler',
        'blogs.automate_your_trading': 'DipSway AI ile ticaretinizi bugün otomatikleştirin.',
        'blogs.automate_your_trading_description': 'DipSway, size haksız bir avantaj sağlamak için 121+ teknik gösterge ve 17+ desen dedektörü kombinasyonu kullanır.',
        'btn.start_7_day_free_trial': '7 günlük ücretsiz denemeyi başlat',
        'blogs.tags': 'Etiketler',
        'blogs.description_site': 'Kripto para eğitimleri, özellik lansmanları, ürün karşılaştırmaları ve daha fazlası! DipSway ekibinden içerikleri burada, DipSway blogunda bulun.',
        'blogs.title_site': 'DipSway Blog',
        'blogs.callout': 'Sürüm 4.13\'ü tanıtıyoruz',
        'blogs.description_extended': 'Eğitimler, özellik lansmanları, ürün karşılaştırmaları ve daha fazlası!\nDipSway ekibinden içerikleri burada, DipSway blogunda bulun.',
        'blogs.go_to_article': 'Makaleye git',
        'skip_to_content': 'İçeriğe geç',
        'calculate_your_earnings_and_start_free_trial': 'Kazançlarınızı hesaplayın ve 7 günlük ücretsiz denemeyi başlatın',

        'privacy_policy': 'Gizlilik Politikası',
        'terms_of_service': 'Hizmet Şartları',
        'refund_policy': 'İade Politikası',
        'affiliate_policy': 'Ortaklık Politikası',
        'blog': 'Blog',
        'supported_exchanges': 'Desteklenen Borsalar',
        'pricing': 'Fiyatlandırma',
        'resources': 'Kaynaklar',
        'company': 'Şirket',
        'support': 'Destek',
        'affiliate_program': 'Ortaklık Programı',
        'about_us': 'Hakkımızda',
        'customer_success_agent': 'Müşteri Başarı Temsilcisi',
        'dipsway_ai_optimization': 'DipSway AI optimizasyonu',
        'live_back_tests': 'Canlı geriye dönük testler',
        'live_back_tests_performances': 'DipSway canlı geriye dönük testler ve performans',
        'historical_performance_data': 'Geçmiş performans verileri',
        'dipsway_earning_calculator': 'DipSway kazanç hesaplayıcı',
        'performance': 'Performans',
        'common:footer.disclaimer': 'DipSway yalnızca yazılım sağlar. Ticaret, takas, transfer veya cüzdan hizmetlerine yapılan herhangi bir atıf, üçüncü taraf hizmet sağlayıcıları tarafından sağlanan hizmetlere atıftır.',

        'index:hero.title': 'DipSway - Sıfır yapılandırma, tam otomatik AI kripto ticaret botları',
        'index:hero.description': 'AI botlarımızla sorunsuz kripto ticareti deneyimi yaşayın. Basit bir arayüzde 24/7 kullanılabilen %100 otomatik işlemler için Kripto Ticaret AI botunuzu başlatın.',

        'header:product': 'Ürün',
        'header:product.feature.header': 'DipSway kullanarak sermayeniz ne kazanabilir?',
        'header:product.feature.description': 'DipSway\'in geriye dönük test geçmişine dayalı olarak kazançlarınızı hesaplayın.',
        'header:product.feature.cta': 'Kazançlarınızı hesaplayın',

        'header:product.links.spot_ai_trading_bot': 'Spot AI Ticaret Botu',
        'header:learn': 'Öğren',
        'header:learn.links.ai_trading_optimization': 'AI + Kripto Ticareti',

        'welcome:headline': 'Sıfır yapılandırma, tam otomatik AI kripto ticaret botları',
        'welcome:subheadline': 'Borsanıza bağlanın, otomatik pilotu açın ve işlem tamam.',
        'start_7_days_no_credit_card': '7 günlük ücretsiz denemeyi başlat - Depozito gerekmiyor.',

        '404:title_site': '404 - DipSway | AI Destekli Kripto Ticaret Botu',
        '404:description_site': 'AI botumuzla kripto ticaretine geri dönün. Basit bir arayüzde 24/7 kullanılabilen %100 otomatik işlemler için Kripto Ticaret AI botunuzu başlatın.',
        '404:page_not_found': 'Sayfa bulunamadı',
        '404:page_not_found_message': 'Görünüşe göre bir ... kırık bağlantı buldunuz! Lütfen DipSway desteğine\nhangi sayfanın sizi buraya getirdiğini bildirin!',
        '404:go_home': 'Ana sayfaya git',
        '404:open_dashboard': 'Kontrol panelini aç',

        'affiliate_program_policy:title_site': "DipSway Ortaklık Programı Politikası",
        'affiliate_program_policy:description_site': "DipSway Ortaklık Programı Politikası",

        'crypto_bot_calculator:title_site': "Kripto ticaret botu kazanç hesaplayıcı",
        'crypto_bot_calculator:description_site': "Borsa ücretleri, coin performansları gibi faktörleri dikkate alan ve DipSway botlarının sizin sermaye büyüklüğünüzle nasıl ticaret yapacağını simüle eden DipSway\'in geçmiş verilerine dayalı tam karlılık analizi.",
        'crypto_bot_calculator:label': 'Kripto ticaret botu kazanç hesaplayıcı',
        'crypto_bot_calculator:simulate_based_on': 'Geçmiş performansa dayalı simülasyon yap',
        'crypto_bot_calculator:calculate_your_earnings': 'Kazançlarınızı hesaplayın',
        'crypto_bot_calculator:description': 'Borsa ücretleri, coin performansları gibi faktörleri dikkate alan ve DipSway botlarının sizin sermaye büyüklüğünüzle nasıl ticaret yapacağını simüle eden DipSway\'in geçmiş verilerine dayalı tam karlılık analizi.',

        'featured_on': 'Şurada öne çıktı',
        '4_clicks_to_start': 'Başlamak için 4 tıklama',
        'no_knowledge_required': 'Sıfır yapılandırma, sıfır bilgi gerekli',
        'split_content_description': 'DipSway gerçekten sıfır yapılandırma gerektirir. borsanıza bağlanın → botunuzu açın →\n' +
            '      ve performansını izleyin. En iyi performans gösteren yapılandırmalar\n' +
            '      varsayılan olarak uygulanır.',
        'learn_about_dipsway_ai': 'DipSway AI hakkında bilgi edinin',

        'trained_for_down_trends': 'Düşüş trendlerini tespit etmek için eğitildi',
        'be_safe_on_down_trends': 'Düşüş trendlerinde bile güvende olun',
        'autopilot_content_headline_1': 'Yükselişten önce girin, düşüşten önce çıkın,',
        'autopilot_content_headline_2': 'otomatik pilotta',
        'autopilot_content_description': 'DipSway, siz günlük işlerinizle meşgulken piyasaları analiz eder ve işlem yapar,\n' +
            '      ve sizi durum güncellemeleriyle bilgilendirir.',
        'downtrends_description': 'DipSway\'in AI\'si düşüş trendlerini tanımlamak için eğitilmiştir ve işler kontrolden çıkmadan önce\n' +
            '      sizi çıkaracaktır',

        'see_how_dipsway_works': 'DipSway\'in nasıl çalıştığını görün',

        'free_trial_action:label': 'Hazır olduğunuzda.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Güvenlik',
        'safety_description': 'Fonlarınızı borsanızda güvende tutun, güvenliğiniz bizim önceliğimizdir.',

        'ai_trading_optimization_description': 'Fırsatları tanımak için eğitilmiş özel AI modelimiz.',

        '3months': '3 ay',
        'total_profits_within_label': 'Son dönemde tüm dağıtılan DipSway botlarında toplam karlar',

        'dipsway_uses_combination_of': 'DipSway şunların bir kombinasyonunu kullanır',
        'and': 've',
        'technical_indicators': 'teknik gösterge',
        'pattern_detectors': 'desen dedektörü',
        'detect_next_move': 'piyasanın bir sonraki hareketini tespit etmek için.',

        'are_you_influencer': 'Bir influencer mısınız? Bizimle ortak olun',
        'influencer_description': 'Bizimle çalışan influencer\'lar, kullanıcı tabanlarının ilgi alanlarına\n' +
            '      uyarlanmış özel bir başlangıç açılış sayfası elde eder.',

        'get_in_touch': 'İletişime geçin',

        'spot_ai_bot:title_site': 'Spot AI Kripto Ticaret Botu | %100 Otomatik İşlemler',
        'spot_ai_bot:description_site': 'Kripto Ticaret Botunuzu açın ve %100 otomatik işlemlerden yararlanın. 7/24 çalışan botunuzu bugün başlatın. Kolaylığın tadını çıkarın ve karlarınızı maksimize edin.',

        'spot_ai_bot:24_7_automated_trading': '7/24 otomatik ticaret, neredeyse anında işlem yerleştirme',
        'spot_ai_bot:algo_crypto_by_ml': 'Makine Öğrenimi tarafından yönlendirilen AI Algoritmik Kripto Ticareti',

        'spot_ai_bot:desc': 'Piyasayı analiz etmek ve makine benzeri hızda ticaret kararları\n' +
            'vermek için AI kullanan sektördeki en iyi ticaret botu.',

        'million': 'milyon',

        'avg_monthly_volume_moved_by_bots': 'DipSway botları tarafından taşınan ortalama aylık hacim.',
        'dipsway_has_more_than_350_users': 'DipSway dünya çapında 350\'den fazla kullanıcıya sahiptir.',
        'dipsway_supports_315_coins': 'DipSway 315+ para birimi için ticaret desteği sunar.',

        'ai_powered': "AI Destekli",
        'trading_strategies_powered_by_ai': "Ticaret stratejileri, teknik analiz ve temel analiz kombinasyonu kullanarak AI tarafından desteklenmektedir.",

        'geolocation_advantage': "Coğrafi Konum Avantajı",
        'geolocation_advantage_desc': "Botu barındıran sunucular, mümkün olan en düşük gecikmeyi sağlamak için borsa ile aynı coğrafi konumda bulunur (ort. ~932μs gecikme).",

        'high_frequency_trading': 'Yüksek Frekanslı Ticaret',
        'high_frequency_trading_desc': 'Çoklu kiracı mimarisi sayesinde, ticaret stratejileri 1 saniyelik mum çubukları granülaritesiyle yürütülür.',

        'advanced_risk_management': 'Gelişmiş Risk Yönetimi',
        'advanced_risk_management_desc': 'Bot, birden fazla para biriminde yatırımları yönetebilir ve portföyün riskini otomatik olarak hedge edebilir.',

        'optimization_overfitting_avoidance': 'Optimizasyon ve Aşırı Uyum Önleme',
        'optimization_overfitting_avoidance_desc': 'Bot, bir AI-genetik algoritma ve özel Walk-Forward Analizi kullanarak ticaret stratejilerinin parametrelerini optimize edebilir ve aşırı uyumu önleyebilir.',

        'backtesting': 'Geriye Dönük Test',
        'backtesting_desc': 'Her 24 saatte bir, tüm ticaret stratejilerinin geriye dönük testlerini çalıştırırız ve "Çevrimiçi Öğrenme" yaklaşımı kullanarak AI tarafından kullanılan ağırlıkları yeniden hesaplarız.',
        'how_do_dipsways_strategies_work': "DipSway\'in stratejileri nasıl çalışır?",
        'no_one_wants_to_invest_in_black_box': "Kimse bir kara kutuya yatırım yapmak istemez. Bu yüzden DipSway\'in AI Botunun perde arkasını takip etmek için kolay bir yol oluşturduk - DipSway, hızla değişen kripto dünyasında size bir avantaj sağlamak için 112+ gösterge ve stratejiyi birleştirir.",
        'learn_more_about_dipsway_ai': 'DipSway AI hakkında daha fazla bilgi edinin',

        'autopilot_buys_sharp_as_knife': 'Otomatik pilot alımları, bıçak gibi keskin',
        'autopilot_buys_sharp_as_knife_desc': 'Çoğu kripto piyasası yatay hareket eder ve büyük fiyat dalgalanmaları uzun sürmez. Makine Öğrenimi karar verme süreci sayesinde, AI bot, yatay piyasalarda sizi sürekli kârlı tutacak ve büyük düşüşler olduğunda bunlardan yararlanacak keskin alım emirleri yürütmek üzere tasarlanmıştır.',
        'other_strategies_dont_work': 'Diğer mevcut stratejiler işe yaramaz çünkü statiktirler ve piyasa değişiklikleriyle birlikte evrimleşemezler.',

        'autopilot_sells_and_youre_in_profit': 'Otomatik pilot satışlar ve siz kârdasınız.',

        'dont_let_downturns_catch_you_off_guard': 'Düşüşlerin sizi hazırlıksız yakalamasına izin vermeyin.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ gösterge ve ihtiyacınız olan her metrik tek bir platformda.',

        'your_bot_runs_in_cloud': 'Botunuz Bulut\'ta 7/24 çalışır.',
        'your_bot_runs_in_cloud_desc': 'Botumuz 7/24 çalışan bulut tabanlı bir ticaret botudur, bu yüzden çevrimdışı olma konusunda endişelenmenize gerek yok! Her zaman piyasaya bağlıdır, alım ve satım için en iyi zamanlamayı analiz eder.\n',

        'unfair_advantage': 'size haksız bir avantaj sağlamak için.',
        'and_many_more': 'Ve daha fazlası...',

        'pricing:title_site': 'DipSway Fiyatlandırma',
        'pricing:description_site': "Uygun Fiyatlı Kripto Ticaret AI Botu | Botunuzu Bugün Başlatın | 7/24 Otomatik Ticaretten Yararlanın",
        'pricing:supercharge_text': 'DipSway AI ile ticaretinizi hızlandırın',

        'performance:title_site': 'DipSway Performans Geçmişi ve Geriye Dönük Testler',
        'performance:description_site': "Kripto Ticaret AI Botumuzla canlı geriye dönük testleri deneyimleyin. 7/24 çalışan botunuzu bugün başlatın. Kolaylığın tadını çıkarın ve kârlarınızı maksimize edin.",
        'performance:label': "DipSway Performans Geçmişi ve Geriye Dönük Testler",

        'connect_to_exchange': 'Borsanıza bağlanın, otomatik pilotu açın ve işlem tamam.',
        'calculate_your_earnings': 'Kazançlarınızı hesaplayın',

        'trading_optimization:title_site': "DipSway AI Ticaret Optimizasyonu",
        'trading_optimization:description_site': "Kripto Ticaret AI Botumuzla AI Ticaret Optimizasyonunu deneyimleyin. 7/24 çalışan botunuzu bugün başlatın. Kolaylığın tadını çıkarın ve kârlarınızı maksimize edin.",
        'trading_optimization:label': "AI Ticaret Optimizasyonu",
        'trading_optimization:breakdown': 'Analiz: DipSway\'in AI Kripto Ticareti',

        'trading_optimization:desc': 'DipSway\'in güçlü AI botu, hızlı piyasa değişikliklerine dinamik olarak uyum sağlar.',
        'trading_optimization:steps': 'Tüm bunlar bir dizi adım aracılığıyla yapılır:',

        'behind_scenes:behind_the_scenes': 'Perde arkasında neler oluyor?',

        'behind_scenes:indicators': 'Bazı göstergeler potansiyel bir alım sinyali (yeşil) gösterirken, diğerleri potansiyel bir satış sinyali (gri) gösterir.',
        'behind_scenes:neural_network': 'DipSway Sinir Ağı tüm bu göstergeleri dikkate alır ve genel resme dayalı bir karar verir, tek bir alım veya satım sinyali üretir.',

        'affiliate_program:title_site': 'Kripto bot ortaklık programı',
        'affiliate_program:description_site': "Ortaklık Programımıza katılın. Arkadaş davetlerinde %30 tekrarlayan komisyondan yararlanın.",
        'affiliate_program:label': "Ortaklık Programı",
        'affiliate_program:desc': "Her yönlendirmede %30 komisyon kazanın, tekrarlayan",
        'affiliate_program:desc2': "Borsanıza bağlanın, otomatik pilotu açın ve işlem tamam.",
        'affiliate_program:get_your_affiliate_link': 'Ortaklık bağlantınızı alın',

        'conference': 'Konferans',

        'about:title_site': "DipSway Hakkında",
        'about:our_journey': 'Yolculuğumuz',
        'about:our_journey_desc': 'Yatırım cüzdanlarını büyüten ve günlük performanslarını artıran her seviyeden\n' +
            'tüccar için yüksek kaliteli, kârlı AI kripto ticaret botları sağlamak.',
        'about:just_getting_started': 'Ve biz daha yeni başlıyoruz.',

        'about:description_site': "Kripto Ticaret AI Botumuzla AI Ticaret Optimizasyonunu deneyimleyin. 7/24 çalışan botunuzu bugün başlatın. Kolaylığın tadını çıkarın ve kârlarınızı maksimize edin.",
        'about:label': 'Hakkında',
        'about:behind_scenes': 'Perde arkası',
        'founder': 'Kurucu',

        'about:founder1_msg': 'DipSway, kripto ticaretini son teknoloji AI ile devrimleştirme vizyonuyla doğdu. Yazılım mühendisleri ve kripto meraklıları olarak, gerçekten yenilikçi bir şey yaratma fırsatı gördük - profesyonel bir tüccar gibi düşünen ancak insanüstü hızda çalışan bir ticaret botu.\nGüçlü teknik uzmanlığımız sayesinde, atılımımız sadece önceden belirlenmiş kuralları takip etmeyen, aynı zamanda piyasa dinamiklerine gerçek zamanlı olarak uyum sağlayan bir AI geliştirdiğimizde geldi.',
        'about:founder2_msg': 'Bu sıradan bir ticaret botu değil; büyük miktarda veri analiz eden, karmaşık kalıpları tanıyan ve dikkat çekici bir doğrulukla saniyeler içinde kararlar veren sofistike bir sistem.\nDipSway\'i farklı kılan şey, AI öğrenmeye yönelik benzersiz yaklaşımımızdır. Bu, sizin için 7/24 çalışan uzman tüccarlar ekibine sahip olmak gibidir, ancak daha hızlı ve daha verimlidir.\nMisyonumuz, deneyimli profesyonellerden meraklı yeni başlayanlara kadar herkes için gelişmiş kripto ticaretini erişilebilir kılmaktır.',

        'about:join_team': 'DipSway ekibine katılın',
        'about:join_team_desc': 'Finans, ticaret ve teknoloji konusunda tutkulu olan kripto meraklılarını arıyoruz.\n' +
            'Dünyanın kriptoya yatırım yapma şeklini değiştirmeye hazır mısınız?',

        'read_post': 'Gönderiyi oku',

        'about:development_starts': "Geliştirme başlıyor",
        'about:development_starts_desc': "Bir gün Coinbase mobil uygulamasından Bitcoin\'in son fiyatıyla ilgili \'Bitcoin bugün %5 yükseldi\' gibi bildirimler alıyorduk. Bu tür bildirimlerden ÖNCE çalışacak bir bota sahip olmanın harika olacağını düşündük. Web\'de aradık ve bulutta çalışan, kullanımı kolay bir şey bulamadık. Bu yüzden kendimiz inşa etmeye karar verdik.",

        'about:first_bot_version': "İlk bot versiyonu",
        'about:first_bot_version_desc': "Bitcoin ve Ethereum için Algoritmik Ticaret stratejisinin ilk simülasyonu, daha fazla kripto varlık ve daha fazla strateji eklemeye başladık. Botumuzun potansiyelini görmeye başladık ve halka açmaya karar verdik.",

        'about:simple_strategy_not_enough': '"Basit bir strateji" yeterli değildi',
        'about:simple_strategy_not_enough_desc': "Piyasadan öğrenecek ve tahminler yapacak bir sinir ağı inşa ettik ve eğittik. Sonuçlar çok yüksek bir doğruluk gösterdi.",

        'about:live_bot_release': "Canlı bot lansmanı",
        'about:live_bot_release_desc': "Piyasadan öğrenecek ve tahminler yapacak bir sinir ağı inşa ettik ve eğittik. Sonuçlar çok yüksek bir doğruluk gösterdi.",

        'about:our_transparency_value': "Şeffaflık değerimiz",
        'about:our_transparency_value_desc': "Canlı geriye dönük testler ve canlı performanslar halka açıklandı. Şeffaflığın, sonuçlarına dair hiçbir kanıt göstermeyen diğer şirketlerle aramızdaki kilit farklılaştırıcı olmasını istiyoruz.",

        'about:infrastructure_revolution': "Altyapı Devrimi",
        'about:infrastructure_revolution_desc': "2023, daha fazla tüccarın platformumuzu benimsemesiyle başarılı bir yıl oldu. Tüccarlarla olan etkileşimimizi artırdık, herkesin bir bot platformunda gerçekten neye ihtiyaç duyduğunu anlamaya çalıştık. Bu büyüyen kullanıcı tabanı göz önüne alındığında, DipSway\'i mühendislik düzeyinde hata yapmaz hale getirmenin önemini fark ettik. Heyecan verici yeni güncellemeler, özellikler, araçlar ve botlar tanıtmadan önce, sistemlerimizin 7/24 sorunsuz çalıştığından ve performans ve kalitenin en üst düzeyde olduğundan emin olmaya öncelik verdik.",

        'about:new_exchanges': "Yeni borsalar",
        'about:new_exchanges_desc': "Şubat ayında, Coinbase Advanced, BingX ve Bitmart gibi büyük borsaları entegre ederek platformumuzu önemli ölçüde genişlettik. Ayrıca KuCoin ve Bybit ile ortaklık kurarak her ikisinde de resmi üçüncü taraf uygulama olduk. Bu eklemeler ve ortaklıklar, kullanıcılarımızın ticaret seçeneklerini ve piyasa erişimini büyük ölçüde artırdı, platformumuzun güvenilirliğini doğruladı ve büyümemizde önemli bir kilometre taşı oldu.",

        'about:rotating_proxy': "Döner Proxy ve yeni öne çıkan özellikler",
        'about:rotating_proxy_desc': "Altyapımızda önemli bir yükseltme olarak, 20 ek IP uyguladık ve veritabanı replikalarımızı 2\'den 7\'ye genişlettik, borsalardaki yeni mumlara botların yanıt verme yeteneğini artırdık. Bu iyileştirme, botlarımızı hız ve güvenilirlik açısından piyasada rekabetçi bir şekilde konumlandırıyor. Ayrıca ﻿Crypto(.)com\'u entegre ederek desteklenen borsalarımızı ve kullanıcılar için ticaret seçeneklerini daha da genişlettik. Dikkat çeken yeni bir özellik, kullanıcıların bot performanslarını kontrol etmelerine ve en iyi performans gösteren bot ayarlarını kopyalamalarına olanak tanıyan Bot Liderlik Tablosudur. Bu geliştirmeler toplu olarak platformumuzun yeteneklerini güçlendirerek, kullanıcılara daha sağlam, verimli ve çeşitli ticaret fırsatları sunuyor.",

        'about:multiple_quote_currencies': "Çoklu kotasyon para birimleri",
        'about:multiple_quote_currencies_desc': "Botların USDT dışındaki çeşitli kotasyon çiftleriyle ticaret yapmasına olanak tanıyan çoklu para birimi desteğini tanıttık. Bu geliştirme, kullanıcılara daha fazla esneklik sunarak farklı borsa yapılarına ve coğrafi düzenlemelere uyum sağlıyor. Birden fazla para biriminde ticarete izin vererek, kullanıcıların belirli ihtiyaçlara ve piyasa koşullarına göre stratejilerini optimize etmelerini sağladık, genel ticaret deneyimlerini iyileştirdik.",

        'about:new_admin_dashboard': "Yeni yönetici kontrol paneli",
        'about:new_admin_dashboard_desc': "Yeni platformumuzun lansmanı, kullanıcı deneyimi ve işlevsellik açısından önemli bir sıçrama anlamına geliyor. Yeni özelliklerin daha hızlı geliştirilmesini ve teslim edilmesini sağlıyor, üst düzey destek sağlamak için bot sorun giderme üzerinde daha fazla kontrol sağlıyor. Arayüz ve arka uç arasındaki iletişimi güçlendirerek veri doğruluğunu ve güvenilir işlevselliği garanti altına aldık. Minimalist, temiz ve gezinmesi kolay bir tasarıma olan bağlılığımızı korurken, aynı zamanda platformu kapsamlı veri ve analizlerle zenginleştirdik. Bu denge, kullanıcılara basitlikten ödün vermeden derinlemesine içgörüler sağlayarak, kullanıcı dostu bir ortamda daha bilinçli karar vermeyi mümkün kılıyor.",

        'about:autopilot_tradingview': "Otomatik pilot ve TradingView sinyal otomasyonu",
        'about:autopilot_tradingview_desc': "Otomatik Pilot Botu, devrim niteliğindeki AI destekli ticaret çözümümüz. AI botlarımızın bu sıfır yapılandırma geliştirmesi, ticaret stratejilerini sürekli olarak optimize etmek için geçmiş verileri, sinir ağı hesaplamasını ve tüm DipSway botlarından CANLI verileri kullanır. Gerçek zamanlı piyasa parametrelerine dayalı olarak en iyi performans gösteren varlıkları otomatik olarak seçer ve yapılandırmaları ayarlar, kullanıcılara piyasa koşullarına anında uyum sağlayan eller serbest, veri odaklı bir ticaret deneyimi sunar.",

        'about:ai_futures_bot': "AI Vadeli İşlemler botu",
        'about:ai_futures_bot_desc': "Dahili SL & TP sisteminin tanıtılmasıyla (Trailing işlemleri yakında kullanıma sunulacak), tüm kullanıcılar artık botları için özel ayarlarında %100 başarı oranına sahip. Dahili sistem, gerçek bir AI Vadeli İşlemler botunun ilk neslini piyasaya sürmemize olanak tanıdı.",

        'about:new_features': "Yeni özellikler",
        'about:new_features_desc': "Kullanıcıların kendi stratejilerini oluşturmasına ve toplulukla paylaşmasına olanak tanıyacak yeni bir özellik üzerinde çalışıyoruz.",

        'proven_to_perform': 'Performans kanıtlandı',

        'see_how_dipsway_averages': 'DipSway botlarının gücünü kullanarak DipSway kullanıcılarının nasıl ortalama yaptığını görün.',
        'last': 'Son',
        'months_compound_roi': 'Ay Bileşik ROI',
        'total_return_based_on_last': 'Son döneme dayalı Toplam Getiri',
        'show_as_multiplier_values': 'Çarpan değerleri olarak göster',
        'show_as_percentage_values': 'Yüzde değerleri olarak göster',
        'join': 'Katıl',
        'stars': 'Yıldız',

        'faq': 'Sıkça Sorulan Sorular',
        'faq_desc': 'Cevaplarınızı burada bulun. SSS\'de ele alınmayan bir sorunuz varsa,\n' +
            'lütfen bizimle iletişime geçmekten çekinmeyin.',
        'faq_sub_desc': 'Aradığınız cevabı bulamadınız mı?',
        'contact_support': 'Desteğe başvurun',

        'view_supported_platform': 'Desteklenen Platformları Görüntüle',

        'simulate_based_on_past_performances': 'Geçmiş performansa dayalı simülasyon yap',
        'calculate_your_earnings_desc': 'Borsa ücretleri, coin performansları gibi faktörleri dikkate alan ve DipSway botlarının sizin sermaye büyüklüğünüzle nasıl ticaret yapacağını simüle eden DipSway\'in geçmiş verilerine dayalı tam karlılık analizi.',

        'go_to_tweet': 'Tweet\'e git',
        'view_on': 'Görüntüle',

        'security': 'Güvenlik',
        'safe_and_secure': 'Güvenli ve emniyetli',
        'safe_and_secure_desc': 'Fonlarınız borsanızda kalır ve biz sizin için işlem yapmak için\n' +
            'yalnızca minimum izinler isteriz.',

        'follow_dipsway_development': 'DipSway\'in gelişimini takip edin ve toplulukla tanışın',
        'join_us_on_telegram': 'Telegram\'da bize katılın',
        'here_when_you_need_help': 'Yardıma ihtiyacınız olduğunda buradayız',
        'dipsway_customer_care': 'DipSway Müşteri Hizmetleri',

        'for_us_top_10_percent': 'Bizim için üst %10, DipSway kullanıcıları için standarttır. Farklı yapılandırmalarla\n' +
            'deney yapmakta özgürsünüz, ancak optimize etmek için yardıma ihtiyacınız olduğunda\n' +
            'biz buradayız.',

        'join_the_telegram_community': 'Telegram topluluğuna katılın',

        'made': 'kâr etti',
        'profit_on': 'tek bir işlemde',
        'a_single_trade': 'kâr',

        'dont_go_back_to_manual_trading': 'Manuel ticarete geri dönmeyin,',

        'get_in_before_the_pump': 'yükselişten önce girin',
        'get_out_before_the_drop': 'düşüşten önce çıkın',

        'on_autopilot': 'Otomatik pilotta',

        'start_your_dipsway_bot': 'DipSway botunuzu 4 tıklamayla başlatın',

        'safe_for_you': 'Sizin için güvenli, tasarım gereği',
        'safe_for_you_desc': 'DipSway ile fonlarınız kripto borsanızda güvende, ve bize yalnızca\n' +
            'botların çalışması için gereken kadar güvenmenizi istiyoruz.',

        'ip_whitelisting': 'IP beyaz listesi',
        'ip_whitelisting_desc': 'Yalnızca DipSway kontrollü IP adresleri hesabınızda işlem yapabilir, API anahtarınız çalınmış olsa bile kötü niyetli aktörler işlem yapamaz.',

        'restricted_access': 'Kısıtlı erişim',
        'restricted_access_desc': 'DipSway yalnızca sizin adınıza izlemek ve işlem yapmak için\n' +
            'en basit izinleri ister.',

        'bright_green_performances': 'Parlak yeşil performanslar',
        'on_capital': 'Sermaye üzerinde',
        'win_rate': 'kazanma oranı',
        'average_trade': 'ortalama işlem',

        'see_dipsway_live_backtests': 'DipSway canlı geriye dönük testleri ve performansını görün',
        'find_and_download': 'Geçmiş performans verilerini ve canlı geriye dönük testleri bulun ve indirin.',

        'available_at_no_extra_cost': 'Ek maliyet olmadan kullanılabilir',

        'full_autopilot_mode': 'Tam Otomatik Pilot modu',
        'full_autopilot_mode_desc': 'DipSway Otomatik Pilot ile sadece işlemleriniz otomatikleştirilmekle kalmaz,\n' +
            'DipSway aynı zamanda o an için en iyi kripto para birimini\n' +
            'piyasa koşullarına göre seçer.',

        'calculate_your_earnings_with_autopilot': 'Otomatik Pilot ile kazançlarınızı hesaplayın',
        'high_performance': 'Yüksek performans',
        'live_backtests_historical_performance': 'Canlı geriye dönük testler, geçmiş performans.',
        'backtests_performance': 'Geriye dönük testler ve performans',
        'extendable_via_tradingview': 'TradingView üzerinden genişletilebilir',
        'use_power_tradingview': 'DipSway\'i daha fazla yapılandırmak için TradingView\'in gücünü kullanın.',
        'coming_soon': 'Yakında...',
        '3_green_trades_in_30_days': '30 günde 3 kârlı işlem veya para iade garantisi',
        'need_help_choosing': 'Seçim yaparken yardıma mı ihtiyacınız var?',
        'use_dipsway_earnings_calculator': 'DipSway kazanç hesaplayıcısını kullanın, ne kadar kazanabileceğinizi öğrenin ve bir plan önerisi alın.',
        'launch_earnings_calculator': 'Kazanç hesaplayıcıyı başlat',
        'popular': 'Popüler',
        'recommended_for_you': 'Sizin için önerilen',
        'no_deposit_required': 'Depozito gerekmiyor',
        'top_10_of_users': "Kullanıcıların en iyi %10\'u",
        'all_users': "Tüm kullanıcılar",
        'downtrend_page:desc1': 'İşlem: Acil pozisyon çıkışı. Düşüş trendi tespit edildi.',
        'downtrend_page:sell_order_fulfilled': '12 BTC için satış emri gerçekleştirildi.',
        'performance_history': 'Performans Geçmişi',
        'trained_to_recognize_opportunities': 'Fırsatları tanımak için eğitildi',
        'live_backtests_performances': 'Canlı geriye dönük test performansları',
        'see_how_our_strategies_performed': 'Stratejilerimizin geçmişte nasıl performans gösterdiğini yüksek kaliteli canlı geriye dönük testlerle görün.',
        'backtests_from': 'Geriye dönük testler',
        'past_6_months': 'son 6 ay',
        'collapse': 'Daralt',
        'expand': 'Genişlet',
        'view_report': 'Raporu görüntüle',
        'knowledge:title1': 'Yüksek kaliteli Geçmiş Veri',
        'knowledge:description1': 'Kullanılan canlı mumların en yüksek kalitede olmasını sağlıyoruz.',
        'knowledge:title2': 'Ücretler ve Gecikmeler dahil',
        'knowledge:description2': 'Her işleme %0.1 maker ve taker ücreti uygulanır. Her işleme %0.1 slipaj ve birkaç dakikalık gecikme uygulanır.',
        'knowledge:title3': 'Aşırı Uyum Önleme',
        'knowledge:description3': 'DipSway, kötü performans gösteren hesapları pasif olarak izler ve eylemler önerir.',
        'operation': 'İşlem',
        'buy_the_dip': 'Düşüşü satın al',
        'sell_the_high': 'Yükselişi sat',
        'bought': 'Satın alındı',
        'watching_market': 'piyasayı izliyor',
        'sell_order_for': 'için satış emri',
        'fulfilled': 'gerçekleştirildi',
        'profit_made': 'Elde edilen kâr',
        'calculation_results': 'Hesaplama sonuçları',
        'calculating': 'Hesaplanıyor',
        'total_expected_profit_at_the_end_of': 'dönem sonunda beklenen toplam kâr',
        'period_based_off_of_historical_backtest_performances': 'geçmiş geriye dönük test performanslarına dayalı dönem',
        'trades': 'işlem',
        'day': 'gün',
        'profit': 'kâr',
        'recommended_plan_for_you': 'Sizin için önerilen plan',
        'go_to': 'Git',
        'free_trial_available': 'Ücretsiz Deneme Mevcut',
        'runs_at': 'Çalışma hızı',
        'learn_how_we_calculate_your_earnings': 'Kazançlarınızı nasıl hesapladığımızı öğrenin',
        'click_to_open_deepdive': 'Desteklediğimiz diğer borsalar arasından seçim yapabileceğiniz derinlemesine analize gitmek için tıklayın.',
        'your_capital': 'Sermayeniz',
        'currency': 'Para birimi',
        'enabled': 'Etkin',
        'set_to': 'ayarlandı',
        'coin_count': 'coin sayısı',
        'disabled': 'Devre dışı',
        'auto': 'Otomatik',
        'selected_coins': 'Seçilen coinler',
        'loading_coins': 'Coinler yükleniyor',
        'login': 'Giriş yap',

        'trading_with': 'ile ticaret yapıyor',

    },

    nl: {

        'all_payment_types':'alle betaalmethoden',
        'all_payment_types_description': 'Ervaar moeiteloze transacties met Bitcoin, Ethereum en meer. Betaal veilig via de vertrouwde Cryptomus-gateway voor directe toegang tot de functies van DipSway.\n',
        'extra':'Extra',

        'no-token:footer.disclaimer': 'DipSway verkoopt, biedt, faciliteert of verleent geen toegang tot DeFi-tokens of cryptocurrencies op enige blockchain. Wij zijn uitsluitend een softwareleverancier.',

        "different_ways_to_swap": "Verschillende Manieren om te Ruilen",

        'coin_swap_description': 'Optimaliseer uw {crypto} naar {quote} trades met onze Coin Swap Paths finder. Vergelijk direct meerdere {crypto}/{quote} conversie routes, visualiseer complexe swaps en beveilig de beste wisselkoersen voor uw {crypto} naar {quote} conversies. Perfect voor zowel beginnende als ervaren crypto-traders die hun {crypto} naar {quote} trades willen maximaliseren.',

        "current_price_info": "De huidige {crypto} prijs is {price}, wat een verandering van {change24h} in de afgelopen 24 uur en {change7d} in de afgelopen 7 dagen betekent.",
        "circulating_supply_info": "Met een circulerende voorraad van 21M is de live marktkapitalisatie van {crypto} {marketCap}.",
        "trading_volume_info": "Het 24-uurs handelsvolume is {volume}.",
        "conversion_rate_calculation": "De conversiekoersen worden berekend met behulp van de Live {crypto} Prijsindex samen met andere digitale activaprijsindexen.",
        "exchange_rate_update": "De {crypto}/{quote} wisselkoers wordt in realtime bijgewerkt.",
        "follow_dipsway_info": "Volg DipSway's crypto converter en calculator voor 24/7 live updates van de wisselkoersen tussen crypto en fiat valuta's, waaronder {crypto}, {currencies}, en meer.",

        "description_calculator_pt_1": "Deze calculator biedt een wisselkoers voor",
        "description_calculator_pt_2": "naar",
        "description_calculator_pt_3": "De wisselkoers is gebaseerd op de marktkoers van de laatste 24 uur.",
        "to": "naar",
        "converter": "Omrekenmachine",
        "crypto_prices_and_market_data": "Crypto Prijzen & Marktgegevens",
        "converter_site:desc": "Converteer tussen cryptocurrencies en fiat valuta's met onze gebruiksvriendelijke omrekentool. Krijg de laatste wisselkoersen en marktgegevens voor uw trades.",
        "callout_calculator": "AI-aangedreven Crypto Trading Bots",
        "info_on_price": "Prijsinformatie",
        "live_data": "Live gegevens",

        "prices_of": "Prijzen van",
        "on_different_exchanges": "op verschillende crypto beurzen",
        "converted_to": "Omgerekend naar",
        "on_different_amounts": "voor verschillende bedragen",
        "up_of": "Stijging van",
        "dropped_of": "Daling van",
        "respect_yesterday": "ten opzichte van gisteren",
        "close_price": "Slotkoers",
        "price_variation": "Prijsvariatie",
        "vwap": "Gemiddelde prijs",
        "volume_last_24h": "Volume 24u",
        "exchange": "Beurs",
        "price": "Prijs",
        "volume": "Volume",
        "quote_volume": "Quote Volume",
        "percentage": "Percentage",
        "asset": "Asset",
        "today_at": "Vandaag om",
        "calculator_title": "Calculator van {crypto} naar {quote}",
        "crypto_label": "Crypto",
        "currency_label": "Quote",
        "enter_amount": "Voer het bedrag in",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Handel in {crypto}",

        'search_for_crypto': 'Zoek naar crypto',
        'crypto_prices_title': 'Ondersteunde Cryptocurrency Prijzen Laatste 24u Verandering',
        'see_current_crypto_market_trend': 'Bekijk de huidige trend van de cryptomarkt',

        'see_more': 'Meer zien',
        'dipsway': 'DipSway',
        'home_page': 'startpagina',
        'blogs.related_posts': 'Gerelateerde berichten',
        'blogs.automate_your_trading': 'Automatiseer uw handel vandaag nog met DipSway AI.',
        'blogs.automate_your_trading_description': 'DipSway gebruikt een combinatie van 121+ technische indicatoren en 17+ patroondetectoren om u een oneerlijk voordeel te geven.',
        'btn.start_7_day_free_trial': 'Start 7 dagen gratis proefperiode',
        'blogs.tags': 'Tags',
        'blogs.description_site': 'Crypto tutorials, feature releases, productvergelijkingen en meer! Vind content van het DipSway team hier op de DipSway blog.',
        'blogs.title_site': 'DipSway Blog',
        'blogs.callout': 'Introductie van versie 4.13',
        'blogs.description_extended': 'Tutorials, feature releases, productvergelijkingen en meer!\nVind content van het DipSway team hier op de DipSway blog.',
        'blogs.go_to_article': 'Ga naar artikel',
        'skip_to_content': 'Ga naar inhoud',
        'calculate_your_earnings_and_start_free_trial': 'Bereken uw inkomsten & Start 7 dagen gratis proefperiode',

        'privacy_policy': 'Privacybeleid',
        'terms_of_service': 'Servicevoorwaarden',
        'refund_policy': 'Terugbetalingsbeleid',
        'affiliate_policy': 'Partnerbeleid',
        'blog': 'Blog',
        'supported_exchanges': 'Ondersteunde beurzen',
        'pricing': 'Prijzen',
        'resources': 'Bronnen',
        'company': 'Bedrijf',
        'support': 'Ondersteuning',
        'affiliate_program': 'Partnerprogramma',
        'about_us': 'Over ons',
        'customer_success_agent': 'Klantsuccesagent',
        'dipsway_ai_optimization': 'DipSway AI-optimalisatie',
        'live_back_tests': 'Live backtests',
        'live_back_tests_performances': 'DipSway live backtests & prestaties',
        'historical_performance_data': 'Historische prestatiegegevens',
        'dipsway_earning_calculator': 'DipSway inkomstencalculator',
        'performance': 'Prestaties',
        'common:footer.disclaimer': 'DipSway levert alleen software. Alle verwijzingen naar handels-, uitwisselings-, overdrachts- of walletdiensten, etc. zijn verwijzingen naar diensten die worden geleverd door externe dienstverleners.',

        'index:hero.title': 'DipSway - Zero configuratie, volledig automatische AI crypto trading bots',
        'index:hero.description': 'Ervaar naadloze crypto-handel met onze AI-bots. Start uw Crypto Trading AI-bot voor 100% geautomatiseerde trades, 24/7 beschikbaar op een eenvoudige interface.',

        'header:product': 'Product',
        'header:product.feature.header': 'Wat kan uw kapitaal verdienen met DipSway?',
        'header:product.feature.description': 'Bereken uw inkomsten op basis van de backtest geschiedenis van DipSway.',
        'header:product.feature.cta': 'Bereken uw inkomsten',

        'header:product.links.spot_ai_trading_bot': 'Spot AI Trading Bot',
        'header:learn': 'Leren',
        'header:learn.links.ai_trading_optimization': 'AI + Crypto Trading',

        'welcome:headline': 'Zero configuratie, volledig automatische AI crypto trading bots',
        'welcome:subheadline': 'Maak verbinding met uw beurs, zet de autopilot aan en u bent klaar.',
        'start_7_days_no_credit_card': 'Start 7 dagen gratis proefperiode - Geen storting vereist.',

        '404:title_site': '404 - DipSway | AI-aangedreven Crypto Trading Bot',
        '404:description_site': 'Vind uw weg terug naar crypto trading met onze AI-bot. Start uw Crypto Trading AI-bot voor 100% geautomatiseerde trades, 24/7 beschikbaar op een eenvoudige interface.',
        '404:page_not_found': 'Pagina niet gevonden',
        '404:page_not_found_message': 'Het lijkt erop dat u een ... gebroken link heeft gevonden! Laat DipSway support alstublieft weten\nwelke pagina u hier naartoe bracht!',
        '404:go_home': 'Ga naar home',
        '404:open_dashboard': 'Open dashboard',

        'affiliate_program_policy:title_site': "DipSway Partnerprogramma Beleid",
        'affiliate_program_policy:description_site': "DipSway Partnerprogramma Beleid",

        'crypto_bot_calculator:title_site': "Crypto trading bot inkomstencalculator",
        'crypto_bot_calculator:description_site': "Volledige winstgevendheidsanalyse op basis van DipSway\'s historische gegevens, rekening houdend met zaken zoals beurskosten, coinprestaties, en simulatie van hoe DipSway bots zouden handelen met uw kapitaalomvang.",
        'crypto_bot_calculator:label': 'Crypto trading bot inkomstencalculator',
        'crypto_bot_calculator:simulate_based_on': 'Simuleer op basis van eerdere prestaties',
        'crypto_bot_calculator:calculate_your_earnings': 'Bereken uw inkomsten',
        'crypto_bot_calculator:description': 'Volledige winstgevendheidsanalyse op basis van DipSway\'s historische gegevens, rekening houdend met zaken zoals beurskosten, coinprestaties, en simulatie van hoe DipSway bots zouden handelen met uw kapitaalomvang.',

        'featured_on': 'Uitgelicht op',
        '4_clicks_to_start': '4 klikken om te starten',
        'no_knowledge_required': 'Geen configuratie, geen kennis vereist',
        'split_content_description': 'DipSway is echt zero config. verbind uw beurs → zet uw bot aan →\n' +
            '      en monitor de prestaties. De best presterende configuraties worden\n' +
            '      standaard toegepast.',
        'learn_about_dipsway_ai': 'Leer over DipSway AI',

        'trained_for_down_trends': 'Getraind om neerwaartse trends te detecteren',
        'be_safe_on_down_trends': 'Wees veilig, zelfs bij neerwaartse trends',
        'autopilot_content_headline_1': 'Binnen voor de pump, buiten voor de drop,',
        'autopilot_content_headline_2': 'op autopilot',
        'autopilot_content_description': 'DipSway analyseert markten en plaatst trades terwijl u bezig bent met uw\n' +
            '      dagelijkse verplichtingen, en houdt u op de hoogte met statusupdates.',
        'downtrends_description': 'DipSway\'s AI is getraind om neerwaartse trends te identificeren en voordat de zaken uit de hand lopen,\n' +
            '      zal het u eruit halen',

        'see_how_dipsway_works': 'Zie hoe DipSway werkt',

        'free_trial_action:label': 'Klaar wanneer u dat bent.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Veiligheid',
        'safety_description': 'Houd uw fondsen veilig op uw beurs, uw veiligheid is onze prioriteit.',

        'ai_trading_optimization_description': 'Ons eigen AI-model getraind om kansen te herkennen.',

        '3months': '3 maanden',
        'total_profits_within_label': 'Totale winsten over alle ingezette DipSway bots in de laatste',

        'dipsway_uses_combination_of': 'DipSway gebruikt een combinatie van',
        'and': 'en',
        'technical_indicators': 'technische indicatoren',
        'pattern_detectors': 'patroondetectoren',
        'detect_next_move': 'om de volgende zet van de markt te detecteren.',

        'are_you_influencer': 'Bent u een influencer? Partner met ons',
        'influencer_description': 'Influencers die met ons samenwerken krijgen een aangepaste onboarding landingspagina\n' +
            '      op maat gemaakt voor de interesses van hun gebruikersbestand.',

        'get_in_touch': 'Neem contact op',

        'spot_ai_bot:title_site': 'Spot AI Crypto Trading Bot | 100% Geautomatiseerde Trades',
        'spot_ai_bot:description_site': 'Zet uw Crypto Trading Bot aan en geniet van 100% geautomatiseerde trades. Start uw bot vandaag voor een 24/7 draaiende bot. Geniet van gemak en maximaliseer uw winsten.',

        'spot_ai_bot:24_7_automated_trading': '24/7 geautomatiseerde handel, bijna onmiddellijke trade plaatsing',
        'spot_ai_bot:algo_crypto_by_ml': 'AI Algoritmische Crypto Trading, aangedreven door Machine Learning',

        'spot_ai_bot:desc': 'De beste trading bot in de industrie, gebruikmakend van AI om de markt te analyseren en\n' +
            'handelsbeslissingen te nemen op machine-achtige snelheid.',

        'million': 'miljoen',

        'avg_monthly_volume_moved_by_bots': 'Gemiddeld maandelijks volume verplaatst door DipSway bots.',
        'dipsway_has_more_than_350_users': 'DipSway heeft meer dan 350 gebruikers wereldwijd.',
        'dipsway_supports_315_coins': 'DipSway ondersteunt handel voor meer dan 315+ valuta\'s.',

        'ai_powered': "AI-aangedreven",
        'trading_strategies_powered_by_ai': "De handelsstrategieën worden aangedreven door AI, gebruikmakend van een combinatie van technische analyse, fundamentele analyse.",

        'geolocation_advantage': "Geolocatie voordeel",
        'geolocation_advantage_desc': "De servers die de bot hosten bevinden zich op dezelfde geografische locatie als de beurs, om de laagst mogelijke latentie te garanderen (gem. ~932μs vertraging).",

        'high_frequency_trading': 'High Frequency Trading',
        'high_frequency_trading_desc': 'Dankzij een multi-tenancy architectuur worden de handelsstrategieën uitgevoerd met een granulariteit van candlesticks van 1 seconde.',

        'advanced_risk_management': 'Geavanceerd risicobeheer',
        'advanced_risk_management_desc': 'De bot is in staat om investeringen in meerdere valuta\'s te beheren en om het risico van de portefeuille automatisch af te dekken.',

        'optimization_overfitting_avoidance': 'Optimalisatie & Overfitting Vermijding',
        'optimization_overfitting_avoidance_desc': 'De bot is in staat om de parameters van de handelsstrategieën te optimaliseren en overfitting te vermijden met behulp van een AI-genetisch algoritme en een aangepaste Walk-Forward Analyse.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'Elke 24 uur voeren we backtests uit van alle handelsstrategieën en herberekenen we de gewichten die door AI worden gebruikt met behulp van een "Online Learning" benadering.',
        'how_do_dipsways_strategies_work': "Hoe werken DipSway\'s strategieën?",
        'no_one_wants_to_invest_in_black_box': "Niemand wil investeren in een black box. Daarom hebben we een eenvoudige manier gecreëerd om de achtergrond van DipSway\'s AI Bot te volgen - DipSway combineert 112+ indicatoren & strategieën om u een voordeel te geven in de snel veranderende wereld van crypto.",
        'learn_more_about_dipsway_ai': 'Leer meer over DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Autopilot aankopen, scherp als een mes',
        'autopilot_buys_sharp_as_knife_desc': 'De meeste cryptomarkten bewegen zijwaarts en grote prijsschommelingen duren niet lang. Dankzij een Machine Learning beslissingsproces is de AI-bot gebouwd om scherpe kooporders uit te voeren die u voortdurend winstgevend houden in zijwaartse markten en profiteren wanneer die grote dips zich voordoen.',
        'other_strategies_dont_work': 'Andere strategieën werken niet omdat ze statisch zijn en niet kunnen evolueren met marktveranderingen.',

        'autopilot_sells_and_youre_in_profit': 'Autopilot verkoopt, en u bent in winst.',

        'dont_let_downturns_catch_you_off_guard': 'Laat neergangen u niet verrassen.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ indicatoren en elke metric die u nodig heeft in één platform.',

        'your_bot_runs_in_cloud': 'Uw bot draait in de Cloud, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Onze bot is een cloud-gebaseerde trading bot die 24/7 draait, dus u hoeft zich geen zorgen te maken over offline gaan! Hij is altijd verbonden met de markt, analyseert de beste timing om te kopen en verkopen.\n',

        'unfair_advantage': 'om u een oneerlijk voordeel te geven.',
        'and_many_more': 'En nog veel meer...',

        'pricing:title_site': 'DipSway Prijzen',
        'pricing:description_site': "Betaalbare Crypto Trading AI Bot | Start Uw Bot Vandaag | Geniet van 24/7 Geautomatiseerde Handel",
        'pricing:supercharge_text': 'Supercharge uw trading met DipSway AI',

        'performance:title_site': 'DipSway Prestatiegeschiedenis & Backtests',
        'performance:description_site': "Ervaar live backtests met onze Crypto Trading AI Bot. Start uw bot vandaag voor een 24/7 draaiende bot. Geniet van gemak en maximaliseer uw winsten.",
        'performance:label': "DipSway Prestatiegeschiedenis & Backtests",

        'connect_to_exchange': 'Verbind met uw beurs, zet de autopilot aan en u bent klaar.',
        'calculate_your_earnings': 'Bereken uw inkomsten',

        'trading_optimization:title_site': "DipSway AI Handelsoptimalisatie",
        'trading_optimization:description_site': "Ervaar AI Handelsoptimalisatie met onze Crypto Trading AI Bot. Start uw bot vandaag voor een 24/7 draaiende bot. Geniet van gemak en maximaliseer uw winsten.",
        'trading_optimization:label': "AI Handelsoptimalisatie",
        'trading_optimization:breakdown': 'Uitsplitsing: DipSway\'s AI Crypto Trading',

        'trading_optimization:desc': 'DipSway\'s krachtige AI-bot past zich dynamisch aan aan de snelle marktveranderingen.',
        'trading_optimization:steps': 'Dit alles gebeurt via een reeks stappen:',

        'behind_scenes:behind_the_scenes': 'Wat gebeurt er achter de schermen?',

        'behind_scenes:indicators': 'Sommige indicatoren tonen een potentieel koopsignaal (groen), terwijl andere een potentieel verkoopsignaal tonen (grijs).',
        'behind_scenes:neural_network': 'Het DipSway Neurale Netwerk houdt rekening met al deze indicatoren en neemt een beslissing op basis van het totaalbeeld, waarbij het één enkel koop- of verkoopsignaal produceert.',

        'affiliate_program:title_site': 'Cryptocurrency bot partnerprogramma',
        'affiliate_program:description_site': "Word lid van ons Partnerprogramma. Geniet van 30% terugkerende commissie op vrienduitnodigingen.",
        'affiliate_program:label': "Partnerprogramma",
        'affiliate_program:desc': "Verdien 30% commissie op elke doorverwijzing, terugkerend",
        'affiliate_program:desc2': "Verbind met uw beurs, zet de autopilot aan en u bent klaar.",
        'affiliate_program:get_your_affiliate_link': 'Krijg uw partnerlink',

        'conference': 'Conferentie',

        'about:title_site': "Over DipSway",
        'about:our_journey': 'Onze reis',
        'about:our_journey_desc': 'Om hoogwaardige, winstgevende AI crypto trading bots te leveren voor handelaren\n' +
            'van elk niveau die hun investeringsportefeuille laten groeien en hun dagelijkse\n' +
            'prestaties verbeteren.',
        'about:just_getting_started': 'En we zijn nog maar net begonnen.',

        'about:description_site': "Ervaar AI Handelsoptimalisatie met onze Crypto Trading AI Bot. Start uw bot vandaag voor een 24/7 draaiende bot. Geniet van gemak en maximaliseer uw winsten.",
        'about:label': 'Over',
        'about:behind_scenes': 'Achter de schermen',
        'founder': 'Oprichter',

        'about:founder1_msg': 'DipSway is ontstaan uit een visie om crypto trading te revolutioneren door middel van geavanceerde AI. Als software-ingenieurs en crypto-enthousiastelingen zagen we een kans om iets echt innovatiefs te creëren - een trading bot die denkt als een professionele trader maar op bovenmenselijke snelheid opereert.\nDankzij onze sterke technische expertise kwam onze doorbraak toen we een AI ontwikkelden die niet alleen vooraf ingestelde regels volgt, maar zich in realtime aanpast aan marktdynamiek.',
        'about:founder2_msg': 'Dit is geen gemiddelde trading bot; het is een geavanceerd systeem dat enorme hoeveelheden gegevens analyseert, complexe patronen herkent en in een fractie van een seconde beslissingen neemt met opmerkelijke nauwkeurigheid.\nWat DipSway onderscheidt, is onze unieke benadering van AI-leren. Het is alsof je een team van experthandelaren 24/7 voor je hebt werken, maar sneller en efficiënter.\nOnze missie is om geavanceerde crypto trading toegankelijk te maken voor iedereen, van doorgewinterde professionals tot nieuwsgierige nieuwkomers.',

        'about:join_team': 'Word lid van het DipSway team',
        'about:join_team_desc': 'We zijn op zoek naar crypto-enthousiastelingen die gepassioneerd zijn over financiën,\n' +
            'trading en technologie. Klaar om de manier waarop de wereld investeert in\n' +
            'crypto te veranderen?',

        'read_post': 'Lees bericht',

        'about:development_starts': "Ontwikkeling begint",
        'about:development_starts_desc': "Op een dag kregen we meldingen van de Coinbase mobiele app over de recente prijs van Bitcoin zoals \'Bitcoin is vandaag 5% gestegen\'. We dachten dat het cool zou zijn om een bot te hebben die VOOR dergelijke meldingen zou werken. We zochten op het web en konden niets vinden dat gemakkelijk te gebruiken was en in de cloud draaide. Dus besloten we het zelf te bouwen.",

        'about:first_bot_version': "Eerste bot versie",
        'about:first_bot_version_desc': "Eerste simulatie van Algo-Trading strategie voor Bitcoin en Ethereum, we begonnen meer crypto-activa en meer strategieën op te nemen. We begonnen het potentieel van onze bot te zien en besloten het openbaar te maken.",

        'about:simple_strategy_not_enough': 'Een "eenvoudige strategie" was niet genoeg',
        'about:simple_strategy_not_enough_desc': "We hebben een neuraal netwerk gebouwd en getraind dat zou leren van de markt en voorspellingen zou doen. De resultaten toonden een zeer hoge nauwkeurigheid.",

        'about:live_bot_release': "Live bot release",
        'about:live_bot_release_desc': "We hebben een neuraal netwerk gebouwd en getraind dat zou leren van de markt en voorspellingen zou doen. De resultaten toonden een zeer hoge nauwkeurigheid.",

        'about:our_transparency_value': "Onze transparantiewaarde",
        'about:our_transparency_value_desc': "Live backtests en Live prestaties werden openbaar gemaakt. We willen dat transparantie een key differentiator is tussen ons en andere bedrijven die geen bewijs van hun resultaten laten zien.",

        'about:infrastructure_revolution': "Infrastructuur Revolutie",
        'about:infrastructure_revolution_desc': "2023 was een succesvol jaar omdat meer traders ons platform adopteerden. We verhoogden onze betrokkenheid bij traders, strevend naar begrip van wat iedereen echt nodig had in een botplatform. Gezien deze groeiende gebruikersbasis erkenden we het belang van het foutbestendig maken van DipSway op engineeringniveau. Voordat we spannende nieuwe updates, functies, tools en bots introduceerden, gaven we prioriteit aan het verzekeren dat onze systemen 24/7 zonder problemen draaiden en dat prestaties en kwaliteit topniveau waren.",

        'about:new_exchanges': "Nieuwe beurzen",
        'about:new_exchanges_desc': "In februari hebben we ons platform aanzienlijk uitgebreid door grote beurzen zoals Coinbase Advanced, BingX en Bitmart te integreren. We zijn ook partnerschappen aangegaan met KuCoin en Bybit, waardoor we een officiële derde partij applicatie op beide werden. Deze toevoegingen en partnerschappen hebben de handelsopties en markttoegang van onze gebruikers enorm vergroot, wat de betrouwbaarheid van ons platform valideerde en een belangrijke mijlpaal in onze groei markeerde.",

        'about:rotating_proxy': "Roterende Proxy en nieuwe hotfuncties",
        'about:rotating_proxy_desc': "In een significante upgrade van onze infrastructuur hebben we 20 extra IP\'s geïmplementeerd en onze database replica\'s uitgebreid van 2 naar 7, waardoor de responsiviteit van bots op nieuwe candles over beurzen heen werd verbeterd. Deze verbetering positioneert onze bots competitief in de markt voor snelheid en betrouwbaarheid. We hebben ook ﻿Crypto(.)com geïntegreerd, waardoor onze ondersteunde beurzen en handelsopties voor gebruikers verder werden uitgebreid. Een opmerkelijke nieuwe functie is het Bot Leaderboard, waarmee gebruikers botprestaties kunnen bekijken en de best presterende botinstellingen kunnen kopiëren. Deze verbeteringen versterken gezamenlijk de mogelijkheden van ons platform, waardoor gebruikers robuustere, efficiëntere en meer diverse handelsmogelijkheden krijgen.",

        'about:multiple_quote_currencies': "Meerdere quote valuta\'s",
        'about:multiple_quote_currencies_desc': "We hebben ondersteuning voor meerdere valuta\'s geïntroduceerd, waardoor bots kunnen handelen met verschillende quoteparen buiten USDT. Deze verbetering biedt gebruikers meer flexibiliteit, en past zich aan verschillende beursstructuren en geografische regelgeving aan. Door handel in meerdere valuta\'s mogelijk te maken, hebben we gebruikers in staat gesteld hun strategieën te optimaliseren volgens specifieke behoeften en marktomstandigheden, waardoor hun algehele handelservaring verbetert.",

        'about:new_admin_dashboard': "Nieuw admin dashboard",
        'about:new_admin_dashboard_desc': "De release van ons nieuwe platform markeert een significante sprong voorwaarts in gebruikerservaring en functionaliteit. Het maakt snellere ontwikkeling en levering van nieuwe functies mogelijk, geeft ons meer controle over bot probleemoplossing om elite-level ondersteuning te garanderen. We hebben de communicatie tussen interface en backend versterkt, waardoor dataaccuraatheid en betrouwbare functionaliteit gegarandeerd worden. Terwijl we vasthouden aan onze toewijding aan een minimalistisch, schoon en gemakkelijk te navigeren ontwerp, hebben we het platform ook verrijkt met uitgebreide data en analyses. Deze balans stelt gebruikers in staat tot diepgaande inzichten zonder compromissen te sluiten op eenvoud, waardoor meer geïnformeerde besluitvorming mogelijk is in een gebruiksvriendelijke omgeving.",

        'about:autopilot_tradingview': "Autopilot & TradingView signaalautomatisering",
        'about:autopilot_tradingview_desc': "De Autopilot Bot, onze revolutionaire AI-gestuurde handelsoplossing. Deze zero-configuratie verbetering van onze AI-bots maakt gebruik van historische gegevens, neurale netwerkberekeningen en LIVE gegevens van alle DipSway bots om continu handelsstrategieën te optimaliseren. Het selecteert automatisch de best presterende activa en past configuraties aan op basis van real-time marktparameters, waardoor gebruikers een hands-off, data-gedreven handelservaring krijgen die zich onmiddellijk aanpast aan marktomstandigheden.",

        'about:ai_futures_bot': "AI Futures bot",
        'about:ai_futures_bot_desc': "Met de introductie van het in-house SL & TP systeem (Trailing operaties zullen binnenkort beschikbaar zijn), hebben alle gebruikers nu 100% succespercentage op hun aangepaste instellingen voor hun bot. Het in-house systeem stelde ons in staat om de eerste generatie van een authentieke AI Futures bot uit te brengen.",

        'about:new_features': "Nieuwe functies",
        'about:new_features_desc': "We werken aan een nieuwe functie waarmee gebruikers hun eigen strategieën kunnen creëren en delen met de community.",

        'proven_to_perform': 'Bewezen prestaties',

        'see_how_dipsway_averages': 'Zie hoe DipSwayers gemiddeld presteren met de kracht van DipSway bots.',
        'last': 'Laatste',
        'months_compound_roi': 'Maanden Samengestelde ROI',
        'total_return_based_on_last': 'Totale opbrengst gebaseerd op laatste',
        'show_as_multiplier_values': 'Toon als vermenigvuldigingswaarden',
        'show_as_percentage_values': 'Toon als percentagewaarden',
        'join': 'Doe mee',
        'stars': 'Sterren',

        'faq': 'Veelgestelde vragen',
        'faq_desc': 'Vind hier uw antwoorden. Als u een vraag heeft die niet in de FAQ wordt behandeld,\n' +
            'aarzel dan niet om contact met ons op te nemen.',
        'faq_sub_desc': 'Kon u het antwoord niet vinden dat u zocht?',
        'contact_support': 'Contact opnemen met ondersteuning',

        'view_supported_platform': 'Bekijk ondersteunde platforms',

        'simulate_based_on_past_performances': 'Simuleer op basis van eerdere prestaties',
        'calculate_your_earnings_desc': 'Volledige winstgevendheidsanalyse op basis van DipSway\'s historische gegevens, rekening houdend met zaken zoals beurskosten, coinprestaties, en simulatie van hoe DipSway bots zouden handelen met uw kapitaalomvang.',

        'go_to_tweet': 'Ga naar tweet',
        'view_on': 'Bekijk op',

        'security': 'Veiligheid',
        'safe_and_secure': 'Veilig en beveiligd',
        'safe_and_secure_desc': 'Uw fondsen blijven in uw beurs, en we vragen alleen om minimale\n' +
            'toestemmingen om trades voor u uit te voeren.',

        'follow_dipsway_development': 'Volg de ontwikkeling van DipSway en ontmoet de community',
        'join_us_on_telegram': 'Doe mee op Telegram',
        'here_when_you_need_help': 'Hier als u hulp nodig heeft',
        'dipsway_customer_care': 'DipSway Klantenservice',

        'for_us_top_10_percent': 'Voor ons is de top 10% de standaard voor DipSwayers. U bent vrij om\n' +
            'te experimenteren met verschillende configuraties, maar wanneer u hulp nodig heeft\n' +
            'bij het optimaliseren, zijn we er.',

        'join_the_telegram_community': 'Word lid van de Telegram community',

        'made': 'maakte',
        'profit_on': 'winst op',
        'a_single_trade': 'een enkele trade',

        'dont_go_back_to_manual_trading': 'Ga niet terug naar handmatig traden,',

        'get_in_before_the_pump': 'stap in voor de pump',
        'get_out_before_the_drop': 'stap uit voor de drop',

        'on_autopilot': 'Op autopilot',

        'start_your_dipsway_bot': 'Start uw DipSway bot in 4 klikken',

        'safe_for_you': 'Veilig voor u, door ontwerp',
        'safe_for_you_desc': 'Met DipSway zijn uw fondsen veilig in uw crypto-exchange, en we vragen u\n' +
            'ons alleen zoveel te vertrouwen als nodig is om de bots te laten draaien.',

        'ip_whitelisting': 'IP-whitelisting',
        'ip_whitelisting_desc': 'Alleen door DipSway gecontroleerde IP-adressen kunnen op uw account opereren, zelfs als uw API-sleutel is gestolen, kunnen kwaadwillenden geen trades uitvoeren.',

        'restricted_access': 'Beperkte toegang',
        'restricted_access_desc': 'DipSway vraagt alleen de eenvoudigste permissies om namens u\n' +
            'te monitoren en trades uit te voeren.',

        'bright_green_performances': 'Helder groene prestaties',
        'on_capital': 'Op kapitaal',
        'win_rate': 'winstpercentage',
        'average_trade': 'gemiddelde trade',

        'see_dipsway_live_backtests': 'Bekijk DipSway live backtests & prestaties',
        'find_and_download': 'Vind en download historische prestatiegegevens en live backtests.',

        'available_at_no_extra_cost': 'Beschikbaar zonder extra kosten',

        'full_autopilot_mode': 'Volledige Autopilot modus',
        'full_autopilot_mode_desc': 'Met DipSway Autopilot worden niet alleen uw trades geautomatiseerd, maar\n' +
            'kiest DipSway ook de beste cryptocurrency om op dat moment te verhandelen\n' +
            'op basis van marktomstandigheden.',

        'calculate_your_earnings_with_autopilot': 'Bereken uw inkomsten met Autopilot',
        'high_performance': 'Hoge prestaties',
        'live_backtests_historical_performance': 'Live backtests, historische prestaties.',
        'backtests_performance': 'Backtests & prestaties',
        'extendable_via_tradingview': 'Uitbreidbaar via TradingView',
        'use_power_tradingview': 'Gebruik de kracht van TradingView om DipSway verder te configureren.',
        'coming_soon': 'Binnenkort beschikbaar...',
        '3_green_trades_in_30_days': '3 groene trades in 30 dagen of geld-terug-garantie',
        'need_help_choosing': 'Hulp nodig bij het kiezen?',
        'use_dipsway_earnings_calculator': 'Gebruik de DipSway inkomstencalculator, leer hoeveel u kunt verdienen en krijg een planaanbeveling.',
        'launch_earnings_calculator': 'Start inkomstencalculator',
        'popular': 'Populair',
        'recommended_for_you': 'Aanbevolen voor u',
        'no_deposit_required': 'Geen storting vereist',
        'top_10_of_users': "Top 10% van gebruikers",
        'all_users': "Alle gebruikers",
        'downtrend_page:desc1': 'Operatie: Nooduitgang positie. Neerwaartse trend geïdentificeerd.',
        'downtrend_page:sell_order_fulfilled': 'Verkooporder voor 12 BTC vervuld.',
        'performance_history': 'Prestatiegeschiedenis',
        'trained_to_recognize_opportunities': 'Getraind om kansen te herkennen',
        'live_backtests_performances': 'Live backtests prestaties',
        'see_how_our_strategies_performed': 'Zie hoe onze strategieën in het verleden hebben gepresteerd met hoogwaardige live backtests.',
        'backtests_from': 'Backtests van',
        'past_6_months': 'afgelopen 6 maanden',
        'collapse': 'Inklappen',
        'expand': 'Uitklappen',
        'view_report': 'Bekijk rapport',
        'knowledge:title1': 'Hoogwaardige Historische Data',
        'knowledge:description1': 'We zorgen ervoor dat de live-candlesticks die worden gebruikt van de hoogste kwaliteit zijn.',
        'knowledge:title2': 'Kosten & Vertragingen inbegrepen',
        'knowledge:description2': 'Maker- en taker-kosten van 0,1% worden toegepast op elke trade. Slippage van 0,1% en een vertraging van enkele minuten worden toegepast op elke trade.',
        'knowledge:title3': 'Overfitting Vermijding',
        'knowledge:description3': 'DipSway monitort passief accounts die slecht presteren en beveelt acties aan.',
        'operation': 'Operatie',
        'buy_the_dip': 'Koop de dip',
        'sell_the_high': 'Verkoop de top',
        'bought': 'Gekocht',
        'watching_market': 'markt observeren',
        'sell_order_for': 'Verkooporder voor',
        'fulfilled': 'vervuld',
        'profit_made': 'Gemaakte winst',
        'calculation_results': 'Berekeningsresultaten',
        'calculating': 'Berekenen',
        'total_expected_profit_at_the_end_of': 'Totale verwachte winst aan het einde van',
        'period_based_off_of_historical_backtest_performances': 'periode gebaseerd op historische backtest prestaties',
        'trades': 'trades',
        'day': 'dag',
        'profit': 'winst',
        'recommended_plan_for_you': 'Aanbevolen plan voor u',
        'go_to': 'Ga naar',
        'free_trial_available': 'Gratis proefperiode beschikbaar',
        'runs_at': 'Draait op',
        'learn_how_we_calculate_your_earnings': 'Leer hoe we uw inkomsten berekenen',
        'click_to_open_deepdive': 'Klik om de diepgaande analyse te openen waar u kunt kiezen uit andere beurzen die we ondersteunen.',
        'your_capital': 'Uw kapitaal',
        'currency': 'Valuta',
        'enabled': 'Ingeschakeld',
        'set_to': 'ingesteld op',
        'coin_count': 'aantal coins',
        'disabled': 'Uitgeschakeld',
        'auto': 'Auto',
        'selected_coins': 'Geselecteerde coins',
        'loading_coins': 'Coins laden',
        'login': 'Inloggen',

        'trading_with': 'Handelen met',
    },

    zh: {
        'all_payment_types': '所有支付类型',
        'all_payment_types_description': '通过受信任的Cryptomus网关安全地使用比特币、以太坊等进行交易，即可立即访问DipSway的功能。',
        'extra': '额外',

        'no-token:footer.disclaimer': 'DipSway不在任何区块链上销售、提供、促进或提供任何DeFi代币或加密货币的访问。我们仅是软件提供商。',

        "different_ways_to_swap": "不同的交换方式",

        'coin_swap_description': '使用我们的币种交换路径查找器优化您的{crypto}到{quote}交易。即时比较多个{crypto}/{quote}转换路径，可视化复杂的交换，并为您的{crypto}到{quote}转换获得最佳汇率。对于寻求最大化{crypto}到{quote}交易的新手和专业加密货币交易者来说都是完美的选择。',

        "current_price_info": "{crypto}的当前价格为{price}，过去24小时变化{change24h}，过去7天变化{change7d}。",
        "circulating_supply_info": "流通供应量为21M，{crypto}的实时市值为{marketCap}。",
        "trading_volume_info": "24小时交易量为{volume}。",
        "conversion_rate_calculation": "转换率是使用{crypto}实时价格指数以及其他数字资产价格指数计算的。",
        "exchange_rate_update": "{crypto}/{quote}汇率实时更新。",
        "follow_dipsway_info": "关注DipSway的加密货币转换器和计算器，获取加密货币和法定货币之间汇率的24/7实时更新，包括{crypto}、{currencies}等。",

        "description_calculator_pt_1": "此计算器提供以下转换率",
        "description_calculator_pt_2": "到",
        "description_calculator_pt_3": "转换率基于过去24小时的市场汇率。",
        "to": "到",
        "converter": "转换器",
        "crypto_prices_and_market_data": "加密货币价格和市场数据",
        "converter_site:desc": "使用我们易用的转换工具在加密货币和法定货币之间进行转换。获取最新的汇率和市场数据，助您交易。",
        "callout_calculator": "AI驱动的加密货币交易机器人",
        "info_on_price": "价格信息",
        "live_data": "实时数据",

        "prices_of": "价格",
        "on_different_exchanges": "在不同的加密货币交易所",
        "converted_to": "转换为",
        "on_different_amounts": "不同金额",
        "up_of": "上涨",
        "dropped_of": "下跌",
        "respect_yesterday": "相比昨天",
        "close_price": "收盘价",
        "price_variation": "价格变动",
        "vwap": "平均价格",
        "volume_last_24h": "24小时交易量",
        "exchange": "交易所",
        "price": "价格",
        "volume": "交易量",
        "quote_volume": "报价交易量",
        "percentage": "百分比",
        "asset": "资产",
        "today_at": "今天",
        "calculator_title": "{crypto}到{quote}计算器",
        "crypto_label": "加密货币",
        "currency_label": "报价",
        "enter_amount": "输入金额",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "交易{crypto}",

        'search_for_crypto': '搜索加密货币',
        'crypto_prices_title': '支持的加密货币价格最近24小时变化',
        'see_current_crypto_market_trend': '查看当前加密货币市场趋势',

        'see_more': '查看更多',
        'dipsway': 'DipSway',
        'home_page': '主页',
        'blogs.related_posts': '相关帖子',
        'blogs.automate_your_trading': '今天就用DipSway AI自动化您的交易。',
        'blogs.automate_your_trading_description': 'DipSway使用121多个技术指标和17多个模式检测器的组合，为您提供不公平的优势。',
        'btn.start_7_day_free_trial': '开始7天免费试用',
        'blogs.tags': '标签',
        'blogs.description_site': '加密货币教程、功能发布、产品比较等！在DipSway博客上找到来自DipSway团队的内容。',
        'blogs.title_site': 'DipSway博客',
        'blogs.callout': '介绍4.13版本',
        'blogs.description_extended': '教程、功能发布、产品比较等！\n在DipSway博客上找到来自DipSway团队的内容。',
        'blogs.go_to_article': '前往文章',
        'skip_to_content': '跳到内容',
        'calculate_your_earnings_and_start_free_trial': '计算您的收益并开始7天免费试用',

        'privacy_policy': '隐私政策',
        'terms_of_service': '服务条款',
        'refund_policy': '退款政策',
        'affiliate_policy': '联盟政策',
        'blog': '博客',
        'supported_exchanges': '支持的交易所',
        'pricing': '定价',
        'resources': '资源',
        'company': '公司',
        'support': '支持',
        'affiliate_program': '联盟计划',
        'about_us': '关于我们',
        'customer_success_agent': '客户成功代理',
        'dipsway_ai_optimization': 'DipSway AI优化',
        'live_back_tests': '实时回测',
        'live_back_tests_performances': 'DipSway实时回测和性能',
        'historical_performance_data': '历史性能数据',
        'dipsway_earning_calculator': 'DipSway收益计算器',
        'performance': '性能',
        'common:footer.disclaimer': 'DipSway仅提供软件。任何提到交易、交换、转账或钱包服务等，均指由第三方服务提供商提供的服务。',

        'index:hero.title': 'DipSway - 零配置，全自动AI加密货币交易机器人',
        'index:hero.description': '体验我们AI机器人的无缝加密货币交易。在简单的界面上启动您的加密货币交易AI机器人，实现100%自动化交易，全天候可用。',

        'header:product': '产品',
        'header:product.feature.header': '使用DipSway，您的资本能赚多少？',
        'header:product.feature.description': '根据DipSway的回测历史计算您的收益。',
        'header:product.feature.cta': '计算您的收益',

        'header:product.links.spot_ai_trading_bot': '现货AI交易机器人',
        'header:learn': '学习',
        'header:learn.links.ai_trading_optimization': 'AI + 加密货币交易',

        'welcome:headline': '零配置，全自动AI加密货币交易机器人',
        'welcome:subheadline': '连接您的交易所，打开自动驾驶，就这么简单。',
        'start_7_days_no_credit_card': '开始7天免费试用 - 无需存款。',

        '404:title_site': '404 - DipSway | AI驱动的加密货币交易机器人',
        '404:description_site': '通过我们的AI机器人重新回到加密货币交易。在简单的界面上启动您的加密货币交易AI机器人，实现100%自动化交易，全天候可用。',
        '404:page_not_found': '页面未找到',
        '404:page_not_found_message': '看起来您找到了一个...损坏的链接！请让DipSway支持知道\n是哪个页面把您带到这里的！',
        '404:go_home': '返回首页',
        '404:open_dashboard': '打开仪表板',

        'affiliate_program_policy:title_site': "DipSway联盟计划政策",
        'affiliate_program_policy:description_site': "DipSway联盟计划政策",

        'crypto_bot_calculator:title_site': "加密货币交易机器人收益计算器",
        'crypto_bot_calculator:description_site': "基于DipSway的历史数据进行全面的盈利能力分析，考虑了交易所费用、币种表现等因素，并模拟DipSway机器人如何使用您的资本规模进行交易。",
        'crypto_bot_calculator:label': '加密货币交易机器人收益计算器',
        'crypto_bot_calculator:simulate_based_on': '基于过去表现进行模拟',
        'crypto_bot_calculator:calculate_your_earnings': '计算您的收益',
        'crypto_bot_calculator:description': '基于DipSway的历史数据进行全面的盈利能力分析，考虑了交易所费用、币种表现等因素，并模拟DipSway机器人如何使用您的资本规模进行交易。',

        'featured_on': '特色展示于',
        '4_clicks_to_start': '4次点击即可开始',
        'no_knowledge_required': '零配置，零知识要求',
        'split_content_description': 'DipSway真正做到零配置。连接您的交易所 → 打开您的机器人 →\n' +
            '      并监控其表现。最佳表现的配置\n' +
            '      默认应用。',
        'learn_about_dipsway_ai': '了解DipSway AI',

        'trained_for_down_trends': '经过训练以检测下降趋势',
        'be_safe_on_down_trends': '即使在下降趋势中也保持安全',
        'autopilot_content_headline_1': '在上涨前进入，在下跌前退出，',
        'autopilot_content_headline_2': '自动驾驶模式',
        'autopilot_content_description': 'DipSway在您忙于日常事务时分析市场并进行交易，\n' +
            '      并通过状态更新保持您的信息畅通。',
        'downtrends_description': 'DipSway的AI经过训练，可以识别下降趋势，在情况失控之前，\n' +
            '      它会让您退出',

        'see_how_dipsway_works': '看看DipSway如何工作',

        'free_trial_action:label': '随时准备就绪。',

        'dipsway_ai': 'DipSway AI',

        'safety': '安全',
        'safety_description': '在您的交易所保持资金安全，您的安全是我们的首要任务。',

        'ai_trading_optimization_description': '我们专有的AI模型经过训练，可以识别机会。',

        '3months': '3个月',
        'total_profits_within_label': '在过去期间内所有部署的DipSway机器人的总利润',

        'dipsway_uses_combination_of': 'DipSway使用以下的组合',
        'and': '和',
        'technical_indicators': '技术指标',
        'pattern_detectors': '模式检测器',
        'detect_next_move': '来检测市场的下一步动向。',

        'are_you_influencer': '您是影响者吗？与我们合作',
        'influencer_description': '与我们合作的影响者会获得一个定制的入职登陆页面\n' +
            '      根据他们用户群的兴趣量身定制。',

        'get_in_touch': '联系我们',

        'spot_ai_bot:title_site': '现货AI加密货币交易机器人 | 100%自动化交易',
        'spot_ai_bot:description_site': '打开您的加密货币交易机器人，享受100%自动化交易。今天就启动您的机器人，实现全天候运行。享受便利并最大化您的利润。',

        'spot_ai_bot:24_7_automated_trading': '全天候自动化交易，几乎即时的交易执行',
        'spot_ai_bot:algo_crypto_by_ml': '由机器学习驱动的AI算法加密货币交易',

        'spot_ai_bot:desc': '行业中最好的交易机器人，使用AI分析市场并\n' +
            '以机器般的速度做出交易决策。',

        'million': '百万',

        'avg_monthly_volume_moved_by_bots': 'DipSway机器人移动的平均月交易量。',
        'dipsway_has_more_than_350_users': 'DipSway在全球拥有超过350名用户。',
        'dipsway_supports_315_coins': 'DipSway支持超过315+种货币的交易。',

        'ai_powered': "AI驱动",
        'trading_strategies_powered_by_ai': "交易策略由AI驱动，使用技术分析和基本面分析的组合。",

        'geolocation_advantage': "地理位置优势",
        'geolocation_advantage_desc': "托管机器人的服务器与交易所位于相同的地理位置，以确保最低的延迟（平均~932μs延迟）。",

        'high_frequency_trading': '高频交易',
        'high_frequency_trading_desc': '得益于多租户架构，交易策略以1秒钟的K线粒度执行。',

        'advanced_risk_management': '高级风险管理',
        'advanced_risk_management_desc': '机器人能够管理多种货币的投资，并自动对冲投资组合的风险。',

        'optimization_overfitting_avoidance': '优化和过拟合避免',
        'optimization_overfitting_avoidance_desc': '机器人能够使用AI遗传算法和自定义前向分析优化交易策略的参数，并避免过拟合。',

        'backtesting': '回测',
        'backtesting_desc': '每24小时，我们对所有交易策略进行回测，并使用"在线学习"方法重新计算AI使用的权重。',
        'how_do_dipsways_strategies_work': "DipSway的策略如何工作？",
        'no_one_wants_to_invest_in_black_box': "没有人想投资于一个黑盒子。这就是为什么我们创建了一种简单的方法来跟踪DipSway AI机器人背后的运作 - DipSway融合了112多个指标和策略，在快速变化的加密货币世界中为您提供优势。",
        'learn_more_about_dipsway_ai': '了解更多关于DipSway AI的信息',

        'autopilot_buys_sharp_as_knife': '自动驾驶购买，锋利如刀',
        'autopilot_buys_sharp_as_knife_desc': '大多数加密货币市场横向移动，大幅价格波动不会持续很长时间。得益于机器学习决策过程，AI机器人被设计为执行精准的买入订单，这将在横向市场中持续保持您的盈利，并在那些大幅下跌发生时利用机会。',
        'other_strategies_dont_work': '其他策略无法工作，因为它们是静态的，无法随市场变化而演变。',

        'autopilot_sells_and_youre_in_profit': '自动驾驶卖出，您就获得利润。',

        'dont_let_downturns_catch_you_off_guard': '不要让下跌趋势让您措手不及。',
        'dont_let_downturns_catch_you_off_guard_desc': '121多个指标和您需要的每一个指标都在一个平台上。',

        'your_bot_runs_in_cloud': '您的机器人在云端全天候运行。',
        'your_bot_runs_in_cloud_desc': '我们的机器人是一个基于云的交易机器人，全天候运行，所以您不用担心离线问题！它始终连接到市场，分析最佳的买卖时机。\n',

        'unfair_advantage': '为您提供不公平的优势。',
        'and_many_more': '还有更多...',

        'pricing:title_site': 'DipSway定价',
        'pricing:description_site': "实惠的加密货币交易AI机器人 | 今天就启动您的机器人 | 享受全天候自动化交易",
        'pricing:supercharge_text': '用DipSway AI为您的交易充电',

        'performance:title_site': 'DipSway性能历史和回测',
        'performance:description_site': "体验我们加密货币交易AI机器人的实时回测。今天就启动您的机器人，实现全天候运行。享受便利并最大化您的利润。",
        'performance:label': "DipSway性能历史和回测",

        'connect_to_exchange': '连接到您的交易所，打开自动驾驶，就这么简单。',
        'calculate_your_earnings': '计算您的收益',

        'trading_optimization:title_site': "DipSway AI交易优化",
        'trading_optimization:description_site': "体验我们加密货币交易AI机器人的AI交易优化。今天就启动您的机器人，实现全天候运行。享受便利并最大化您的利润。",
        'trading_optimization:label': "AI交易优化",
        'trading_optimization:breakdown': '分解：DipSway的AI加密货币交易',

        'trading_optimization:desc': 'DipSway强大的AI机器人能动态适应快速变化的市场。',
        'trading_optimization:steps': '这一切都通过一系列步骤完成：',

        'behind_scenes:behind_the_scenes': '幕后发生了什么？',

        'behind_scenes:indicators': '一些指标显示潜在的买入信号（绿色），而其他指标显示潜在的卖出信号（灰色）。',
        'behind_scenes:neural_network': 'DipSway神经网络考虑所有这些指标，并基于整体情况做出决策，产生单一的买入或卖出信号。',

        'affiliate_program:title_site': '加密货币机器人联盟计划',
        'affiliate_program:description_site': "加入我们的联盟计划。享受朋友邀请30%的重复佣金。",
        'affiliate_program:label': "联盟计划",
        'affiliate_program:desc': "每次推荐赚取30%的佣金，重复收入",
        'affiliate_program:desc2': "连接到您的交易所，打开自动驾驶，就这么简单。",
        'affiliate_program:get_your_affiliate_link': '获取您的联盟链接',

        'conference': '会议',

        'about:title_site': "关于DipSway",
        'about:our_journey': '我们的旅程',
        'about:our_journey_desc': '为各级交易者提供高质量、盈利的AI加密货币交易机器人，\n' +
            '帮助他们增长投资组合并提高日常\n' +
            '表现。',
        'about:just_getting_started': '我们才刚刚开始。',

        'about:description_site': "体验我们加密货币交易AI机器人的AI交易优化。今天就启动您的机器人，实现全天候运行。享受便利并最大化您的利润。",
        'about:label': '关于',
        'about:behind_scenes': '幕后',
        'founder': '创始人',

        'about:founder1_msg': 'DipSway诞生于通过尖端AI革新加密货币交易的愿景。作为软件工程师和加密货币爱好者，我们看到了创造真正创新事物的机会 - 一个像专业交易员一样思考但以超人速度运作的交易机器人。\n凭借我们强大的技术专长，我们的突破来自于开发出一种不仅仅遵循预设规则，而且能实时适应市场动态的AI。',
        'about:founder2_msg': '这不是您平均的交易机器人；它是一个复杂的系统，能分析大量数据，识别复杂模式，并在几分之一秒内做出决策，精确度令人惊叹。\n使DipSway与众不同的是我们对AI学习的独特方法。这就像拥有一支全天候为您工作的专家交易团队，但更快、更高效。\n我们的使命是让高级加密货币交易对所有人accessible，从经验丰富的专业人士到好奇的新手。',

        'about:join_team': '加入DipSway团队',
        'about:join_team_desc': '我们正在寻找对金融、\n' +
            '交易和技术充满热情的加密货币爱好者。准备好改变世界投资\n' +
            '加密货币的方式了吗？',

        'read_post': '阅读帖子',

        'about:development_starts': "开发开始",
        'about:development_starts_desc': "有一天，我们从Coinbase移动应用收到比特币近期价格的通知，比如'比特币今天上涨了5%'。我们想，如果有一个机器人能在这些通知之前就操作，那就太棒了。我们在网上搜索，但找不到易于使用且在云端运行的东西。于是我们决定自己构建。",

        'about:first_bot_version': "第一个机器人版本",
        'about:first_bot_version_desc': "首次模拟比特币和以太坊的算法交易策略，我们开始包含更多加密资产和更多策略。我们开始看到我们的机器人的潜力，并决定将其公开。",

        'about:simple_strategy_not_enough': '"简单策略"是不够的',
        'about:simple_strategy_not_enough_desc': "我们构建并训练了一个神经网络，它能从市场学习并做出预测。结果显示出非常高的准确性。",

        'about:live_bot_release': "实时机器人发布",
        'about:live_bot_release_desc': "我们构建并训练了一个神经网络，它能从市场学习并做出预测。结果显示出非常高的准确性。",

        'about:our_transparency_value': "我们的透明度价值",
        'about:our_transparency_value_desc': "实时回测和实时性能被公开。我们希望透明度成为我们与不显示结果证据的其他公司之间的关键区别。",

        'about:infrastructure_revolution': "基础设施革命",
        'about:infrastructure_revolution_desc': "2023年是成功的一年，因为更多的交易者采用了我们的平台。我们加强了与交易者的互动，努力了解每个人在机器人平台中真正需要什么。考虑到这个不断增长的用户基础，我们认识到在工程层面使DipSway变得不会失败的重要性。在引入令人兴奋的新更新、功能、工具和机器人之前，我们优先确保我们的系统24/7无故障运行，性能和质量都是顶级的。",

        'about:new_exchanges': "新交易所",
        'about:new_exchanges_desc': "在2月份，我们通过整合Coinbase Advanced、BingX和Bitmart等主要交易所大大扩展了我们的平台。我们还与KuCoin和Bybit建立了合作关系，成为两者的官方第三方应用。这些添加和合作极大地增加了我们用户的交易选择和市场准入，验证了我们平台的可靠性，并标志着我们增长的一个重要里程碑。",

        'about:rotating_proxy': "轮换代理和新热门功能",
        'about:rotating_proxy_desc': "在我们基础设施的重大升级中，我们实施了20个额外的IP，并将我们的数据库副本从2个扩展到7个，提高了机器人对各交易所新蜡烛图的响应能力。这一改进使我们的机器人在速度和可靠性方面在市场上具有竞争力。我们还整合了﻿Crypto(.)com，进一步扩展了我们支持的交易所和用户的交易选项。一个值得注意的新功能是机器人排行榜，它允许用户查看机器人性能并复制表现最佳的机器人设置。这些改进共同强化了我们平台的能力，为用户提供更强大、更高效和更多样化的交易机会。",

        'about:multiple_quote_currencies': "多种报价货币",
        'about:multiple_quote_currencies_desc': "我们引入了多货币支持，使机器人能够使用USDT以外的各种报价对进行交易。这一改进为用户提供了更大的灵活性，适应不同的交易所结构和地理监管。通过允许多货币交易，我们使用户能够根据特定需求和市场条件优化他们的策略，改善他们的整体交易体验。",

        'about:new_admin_dashboard': "新管理仪表板",
        'about:new_admin_dashboard_desc': "我们新平台的发布标志着用户体验和功能性的重大飞跃。它实现了更快的新功能开发和交付，让我们对机器人故障排除有更大的控制力，以确保精英级支持。我们加强了界面和后端之间的通信，保证了数据准确性和可靠的功能性。在保持我们对简约、清晰和易于导航设计承诺的同时，我们还用全面的数据和分析丰富了平台。这种平衡使用户能够在不影响简单性的情况下获得深入的洞察，允许在用户友好的环境中做出更明智的决策。",

        'about:autopilot_tradingview': "自动驾驶和TradingView信号自动化",
        'about:autopilot_tradingview_desc': "自动驾驶机器人，我们革命性的AI驱动交易解决方案。这种对我们AI机器人的零配置增强利用历史数据、神经网络计算和来自所有DipSway机器人的实时数据来持续优化交易策略。它基于实时市场参数自动选择表现最佳的资产并调整配置，为用户提供一个即时适应市场条件的免操作、数据驱动的交易体验。",

        'about:ai_futures_bot': "AI期货机器人",
        'about:ai_futures_bot_desc': "通过引入内部SL & TP系统（追踪操作即将推出），所有用户现在在他们的机器人自定义设置上都有100%的成功率。内部系统使我们能够发布第一代真正的AI期货机器人。",

        'about:new_features': "新功能",
        'about:new_features_desc': "我们正在开发一项新功能，允许用户创建自己的策略并与社区分享。",

        'proven_to_perform': '已证明的性能',

        'see_how_dipsway_averages': '看看DipSway用户如何使用DipSway机器人的力量平均表现。',
        'last': '最后',
        'months_compound_roi': '个月复合ROI',
        'total_return_based_on_last': '基于最后期间的总回报',
        'show_as_multiplier_values': '显示为乘数值',
        'show_as_percentage_values': '显示为百分比值',
        'join': '加入',
        'stars': '星级',

        'faq': '常见问题',
        'faq_desc': '在这里找到您的答案。如果您有FAQ中未涉及的问题，\n' +
            '请随时联系我们。',
        'faq_sub_desc': '没有找到您要找的答案吗？',
        'contact_support': '联系支持',

        'view_supported_platform': '查看支持的平台',

        'simulate_based_on_past_performances': '基于过去表现进行模拟',
        'calculate_your_earnings_desc': '基于DipSway的历史数据进行全面的盈利能力分析，考虑了交易所费用、币种表现等因素，并模拟DipSway机器人如何使用您的资本规模进行交易。',

        'go_to_tweet': '前往推文',
        'view_on': '在上查看',

        'security': '安全',
        'safe_and_secure': '安全可靠',
        'safe_and_secure_desc': '您的资金保留在您的交易所，我们只请求\n' +
            '最少的权限来为您执行交易。',

        'follow_dipsway_development': '关注DipSway的发展并加入社区',
        'join_us_on_telegram': '在Telegram上加入我们',
        'here_when_you_need_help': '当您需要帮助时我们在这里',
        'dipsway_customer_care': 'DipSway客户服务',

        'for_us_top_10_percent': '对我们来说，前10%是DipSway用户的标准。您可以自由\n' +
            '尝试不同的配置，但当您需要帮助\n' +
            '优化时，我们就在这里。',

        'join_the_telegram_community': '加入Telegram社区',

        'made': '赚取了',
        'profit_on': '利润在',
        'a_single_trade': '一次交易中',

        'dont_go_back_to_manual_trading': '不要回到手动交易，',

        'get_in_before_the_pump': '在上涨前进入',
        'get_out_before_the_drop': '在下跌前退出',

        'on_autopilot': '自动驾驶模式',

        'start_your_dipsway_bot': '四次点击启动您的DipSway机器人',

        'safe_for_you': '为您设计的安全',
        'safe_for_you_desc': '使用DipSway，您的资金安全存放在您的加密货币交易所，我们只要求您\n' +
            '信任我们到机器人运行所需的程度。',

        'ip_whitelisting': 'IP白名单',
        'ip_whitelisting_desc': '只有DipSway控制的IP地址可以在您的账户上操作，即使您的API密钥被盗，恶意行为者也无法执行交易。',

        'restricted_access': '受限访问',
        'restricted_access_desc': 'DipSway只请求最简单的权限来代表您\n' +
            '监控和执行交易。',

        'bright_green_performances': '亮绿色表现',
        'on_capital': '在资本上',
        'win_rate': '胜率',
        'average_trade': '平均交易',

        'see_dipsway_live_backtests': '查看DipSway实时回测和性能',
        'find_and_download': '查找并下载历史性能数据和实时回测。',

        'available_at_no_extra_cost': '无需额外费用',

        'full_autopilot_mode': '全自动驾驶模式',
        'full_autopilot_mode_desc': '使用DipSway自动驾驶，不仅您的交易是自动化的，而且\n' +
            'DipSway还会根据市场条件选择当时\n' +
            '最佳的加密货币进行交易。',

        'calculate_your_earnings_with_autopilot': '使用自动驾驶计算您的收益',
        'high_performance': '高性能',
        'live_backtests_historical_performance': '实时回测，历史性能。',
        'backtests_performance': '回测和性能',
        'extendable_via_tradingview': '通过TradingView可扩展',
        'use_power_tradingview': '利用TradingView的力量进一步配置DipSway。',
        'coming_soon': '即将推出...',
        '3_green_trades_in_30_days': '30天内3次绿色交易或退款保证',
        'need_help_choosing': '需要帮助选择吗？',
        'use_dipsway_earnings_calculator': '使用DipSway收益计算器，了解您可以赚多少，并获得计划推荐。',
        'launch_earnings_calculator': '启动收益计算器',
        'popular': '热门',
        'recommended_for_you': '为您推荐',
        'no_deposit_required': '无需存款',
        'top_10_of_users': "前10%的用户",
        'all_users': "所有用户",
        'downtrend_page:desc1': '操作：紧急退出仓位。已识别下降趋势。',
        'downtrend_page:sell_order_fulfilled': '12 BTC的卖出订单已完成。',
        'performance_history': '性能历史',
        'trained_to_recognize_opportunities': '经过训练以识别机会',
        'live_backtests_performances': '实时回测性能',
        'see_how_our_strategies_performed': '通过高质量的实时回测查看我们的策略在过去的表现。',
        'backtests_from': '回测来自',
        'past_6_months': '过去6个月',
        'collapse': '折叠',
        'expand': '展开',
        'view_report': '查看报告',
        'knowledge:title1': '高质量历史数据',
        'knowledge:description1': '我们确保使用的实时K线图是最高质量的。',
        'knowledge:title2': '包含费用和延迟',
        'knowledge:description2': '每笔交易应用0.1%的做市商和吃单方费用。每笔交易应用0.1%的滑点和几分钟的延迟。',
        'knowledge:title3': '避免过拟合',
        'knowledge:description3': 'DipSway被动监控表现不佳的账户并建议采取行动。',
        'operation': '操作',
        'buy_the_dip': '买入低点',
        'sell_the_high': '卖出高点',
        'bought': '已买入',
        'watching_market': '观察市场',
        'sell_order_for': '卖出订单',
        'fulfilled': '已完成',
        'profit_made': '获得利润',
        'calculation_results': '计算结果',
        'calculating': '计算中',
        'total_expected_profit_at_the_end_of': '期末预期总利润',
        'period_based_off_of_historical_backtest_performances': '基于历史回测性能的期间',
        'trades': '交易',
        'day': '天',
        'profit': '利润',
        'recommended_plan_for_you': '为您推荐的计划',
        'go_to': '前往',
        'free_trial_available': '免费试用可用',
        'runs_at': '运行于',
        'learn_how_we_calculate_your_earnings': '了解我们如何计算您的收益',
        'click_to_open_deepdive': '点击打开深度分析，您可以从中选择我们支持的其他交易所。',
        'your_capital': '您的资本',
        'currency': '货币',
        'enabled': '已启用',
        'set_to': '设置为',
        'coin_count': '币种数量',
        'disabled': '已禁用',
        'auto': '自动',
        'selected_coins': '已选择的币种',
        'loading_coins': '加载币种',
        'login': '登录',

        'trading_with': '交易使用',
    },

    de: {

        'all_payment_types': 'alle Zahlungsarten',
        'all_payment_types_description': 'Erleben Sie problemlose Transaktionen mit Bitcoin, Ethereum und mehr. Zahlen Sie sicher über das vertrauenswürdige Cryptomus-Gateway, um sofort auf die Funktionen von DipSway zuzugreifen.',
        'extra': 'Extra',

        'no-token:footer.disclaimer': 'DipSway verkauft, bietet, vermittelt oder gewährt keinen Zugang zu DeFi-Token oder Kryptowährungen auf irgendeiner Blockchain. Wir sind ausschließlich ein Softwareanbieter.',

        "different_ways_to_swap": "Verschiedene Arten des Austauschens",

        'coin_swap_description': 'Optimieren Sie Ihre {crypto} zu {quote} Trades mit unserem Coin Swap Pfadfinder. Vergleichen Sie sofort mehrere {crypto}/{quote} Konvertierungsrouten, visualisieren Sie komplexe Swaps und sichern Sie sich die besten Wechselkurse für Ihre {crypto} zu {quote} Umwandlungen. Perfekt für Anfänger und erfahrene Krypto-Händler, die ihre {crypto} zu {quote} Trades maximieren möchten.',

        "current_price_info": "Der aktuelle {crypto}-Preis beträgt {price}, was einer Änderung von {change24h} in den letzten 24 Stunden und {change7d} in den letzten 7 Tagen entspricht.",
        "circulating_supply_info": "Bei einem Umlaufangebot von 21M beträgt die Live-Marktkapitalisierung von {crypto} {marketCap}.",
        "trading_volume_info": "Das 24-Stunden-Handelsvolumen beträgt {volume}.",
        "conversion_rate_calculation": "Die Umrechnungskurse werden unter Verwendung des Live-{crypto}-Preisindex zusammen mit anderen Preisindizes für digitale Vermögenswerte berechnet.",
        "exchange_rate_update": "Der {crypto}/{quote}-Wechselkurs wird in Echtzeit aktualisiert.",
        "follow_dipsway_info": "Folgen Sie DipSway's Krypto-Umrechner und -Rechner für 24/7 Live-Updates der Wechselkurse zwischen Krypto- und Fiatwährungen, einschließlich {crypto}, {currencies} und mehr.",

        "description_calculator_pt_1": "Dieser Rechner bietet einen Wechselkurs für",
        "description_calculator_pt_2": "zu",
        "description_calculator_pt_3": "Der Wechselkurs basiert auf dem Marktkurs der letzten 24 Stunden.",
        "to": "zu",
        "converter": "Umrechner",
        "crypto_prices_and_market_data": "Krypto-Preise & Marktdaten",
        "converter_site:desc": "Konvertieren Sie zwischen Kryptowährungen und Fiatwährungen mit unserem benutzerfreundlichen Umrechnungstool. Erhalten Sie die neuesten Wechselkurse und Marktdaten für Ihre Trades.",
        "callout_calculator": "KI-gesteuerte Krypto-Trading-Bots",
        "info_on_price": "Preisinformationen",
        "live_data": "Echtzeit-Daten",

        "prices_of": "Preise von",
        "on_different_exchanges": "an verschiedenen Krypto-Börsen",
        "converted_to": "Umgerechnet in",
        "on_different_amounts": "für verschiedene Beträge",
        "up_of": "Anstieg um",
        "dropped_of": "Rückgang um",
        "respect_yesterday": "im Vergleich zu gestern",
        "close_price": "Schlusskurs",
        "price_variation": "Preisvariation",
        "vwap": "Durchschnittspreis",
        "volume_last_24h": "24h-Volumen",
        "exchange": "Börse",
        "price": "Preis",
        "volume": "Volumen",
        "quote_volume": "Angebotsvolumen",
        "percentage": "Prozentsatz",
        "asset": "Asset",
        "today_at": "Heute um",
        "calculator_title": "Rechner von {crypto} zu {quote}",
        "crypto_label": "Krypto",
        "currency_label": "Quote",
        "enter_amount": "Betrag eingeben",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Handeln Sie mit {crypto}",

        'search_for_crypto': 'Suche nach Kryptowährung',
        'crypto_prices_title': 'Unterstützte Kryptowährungspreise Letzte 24h Änderung',
        'see_current_crypto_market_trend': 'Sehen Sie sich den aktuellen Kryptowährungsmarkttrend an.',

        'see_more': 'Mehr sehen',
        'dipsway': 'DipSway',
        'home_page': 'Startseite',
        'blogs.related_posts': 'Verwandte Beiträge',
        'blogs.automate_your_trading': 'Automatisieren Sie Ihren Handel heute mit DipSway AI.',
        'blogs.automate_your_trading_description': 'DipSway verwendet eine Kombination von 121+ technischen Indikatoren und 17+ Musterdetektoren, um Ihnen einen unfairen Vorteil zu verschaffen.',
        'btn.start_7_day_free_trial': '7-tägige kostenlose Testversion starten',
        'blogs.tags': 'Tags',
        'blogs.description_site': 'Krypto-Tutorials, Feature-Releases, Produktvergleiche und mehr! Finden Sie hier auf dem DipSway-Blog Inhalte vom DipSway-Team.',
        'blogs.title_site': 'DipSway Blog',
        'blogs.callout': 'Einführung von Version 4.13',
        'blogs.description_extended': 'Tutorials, Feature-Releases, Produktvergleiche und mehr!\nFinden Sie hier auf dem DipSway-Blog Inhalte vom DipSway-Team.',
        'blogs.go_to_article': 'Zum Artikel gehen',
        'skip_to_content': 'Zum Inhalt springen',
        'calculate_your_earnings_and_start_free_trial': 'Berechnen Sie Ihre Einnahmen & Starten Sie die 7-tägige kostenlose Testversion',

        'privacy_policy': 'Datenschutzrichtlinie',
        'terms_of_service': 'Nutzungsbedingungen',
        'refund_policy': 'Rückgaberichtlinie',
        'affiliate_policy': 'Partnerprogramm-Richtlinie',
        'blog': 'Blog',
        'supported_exchanges': 'Unterstützte Börsen',
        'pricing': 'Preisgestaltung',
        'resources': 'Ressourcen',
        'company': 'Unternehmen',
        'support': 'Support',
        'affiliate_program': 'Partnerprogramm',
        'about_us': 'Über uns',
        'customer_success_agent': 'Kundenerfolgsbeauftragter',
        'dipsway_ai_optimization': 'DipSway AI-Optimierung',
        'live_back_tests': 'Live-Backtests',
        'live_back_tests_performances': 'DipSway Live-Backtests & Leistungen',
        'historical_performance_data': 'Historische Leistungsdaten',
        'dipsway_earning_calculator': 'DipSway Ertragsrechner',
        'performance': 'Leistung',
        'common:footer.disclaimer': 'DipSway bietet nur Software an. Alle Verweise auf Handels-, Börsen-, Transfer- oder Wallet-Dienste usw. sind Verweise auf Dienste, die von Drittanbietern bereitgestellt werden.',

        'index:hero.title': 'DipSway - Konfigurationsfreie, vollautomatische KI-Krypto-Handelsbots',
        'index:hero.description': 'Erleben Sie nahtlosen Krypto-Handel mit unseren KI-Bots. Starten Sie Ihren Krypto-Handels-KI-Bot für 100% automatisierte Trades, rund um die Uhr auf einer einfachen Benutzeroberfläche verfügbar.',

        'header:product': 'Produkt',
        'header:product.feature.header': 'Was kann Ihr Kapital mit DipSway verdienen?',
        'header:product.feature.description': 'Berechnen Sie Ihre Einnahmen basierend auf DipSways Backtest-Historie.',
        'header:product.feature.cta': 'Berechnen Sie Ihre Einnahmen',

        'header:product.links.spot_ai_trading_bot': 'Spot KI-Handelsbot',
        'header:learn': 'Lernen',
        'header:learn.links.ai_trading_optimization': 'KI + Krypto-Handel',

        'welcome:headline': 'Konfigurationsfreie, vollautomatische KI-Krypto-Handelsbots',
        'welcome:subheadline': 'Verbinden Sie sich mit Ihrer Börse, schalten Sie den Autopiloten ein und Sie sind fertig.',
        'start_7_days_no_credit_card': '7-tägige kostenlose Testversion starten - Keine Einzahlung erforderlich.',

        '404:title_site': '404 - DipSway | KI-gesteuerter Krypto-Handelsbot',
        '404:description_site': 'Finden Sie Ihren Weg zurück zum Krypto-Handel mit unserem KI-Bot. Starten Sie Ihren Krypto-Handels-KI-Bot für 100% automatisierte Trades, rund um die Uhr auf einer einfachen Benutzeroberfläche verfügbar.',
        '404:page_not_found': 'Seite nicht gefunden',
        '404:page_not_found_message': 'Sieht so aus, als hätten Sie einen ... kaputten Link gefunden! Bitte lassen Sie den DipSway-Support wissen,\nwelche Seite Sie hierher geführt hat!',
        '404:go_home': 'Zur Startseite',
        '404:open_dashboard': 'Dashboard öffnen',

        'affiliate_program_policy:title_site': "DipSway Partnerprogramm-Richtlinie",
        'affiliate_program_policy:description_site': "DipSway Partnerprogramm-Richtlinie",

        'crypto_bot_calculator:title_site': "Krypto-Handelsbot-Ertragsrechner",
        'crypto_bot_calculator:description_site': "Vollständige Rentabilitätsanalyse basierend auf DipSways historischen Daten, unter Berücksichtigung von Faktoren wie Börsengebühren, Coin-Leistungen und Simulation, wie DipSway-Bots mit Ihrer Kapitalgröße handeln würden.",
        'crypto_bot_calculator:label': 'Krypto-Handelsbot-Ertragsrechner',
        'crypto_bot_calculator:simulate_based_on': 'Simulieren Sie basierend auf früheren Leistungen',
        'crypto_bot_calculator:calculate_your_earnings': 'Berechnen Sie Ihre Einnahmen',
        'crypto_bot_calculator:description': 'Vollständige Rentabilitätsanalyse basierend auf DipSways historischen Daten, unter Berücksichtigung von Faktoren wie Börsengebühren, Coin-Leistungen und Simulation, wie DipSway-Bots mit Ihrer Kapitalgröße handeln würden.',

        'featured_on': 'Vorgestellt auf',
        '4_clicks_to_start': '4 Klicks zum Start',
        'no_knowledge_required': 'Keine Konfiguration, keine Kenntnisse erforderlich',
        'split_content_description': 'DipSway ist wirklich konfigurationsfrei. Verbinden Sie Ihre Börse → schalten Sie Ihren Bot ein →\n' +
            '      und überwachen Sie seine Leistung. Die bestperformenden Konfigurationen werden\n' +
            '      standardmäßig angewendet.',
        'learn_about_dipsway_ai': 'Erfahren Sie mehr über DipSway KI',

        'trained_for_down_trends': 'Trainiert für Abwärtstrends',
        'be_safe_on_down_trends': 'Bleiben Sie sicher, auch bei Abwärtstrends',
        'autopilot_content_headline_1': 'Einsteigen vor dem Pump, aussteigen vor dem Drop,',
        'autopilot_content_headline_2': 'im Autopilot-Modus',
        'autopilot_content_description': 'DipSway analysiert Märkte und platziert Trades, während Sie mit Ihren\n' +
            '      täglichen Verpflichtungen beschäftigt sind, und hält Sie mit Statusupdates auf dem Laufenden.',
        'downtrends_description': 'DipSways KI ist darauf trainiert, Abwärtstrends zu erkennen und bevor die Dinge außer Kontrolle geraten,\n' +
            '      wird sie Sie herausbringen',

        'see_how_dipsway_works': 'Sehen Sie, wie DipSway funktioniert',

        'free_trial_action:label': 'Bereit, wenn Sie es sind.',

        'dipsway_ai': 'DipSway KI',

        'safety': 'Sicherheit',
        'safety_description': 'Bewahren Sie Ihre Gelder sicher in Ihrer Börse auf, Ihre Sicherheit ist unsere Priorität.',

        'ai_trading_optimization_description': 'Unser proprietäres KI-Modell, trainiert um Chancen zu erkennen.',

        '3months': '3 Monate',
        'total_profits_within_label': 'Gesamtgewinne über alle eingesetzten DipSway-Bots in den letzten',

        'dipsway_uses_combination_of': 'DipSway verwendet eine Kombination von',
        'and': 'und',
        'technical_indicators': 'technischen Indikatoren',
        'pattern_detectors': 'Musterdetektoren',
        'detect_next_move': 'um den nächsten Zug des Marktes zu erkennen.',

        'are_you_influencer': 'Sind Sie ein Influencer? Werden Sie Partner',
        'influencer_description': 'Influencer, die mit uns zusammenarbeiten, erhalten eine maßgeschneiderte Onboarding-Landingpage,\n' +
            '      die auf die Interessen ihrer Nutzerbasis zugeschnitten ist.',

        'get_in_touch': 'Kontaktieren Sie uns',

        'spot_ai_bot:title_site': 'Spot KI-Krypto-Handelsbot | 100% automatisierte Trades',
        'spot_ai_bot:description_site': 'Schalten Sie Ihren Krypto-Handelsbot ein und genießen Sie 100% automatisierte Trades. Starten Sie Ihren Bot heute für einen rund um die Uhr laufenden Bot. Genießen Sie Bequemlichkeit und maximieren Sie Ihre Gewinne.',

        'spot_ai_bot:24_7_automated_trading': '24/7 automatisierter Handel, nahezu sofortige Trade-Platzierung',
        'spot_ai_bot:algo_crypto_by_ml': 'KI-Algorithmischer Krypto-Handel, angetrieben durch maschinelles Lernen',

        'spot_ai_bot:desc': 'Der beste Handelsbot in der Branche, der KI verwendet, um den Markt zu analysieren und\n' +
            'Handelsentscheidungen mit maschinenähnlicher Geschwindigkeit zu treffen.',

        'million': 'Million',

        'avg_monthly_volume_moved_by_bots': 'Durchschnittliches monatliches Volumen, das von DipSway-Bots bewegt wird.',
        'dipsway_has_more_than_350_users': 'DipSway hat weltweit mehr als 350 Benutzer.',
        'dipsway_supports_315_coins': 'DipSway unterstützt den Handel für über 315+ Währungen.',

        'ai_powered': "KI-gesteuert",
        'trading_strategies_powered_by_ai': "Die Handelsstrategien werden von KI angetrieben und nutzen eine Kombination aus technischer Analyse und Fundamentalanalyse.",

        'geolocation_advantage': "Geolokalisierungsvorteil",
        'geolocation_advantage_desc': "Die Server, die den Bot hosten, befinden sich am gleichen geografischen Standort wie die Börse, um die geringstmögliche Latenz zu gewährleisten (durchschnittlich ~932μs Verzögerung).",

        'high_frequency_trading': 'Hochfrequenzhandel',
        'high_frequency_trading_desc': 'Dank einer Multi-Tenant-Architektur werden die Handelsstrategien mit einer Granularität von 1-Sekunden-Kerzen ausgeführt.',

        'advanced_risk_management': 'Fortschrittliches Risikomanagement',
        'advanced_risk_management_desc': 'Der Bot ist in der Lage, Investitionen in mehreren Währungen zu verwalten und das Risiko des Portfolios automatisch abzusichern.',

        'optimization_overfitting_avoidance': 'Optimierung & Vermeidung von Überanpassung',
        'optimization_overfitting_avoidance_desc': 'Der Bot ist in der Lage, die Parameter der Handelsstrategien zu optimieren und Überanpassung zu vermeiden, indem er einen KI-genetischen Algorithmus und eine benutzerdefinierte Walk-Forward-Analyse verwendet.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'Alle 24 Stunden führen wir Backtests aller Handelsstrategien durch und berechnen die von der KI verwendeten Gewichte unter Verwendung eines "Online Learning"-Ansatzes neu.',
        'how_do_dipsways_strategies_work': "Wie funktionieren DipSways Strategien?",
        'no_one_wants_to_invest_in_black_box': "Niemand möchte in eine Black Box investieren. Deshalb haben wir eine einfache Möglichkeit geschaffen, hinter die Kulissen von DipSways KI-Bot zu blicken - DipSway kombiniert 112+ Indikatoren & Strategien, um Ihnen einen Vorteil in der sich schnell verändernden Welt der Kryptowährungen zu verschaffen.",
        'learn_more_about_dipsway_ai': 'Erfahren Sie mehr über DipSway KI',

        'autopilot_buys_sharp_as_knife': 'Autopilot-Käufe, scharf wie ein Messer',
        'autopilot_buys_sharp_as_knife_desc': 'Die meisten Kryptomärkte bewegen sich seitwärts und große Preisschwankungen halten nicht lange an. Dank eines maschinellen Lernprozesses zur Entscheidungsfindung ist der KI-Bot darauf ausgelegt, scharfe Kaufaufträge auszuführen, die Sie in Seitwärtsmärkten kontinuierlich profitabel halten und von großen Dips profitieren, wenn sie auftreten.',
        'other_strategies_dont_work': 'Andere Strategien funktionieren nicht, weil sie statisch sind und sich nicht mit Marktveränderungen weiterentwickeln können.',

        'autopilot_sells_and_youre_in_profit': 'Autopilot verkauft und Sie sind im Gewinn.',

        'dont_let_downturns_catch_you_off_guard': 'Lassen Sie sich von Abschwüngen nicht überraschen.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ Indikatoren und jede Metrik, die Sie auf einer Plattform benötigen.',

        'your_bot_runs_in_cloud': 'Ihr Bot läuft in der Cloud, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Unser Bot ist ein cloudbasierter Handelsbot, der rund um die Uhr läuft, sodass Sie sich keine Sorgen um Offline-Zeiten machen müssen! Er ist immer mit dem Markt verbunden und analysiert den besten Zeitpunkt zum Kaufen und Verkaufen.\n',

        'unfair_advantage': 'um Ihnen einen unfairen Vorteil zu verschaffen.',
        'and_many_more': 'Und vieles mehr...',

        'pricing:title_site': 'DipSway Preisgestaltung',
        'pricing:description_site': "Erschwinglicher Krypto-Handels-KI-Bot | Starten Sie Ihren Bot heute | Genießen Sie 24/7 automatisierten Handel",
        'pricing:supercharge_text': 'Beschleunigen Sie Ihren Handel mit DipSway KI',

        'performance:title_site': 'DipSway Leistungshistorie & Backtests',
        'performance:description_site': "Erleben Sie Live-Backtests mit unserem Krypto-Handels-KI-Bot. Starten Sie Ihren Bot heute für einen rund um die Uhr laufenden Bot. Genießen Sie Bequemlichkeit und maximieren Sie Ihre Gewinne.",
        'performance:label': "DipSway Leistungshistorie & Backtests",

        'connect_to_exchange': 'Verbinden Sie sich mit Ihrer Börse, schalten Sie den Autopiloten ein und Sie sind fertig.',
        'calculate_your_earnings': 'Berechnen Sie Ihre Einnahmen',

        'trading_optimization:title_site': "DipSway KI-Handelsoptimierung",
        'trading_optimization:description_site': "Erleben Sie KI-Handelsoptimierung mit unserem Krypto-Handels-KI-Bot. Starten Sie Ihren Bot heute für einen rund um die Uhr laufenden Bot. Genießen Sie Bequemlichkeit und maximieren Sie Ihre Gewinne.",
        'trading_optimization:label': "KI-Handelsoptimierung",
        'trading_optimization:breakdown': 'Aufschlüsselung: DipSways KI-Krypto-Handel',

        'trading_optimization:desc': 'DipSways leistungsstarker KI-Bot passt sich dynamisch an schnelle Marktveränderungen an.',
        'trading_optimization:steps': 'All dies geschieht durch eine Reihe von Schritten:',

        'behind_scenes:behind_the_scenes': 'Was passiert hinter den Kulissen?',

        'behind_scenes:indicators': 'Einige Indikatoren zeigen ein potenzielles Kaufsignal (grün), während andere ein potenzielles Verkaufssignal zeigen (grau).',
        'behind_scenes:neural_network': 'Das DipSway Neuronale Netzwerk berücksichtigt all diese Indikatoren und trifft eine Entscheidung basierend auf dem Gesamtbild, wobei es ein einziges Kauf- oder Verkaufssignal erzeugt.',

        'affiliate_program:title_site': 'Kryptowährungs-Bot Partnerprogramm',
        'affiliate_program:description_site': "Treten Sie unserem Partnerprogramm bei. Genießen Sie 30% wiederkehrende Provision auf Freundeseinladungen.",
        'affiliate_program:label': "Partnerprogramm",
        'affiliate_program:desc': "Verdienen Sie 30% Provision für jede Empfehlung, wiederkehrend",
        'affiliate_program:desc2': "Verbinden Sie sich mit Ihrer Börse, schalten Sie den Autopiloten ein und Sie sind fertig.",
        'affiliate_program:get_your_affiliate_link': 'Holen Sie sich Ihren Partnerlink',

        'conference': 'Konferenz',

        'about:title_site': "Über DipSway",
        'about:our_journey': 'Unsere Reise',
        'about:our_journey_desc': 'Hochwertige, profitable KI-Krypto-Handelsbots für Trader\n' +
            'aller Ebenen bereitzustellen, die ihr Investitionsportfolio wachsen lassen und ihre täglichen\n' +
            'Leistungen verbessern.',
        'about:just_getting_started': 'Und wir fangen gerade erst an.',

        'about:description_site': "Erleben Sie KI-Handelsoptimierung mit unserem Krypto-Handels-KI-Bot. Starten Sie Ihren Bot heute für einen rund um die Uhr laufenden Bot. Genießen Sie Bequemlichkeit und maximieren Sie Ihre Gewinne.",
        'about:label': 'Über uns',
        'about:behind_scenes': 'Hinter den Kulissen',
        'founder': 'Gründer',

        'about:founder1_msg': 'DipSway entstand aus einer Vision, den Krypto-Handel durch modernste KI zu revolutionieren. Als Software-Ingenieure und Krypto-Enthusiasten sahen wir eine Chance, etwas wirklich Innovatives zu schaffen - einen Handelsbot, der wie ein professioneller Trader denkt, aber mit übermenschlicher Geschwindigkeit arbeitet.\nDank unserer starken technischen Expertise kam unser Durchbruch, als wir eine KI entwickelten, die nicht einfach nur voreingestellten Regeln folgt, sondern sich in Echtzeit an die Marktdynamik anpasst.',
        'about:founder2_msg': 'Dies ist kein durchschnittlicher Handelsbot; es ist ein ausgeklügeltes System, das riesige Datenmengen analysiert, komplexe Muster erkennt und in Sekundenbruchteilen Entscheidungen mit bemerkenswerter Genauigkeit trifft.\nWas DipSway auszeichnet, ist unser einzigartiger Ansatz für KI-Lernen. Es ist, als hätten Sie ein Team von Handelsexperten, die rund um die Uhr für Sie arbeiten, aber schneller und effizienter.\nUnsere Mission ist es, fortgeschrittenen Krypto-Handel für jeden zugänglich zu machen, von erfahrenen Profis bis hin zu neugierigen Neulingen.',

        'about:join_team': 'Treten Sie dem DipSway-Team bei',
        'about:join_team_desc': 'Wir suchen Krypto-Enthusiasten, die leidenschaftlich für Finanzen,\n' +
            'Handel und Technologie sind. Bereit, die Art und Weise zu ändern, wie die Welt in\n' +
            'Krypto investiert?',

        'read_post': 'Beitrag lesen',

        'about:development_starts': "Entwicklung beginnt",
        'about:development_starts_desc': "Eines Tages erhielten wir Benachrichtigungen von der Coinbase-Mobile-App über den aktuellen Bitcoin-Preis wie 'Bitcoin ist heute um 5% gestiegen'. Wir dachten, es wäre cool, einen Bot zu haben, der VOR solchen Benachrichtigungen arbeitet. Wir suchten im Web und konnten nichts Einfaches zu Bedienendes finden, das in der Cloud läuft. Also beschlossen wir, es selbst zu bauen.",

        'about:first_bot_version': "Erste Bot-Version",
        'about:first_bot_version_desc': "Erste Simulation der Algo-Trading-Strategie für Bitcoin und Ethereum, wir begannen, mehr Krypto-Assets und mehr Strategien einzubeziehen. Wir begannen das Potenzial unseres Bots zu sehen und beschlossen, ihn öffentlich zu machen.",

        'about:simple_strategy_not_enough': 'Eine "einfache Strategie" war nicht genug',
        'about:simple_strategy_not_enough_desc': "Wir bauten und trainierten ein neuronales Netzwerk, das vom Markt lernen und Vorhersagen treffen würde. Die Ergebnisse zeigten eine sehr hohe Genauigkeit.",

        'about:live_bot_release': "Live-Bot-Veröffentlichung",
        'about:live_bot_release_desc': "Wir bauten und trainierten ein neuronales Netzwerk, das vom Markt lernen und Vorhersagen treffen würde. Die Ergebnisse zeigten eine sehr hohe Genauigkeit.",

        'about:our_transparency_value': "Unser Transparenzwert",
        'about:our_transparency_value_desc': "Live-Backtests und Live-Leistungen wurden öffentlich gemacht. Wir möchten, dass Transparenz ein Schlüsselmerkmal ist, das uns von anderen Unternehmen unterscheidet, die keine Beweise für ihre Ergebnisse zeigen.",

        'about:infrastructure_revolution': "Infrastruktur-Revolution",
        'about:infrastructure_revolution_desc': "2023 war ein erfolgreiches Jahr, da mehr Trader unsere Plattform annahmen. Wir erhöhten unser Engagement für Trader und versuchten zu verstehen, was jeder wirklich in einer Bot-Plattform brauchte. Angesichts dieser wachsenden Nutzerbasis erkannten wir die Wichtigkeit, DipSway auf Ingenieurebene fehlerfrei zu machen. Bevor wir aufregende neue Updates, Funktionen, Tools und Bots einführten, priorisierten wir sicherzustellen, dass unsere Systeme 24/7 ohne Probleme liefen und dass Leistung und Qualität erstklassig waren.",

        'about:new_exchanges': "Neue Börsen",
        'about:new_exchanges_desc': "Im Februar erweiterten wir unsere Plattform erheblich durch die Integration großer Börsen wie Coinbase Advanced, BingX und Bitmart. Wir gingen auch Partnerschaften mit KuCoin und Bybit ein und wurden eine offizielle Drittanbieter-Anwendung auf beiden. Diese Ergänzungen und Partnerschaften erhöhten die Handelsmöglichkeiten und den Marktzugang unserer Nutzer erheblich, bestätigten die Zuverlässigkeit unserer Plattform und markierten einen wichtigen Meilenstein in unserem Wachstum.",

        'about:rotating_proxy': "Rotierender Proxy und neue Hotfunktionen",
        'about:rotating_proxy_desc': "In einem bedeutenden Upgrade unserer Infrastruktur implementierten wir 20 zusätzliche IPs und erweiterten unsere Datenbank-Replikate von 2 auf 7, was die Reaktionsfähigkeit der Bots auf neue Kerzen über Börsen hinweg verbesserte. Diese Verbesserung positioniert unsere Bots wettbewerbsfähig im Markt in Bezug auf Geschwindigkeit und Zuverlässigkeit. Wir integrierten auch ﻿Crypto(.)com, was unsere unterstützten Börsen und Handelsoptionen für Nutzer weiter ausweitete. Eine bemerkenswerte neue Funktion ist das Bot-Leaderboard, das es Nutzern ermöglicht, Bot-Leistungen zu überprüfen und die Einstellungen der am besten performenden Bots zu kopieren. Diese Verbesserungen stärken gemeinsam die Fähigkeiten unserer Plattform und bieten Nutzern robustere, effizientere und vielfältigere Handelsmöglichkeiten.",

        'about:multiple_quote_currencies': "Mehrere Notierungswährungen",
        'about:multiple_quote_currencies_desc': "Wir haben die Unterstützung für mehrere Währungen eingeführt, sodass Bots mit verschiedenen Notierungspaaren über USDT hinaus handeln können. Diese Verbesserung bietet Nutzern mehr Flexibilität und passt sich unterschiedlichen Börsenstrukturen und geografischen Vorschriften an. Indem wir den Handel in mehreren Währungen ermöglichen, haben wir Nutzer in die Lage versetzt, ihre Strategien entsprechend spezifischen Bedürfnissen und Marktbedingungen zu optimieren und so ihre gesamte Handelserfahrung zu verbessern.",

        'about:new_admin_dashboard': "Neues Admin-Dashboard",
        'about:new_admin_dashboard_desc': "Die Veröffentlichung unserer neuen Plattform markiert einen bedeutenden Sprung in Benutzererfahrung und Funktionalität. Sie ermöglicht eine schnellere Entwicklung und Bereitstellung neuer Funktionen und gibt uns mehr Kontrolle über die Bot-Fehlerbehebung, um erstklassigen Support zu gewährleisten. Wir haben die Kommunikation zwischen Benutzeroberfläche und Backend verstärkt, was Datengenauigkeit und zuverlässige Funktionalität garantiert. Während wir uns weiterhin einem minimalistischen, sauberen und einfach zu navigierenden Design verpflichtet fühlen, haben wir die Plattform auch mit umfassenden Daten und Analysen angereichert. Dieses Gleichgewicht ermöglicht es Nutzern, tiefe Einblicke zu gewinnen, ohne Kompromisse bei der Einfachheit einzugehen, und erlaubt eine fundiertere Entscheidungsfindung in einer benutzerfreundlichen Umgebung.",

        'about:autopilot_tradingview': "Autopilot & TradingView Signalautomatisierung",
        'about:autopilot_tradingview_desc': "Der Autopilot-Bot, unsere revolutionäre KI-gesteuerte Handelslösung. Diese konfigurationsfreie Verbesserung unserer KI-Bots nutzt historische Daten, neuronale Netzwerkberechnungen und LIVE-Daten von allen DipSway-Bots, um Handelsstrategien kontinuierlich zu optimieren. Er wählt automatisch die am besten performenden Assets aus und passt Konfigurationen basierend auf Echtzeit-Marktparametern an, was den Nutzern eine handfreie, datengesteuerte Handelserfahrung bietet, die sich sofort an Marktbedingungen anpasst.",

        'about:ai_futures_bot': "KI-Futures-Bot",
        'about:ai_futures_bot_desc': "Mit der Einführung des internen SL & TP-Systems (Trailing-Operationen werden bald verfügbar sein) haben jetzt alle Nutzer eine 100%ige Erfolgsquote bei ihren benutzerdefinierten Einstellungen für ihren Bot. Das interne System ermöglichte es uns, die erste Generation eines authentischen KI-Futures-Bots zu veröffentlichen.",

        'about:new_features': "Neue Funktionen",
        'about:new_features_desc': "Wir arbeiten an einer neuen Funktion, die es Nutzern ermöglicht, ihre eigenen Strategien zu erstellen und mit der Community zu teilen.",

        'proven_to_perform': 'Bewährte Leistung',

        'see_how_dipsway_averages': 'Sehen Sie, wie DipSwayer im Durchschnitt mit der Kraft von DipSway-Bots abschneiden.',
        'last': 'Letzte',
        'months_compound_roi': 'Monate zusammengesetzter ROI',
        'total_return_based_on_last': 'Gesamtrendite basierend auf den letzten',
        'show_as_multiplier_values': 'Als Multiplikatorwerte anzeigen',
        'show_as_percentage_values': 'Als Prozentwerte anzeigen',
        'join': 'Beitreten',
        'stars': 'Sterne',

        'faq': 'Häufig gestellte Fragen',
        'faq_desc': 'Finden Sie hier Ihre Antworten. Wenn Sie eine Frage haben, die nicht in den FAQ behandelt wird,\n' +
            'zögern Sie nicht, uns zu kontaktieren.',
        'faq_sub_desc': 'Konnten Sie die gesuchte Antwort nicht finden?',
        'contact_support': 'Support kontaktieren',

        'view_supported_platform': 'Unterstützte Plattformen anzeigen',

        'simulate_based_on_past_performances': 'Basierend auf vergangenen Leistungen simulieren',
        'calculate_your_earnings_desc': 'Vollständige Rentabilitätsanalyse basierend auf DipSways historischen Daten, unter Berücksichtigung von Faktoren wie Börsengebühren, Coin-Leistungen und Simulation, wie DipSway-Bots mit Ihrer Kapitalgröße handeln würden.',

        'go_to_tweet': 'Zum Tweet gehen',
        'view_on': 'Ansehen auf',

        'security': 'Sicherheit',
        'safe_and_secure': 'Sicher und geschützt',
        'safe_and_secure_desc': 'Ihre Gelder bleiben in Ihrer Börse, und wir bitten nur um minimale\n' +
            'Berechtigungen, um Trades für Sie auszuführen.',

        'follow_dipsway_development': 'Verfolgen Sie die Entwicklung von DipSway und treffen Sie die Community',
        'join_us_on_telegram': 'Treten Sie uns auf Telegram bei',
        'here_when_you_need_help': 'Hier, wenn Sie Hilfe benötigen',
        'dipsway_customer_care': 'DipSway Kundenbetreuung',

        'for_us_top_10_percent': 'Für uns ist die Top 10% der Standard für DipSwayer. Sie können frei\n' +
            'mit verschiedenen Konfigurationen experimentieren, aber wenn Sie Hilfe bei der\n' +
            'Optimierung benötigen, sind wir da.',

        'join_the_telegram_community': 'Treten Sie der Telegram-Community bei',

        'made': 'machte',
        'profit_on': 'Gewinn bei',
        'a_single_trade': 'einem einzelnen Trade',

        'dont_go_back_to_manual_trading': 'Gehen Sie nicht zurück zum manuellen Handel,',

        'get_in_before_the_pump': 'steigen Sie vor dem Pump ein',
        'get_out_before_the_drop': 'steigen Sie vor dem Drop aus',

        'on_autopilot': 'Im Autopilot-Modus',

        'start_your_dipsway_bot': 'Starten Sie Ihren DipSway-Bot in 4 Klicks',

        'safe_for_you': 'Sicher für Sie, durch Design',
        'safe_for_you_desc': 'Mit DipSway sind Ihre Gelder sicher in Ihrer Krypto-Börse, und wir bitten Sie nur,\n' +
            'uns so weit zu vertrauen, wie es für den Betrieb der Bots notwendig ist.',

        'ip_whitelisting': 'IP-Whitelisting',
        'ip_whitelisting_desc': 'Nur von DipSway kontrollierte IP-Adressen können auf Ihrem Konto operieren, selbst wenn Ihr API-Schlüssel gestohlen wurde, können böswillige Akteure keine Trades ausführen.',

        'restricted_access': 'Eingeschränkter Zugriff',
        'restricted_access_desc': 'DipSway fordert nur die einfachsten Berechtigungen an, um in Ihrem Namen\n' +
            'zu überwachen und Trades auszuführen.',

        'bright_green_performances': 'Hellgrüne Leistungen',
        'on_capital': 'Auf Kapital',
        'win_rate': 'Gewinnrate',
        'average_trade': 'Durchschnittlicher Trade',

        'see_dipsway_live_backtests': 'Sehen Sie DipSway Live-Backtests & Leistungen',
        'find_and_download': 'Finden und laden Sie historische Leistungsdaten und Live-Backtests herunter.',

        'available_at_no_extra_cost': 'Verfügbar ohne zusätzliche Kosten',

        'full_autopilot_mode': 'Vollständiger Autopilot-Modus',
        'full_autopilot_mode_desc': 'Mit DipSway Autopilot werden nicht nur Ihre Trades automatisiert, sondern\n' +
            'DipSway wählt auch die beste Kryptowährung aus, die zu diesem Zeitpunkt\n' +
            'basierend auf Marktbedingungen gehandelt werden soll.',

        'calculate_your_earnings_with_autopilot': 'Berechnen Sie Ihre Einnahmen mit Autopilot',
        'high_performance': 'Hohe Leistung',
        'live_backtests_historical_performance': 'Live-Backtests, historische Leistung.',
        'backtests_performance': 'Backtests & Leistung',
        'extendable_via_tradingview': 'Erweiterbar über TradingView',
        'use_power_tradingview': 'Nutzen Sie die Kraft von TradingView, um DipSway weiter zu konfigurieren.',
        'coming_soon': 'Demnächst verfügbar...',
        '3_green_trades_in_30_days': '3 grüne Trades in 30 Tagen oder Geld-zurück-Garantie',
        'need_help_choosing': 'Brauchen Sie Hilfe bei der Auswahl?',
        'use_dipsway_earnings_calculator': 'Verwenden Sie den DipSway-Ertragsrechner, erfahren Sie, wie viel Sie verdienen können, und erhalten Sie eine Planempfehlung.',
        'launch_earnings_calculator': 'Ertragsrechner starten',
        'popular': 'Beliebt',
        'recommended_for_you': 'Für Sie empfohlen',
        'no_deposit_required': 'Keine Einzahlung erforderlich',
        'top_10_of_users': "Top 10% der Benutzer",
        'all_users': "Alle Benutzer",
        'downtrend_page:desc1': 'Operation: Notfallposition-Exit. Abwärtstrend identifiziert.',
        'downtrend_page:sell_order_fulfilled': 'Verkaufsauftrag für 12 BTC erfüllt.',
        'performance_history': 'Leistungshistorie',
        'trained_to_recognize_opportunities': 'Trainiert, um Chancen zu erkennen',
        'live_backtests_performances': 'Live-Backtests-Leistungen',
        'see_how_our_strategies_performed': 'Sehen Sie, wie unsere Strategien in der Vergangenheit mit hochwertigen Live-Backtests abgeschnitten haben.',
        'backtests_from': 'Backtests der',
        'past_6_months': 'letzten 6 Monate',
        'collapse': 'Einklappen',
        'expand': 'Erweitern',
        'view_report': 'Bericht anzeigen',
        'knowledge:title1': 'Hochwertige historische Daten',
        'knowledge:description1': 'Wir stellen sicher, dass die verwendeten Live-Candlesticks von höchster Qualität sind.',
        'knowledge:title2': 'Gebühren & Verzögerungen inbegriffen',
        'knowledge:description2': 'Maker- und Taker-Gebühren von 0,1% werden auf jeden Trade angewendet. Ein Slippage von 0,1% und eine Verzögerung von wenigen Minuten werden auf jeden Trade angewendet.',
        'knowledge:title3': 'Vermeidung von Überanpassung',
        'knowledge:description3': 'DipSway überwacht passiv schlecht performende Konten und empfiehlt Maßnahmen.',
        'operation': 'Operation',
        'buy_the_dip': 'Den Dip kaufen',
        'sell_the_high': 'Das Hoch verkaufen',
        'bought': 'Gekauft',
        'watching_market': 'Markt beobachten',
        'sell_order_for': 'Verkaufsauftrag für',
        'fulfilled': 'erfüllt',
        'profit_made': 'Erzielter Gewinn',
        'calculation_results': 'Berechnungsergebnisse',
        'calculating': 'Berechnung läuft',
        'total_expected_profit_at_the_end_of': 'Erwarteter Gesamtgewinn am Ende von',
        'period_based_off_of_historical_backtest_performances': 'Zeitraum basierend auf historischen Backtest-Leistungen',
        'trades': 'Trades',
        'day': 'Tag',
        'profit': 'Gewinn',
        'recommended_plan_for_you': 'Empfohlener Plan für Sie',
        'go_to': 'Gehen Sie zu',
        'free_trial_available': 'Kostenlose Testversion verfügbar',
        'runs_at': 'Läuft mit',
        'learn_how_we_calculate_your_earnings': 'Erfahren Sie, wie wir Ihre Einnahmen berechnen',
        'click_to_open_deepdive': 'Klicken Sie hier, um die Tiefenanalyse zu öffnen, wo Sie aus anderen von uns unterstützten Börsen wählen können.',
        'your_capital': 'Ihr Kapital',
        'currency': 'Währung',
        'enabled': 'Aktiviert',
        'set_to': 'eingestellt auf',
        'coin_count': 'Münzanzahl',
        'disabled': 'Deaktiviert',
        'auto': 'Auto',
        'selected_coins': 'Ausgewählte Münzen',
        'loading_coins': 'Münzen werden geladen',
        'login': 'Anmelden',

        'trading_with': 'Handel mit',
    },

    pt: {

        'all_payment_types': 'todos os tipos de pagamento',
        'all_payment_types_description': 'Experimente transações sem complicações com Bitcoin, Ethereum e muito mais. Pague com segurança através do gateway Cryptomus confiável para acesso instantâneo aos recursos da DipSway.\n',
        'extra': 'Extra',

        'no-token:footer.disclaimer': 'A DipSway não vende, oferece, facilita ou fornece acesso a qualquer token DeFi ou criptomoeda em qualquer blockchain. Somos apenas um fornecedor de software.',

        "different_ways_to_swap": "Diferentes Formas de Trocar",
        'coin_swap_description': 'Otimize suas negociações de {crypto} para {quote} com nosso localizador de Caminhos de Troca de Moedas. Compare instantaneamente várias rotas de conversão de {crypto}/{quote}, visualize trocas complexas e garanta as melhores taxas de câmbio para suas conversões de {crypto} para {quote}. Perfeito tanto para traders de criptomoedas novatos quanto experientes que buscam maximizar suas negociações de {crypto} para {quote}.',

        "current_price_info": "O preço atual de {crypto} é {price}, o que representa uma mudança de {change24h} nas últimas 24 horas e {change7d} nos últimos 7 dias.",
        "circulating_supply_info": "Com um fornecimento circulante de 21M, a capitalização de mercado ao vivo de {crypto} é {marketCap}.",
        "trading_volume_info": "O volume de negociação de 24 horas é {volume}.",
        "conversion_rate_calculation": "As taxas de conversão são calculadas usando o Índice de Preço ao Vivo de {crypto} junto com outros índices de preços de ativos digitais.",
        "exchange_rate_update": "A taxa de câmbio {crypto}/{quote} é atualizada em tempo real.",
        "follow_dipsway_info": "Siga o conversor e calculadora de criptomoedas da DipSway para atualizações ao vivo 24/7 das taxas de câmbio entre criptomoedas e moedas fiduciárias, incluindo {crypto}, {currencies} e mais.",

        "description_calculator_pt_1": "Esta calculadora fornece uma taxa de conversão para",
        "description_calculator_pt_2": "para",
        "description_calculator_pt_3": "A taxa de conversão é baseada na taxa de mercado das últimas 24h.",
        "to": "para",
        "converter": "Conversor",
        "crypto_prices_and_market_data": "Preços de Criptomoedas e Dados de Mercado",
        "converter_site:desc": "Converta entre criptomoedas e moedas fiduciárias com nossa ferramenta de conversão fácil de usar. Obtenha as taxas de câmbio mais recentes e dados de mercado para suas negociações.",
        "callout_calculator": "Bots de Trading de Criptomoedas alimentados por IA",
        "info_on_price": "Informação de preço",
        "live_data": "Dados em tempo real",

        "prices_of": "Preços de",
        "on_different_exchanges": "em diferentes exchanges de criptomoedas",
        "converted_to": "Convertido para",
        "on_different_amounts": "em diferentes quantidades",
        "up_of": "Aumento de",
        "dropped_of": "Queda de",
        "respect_yesterday": "em relação a ontem",
        "close_price": "Preço de fechamento",
        "price_variation": "Variação de preço",
        "vwap": "Preço médio",
        "volume_last_24h": "Volume 24h",
        "exchange": "Exchange",
        "price": "Preço",
        "volume": "Volume",
        "quote_volume": "Volume de cotação",
        "percentage": "Porcentagem",
        "asset": "Ativo",
        "today_at": "Hoje às",
        "calculator_title": "Calculadora de {crypto} para {quote}",
        "crypto_label": "Cripto",
        "currency_label": "Cotação",
        "enter_amount": "Insira o valor",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Negociar {crypto}",

        'search_for_crypto': 'Pesquisar por criptomoeda',
        'crypto_prices_title': 'Preços de criptomoedas suportadas',
        'see_current_crypto_market_trend': 'Veja a tendência atual do mercado de criptomoedas',

        'see_more': 'Ver mais',
        'dipsway': 'DipSway',
        'home_page': 'página inicial',
        'blogs.related_posts': 'Posts Relacionados',
        'blogs.automate_your_trading': 'Automatize suas negociações com DipSway AI hoje.',
        'blogs.automate_your_trading_description': 'DipSway usa uma combinação de mais de 121 indicadores técnicos e mais de 17 detectores de padrões para lhe dar uma vantagem injusta.',
        'btn.start_7_day_free_trial': 'Inicie o teste gratuito de 7 dias',
        'blogs.tags': 'Tags',
        'blogs.description_site': 'Tutoriais de criptomoedas, lançamentos de recursos, comparações de produtos e mais! Encontre conteúdo da equipe DipSway aqui no blog DipSway.',
        'blogs.title_site': 'Blog DipSway',
        'blogs.callout': 'Apresentando a versão 4.13',
        'blogs.description_extended': 'Tutoriais, lançamentos de recursos, comparações de produtos e mais!\nEncontre conteúdo da equipe DipSway aqui no blog DipSway.',
        'blogs.go_to_article': 'Ir para o artigo',
        'skip_to_content': 'Pular para o conteúdo',
        'calculate_your_earnings_and_start_free_trial': 'Calcule seus ganhos e inicie o teste gratuito de 7 dias',

        'privacy_policy': 'Política de Privacidade',
        'terms_of_service': 'Termos de Serviço',
        'refund_policy': 'Política de Reembolso',
        'affiliate_policy': 'Política de Afiliados',
        'blog': 'Blog',
        'supported_exchanges': 'Exchanges Suportadas',
        'pricing': 'Preços',
        'resources': 'Recursos',
        'company': 'Empresa',
        'support': 'Suporte',
        'affiliate_program': 'Programa de Afiliados',
        'about_us': 'Sobre Nós',
        'customer_success_agent': 'Agente de Sucesso do Cliente',
        'dipsway_ai_optimization': 'Otimização DipSway AI',
        'live_back_tests': 'Backtests ao vivo',
        'live_back_tests_performances': 'Backtests e desempenhos ao vivo do DipSway',
        'historical_performance_data': 'Dados de desempenho histórico',
        'dipsway_earning_calculator': 'Calculadora de ganhos DipSway',
        'performance': 'Desempenho',
        'common:footer.disclaimer': 'DipSway fornece apenas software. Quaisquer referências a serviços de negociação, troca, transferência ou carteira, etc., são referências a serviços fornecidos por provedores de serviços de terceiros.',

        'index:hero.title': 'DipSway - Bots de negociação de criptomoedas com IA em piloto automático e sem configuração',
        'index:hero.description': 'Experimente a negociação de criptomoedas sem esforço com nossos bots de IA. Inicie seu bot de negociação de criptomoedas com IA para negociações 100% automatizadas, disponível 24/7 em uma interface simples.',

        'header:product': 'Produto',
        'header:product.feature.header': 'O que seu capital pode ganhar usando DipSway?',
        'header:product.feature.description': 'Calcule seus ganhos com base no histórico de backtests do DipSway.',
        'header:product.feature.cta': 'Calcule seus ganhos',

        'header:product.links.spot_ai_trading_bot': 'Bot de Negociação Spot com IA',
        'header:learn': 'Aprender',
        'header:learn.links.ai_trading_optimization': 'IA + Negociação de Criptomoedas',

        'welcome:headline': 'Bots de negociação de criptomoedas com IA em piloto automático e sem configuração',
        'welcome:subheadline': 'Conecte-se à sua exchange, ative o piloto automático e pronto.',
        'start_7_days_no_credit_card': 'Inicie o teste gratuito de 7 dias - Sem depósito necessário.',

        '404:title_site': '404 - DipSway | Bot de Negociação de Criptomoedas Impulsionado por IA',
        '404:description_site': 'Encontre seu caminho de volta para a negociação de criptomoedas com nosso bot de IA. Inicie seu bot de negociação de criptomoedas com IA para negociações 100% automatizadas, disponível 24/7 em uma interface simples.',
        '404:page_not_found': 'Página não encontrada',
        '404:page_not_found_message': 'Parece que você encontrou um... link quebrado! Por favor, informe ao suporte do DipSway\nqual página o trouxe até aqui!',
        '404:go_home': 'Ir para a página inicial',
        '404:open_dashboard': 'Abrir painel',

        'affiliate_program_policy:title_site': "Política do Programa de Afiliados DipSway",
        'affiliate_program_policy:description_site': "Política do Programa de Afiliados DipSway",

        'crypto_bot_calculator:title_site': "Calculadora de ganhos do bot de negociação de criptomoedas",
        'crypto_bot_calculator:description_site': "Análise completa de rentabilidade baseada nos dados históricos do DipSway, considerando fatores como taxas de exchange, desempenho das moedas e simulando como os bots DipSway negociariam com o tamanho do seu capital.",
        'crypto_bot_calculator:label': 'Calculadora de ganhos do bot de negociação de criptomoedas',
        'crypto_bot_calculator:simulate_based_on': 'Simular com base no desempenho passado',
        'crypto_bot_calculator:calculate_your_earnings': 'Calcule seus ganhos',
        'crypto_bot_calculator:description': 'Análise completa de rentabilidade baseada nos dados históricos do DipSway, considerando fatores como taxas de exchange, desempenho das moedas e simulando como os bots DipSway negociariam com o tamanho do seu capital.',

        'featured_on': 'Destaque em',
        '4_clicks_to_start': '4 cliques para começar',
        'no_knowledge_required': 'Sem configuração, sem conhecimento necessário',
        'split_content_description': 'DipSway é realmente sem configuração. conecte-se à sua exchange → ative seu bot →\n' +
            '      e monitore seu desempenho. As configurações de melhor desempenho são\n' +
            '      aplicadas por padrão.',
        'learn_about_dipsway_ai': 'Saiba mais sobre DipSway AI',

        'trained_for_down_trends': 'Treinado para tendências de baixa',
        'be_safe_on_down_trends': 'Fique seguro, mesmo em tendências de baixa',
        'autopilot_content_headline_1': 'Entre antes da alta, saia antes da queda,',
        'autopilot_content_headline_2': 'em piloto automático',
        'autopilot_content_description': 'DipSway analisa mercados e realiza negociações enquanto você está ocupado com seus\n' +
            '      compromissos diários, e mantém você informado com atualizações de status.',
        'downtrends_description': 'A IA do DipSway é treinada para identificar tendências de baixa e antes que as coisas fiquem fora de controle,\n' +
            '      ela tirará você',

        'see_how_dipsway_works': 'Veja como DipSway funciona',

        'free_trial_action:label': 'Pronto quando você estiver.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Segurança',
        'safety_description': 'Mantenha seus fundos seguros em sua exchange, sua segurança é nossa prioridade.',

        'ai_trading_optimization_description': 'Nosso modelo de IA proprietário treinado para reconhecer oportunidades.',

        '3months': '3 meses',
        'total_profits_within_label': 'Lucros totais em todos os bots DipSway implantados nos últimos',

        'dipsway_uses_combination_of': 'DipSway usa uma combinação de',
        'and': 'e',
        'technical_indicators': 'indicadores técnicos',
        'pattern_detectors': 'detectores de padrões',
        'detect_next_move': 'para detectar o próximo movimento do mercado.',

        'are_you_influencer': 'Você é um influenciador? Faça parceria conosco',
        'influencer_description': 'Influenciadores que trabalham conosco recebem uma página de destino de integração personalizada\n' +
            '      adaptada aos interesses de sua base de usuários.',

        'get_in_touch': 'Entre em contato',

        'spot_ai_bot:title_site': 'Bot de Negociação Spot com IA | Negociações 100% Automatizadas',
        'spot_ai_bot:description_site': 'Ative seu Bot de Negociação de Criptomoedas e aproveite as negociações 100% automatizadas. Inicie seu bot hoje para um bot funcionando 24/7. Aproveite a conveniência e maximize seus lucros.',

        'spot_ai_bot:24_7_automated_trading': 'Negociação automatizada 24/7, colocação de negociações quase instantânea',
        'spot_ai_bot:algo_crypto_by_ml': 'Negociação Algorítmica de Criptomoedas com IA, impulsionada por Aprendizado de Máquina',

        'spot_ai_bot:desc': 'O melhor bot de negociação da indústria, usando IA para analisar o mercado e\n' +
            'tomar decisões de negociação em velocidade de máquina.',

        'million': 'milhão',

        'avg_monthly_volume_moved_by_bots': 'Volume mensal médio movido pelos bots DipSway.',
        'dipsway_has_more_than_350_users': 'DipSway tem mais de 350 usuários em todo o mundo.',
        'dipsway_supports_315_coins': 'DipSway suporta negociação para mais de 315+ moedas.',

        'ai_powered': "Impulsionado por IA",
        'trading_strategies_powered_by_ai': "As estratégias de negociação são impulsionadas por IA, usando uma combinação de análise técnica e análise fundamental.",

        'geolocation_advantage': "Vantagem de Geolocalização",
        'geolocation_advantage_desc': "Os servidores que hospedam o bot estão localizados na mesma localização geográfica que a exchange, para garantir a menor latência possível (atraso médio de ~932μs).",

        'high_frequency_trading': 'Negociação de Alta Frequência',
        'high_frequency_trading_desc': 'Graças a uma arquitetura multi-tenant, as estratégias de negociação são executadas com uma granularidade de velas de 1 segundo.',

        'advanced_risk_management': 'Gerenciamento de Risco Avançado',
        'advanced_risk_management_desc': 'O bot é capaz de gerenciar investimentos em múltiplas moedas e fazer hedge automático do risco da carteira.',

        'optimization_overfitting_avoidance': 'Otimização & Prevenção de Overfitting',
        'optimization_overfitting_avoidance_desc': 'O bot é capaz de otimizar os parâmetros das estratégias de negociação e evitar overfitting usando um algoritmo genético de IA e uma Análise de Walk-Forward personalizada.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'A cada 24 horas, executamos backtests de todas as estratégias de negociação e recalculamos os pesos usados pela IA usando uma abordagem de "Aprendizado Online".',
        'how_do_dipsways_strategies_work': "Como funcionam as estratégias do DipSway?",
        'no_one_wants_to_invest_in_black_box': "Ninguém quer investir em uma caixa preta. É por isso que criamos uma maneira fácil de acompanhar os bastidores do Bot de IA do DipSway - DipSway combina 112+ indicadores e estratégias para lhe dar uma vantagem no mundo em rápida mudança das criptomoedas.",
        'learn_more_about_dipsway_ai': 'Saiba mais sobre DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Compras em piloto automático, afiadas como uma faca',
        'autopilot_buys_sharp_as_knife_desc': 'A maioria dos mercados de criptomoedas se move lateralmente e grandes oscilações de preços não duram muito. Graças a um processo de decisão de Aprendizado de Máquina, o bot de IA é construído para executar ordens de compra afiadas que manterão você continuamente lucrativo em mercados laterais e aproveitarão quando esses grandes dips ocorrerem.',
        'other_strategies_dont_work': 'Outras estratégias por aí não funcionam porque são estáticas e não podem evoluir com as mudanças do mercado.',

        'autopilot_sells_and_youre_in_profit': 'Piloto automático vende e você está no lucro.',

        'dont_let_downturns_catch_you_off_guard': 'Não deixe que quedas o peguem desprevenido.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ indicadores e todas as métricas que você precisa em uma plataforma.',

        'your_bot_runs_in_cloud': 'Seu bot roda na nuvem, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Nosso bot é um bot de negociação baseado em nuvem que roda 24/7, então você não precisa se preocupar em ficar offline! Ele está sempre conectado ao mercado, analisando o melhor momento para comprar e vender.\n',

        'unfair_advantage': 'para lhe dar uma vantagem injusta.',
        'and_many_more': 'E muito mais...',

        'pricing:title_site': 'Preços DipSway',
        'pricing:description_site': "Bot de Negociação de Criptomoedas com IA Acessível | Inicie Seu Bot Hoje | Aproveite Negociação Automatizada 24/7",
        'pricing:supercharge_text': 'Turbine suas negociações com DipSway AI',

        'performance:title_site': 'Histórico de Desempenho e Backtests do DipSway',
        'performance:description_site': "Experimente backtests ao vivo com nosso Bot de Negociação de Criptomoedas com IA. Inicie seu bot hoje para um bot funcionando 24/7. Aproveite a conveniência e maximize seus lucros.",
        'performance:label': "Histórico de Desempenho e Backtests do DipSway",

        'connect_to_exchange': 'Conecte-se à sua exchange, ative o piloto automático e pronto.',
        'calculate_your_earnings': 'Calcule seus ganhos',

        'trading_optimization:title_site': "Otimização de Negociação com IA DipSway",
        'trading_optimization:description_site': "Experimente a Otimização de Negociação com IA com nosso Bot de Negociação de Criptomoedas com IA. Inicie seu bot hoje para um bot funcionando 24/7. Aproveite a conveniência e maximize seus lucros.",
        'trading_optimization:label': "Otimização de Negociação com IA",
        'trading_optimization:breakdown': 'Detalhamento: Negociação de Criptomoedas com IA do DipSway',

        'trading_optimization:desc': 'O poderoso bot de IA do DipSway se adapta dinamicamente às rápidas mudanças do mercado.',
        'trading_optimization:steps': 'Tudo isso é feito através de uma série de etapas:',

        'behind_scenes:behind_the_scenes': 'O que acontece nos bastidores?',

        'behind_scenes:indicators': 'Alguns indicadores mostram um potencial sinal de compra (verde), enquanto outros mostram um potencial sinal de venda (cinza).',
        'behind_scenes:neural_network': 'A Rede Neural do DipSway leva em conta todos esses indicadores e toma uma decisão baseada no quadro geral, produzindo um único sinal de compra ou venda.',

        'affiliate_program:title_site': 'Programa de afiliados de bot de criptomoedas',
        'affiliate_program:description_site': "Junte-se ao nosso Programa de Afiliados. Aproveite 30% de comissão recorrente em convites de amigos.",
        'affiliate_program:label': "Programa de Afiliados",
        'affiliate_program:desc': "Ganhe 30% de comissão em cada indicação, de forma recorrente",
        'affiliate_program:desc2': "Conecte-se à sua exchange, ative o piloto automático e pronto.",
        'affiliate_program:get_your_affiliate_link': 'Obtenha seu link de afiliado',

        'conference': 'Conferência',

        'about:title_site': "Sobre o DipSway",
        'about:our_journey': 'Nossa jornada',
        'about:our_journey_desc': 'Fornecer bots de negociação de criptomoedas com IA de alta qualidade e rentáveis para traders\n' +
            'de todos os níveis que estão fazendo crescer sua carteira de investimentos e melhorando seus\n' +
            'desempenhos diários.',
        'about:just_getting_started': 'E estamos apenas começando.',

        'about:description_site': "Experimente a Otimização de Negociação com IA com nosso Bot de Negociação de Criptomoedas com IA. Inicie seu bot hoje para um bot funcionando 24/7. Aproveite a conveniência e maximize seus lucros.",
        'about:label': 'Sobre',
        'about:behind_scenes': 'Nos bastidores',
        'founder': 'Fundador',

        'about:founder1_msg': 'DipSway nasceu de uma visão para revolucionar a negociação de criptomoedas através de IA de ponta. Como engenheiros de software e entusiastas de criptomoedas, vimos uma oportunidade de criar algo verdadeiramente inovador - um bot de negociação que pensa como um trader profissional, mas opera em velocidade sobre-humana.\nGraças à nossa forte expertise técnica, nosso avanço veio quando desenvolvemos uma IA que não apenas segue regras predefinidas, mas se adapta em tempo real à dinâmica do mercado.',
        'about:founder2_msg': 'Este não é seu bot de negociação médio; é um sistema sofisticado que analisa vastas quantidades de dados, reconhece padrões complexos e toma decisões em frações de segundo com notável precisão.\nO que distingue o DipSway é nossa abordagem única para o aprendizado de IA. É como ter uma equipe de traders especialistas trabalhando para você 24/7, mas mais rápido e mais eficiente.\nNossa missão é tornar a negociação avançada de criptomoedas acessível a todos, desde profissionais experientes até novatos curiosos.',

        'about:join_team': 'Junte-se à equipe DipSway',
        'about:join_team_desc': 'Estamos procurando por entusiastas de criptomoedas que são apaixonados por finanças,\n' +
            'negociação e tecnologia. Pronto para mudar a forma como o mundo investe em\n' +
            'criptomoedas?',

        'read_post': 'Ler post',

        'about:development_starts': "O desenvolvimento começa",
        'about:development_starts_desc': "Um dia, estávamos recebendo notificações do aplicativo móvel Coinbase sobre o preço recente do Bitcoin como 'Bitcoin subiu 5% hoje'. Pensamos que seria legal ter um bot que operasse ANTES de tais notificações. Procuramos na web e não conseguimos encontrar nada fácil de usar que rodasse na nuvem. Então decidimos construir nós mesmos.",

        'about:first_bot_version': "Primeira versão do bot",
        'about:first_bot_version_desc': "Primeira simulação de estratégia de Algo-Trading para Bitcoin e Ethereum, começamos a incluir mais ativos criptográficos e mais estratégias. Começamos a ver o potencial do nosso bot e decidimos torná-lo público.",

        'about:simple_strategy_not_enough': 'Uma "estratégia simples" não era suficiente',
        'about:simple_strategy_not_enough_desc': "Construímos e treinamos uma rede neural que aprenderia com o mercado e faria previsões. Os resultados mostraram uma precisão muito alta.",

        'about:live_bot_release': "Lançamento do bot ao vivo",
        'about:live_bot_release_desc': "Construímos e treinamos uma rede neural que aprenderia com o mercado e faria previsões. Os resultados mostraram uma precisão muito alta.",

        'about:our_transparency_value': "Nosso valor de transparência",
        'about:our_transparency_value_desc': "Backtests ao vivo e performances ao vivo foram tornados públicos. Queremos que a transparência seja um diferenciador chave entre nós e outras empresas que não mostram evidências de seus resultados.",

        'about:infrastructure_revolution': "Revolução da Infraestrutura",
        'about:infrastructure_revolution_desc': "2023 foi um ano bem-sucedido à medida que mais traders adotaram nossa plataforma. Aumentamos nosso engajamento com os traders, esforçando-nos para entender o que todos realmente precisavam em uma plataforma de bot. Dada essa base crescente de usuários, reconhecemos a importância de tornar o DipSway à prova de falhas no nível de engenharia. Antes de introduzir atualizações, recursos, ferramentas e bots empolgantes, priorizamos garantir que nossos sistemas estivessem rodando 24/7 sem problemas e que o desempenho e a qualidade estivessem no topo.",

        'about:new_exchanges': "Novas exchanges",
        'about:new_exchanges_desc': "Em fevereiro, expandimos significativamente nossa plataforma integrando grandes exchanges como Coinbase Advanced, BingX e Bitmart. Também fizemos parcerias com KuCoin e Bybit, tornando-nos um aplicativo oficial de terceiros em ambos. Essas adições e parcerias aumentaram enormemente as opções de negociação e acesso ao mercado de nossos usuários, validando a confiabilidade de nossa plataforma e marcando um marco importante em nosso crescimento.",

        'about:rotating_proxy': "Proxy Rotativo e novos recursos quentes",
        'about:rotating_proxy_desc': "Em uma atualização significativa de nossa infraestrutura, implementamos 20 IPs adicionais e expandimos nossas réplicas de banco de dados de 2 para 7, melhorando a capacidade de resposta dos bots a novos candles em todas as exchanges. Esta melhoria posiciona nossos bots de forma competitiva no mercado em termos de velocidade e confiabilidade. Também integramos ﻿Crypto(.)com, expandindo ainda mais nossas exchanges suportadas e opções de negociação para os usuários. Um novo recurso notável é o Leaderboard de Bots, que permite aos usuários verificar os desempenhos dos bots e copiar as configurações dos bots com melhor desempenho. Essas melhorias coletivamente fortalecem as capacidades de nossa plataforma, oferecendo aos usuários oportunidades de negociação mais robustas, eficientes e diversas.",

        'about:multiple_quote_currencies': "Múltiplas moedas de cotação",
        'about:multiple_quote_currencies_desc': "Introduzimos suporte para múltiplas moedas, permitindo que os bots negociem com vários pares de cotação além do USDT. Esta melhoria oferece aos usuários maior flexibilidade, acomodando diferentes estruturas de exchange e regulamentações geográficas. Ao permitir negociações em múltiplas moedas, capacitamos os usuários a otimizar suas estratégias de acordo com necessidades específicas e condições de mercado, melhorando sua experiência geral de negociação.",

        'about:new_admin_dashboard': "Novo painel de administração",
        'about:new_admin_dashboard_desc': "O lançamento de nossa nova plataforma marca um salto significativo na experiência do usuário e funcionalidade. Ele permite um desenvolvimento e entrega mais rápidos de novos recursos, nos dando maior controle sobre a resolução de problemas dos bots para garantir suporte de nível elite. Fortalecemos a comunicação entre a interface e o backend, garantindo precisão dos dados e funcionalidade confiável. Enquanto mantemos nosso compromisso com um design minimalista, limpo e fácil de navegar, também enriquecemos a plataforma com dados e análises abrangentes. Este equilíbrio permite aos usuários obter insights profundos sem comprometer a simplicidade, permitindo uma tomada de decisão mais informada em um ambiente amigável ao usuário.",

        'about:autopilot_tradingview': "Piloto Automático e Automação de Sinais TradingView",
        'about:autopilot_tradingview_desc': "O Bot Piloto Automático, nossa solução de negociação revolucionária impulsionada por IA. Esta melhoria de configuração zero para nossos bots de IA aproveita dados históricos, computação de rede neural e dados AO VIVO de todos os bots DipSway para otimizar continuamente as estratégias de negociação. Ele seleciona automaticamente os ativos com melhor desempenho e ajusta as configurações com base em parâmetros de mercado em tempo real, oferecendo aos usuários uma experiência de negociação hands-off e baseada em dados que se adapta instantaneamente às condições do mercado.",

        'about:ai_futures_bot': "Bot de Futuros com IA",
        'about:ai_futures_bot_desc': "Com a introdução do sistema interno de SL & TP (operações de trailing estarão disponíveis em breve), todos os usuários agora têm 100% de taxa de sucesso em suas configurações personalizadas para seu bot. O sistema interno nos permitiu lançar a primeira geração de um autêntico bot de Futuros com IA.",

        'about:new_features': "Novos recursos",
        'about:new_features_desc': "Estamos trabalhando em um novo recurso que permitirá aos usuários criar suas próprias estratégias e compartilhá-las com a comunidade.",

        'proven_to_perform': 'Comprovado para performar',

        'see_how_dipsway_averages': 'Veja como os usuários do DipSway estão obtendo média usando o poder dos bots DipSway.',
        'last': 'Últimos',
        'months_compound_roi': 'Meses de ROI Composto',
        'total_return_based_on_last': 'Retorno total baseado nos últimos',
        'show_as_multiplier_values': 'Mostrar como valores multiplicadores',
        'show_as_percentage_values': 'Mostrar como valores percentuais',
        'join': 'Junte-se',
        'stars': 'Estrelas',

        'faq': 'Perguntas Frequentes',
        'faq_desc': 'Encontre suas respostas aqui. Se você tiver uma pergunta que não está coberta nas FAQ,\n' +
            'não hesite em entrar em contato conosco.',
        'faq_sub_desc': 'Não encontrou a resposta que estava procurando?',
        'contact_support': 'Contatar suporte',

        'view_supported_platform': 'Ver plataformas suportadas',

        'simulate_based_on_past_performances': 'Simular com base em desempenhos passados',
        'calculate_your_earnings_desc': 'Análise completa de rentabilidade baseada nos dados históricos do DipSway, considerando fatores como taxas de exchange, desempenho das moedas e simulando como os bots DipSway negociariam com o tamanho do seu capital.',

        'go_to_tweet': 'Ir para o tweet',
        'view_on': 'Ver em',

        'security': 'Segurança',
        'safe_and_secure': 'Seguro e protegido',
        'safe_and_secure_desc': 'Seus fundos permanecem em sua exchange, e pedimos apenas\n' +
            'permissões mínimas para executar negociações para você.',

        'follow_dipsway_development': 'Siga o desenvolvimento do DipSway e conheça a comunidade',
        'join_us_on_telegram': 'Junte-se a nós no Telegram',
        'here_when_you_need_help': 'Aqui quando você precisa de ajuda',
        'dipsway_customer_care': 'Atendimento ao Cliente DipSway',

        'for_us_top_10_percent': 'Para nós, os 10% superiores são o padrão para os usuários do DipSway. Você é livre para\n' +
            'experimentar com diferentes configurações, mas quando precisar de ajuda\n' +
            'para otimizar, estamos aqui.',

        'join_the_telegram_community': 'Junte-se à comunidade do Telegram',

        'made': 'fez',
        'profit_on': 'de lucro em',
        'a_single_trade': 'uma única negociação',

        'dont_go_back_to_manual_trading': 'Não volte para a negociação manual,',

        'get_in_before_the_pump': 'entre antes da alta',
        'get_out_before_the_drop': 'saia antes da queda',

        'on_autopilot': 'Em piloto automático',

        'start_your_dipsway_bot': 'Inicie seu bot DipSway em 4 cliques',

        'safe_for_you': 'Seguro para você, por design',
        'safe_for_you_desc': 'Com o DipSway, seus fundos estão seguros em sua exchange de criptomoedas, e pedimos que você\n' +
            'confie em nós apenas o necessário para que os bots funcionem.',

        'ip_whitelisting': 'Lista branca de IP',
        'ip_whitelisting_desc': 'Apenas endereços IP controlados pelo DipSway podem operar em sua conta, mesmo se sua chave API for roubada, atores mal-intencionados não podem executar negociações.',

        'restricted_access': 'Acesso restrito',
        'restricted_access_desc': 'DipSway solicita apenas as permissões mais simples para monitorar e executar\n' +
            'negociações em seu nome.',

        'bright_green_performances': 'Desempenhos verde brilhante',
        'on_capital': 'Sobre o capital',
        'win_rate': 'taxa de vitória',
        'average_trade': 'negociação média',

        'see_dipsway_live_backtests': 'Veja os backtests ao vivo e desempenho do DipSway',
        'find_and_download': 'Encontre e baixe dados de desempenho histórico e backtests ao vivo.',

        'available_at_no_extra_cost': 'Disponível sem custo adicional',

        'full_autopilot_mode': 'Modo piloto automático completo',
        'full_autopilot_mode_desc': 'Com o Piloto Automático DipSway, não apenas suas negociações são automatizadas, mas\n' +
            'o DipSway também escolhe a melhor criptomoeda para negociar naquele momento\n' +
            'com base nas condições do mercado.',

        'calculate_your_earnings_with_autopilot': 'Calcule seus ganhos com o Piloto Automático',
        'high_performance': 'Alto desempenho',
        'live_backtests_historical_performance': 'Backtests ao vivo, desempenho histórico.',
        'backtests_performance': 'Backtests e desempenho',
        'extendable_via_tradingview': 'Extensível via TradingView',
        'use_power_tradingview': 'Use o poder do TradingView para configurar ainda mais o DipSway.',
        'coming_soon': 'Em breve...',
        '3_green_trades_in_30_days': '3 negociações verdes em 30 dias ou garantia de devolução do dinheiro',
        'need_help_choosing': 'Precisa de ajuda para escolher?',
        'use_dipsway_earnings_calculator': 'Use a calculadora de ganhos DipSway, saiba quanto você pode ganhar e obtenha uma recomendação de plano.',
        'launch_earnings_calculator': 'Iniciar calculadora de ganhos',
        'popular': 'Popular',
        'recommended_for_you': 'Recomendado para você',
        'no_deposit_required': 'Sem depósito necessário',
        'top_10_of_users': "10% superiores dos usuários",
        'all_users': "Todos os usuários",
        'downtrend_page:desc1': 'Operação: Saída de posição de emergência. Tendência de baixa identificada.',
        'downtrend_page:sell_order_fulfilled': 'Ordem de venda para 12 BTC cumprida.',
        'performance_history': 'Histórico de desempenho',
        'trained_to_recognize_opportunities': 'Treinado para reconhecer oportunidades',
        'live_backtests_performances': 'Desempenhos de backtests ao vivo',
        'see_how_our_strategies_performed': 'Veja como nossas estratégias se saíram no passado com backtests ao vivo de alta qualidade.',
        'backtests_from': 'Backtests dos',
        'past_6_months': 'últimos 6 meses',
        'collapse': 'Recolher',
        'expand': 'Expandir',
        'view_report': 'Ver relatório',
        'knowledge:title1': 'Dados Históricos de Alta Qualidade',
        'knowledge:description1': 'Garantimos que os candles ao vivo usados sejam da mais alta qualidade.',
        'knowledge:title2': 'Taxas e Atrasos incluídos',
        'knowledge:description2': 'Taxas de maker e taker de 0,1% são aplicadas a cada negociação. Slippage de 0,1% e um atraso de poucos minutos são aplicados a cada negociação.',
        'knowledge:title3': 'Prevenção de Overfitting',
        'knowledge:description3': 'DipSway monitora passivamente contas com mau desempenho e recomenda ações.',
        'operation': 'Operação',
        'buy_the_dip': 'Comprar na baixa',
        'sell_the_high': 'Vender na alta',
        'bought': 'Comprado',
        'watching_market': 'observando o mercado',
        'sell_order_for': 'Ordem de venda para',
        'fulfilled': 'cumprida',
        'profit_made': 'Lucro obtido',
        'calculation_results': 'Resultados do cálculo',
        'calculating': 'Calculando',
        'total_expected_profit_at_the_end_of': 'Lucro total esperado ao final de',
        'period_based_off_of_historical_backtest_performances': 'período baseado em desempenhos históricos de backtests',
        'trades': 'negociações',
        'day': 'dia',
        'profit': 'lucro',
        'recommended_plan_for_you': 'Plano recomendado para você',
        'go_to': 'Ir para',
        'free_trial_available': 'Teste gratuito disponível',
        'runs_at': 'Roda a',
        'learn_how_we_calculate_your_earnings': 'Saiba como calculamos seus ganhos',
        'click_to_open_deepdive': 'Clique para abrir a análise aprofundada onde você pode escolher entre outras exchanges que suportamos.',
        'your_capital': 'Seu capital',
        'currency': 'Moeda',
        'enabled': 'Ativado',
        'set_to': 'definido para',
        'coin_count': 'contagem de moedas',
        'disabled': 'Desativado',
        'auto': 'Auto',
        'selected_coins': 'Moedas selecionadas',
        'loading_coins': 'Carregando moedas',
        'login': 'Entrar',

        'trading_with': 'Negociando com',
    },

    ru: {

        'all_payment_types': 'все виды платежей',
        'all_payment_types_description': 'Опыт беззаботных транзакций с Bitcoin, Ethereum и многими другими. Оплачивайте безопасно через надежный шлюз Cryptomus для мгновенного доступа к функциям DipSway.\n',
        'extra': 'Дополнительно',

        'no-token:footer.disclaimer': 'DipSway не продает, не предлагает, не облегчает и не предоставляет доступ к каким-либо DeFi-токенам или криптовалютам на любом блокчейне. Мы являемся исключительно поставщиком программного обеспечения.',

        "different_ways_to_swap": "Различные Способы Обмена",

        'coin_swap_description': 'Оптимизируйте ваши сделки по обмену {crypto} на {quote} с помощью нашего поисковика путей обмена монет. Мгновенно сравнивайте несколько маршрутов конвертации {crypto}/{quote}, визуализируйте сложные обмены и обеспечивайте лучшие обменные курсы для ваших конвертаций {crypto} в {quote}. Идеально подходит как для начинающих, так и для опытных крипто-трейдеров, стремящихся максимизировать свои сделки по обмену {crypto} на {quote}.',

        "current_price_info": "Текущая цена {crypto} составляет {price}, что означает изменение на {change24h} за последние 24 часа и на {change7d} за последние 7 дней.",
        "circulating_supply_info": "При объеме циркулирующего предложения в 21 млн, текущая рыночная капитализация {crypto} составляет {marketCap}.",
        "trading_volume_info": "Объем торгов за 24 часа составляет {volume}.",
        "conversion_rate_calculation": "Курсы конвертации рассчитываются с использованием Индекса цен {crypto} в реальном времени вместе с другими индексами цен на цифровые активы.",
        "exchange_rate_update": "Обменный курс {crypto}/{quote} обновляется в реальном времени.",
        "follow_dipsway_info": "Следите за конвертером и калькулятором криптовалют DipSway для получения круглосуточных обновлений обменных курсов между криптовалютами и фиатными валютами, включая {crypto}, {currencies} и другие.",

        "description_calculator_pt_1": "Этот калькулятор предоставляет курс конвертации для",
        "description_calculator_pt_2": "в",
        "description_calculator_pt_3": "Курс конвертации основан на рыночном курсе за последние 24 часа.",
        "to": "в",
        "converter": "Конвертер",
        "crypto_prices_and_market_data": "Цены на криптовалюты и рыночные данные",
        "converter_site:desc": "Конвертируйте криптовалюты и фиатные валюты с помощью нашего простого в использовании инструмента конвертации. Получайте последние обменные курсы и рыночные данные для ваших сделок.",
        "callout_calculator": "Торговые боты для криптовалют на базе ИИ",
        "info_on_price": "Информация о цене",
        "live_data": "Данные в реальном времени",

        "prices_of": "Цены",
        "on_different_exchanges": "на разных криптовалютных биржах",
        "converted_to": "Конвертировано в",
        "on_different_amounts": "для разных сумм",
        "up_of": "Рост на",
        "dropped_of": "Падение на",
        "respect_yesterday": "по отношению к вчерашнему дню",
        "close_price": "Цена закрытия",
        "price_variation": "Изменение цены",
        "vwap": "Средняя цена",
        "volume_last_24h": "Объем за 24ч",
        "exchange": "Биржа",
        "price": "Цена",
        "volume": "Объем",
        "quote_volume": "Объем котировки",
        "percentage": "Процент",
        "asset": "Актив",
        "today_at": "Сегодня в",
        "calculator_title": "Калькулятор из {crypto} в {quote}",
        "crypto_label": "Крипто",
        "currency_label": "Котировка",
        "enter_amount": "Введите сумму",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Торговать {crypto}",

        'search_for_crypto': 'Поиск криптовалюты',
        'crypto_prices_title': 'Поддерживаемые цены криптовалют Изменение за последние 24 часа',
        'see_current_crypto_market_trend': 'Посмотреть текущий тренд рынка криптовалют',

        'see_more': 'Показать больше',
        'dipsway': 'DipSway',
        'home_page': 'главная страница',
        'blogs.related_posts': 'Похожие посты',
        'blogs.automate_your_trading': 'Автоматизируйте свою торговлю с DipSway AI уже сегодня.',
        'blogs.automate_your_trading_description': 'DipSway использует комбинацию из 121+ технических индикаторов и 17+ детекторов паттернов, чтобы дать вам несправедливое преимущество.',
        'btn.start_7_day_free_trial': 'Начать 7-дневный бесплатный пробный период',
        'blogs.tags': 'Теги',
        'blogs.description_site': 'Крипто-уроки, выпуски функций, сравнения продуктов и многое другое! Найдите контент от команды DipSway здесь, в блоге DipSway.',
        'blogs.title_site': 'Блог DipSway',
        'blogs.callout': 'Представляем версию 4.13',
        'blogs.description_extended': 'Уроки, выпуски функций, сравнения продуктов и многое другое!\nНайдите контент от команды DipSway здесь, в блоге DipSway.',
        'blogs.go_to_article': 'Перейти к статье',
        'skip_to_content': 'Перейти к содержанию',
        'calculate_your_earnings_and_start_free_trial': 'Рассчитайте свой доход и начните 7-дневный бесплатный пробный период',

        'privacy_policy': 'Политика конфиденциальности',
        'terms_of_service': 'Условия использования',
        'refund_policy': 'Политика возврата',
        'affiliate_policy': 'Партнерская политика',
        'blog': 'Блог',
        'supported_exchanges': 'Поддерживаемые биржи',
        'pricing': 'Цены',
        'resources': 'Ресурсы',
        'company': 'Компания',
        'support': 'Поддержка',
        'affiliate_program': 'Партнерская программа',
        'about_us': 'О нас',
        'customer_success_agent': 'Агент по работе с клиентами',
        'dipsway_ai_optimization': 'Оптимизация DipSway AI',
        'live_back_tests': 'Живые бэктесты',
        'live_back_tests_performances': 'Живые бэктесты и производительность DipSway',
        'historical_performance_data': 'Исторические данные о производительности',
        'dipsway_earning_calculator': 'Калькулятор заработка DipSway',
        'performance': 'Производительность',
        'common:footer.disclaimer': 'DipSway предоставляет только программное обеспечение. Любые ссылки на услуги торговли, обмена, перевода или кошелька и т.д. являются ссылками на услуги, предоставляемые сторонними поставщиками услуг.',

        'index:hero.title': 'DipSway - Крипто-боты с искусственным интеллектом без настройки и полным автопилотом',
        'index:hero.description': 'Испытайте беспроблемную торговлю криптовалютой с нашими ИИ-ботами. Запустите своего Crypto Trading AI бота для 100% автоматизированных сделок, доступных 24/7 на простом интерфейсе.',

        'header:product': 'Продукт',
        'header:product.feature.header': 'Сколько может заработать ваш капитал, используя DipSway?',
        'header:product.feature.description': 'Рассчитайте свой заработок на основе истории бэктестов DipSway.',
        'header:product.feature.cta': 'Рассчитать ваш заработок',

        'header:product.links.spot_ai_trading_bot': 'Спот AI Trading Bot',
        'header:learn': 'Обучение',
        'header:learn.links.ai_trading_optimization': 'ИИ + Крипто-трейдинг',

        'welcome:headline': 'Крипто-боты с искусственным интеллектом без настройки и полным автопилотом',
        'welcome:subheadline': 'Подключитесь к своей бирже, включите автопилот, и вы готовы.',
        'start_7_days_no_credit_card': 'Начните 7-дневный бесплатный пробный период - Депозит не требуется.',


        '404:title_site': '404 - DipSway | Крипто-бот с искусственным интеллектом',
        '404:description_site': 'Найдите свой путь обратно к крипто-трейдингу с нашим ИИ-ботом. Запустите своего Crypto Trading AI бота для 100% автоматизированных сделок, доступных 24/7 на простом интерфейсе.',
        '404:page_not_found': 'Страница не найдена',
        '404:page_not_found_message': 'Похоже, вы нашли... сломанную ссылку! Пожалуйста, сообщите поддержке DipSway, \nкакая страница привела вас сюда!',
        '404:go_home': 'Вернуться на главную',
        '404:open_dashboard': 'Открыть панель управления',


        'affiliate_program_policy:title_site': "Политика партнерской программы DipSway",
        'affiliate_program_policy:description_site': "Политика партнерской программы DipSway",


        'crypto_bot_calculator:title_site': "Калькулятор заработка крипто-торгового бота",
        'crypto_bot_calculator:description_site': "Полный анализ прибыльности на основе исторических данных DipSway, учитывающий такие факторы, как комиссии бирж, показатели монет и симулирующий, как боты DipSway будут торговать с вашим размером капитала.",
        'crypto_bot_calculator:label': 'Калькулятор заработка крипто-торгового бота',
        'crypto_bot_calculator:simulate_based_on': 'Симуляция на основе прошлой производительности',
        'crypto_bot_calculator:calculate_your_earnings': 'Рассчитать ваш заработок',
        'crypto_bot_calculator:description': 'Полный анализ прибыльности на основе исторических данных DipSway, учитывающий такие факторы, как комиссии бирж, показатели монет и симулирующий, как боты DipSway будут торговать с вашим размером капитала.',


        'featured_on': 'Упоминается в',
        '4_clicks_to_start': '4 клика до старта',
        'no_knowledge_required': 'Нулевая настройка, нулевые знания требуются',
        'split_content_description': 'DipSway действительно не требует настройки. подключите свою биржу → включите своего бота → \n' +
            '      и следите за его производительностью. Лучшие конфигурации \n' +
            '      применяются по умолчанию.',
        'learn_about_dipsway_ai': 'Узнать о DipSway AI',

        'trained_for_down_trends': 'Обучен распознавать нисходящие тренды',
        'be_safe_on_down_trends': 'Будьте в безопасности даже при нисходящих трендах',
        'autopilot_content_headline_1': 'Вход перед ростом, выход перед падением,',
        'autopilot_content_headline_2': 'на автопилоте',
        'autopilot_description': 'DipSway анализирует рынки и совершает сделки, пока вы заняты своими \n' +
            '      повседневными делами, и держит вас в курсе с обновлениями статуса.',
        'downtrends_description': 'ИИ DipSway обучен идентифицировать нисходящие тренды, и прежде чем ситуация выйдет из-под контроля, \n' +
            '      он выведет вас',


        'see_how_dipsway_works': 'Посмотрите, как работает DipSway',

        'free_trial_action:label': 'Готов, когда вы готовы.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Безопасность',
        'safety_description': 'Сохраняйте свои средства в безопасности на вашей бирже, ваша безопасность - наш приоритет.',

        'ai_trading_optimization_description': 'Наша патентованная модель ИИ, обученная распознавать возможности.',

        '3months': '3 месяца',
        'total_profits_within_label': 'Общая прибыль по всем развернутым ботам DipSway за последние',

        'dipsway_uses_combination_of': 'DipSway использует комбинацию из',
        'and': 'и',
        'technical_indicators': 'технических индикаторов',
        'pattern_detectors': 'детекторов паттернов',
        'detect_next_move': 'для определения следующего движения рынка.',

        'are_you_influencer': 'Вы инфлюенсер? Станьте нашим партнером',
        'influencer_description': 'Инфлюенсеры, которые работают с нами, получают настроенную целевую страницу для онбординга, \n' +
            '      адаптированную под интересы их аудитории.',

        'get_in_touch': 'Связаться',

        'spot_ai_bot:title_site': 'Спот AI Крипто-торговый бот | 100% автоматизированные сделки',
        'spot_ai_bot:description_site': 'Включите своего Крипто-торгового бота и наслаждайтесь 100% автоматизированными сделками. Запустите своего бота сегодня для работы 24/7. Наслаждайтесь удобством и максимизируйте свою прибыль.',

        'spot_ai_bot:24_7_automated_trading': '24/7 автоматизированная торговля, практически мгновенное размещение сделок',
        'spot_ai_bot:algo_crypto_by_ml': 'Алгоритмическая крипто-торговля, управляемая машинным обучением',

        'spot_ai_bot:desc': 'Лучший торговый бот в индустрии, использующий ИИ для анализа рынка и \n' +
            'принятия торговых решений со скоростью машины.',

        'million': 'миллион',

        'avg_monthly_volume_moved_by_bots': 'Средний месячный объем, перемещаемый ботами DipSway.',
        'dipsway_has_more_than_350_users': 'У DipSway более 350 пользователей по всему миру.',
        'dipsway_supports_315_coins': 'DipSway поддерживает торговлю более чем 315+ валютами.',

        'ai_powered': "На основе ИИ",
        'trading_strategies_powered_by_ai': "Торговые стратегии работают на основе ИИ, используя комбинацию технического анализа, фундаментального анализа.",

        'geolocation_advantage': "Преимущество геолокации",
        'geolocation_advantage_desc': "Серверы, на которых размещен бот, находятся в том же географическом местоположении, что и биржа, чтобы обеспечить минимально возможную задержку (в среднем ~932μs задержки).",

        'high_frequency_trading': 'Высокочастотная торговля',
        'high_frequency_trading_desc': 'Благодаря мультитенантной архитектуре, торговые стратегии выполняются с гранулярностью свечей в 1 секунду.',

        'advanced_risk_management': 'Продвинутое управление рисками',
        'advanced_risk_management_desc': 'Бот способен управлять инвестициями в нескольких валютах и автоматически хеджировать риск портфеля.',

        'optimization_overfitting_avoidance': 'Оптимизация и избегание переобучения',
        'optimization_overfitting_avoidance_desc': 'Бот способен оптимизировать параметры торговых стратегий и избегать переобучения, используя ИИ-генетический алгоритм и пользовательский анализ Walk-Forward.',

        'backtesting': 'Бэктестинг',
        'backtesting_desc': 'Каждые 24 часа мы проводим бэктесты всех торговых стратегий и пересчитываем веса, используемые ИИ, используя подход "Онлайн-обучения".',
        'how_do_dipsways_strategies_work': "Как работают стратегии DipSway?",
        'no_one_wants_to_invest_in_black_box': "Никто не хочет инвестировать в черный ящик. Вот почему мы создали простой способ следить за кулисами ИИ-бота DipSway - DipSway объединяет 112+ индикаторов и стратегий, чтобы дать вам преимущество в быстро меняющемся мире криптовалют.",
        'learn_more_about_dipsway_ai': 'Узнать больше о DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Автопилот покупает, остро как нож',

        'autopilot_buys_sharp_as_knife_desc': 'Большинство крипто-рынков двигаются в сторону, и большие колебания цен не длятся долго. Благодаря процессу принятия решений на основе машинного обучения, ИИ-бот построен для выполнения острых ордеров на покупку, которые будут постоянно поддерживать вашу прибыльность на боковых рынках и использовать преимущества, когда происходят большие падения.',
        'other_strategies_dont_work': 'Другие стратегии не работают, потому что они статичны и не могут развиваться вместе с изменениями рынка.',

        'autopilot_sells_and_youre_in_profit': 'Автопилот продает, и вы в прибыли.',

        'dont_let_downturns_catch_you_off_guard': 'Не позволяйте спадам застать вас врасплох.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ индикаторов и все метрики, которые вам нужны, на одной платформе.',

        'your_bot_runs_in_cloud': 'Ваш бот работает в облаке, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Наш бот - это облачный торговый бот, который работает 24/7, поэтому вам не нужно беспокоиться о том, что он уйдет в офлайн! Он всегда подключен к рынку, анализируя лучшее время для покупки и продажи.\n',

        'unfair_advantage': 'чтобы дать вам несправедливое преимущество.',
        'and_many_more': 'И многое другое...',

        'pricing:title_site': 'Цены DipSway',
        'pricing:description_site': "Доступный Crypto Trading AI Bot | Запустите своего бота сегодня | Наслаждайтесь 24/7 автоматизированной торговлей",
        'pricing:supercharge_text': 'Усильте свою торговлю с DipSway AI',

        'performance:title_site': 'История производительности DipSway и бэктесты',
        'performance:description_site': "Испытайте живые бэктесты с нашим Crypto Trading AI Bot. Запустите своего бота сегодня для работы 24/7. Наслаждайтесь удобством и максимизируйте свою прибыль.",
        'performance:label': "История производительности DipSway и бэктесты",

        'connect_to_exchange': 'Подключитесь к своей бирже, включите автопилот, и вы готовы.',
        'calculate_your_earnings': 'Рассчитайте ваш заработок',

        'trading_optimization:title_site': "Оптимизация торговли DipSway AI",
        'trading_optimization:description_site': "Испытайте оптимизацию торговли с ИИ с нашим Crypto Trading AI Bot. Запустите своего бота сегодня для работы 24/7. Наслаждайтесь удобством и максимизируйте свою прибыль.",
        'trading_optimization:label': "Оптимизация торговли с ИИ",
        'trading_optimization:breakdown': 'Разбор: Крипто-трейдинг с ИИ DipSway',

        'trading_optimization:desc': 'Мощный ИИ-бот DipSway динамически адаптируется к быстрым изменениям рынка.',
        'trading_optimization:steps': 'Все это делается через серию шагов:',

        'behind_scenes:behind_the_scenes': 'Что происходит за кулисами?',

        'behind_scenes:indicators': 'Некоторые индикаторы показывают потенциальный сигнал на покупку (зеленый), в то время как другие показывают потенциальный сигнал на продажу (серый).',
        'behind_scenes:neural_network': 'Нейронная сеть DipSway учитывает все эти индикаторы и принимает решение на основе общей картины, создавая единый сигнал на покупку или продажу.',

        'affiliate_program:title_site': 'Партнерская программа крипто-бота',
        'affiliate_program:description_site': "Присоединяйтесь к нашей партнерской программе. Получайте 30% повторяющейся комиссии за приглашения друзей.",
        'affiliate_program:label': "Партнерская программа",
        'affiliate_program:desc': "Зарабатывайте 30% комиссии с каждого реферала, повторяющейся",
        'affiliate_program:desc2': "Подключитесь к своей бирже, включите автопилот, и вы готовы.",
        'affiliate_program:get_your_affiliate_link': 'Получите вашу партнерскую ссылку',

        'conference': 'Конференция',

        'about:title_site': "О DipSway",
        'about:our_journey': 'Наш путь',
        'about:our_journey_desc': 'Предоставить высококачественные, прибыльные AI крипто-торговые боты для трейдеров \n' +
            'любого уровня, увеличивающие их инвестиционный портфель и улучшающие их ежедневные \n' +
            'показатели.',
        'about:just_getting_started': 'И мы только начинаем.',

        'about:description_site': "Испытайте оптимизацию торговли с ИИ с нашим Crypto Trading AI Bot. Запустите своего бота сегодня для работы 24/7. Наслаждайтесь удобством и максимизируйте свою прибыль.",
        'about:label': 'О нас',
        'about:behind_scenes': 'За кулисами',
        'founder': 'Основатель',

        'about:founder1_msg': 'DipSway родился из видения революционизировать крипто-трейдинг с помощью передового ИИ. Как программисты и энтузиасты криптовалют, мы увидели возможность создать что-то действительно инновационное - торгового бота, который думает как профессиональный трейдер, но работает со сверхчеловеческой скоростью.\nБлагодаря нашему сильному техническому опыту, наш прорыв произошел, когда мы разработали ИИ, который не просто следует заранее установленным правилам, а адаптируется в реальном времени к динамике рынка.',
        'about:founder2_msg': 'Это не ваш обычный торговый бот; это сложная система, которая анализирует огромные объемы данных, распознает сложные паттерны и принимает мгновенные решения с удивительной точностью.\nОтличительной чертой DipSway является наш уникальный подход к обучению ИИ. Это как иметь команду экспертов-трейдеров, работающих на вас 24/7, но быстрее и эффективнее.\nНаша миссия - сделать продвинутый крипто-трейдинг доступным для всех, от опытных профессионалов до любопытных новичков.',

        'about:join_team': 'Присоединяйтесь к команде DipSway',
        'about:join_team_desc': 'Мы ищем энтузиастов криптовалют, которые увлечены финансами, \n' +
            'трейдингом и технологиями. Готовы изменить способ инвестирования мира в \n' +
            'криптовалюты?',

        'read_post': 'Читать пост',

        'about:development_starts': "Начало разработки",
        'about:development_starts_desc': "Однажды мы получали уведомления из мобильного приложения Coinbase о недавней цене Биткоина, например, 'Биткоин вырос на 5% сегодня'. Мы подумали, что было бы круто иметь бота, который работал бы ДО таких уведомлений. Мы искали в интернете и не смогли найти ничего простого в использовании, что работало бы в облаке. Поэтому мы решили создать его сами.",

        'about:first_bot_version': "Первая версия бота",
        'about:first_bot_version_desc': "Первая симуляция стратегии алгоритмической торговли для Биткоина и Эфириума, мы начали включать больше криптоактивов и больше стратегий. Мы начали видеть потенциал нашего бота и решили сделать его публичным.",

        'about:simple_strategy_not_enough': '"Простой стратегии" было недостаточно',
        'about:simple_strategy_not_enough_desc': "Мы создали и обучили нейронную сеть, которая училась на рынке и делала прогнозы. Результаты показали очень высокую точность.",

        'about:live_bot_release': "Выпуск живого бота",
        'about:live_bot_release_desc': "Мы создали и обучили нейронную сеть, которая училась на рынке и делала прогнозы. Результаты показали очень высокую точность.",

        'about:our_transparency_value': "Наша ценность прозрачности",
        'about:our_transparency_value_desc': "Живые бэктесты и живая производительность были представлены публике. Мы хотим, чтобы прозрачность была ключевым отличием между нами и другими компаниями, которые не показывают никаких доказательств своих результатов.",

        'about:infrastructure_revolution': "Революция инфраструктуры",
        'about:infrastructure_revolution_desc': "2023 год был успешным, поскольку все больше трейдеров приняли нашу платформу. Мы увеличили наше взаимодействие с трейдерами, стремясь понять, что действительно нужно каждому в платформе ботов. Учитывая эту растущую пользовательскую базу, мы признали важность сделать DipSway отказоустойчивым на инженерном уровне. Прежде чем вводить захватывающие новые обновления, функции, инструменты и ботов, мы сосредоточились на обеспечении бесперебойной работы наших систем 24/7 и на том, чтобы производительность и качество были на высшем уровне.",

        'about:new_exchanges': "Новые биржи",
        'about:new_exchanges_desc': "В феврале мы значительно расширили нашу платформу, интегрировав крупные биржи, такие как Coinbase Advanced, BingX и Bitmart. Мы также стали партнерами с KuCoin и Bybit, став официальным сторонним приложением на обеих. Эти дополнения и партнерства значительно увеличили торговые возможности наших пользователей и доступ к рынку, подтверждая надежность нашей платформы и отмечая важную веху в нашем росте.",

        'about:rotating_proxy': "Ротационный прокси и новые горячие функции",
        'about:rotating_proxy_desc': "В рамках значительного обновления нашей инфраструктуры мы внедрили 20 дополнительных IP-адресов и расширили наши реплики баз данных с 2 до 7, улучшив реакцию ботов на новые свечи на биржах. Это улучшение позиционирует наших ботов конкурентоспособно на рынке по скорости и надежности. Мы также интегрировали Crypto(.)com, дальше расширяя наши поддерживаемые биржи и торговые опции для пользователей. Заметной новой функцией является Доска лидеров ботов, которая позволяет пользователям проверять производительность ботов и копировать настройки лучших ботов. Эти улучшения в совокупности усиливают возможности нашей платформы, предлагая пользователям более надежные, эффективные и разнообразные торговые возможности.",

        'about:multiple_quote_currencies': "Несколько котировочных валют",
        'about:multiple_quote_currencies_desc': "Мы внедрили поддержку нескольких валют, позволяя ботам торговать с различными котировочными парами помимо USDT. Это улучшение предлагает пользователям большую гибкость, учитывая различные структуры бирж и географические регуляции. Позволяя торговать в нескольких валютах, мы дали пользователям возможность оптимизировать свои стратегии в соответствии с конкретными потребностями и рыночными условиями, улучшая их общий опыт торговли.",

        'about:new_admin_dashboard': "Новая панель администратора",

        'about:new_admin_dashboard_desc': "овательской среде.",

        'about:autopilot_tradingview': "Автопилот и автоматизация сигналов TradingView",
        'about:autopilot_tradingview_desc': "Бот Автопилот, наше революционное торговое решение на основе ИИ. Это улучшение наших ИИ-ботов без необходимости настройки использует исторические данные, вычисления нейронной сети и ЖИВЫЕ данные от всех ботов DipSway для непрерывной оптимизации торговых стратегий. Он автоматически выбирает наиболее эффективные активы и корректирует конфигурации на основе параметров рынка в режиме реального времени, предлагая пользователям автономный, основанный на данных торговый опыт, который мгновенно адаптируется к рыночным условиям.",

        'about:ai_futures_bot': "ИИ-бот для фьючерсов",
        'about:ai_futures_bot_desc': "С введением собственной системы SL & TP (Трейлинг-операции будут доступны в ближайшее время), все пользователи теперь имеют 100% успешность в своих пользовательских настройках для своего бота. Собственная система позволила нам выпустить первое поколение подлинного ИИ-бота для фьючерсов.",

        'about:new_features': "Новые функции",
        'about:new_features_desc': "Мы работаем над новой функцией, которая позволит пользователям создавать свои собственные стратегии и делиться ими с сообществом. Мы также работаем над новой функцией, которая позволит пользователям создавать свои собственные стратегии и делиться ими с сообществом.",

        'proven_to_perform': 'Доказанная эффективность',

        'see_how_dipsway_averages': 'Посмотрите, как пользователи DipSway в среднем используют мощь ботов DipSway.',
        'last': 'Последние',
        'months_compound_roi': 'Месяцев Сложный ROI',
        'total_return_based_on_last': 'Общая доходность на основе последних',
        'show_as_multiplier_values': 'Показать как множественные значения',
        'show_as_percentage_values': 'Показать как процентные значения',
        'join': 'Присоединиться',
        'stars': 'Звезды',

        'faq': 'Часто задаваемые вопросы',
        'faq_desc': 'Найдите свои ответы здесь. Если у вас есть вопрос, не охваченный FAQ, \n' +
            'пожалуйста, не стесняйтесь обращаться к нам.',
        'faq_sub_desc': 'Не нашли ответ, который искали?',
        'contact_support': 'Связаться с поддержкой',

        'view_supported_platform': 'Просмотреть поддерживаемые платформы',

        'simulate_based_on_past_performances': 'Симуляция на основе прошлой производительности',
        'calculate_your_earnings_desc': 'Полный анализ прибыльности на основе исторических данных DipSway, учитывающий такие факторы, как комиссии бирж, показатели монет и симулирующий, как боты DipSway будут торговать с вашим размером капитала.',

        'go_to_tweet': 'Перейти к твиту',
        'view_on': 'Посмотреть на',

        'security': 'Безопасность',
        'safe_and_secure': 'Безопасно и надежно',
        'safe_and_secure_desc': 'Ваши средства остаются на вашей бирже, и мы запрашиваем только минимальные \n' +
            'разрешения для выполнения сделок за вас.',

        'follow_dipsway_development': 'Следите за развитием DipSway и познакомьтесь с сообществом',
        'join_us_on_telegram': 'Присоединяйтесь к нам в Telegram',
        'here_when_you_need_help': 'Здесь, когда вам нужна помощь',
        'dipsway_customer_care': 'Служба поддержки клиентов DipSway',

        'for_us_top_10_percent': 'Для нас топ 10% - это стандарт для пользователей DipSway. Вы свободны \n' +
            'экспериментировать с различными конфигурациями, но когда вам нужна помощь \n' +
            'в оптимизации, мы здесь.',

        'join_the_telegram_community': 'Присоединяйтесь к сообществу в Telegram',

        'made': 'сделал',
        'profit_on': 'прибыль на',
        'a_single_trade': 'одной сделке',

        'dont_go_back_to_manual_trading': 'Не возвращайтесь к ручной торговле,',

        'get_in_before_the_pump': 'войдите до роста',
        'get_out_before_the_drop': 'выйдите до падения',

        'on_autopilot': 'На автопилоте',

        'start_your_dipsway_bot': 'Запустите своего бота DipSway за 4 клика',

        'safe_for_you': 'Безопасно для вас, по дизайну',
        'safe_for_you_desc': `С DipSway ваши средства в безопасности на вашей крипто-бирже, и мы просим вас 
доверять нам только в той мере, в какой это необходимо для работы ботов.`,

        'ip_whitelisting': 'Белый список IP',
        'ip_whitelisting_desc': 'Только IP-адреса, контролируемые DipSway, могут работать с вашим аккаунтом, даже если ваш API-ключ был украден, злоумышленники не смогут выполнять сделки.',

        'restricted_access': 'Ограниченный доступ',
        'restricted_access_desc': 'DipSway запрашивает только самые простые разрешения для мониторинга и выполнения \n' +
            'сделок от вашего имени.',

        'bright_green_performances': 'Яркая зеленая производительность',
        'on_capital': 'На капитал',
        'win_rate': 'процент выигрышей',
        'average_trade': 'средняя сделка',

        'see_dipsway_live_backtests': 'Посмотрите живые бэктесты и производительность DipSway',
        'find_and_download': 'Найдите и скачайте исторические данные о производительности и живые бэктесты.',

        'available_at_no_extra_cost': 'Доступно без дополнительных затрат',

        'full_autopilot_mode': 'Режим полного автопилота',
        'full_autopilot_mode_desc': 'С Автопилотом DipSway не только ваши сделки автоматизированы, но \n' +
            'DipSway также выбирает лучшую криптовалюту для торговли в данный момент \n' +
            'на основе рыночных условий.',

        'calculate_your_earnings_with_autopilot': 'Рассчитайте ваш заработок с Автопилотом',
        'high_performance': 'Высокая производительность',
        'live_backtests_historical_performance': 'Живые бэктесты, историческая производительность.',
        'backtests_performance': 'Бэктесты и производительность',
        'extendable_via_tradingview': 'Расширяемый через TradingView',
        'use_power_tradingview': 'Используйте мощь TradingView для дальнейшей настройки DipSway.',
        'coming_soon': 'Скоро...',
        '3_green_trades_in_30_days': '3 прибыльные сделки за 30 дней или возврат денег гарантирован',
        'need_help_choosing': 'Нужна помощь в выборе?',
        'use_dipsway_earnings_calculator': 'Используйте калькулятор заработка DipSway, узнайте, сколько вы можете заработать, и получите рекомендацию по плану.',
        'launch_earnings_calculator': 'Запустить калькулятор заработка',
        'popular': 'Популярный',
        'recommended_for_you': 'Рекомендовано для вас',
        'no_deposit_required': 'Депозит не требуется',
        'top_10_of_users': "Топ 10% пользователей",
        'all_users': "Все пользователи",
        'downtrend_page:desc1': 'Операция: Экстренный выход из позиции. Обнаружен нисходящий тренд.',
        'downtrend_page:sell_order_fulfilled': 'Ордер на продажу 12 BTC выполнен.',
        'performance_history': 'История производительности',
        'trained_to_recognize_opportunities': 'Обучен распознавать возможности',
        'live_backtests_performances': 'Производительность живых бэктестов',
        'see_how_our_strategies_performed': 'Посмотрите, как наши стратегии работали в прошлом с высококачественными живыми бэктестами.',
        'backtests_from': 'Бэктесты за',
        'past_6_months': 'последние 6 месяцев',
        'collapse': 'Свернуть',
        'expand': 'Развернуть',
        'view_report': 'Посмотреть отчет',
        'knowledge:title1': 'Высококачественные исторические данные',
        'knowledge:description1': 'Мы гарантируем, что используемые живые свечи имеют наивысшее качество.',
        'knowledge:title2': 'Включены комиссии и задержки',
        'knowledge:description2': 'К каждой сделке применяются комиссии мейкера и тейкера в размере 0,1%. К каждой сделке применяется проскальзывание 0,1% и задержка в несколько минут.',
        'knowledge:title3': 'Избегание переобучения',
        'knowledge:description3': 'DipSway пассивно отслеживает аккаунты с плохой производительностью и рекомендует действия.',
        'operation': 'Операция',
        'buy_the_dip': 'Купить на падении',
        'sell_the_high': 'Продать на высоком',
        'bought': 'Куплено',
        'watching_market': 'наблюдение за рынком',
        'sell_order_for': 'Ордер на продажу для',
        'fulfilled': 'выполнен',
        'profit_made': 'Полученная прибыль',
        'calculation_results': 'Результаты расчета',
        'calculating': 'Расчет',
        'total_expected_profit_at_the_end_of': 'Общая ожидаемая прибыль в конце',
        'period_based_off_of_historical_backtest_performances': 'периода на основе исторических показателей бэктестов',
        'trades': 'сделки',
        'day': 'день',
        'profit': 'прибыль',
        'recommended_plan_for_you': 'Рекомендуемый план для вас',
        'go_to': 'Перейти к',
        'free_trial_available': 'Доступен бесплатный пробный период',
        'runs_at': 'Работает при',
        'learn_how_we_calculate_your_earnings': 'Узнайте, как мы рассчитываем ваш заработок',
        'click_to_open_deepdive': 'Нажмите, чтобы открыть глубокий анализ, где вы можете выбрать из других поддерживаемых нами бирж.',
        'your_capital': 'Ваш капитал',
        'currency': 'Валюта',
        'enabled': 'Включено',
        'set_to': 'установлено на',
        'coin_count': 'количество монет',
        'disabled': 'Отключено',
        'auto': 'Авто',
        'selected_coins': 'Выбранные монеты',
        'loading_coins': 'Загрузка монет',

        'trading_with': 'Торговля с',

        'login': 'Войти',
    },

    it: {


        'all_payment_types': 'tutti i tipi di pagamento',
        'all_payment_types_description': 'Prova transazioni senza problemi con Bitcoin, Ethereum e altro. Paga in modo sicuro attraverso il gateway Cryptomus affidabile per un accesso istantaneo alle funzionalità di DipSway.\n',
        'extra': 'Extra',

        'no-token:footer.disclaimer': 'DipSway non vende, offre, facilita o fornisce accesso a token DeFi o criptovalute su alcuna blockchain. Siamo esclusivamente un fornitore di software.',

        "different_ways_to_swap": "Diversi Modi di Scambiare",

        'coin_swap_description': 'Ottimizza i tuoi scambi da {crypto} a {quote} con il nostro Coin Swap Paths finder. Confronta istantaneamente molteplici percorsi di conversione {crypto}/{quote}, visualizza scambi complessi e assicurati i migliori tassi di cambio per le tue conversioni da {crypto} a {quote}. Perfetto sia per trader di criptovalute principianti che esperti che cercano di massimizzare i loro scambi da {crypto} a {quote}.',

        "current_price_info": "Il prezzo attuale di {crypto} è {price}, che rappresenta una variazione del {change24h} nelle ultime 24 ore e del {change7d} negli ultimi 7 giorni.",
        "circulating_supply_info": "Con una fornitura circolante di 21M, la capitalizzazione di mercato in tempo reale di {crypto} è {marketCap}.",
        "trading_volume_info": "Il volume di scambi nelle ultime 24 ore è {volume}.",
        "conversion_rate_calculation": "I tassi di conversione sono calcolati utilizzando l'Indice dei prezzi in tempo reale di {crypto} insieme ad altri indici dei prezzi degli asset digitali.",
        "exchange_rate_update": "Il tasso di cambio {crypto}/{quote} viene aggiornato in tempo reale.",
        "follow_dipsway_info": "Segui il convertitore e il calcolatore di criptovalute di DipSway per aggiornamenti in tempo reale 24/7 dei tassi di cambio tra criptovalute e valute fiat, inclusi {crypto}, {currencies} e altro.",

        "description_calculator_pt_1": "Questo calcolatore fornisce un tasso di conversione per",
        "description_calculator_pt_2": "a",
        "description_calculator_pt_3": "Il tasso di conversione è basato sul tasso di mercato delle ultime 24 ore.",
        "to": "a",
        "converter": "Convertitore",
        "crypto_prices_and_market_data": "Prezzi delle criptovalute e dati di mercato",
        "converter_site:desc": "Converti tra criptovalute e valute fiat con il nostro strumento di conversione facile da usare. Ottieni i tassi di cambio più recenti e i dati di mercato per i tuoi scambi.",
        "callout_calculator": "Bot di trading di criptovalute basati su IA",
        "info_on_price": "Informazioni sul prezzo",
        "live_data": "Dati in tempo reale",

        "prices_of": "Prezzi di",
        "on_different_exchanges": "su diversi exchange di criptovalute",
        "converted_to": "Convertito in",
        "on_different_amounts": "su diversi importi",
        "up_of": "Aumento di",
        "dropped_of": "Calo di",
        "respect_yesterday": "rispetto a ieri",
        "close_price": "Prezzo di chiusura",
        "price_variation": "Variazione di prezzo",
        "vwap": "Prezzo medio",
        "volume_last_24h": "Volume 24h",
        "exchange": "Exchange",
        "price": "Prezzo",
        "volume": "Volume",
        "quote_volume": "Volume quotato",
        "percentage": "Percentuale",
        "asset": "Asset",
        "today_at": "Oggi alle",
        "calculator_title": "Calcolatore da {crypto} a {quote}",
        "crypto_label": "Cripto",
        "currency_label": "Quotazione",
        "enter_amount": "Inserisci l'importo",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Fai trading su {crypto}",

        'search_for_crypto': 'Cerca cripto',
        'crypto_prices_title': 'Prezzi Delle Criptovalute Cambio Delle Ultime 24 ore',
        'see_current_crypto_market_trend': 'Vedi la tendenza attuale del mercato delle criptovalute',

        'see_more': 'Vedi di più',
        "dipsway": "DipSway",
        "home_page": "pagina iniziale",
        "blogs.related_posts": "Post Correlati",
        "blogs.automate_your_trading": "Automatizza il tuo trading con DipSway AI oggi.",
        "blogs.automate_your_trading_description": "DipSway utilizza una combinazione di oltre 121 indicatori tecnici e 17 rilevatori di pattern per darti un vantaggio ingiusto.",
        "btn.start_7_day_free_trial": "Inizia la prova gratuita di 7 giorni",
        "blogs.tags": "Tag",
        "blogs.description_site": "Tutorial di cripto, rilasci di funzionalità, confronti di prodotti e altro! Trova contenuti dal team DipSway qui sul blog DipSway.",
        "blogs.title_site": "Blog DipSway",
        "blogs.callout": "Introduzione della versione 4.13",
        "blogs.description_extended": "Tutorial, rilasci di funzionalità, confronti di prodotti e altro!\nTrova contenuti dal team DipSway qui sul blog DipSway.",
        "blogs.go_to_article": "Vai all'articolo",
        "skip_to_content": "Salta al contenuto",
        "calculate_your_earnings_and_start_free_trial": "Calcola i tuoi guadagni e inizia la prova gratuita di 7 giorni",

        "privacy_policy": "Informativa sulla Privacy",
        "terms_of_service": "Termini di Servizio",
        "refund_policy": "Politica di Rimborso",
        "affiliate_policy": "Politica di Affiliazione",
        "blog": "Blog",
        "supported_exchanges": "Exchange Supportati",
        "pricing": "Prezzi",
        "resources": "Risorse",
        "company": "Azienda",
        "support": "Supporto",
        "affiliate_program": "Programma di Affiliazione",
        "about_us": "Chi Siamo",
        "customer_success_agent": "Agente di Successo del Cliente",
        "dipsway_ai_optimization": "Ottimizzazione AI DipSway",
        "live_back_tests": "Backtest in tempo reale",
        "live_back_tests_performances": "Backtest in tempo reale e prestazioni di DipSway",
        "historical_performance_data": "Dati storici sulle prestazioni",
        "dipsway_earning_calculator": "Calcolatore di guadagni DipSway",
        "performance": "Prestazioni",
        "common:footer.disclaimer": "DipSway fornisce solo software. Qualsiasi riferimento a servizi di trading, scambio, trasferimento o portafoglio, ecc. sono riferimenti a servizi forniti da fornitori di servizi terzi.",

        "index:hero.title": "DipSway - Bot di trading di criptovalute AI a pilota automatico senza configurazione",
        "index:hero.description": "Sperimenta il trading di criptovalute senza soluzione di continuità con i nostri bot AI. Lancia il tuo bot AI di Trading di Criptovalute per scambi 100% automatizzati, disponibile 24/7 su un'interfaccia semplice.",

        "header:product": "Prodotto",
        "header:product.feature.header": "Quanto può guadagnare il tuo capitale usando DipSway?",
        "header:product.feature.description": "Calcola i tuoi guadagni basati sulla storia dei backtest di DipSway.",
        "header:product.feature.cta": "Calcola i tuoi guadagni",

        "header:product.links.spot_ai_trading_bot": "Bot di Trading AI Spot",
        "header:learn": "Impara",
        "header:learn.links.ai_trading_optimization": "AI + Trading di Criptovalute",

        "welcome:headline": "Bot di trading di criptovalute AI a pilota automatico senza configurazione",
        "welcome:subheadline": "Connettiti al tuo exchange, attiva il pilota automatico e hai finito.",
        "start_7_days_no_credit_card": "Inizia la prova gratuita di 7 giorni - Nessun deposito richiesto.",

        "404:title_site": "404 - DipSway | Bot di Trading di Criptovalute Alimentato da AI",
        "404:description_site": "Trova il tuo percorso di ritorno al trading di criptovalute con il nostro bot AI. Lancia il tuo bot AI di Trading di Criptovalute per scambi 100% automatizzati, disponibile 24/7 su un'interfaccia semplice.",
        "404:page_not_found": "Pagina non trovata",
        "404:page_not_found_message": "Sembra che tu abbia trovato un... link rotto! Per favore, fai sapere al supporto DipSway quale pagina ti ha portato qui!",
        "404:go_home": "Vai alla home",
        "404:open_dashboard": "Apri dashboard",

        "affiliate_program_policy:title_site": "Politica del Programma di Affiliazione DipSway",
        "affiliate_program_policy:description_site": "Politica del Programma di Affiliazione DipSway",

        "crypto_bot_calculator:title_site": "Calcolatore di guadagni del bot di trading di criptovalute",
        "crypto_bot_calculator:description_site": "Analisi completa della redditività basata sui dati storici di DipSway, considerando fattori come le commissioni di scambio, le prestazioni delle monete e simulando come i bot DipSway commercerebbero con la tua dimensione di capitale.",
        "crypto_bot_calculator:label": "Calcolatore di guadagni del bot di trading di criptovalute",
        "crypto_bot_calculator:simulate_based_on": "Simula in base alle prestazioni passate",
        "crypto_bot_calculator:calculate_your_earnings": "Calcola i tuoi guadagni",
        "crypto_bot_calculator:description": "Analisi completa della redditività basata sui dati storici di DipSway, considerando fattori come le commissioni di scambio, le prestazioni delle monete e simulando come i bot DipSway commercerebbero con la tua dimensione di capitale.",

        "featured_on": "Presente su",
        "4_clicks_to_start": "4 clic per iniziare",
        "no_knowledge_required": "Zero configurazione, zero conoscenze richieste",
        "split_content_description": "DipSway è veramente a zero configurazione. connetti il tuo exchange → attiva il tuo bot → e monitora le sue prestazioni. Le configurazioni con le migliori prestazioni sono applicate di default.",
        "learn_about_dipsway_ai": "Scopri di più su DipSway AI",

        "trained_for_down_trends": "Addestrato per rilevare i trend discendenti",
        "be_safe_on_down_trends": "Sii al sicuro, anche nei trend discendenti",
        "autopilot_content_headline_1": "Dentro prima del pump, fuori prima del drop,",
        "autopilot_content_headline_2": "in pilota automatico",
        "autopilot_description": "DipSway analizza i mercati e effettua scambi mentre sei occupato con i tuoi impegni quotidiani, e ti tiene aggiornato con aggiornamenti sullo stato.",
        "downtrends_description": "L'AI di DipSway è addestrata per identificare i trend discendenti e prima che le cose peggiorino, ti farà uscire",

        "see_how_dipsway_works": "Vedi come funziona DipSway",

        "free_trial_action:label": "Pronto quando lo sei tu.",

        "dipsway_ai": "DipSway AI",

        "safety": "Sicurezza",
        "safety_description": "Mantieni i tuoi fondi al sicuro nel tuo exchange, la tua sicurezza è una priorità per noi.",

        "ai_trading_optimization_description": "Il nostro modello AI proprietario addestrato per riconoscere le opportunità.",

        "3months": "3 mesi",
        "total_profits_within_label": "Profitti totali su tutti i bot DipSway implementati negli ultimi",

        "dipsway_uses_combination_of": "DipSway usa una combinazione di",
        "and": "e",
        "technical_indicators": "indicatori tecnici",
        "pattern_detectors": "rilevatori di pattern",
        "detect_next_move": "per rilevare la prossima mossa del mercato.",

        "are_you_influencer": "Sei un influencer? Collabora con noi",
        "influencer_description": "Gli influencer che lavorano con noi ottengono una pagina di onboarding personalizzata su misura per gli interessi della loro base utenti.",

        "get_in_touch": "Contattaci",

        "spot_ai_bot:title_site": "Bot di Trading AI Spot | Scambi 100% Automatizzati",
        "spot_ai_bot:description_site": "Attiva il tuo Bot di Trading di Criptovalute e goditi scambi 100% automatizzati. Lancia il tuo bot oggi per un bot attivo 24/7. Goditi la comodità e massimizza i tuoi profitti.",

        "spot_ai_bot:24_7_automated_trading": "Trading automatizzato 24/7, piazzamento degli scambi quasi istantaneo",
        "spot_ai_bot:algo_crypto_by_ml": "Trading Algoritmico di Criptovalute AI, guidato dal Machine Learning",

        "spot_ai_bot:desc": "Il miglior bot di trading del settore, che utilizza l'AI per analizzare il mercato e prendere decisioni di trading a velocità simile a quella di una macchina.",

        "million": "milione",

        "avg_monthly_volume_moved_by_bots": "Volume mensile medio spostato dai bot DipSway.",
        "dipsway_has_more_than_350_users": "DipSway ha più di 350 utenti in tutto il mondo.",
        "dipsway_supports_315_coins": "DipSway supporta il trading per oltre 315+ valute.",

        "ai_powered": "Alimentato dall'AI",
        "trading_strategies_powered_by_ai": "Le strategie di trading sono alimentate dall'AI, utilizzando una combinazione di analisi tecnica, analisi fondamentale.",

        "geolocation_advantage": "Vantaggio di Geolocalizzazione",
        "geolocation_advantage_desc": "I server che ospitano il bot sono situati nella stessa posizione geografica dell'exchange, per garantire la latenza più bassa possibile (ritardo medio di ~932μs).",

        "high_frequency_trading": "Trading ad Alta Frequenza",
        "high_frequency_trading_desc": "Grazie a un'architettura multi-tenant, le strategie di trading sono eseguite con una granularità di candele di 1 secondo.",

        "advanced_risk_management": "Gestione Avanzata del Rischio",
        "advanced_risk_management_desc": "Il bot è in grado di gestire investimenti in più valute e di coprire automaticamente il rischio del portafoglio.",

        "optimization_overfitting_avoidance": "Ottimizzazione e Prevenzione del Sovradattamento",
        "optimization_overfitting_avoidance_desc": "Il bot è in grado di ottimizzare i parametri delle strategie di trading e di evitare il sovradattamento utilizzando un algoritmo genetico AI e un'analisi Walk-Forward personalizzata.",

        "backtesting": "Backtesting",
        "backtesting_desc": "Ogni 24 ore, eseguiamo backtest di tutte le strategie di trading e ricalcoliamo i pesi utilizzati dall'AI utilizzando un approccio di 'Apprendimento Online'.",
        "how_do_dipsways_strategies_work": "Come funzionano le strategie di DipSway?",
        "no_one_wants_to_invest_in_black_box": "Nessuno vuole investire in una scatola nera. Ecco perché abbiamo creato un modo semplice per seguire dietro le quinte del Bot AI di DipSway - DipSway fonde 112+ indicatori e strategie per darti un vantaggio nel mondo in rapida evoluzione delle criptovalute.",
        "learn_more_about_dipsway_ai": "Scopri di più su DipSway AI",

        "autopilot_buys_sharp_as_knife": "Acquisti in autopilota, affilati come un coltello",
        "autopilot_buys_sharp_as_knife_desc": "La maggior parte dei mercati di criptovalute si muove lateralmente e le grandi oscillazioni di prezzo non durano a lungo. Grazie a un processo decisionale di Machine Learning, il bot AI è costruito per eseguire ordini di acquisto netti che ti manterranno costantemente redditizio nei mercati laterali e approfitteranno quando si verificano quei grandi cali.",
        "other_strategies_dont_work": "Altre strategie là fuori non funzionano perché sono statiche e non possono evolversi con i cambiamenti del mercato.",

        "autopilot_sells_and_youre_in_profit": "Vendite in autopilota, e sei in profitto.",

        "dont_let_downturns_catch_you_off_guard": "Non lasciarti cogliere impreparato dai ribassi.",
        "dont_let_downturns_catch_you_off_guard_desc": "121+ indicatori e ogni metrica di cui hai bisogno in una sola piattaforma.",

        "your_bot_runs_in_cloud": "Il tuo bot funziona nel Cloud, 24/7.",
        "your_bot_runs_in_cloud_desc": "Il nostro bot è un bot di trading basato su cloud che funziona 24/7, quindi non devi preoccuparti di andare offline! È sempre connesso al mercato, analizzando il momento migliore per comprare e vendere.",

        "unfair_advantage": "per darti un vantaggio ingiusto.",
        "and_many_more": "E molti altri...",

        "pricing:title_site": "Prezzi DipSway",
        "pricing:description_site": "Bot AI di Trading di Criptovalute a Prezzi Accessibili | Lancia il Tuo Bot Oggi | Goditi il Trading Automatizzato 24/7",
        "pricing:supercharge_text": "Potenzia il tuo trading con DipSway AI",

        "performance:title_site": "Storia delle Prestazioni e Backtest di DipSway",
        "performance:description_site": "Sperimenta backtest in tempo reale con il nostro Bot AI di Trading di Criptovalute. Lancia il tuo bot oggi per un bot attivo 24/7. Goditi la comodità e massimizza i tuoi profitti.",
        "performance:label": "Storia delle Prestazioni e Backtest di DipSway",

        "connect_to_exchange": "Connettiti al tuo exchange, attiva il pilota automatico e hai finito.",
        "calculate_your_earnings": "Calcola i tuoi guadagni",

        "trading_optimization:title_site": "Ottimizzazione del Trading AI DipSway",
        "trading_optimization:description_site": "Sperimenta l'Ottimizzazione del Trading AI con il nostro Bot AI di Trading di Criptovalute. Lancia il tuo bot oggi per un bot attivo 24/7. Goditi la comodità e massimizza i tuoi profitti.",
        "trading_optimization:label": "Ottimizzazione del Trading AI",
        "trading_optimization:breakdown": "Analisi: Trading di Criptovalute AI di DipSway",

        "trading_optimization:desc": "Il potente bot AI di DipSway si adatta dinamicamente ai rapidi cambiamenti del mercato.",
        "trading_optimization:steps": "Tutto questo viene fatto attraverso una serie di passaggi:",

        "behind_scenes:behind_the_scenes": "Cosa c'è dietro le quinte?",

        "behind_scenes:indicators": "Alcuni indicatori mostrano un potenziale segnale di acquisto (verde), mentre altri mostrano un potenziale segnale di vendita (grigio).",
        "behind_scenes:neural_network": "La Rete Neurale DipSway prende in considerazione tutti questi indicatori e prende una decisione basata sul quadro complessivo, producendo un singolo segnale di acquisto o vendita.",

        "affiliate_program:title_site": "Programma di affiliazione per bot di criptovalute",
        "affiliate_program:description_site": "Unisciti al nostro Programma di Affiliazione. Goditi una commissione ricorrente del 30% sugli inviti di amici.",
        "affiliate_program:label": "Programma di Affiliazione",
        "affiliate_program:desc": "Guadagna il 30% di commissione su ogni referral, ricorrente",
        "affiliate_program:desc2": "Connettiti al tuo exchange, attiva il pilota automatico e hai finito.",
        "affiliate_program:get_your_affiliate_link": "Ottieni il tuo link di affiliazione",

        "conference": "Conferenza",

        "about:title_site": "Riguardo DipSway",
        "about:our_journey": "Il nostro viaggio",
        "about:our_journey_desc": "Fornire bot di trading di criptovalute AI di alta qualità e redditizi per trader di qualsiasi livello che fanno crescere il loro portafoglio di investimenti e migliorano le loro prestazioni quotidiane.",
        "about:just_getting_started": "E stiamo solo iniziando.",

        "about:description_site": "Sperimenta l'Ottimizzazione del Trading AI con il nostro Bot AI di Trading di Criptovalute. Lancia il tuo bot oggi per un bot attivo 24/7. Goditi la comodità e massimizza i tuoi profitti.",
        "about:label": "Chi siamo",
        "about:behind_scenes": "Dietro le quinte",
        "founder": "Fondatore",

        "about:founder1_msg": "DipSway è nato da una visione di rivoluzionare il trading di criptovalute attraverso l'AI all'avanguardia. Come ingegneri software ed entusiasti delle criptovalute, abbiamo visto un'opportunità di creare qualcosa di veramente innovativo - un bot di trading che pensa come un trader professionista ma opera a velocità sovrumana. Grazie alla nostra forte esperienza tecnica, la nostra svolta è arrivata quando abbiamo sviluppato un'AI che non segue solo regole predefinite, ma si adatta in tempo reale alle dinamiche di mercato.",
        "about:founder2_msg": "Questo non è il tuo bot di trading medio; è un sistema sofisticato che analizza vaste quantità di dati, riconosce modelli complessi e prende decisioni in frazioni di secondo con notevole precisione. Ciò che distingue DipSway è il nostro approccio unico all'apprendimento dell'AI. È come avere un team di trader esperti che lavorano per te 24/7, ma più veloce e più efficiente. La nostra missione è rendere il trading avanzato di criptovalute accessibile a tutti, dai professionisti esperti ai curiosi principianti.",

        "about:join_team": "Unisciti al team DipSway",
        "about:join_team_desc": "Stiamo cercando appassionati di criptovalute che siano entusiasti della finanza, del trading e della tecnologia. Pronto a cambiare il modo in cui il mondo investe in criptovalute?",

        "read_post": "Leggi il post",

        "about:development_starts": "Inizia lo sviluppo",
        "about:development_starts_desc": "Un giorno stavamo ricevendo notifiche dall'app mobile di Coinbase sul prezzo recente di Bitcoin come 'Bitcoin è salito del 5% oggi'. Abbiamo pensato che sarebbe stato bello avere un bot che operasse PRIMA di tali notifiche. Abbiamo cercato sul web e non abbiamo trovato nulla di facile da usare, che funzionasse nel cloud. Così abbiamo deciso di costruirlo noi stessi.",

        "about:first_bot_version": "Prima versione del bot",
        "about:first_bot_version_desc": "Prima simulazione della strategia di Algo-Trading per Bitcoin ed Ethereum, abbiamo iniziato a includere più asset cripto e più strategie. Abbiamo iniziato a vedere il potenziale del nostro bot e abbiamo deciso di renderlo pubblico.",

        "about:simple_strategy_not_enough": "Una 'strategia semplice' non era sufficiente",
        "about:simple_strategy_not_enough_desc": "Abbiamo costruito e addestrato una rete neurale che avrebbe imparato dal mercato e fatto previsioni. I risultati hanno mostrato un'accuratezza molto alta.",

        "about:live_bot_release": "Rilascio del bot in diretta",
        "about:live_bot_release_desc": "Abbiamo costruito e addestrato una rete neurale che avrebbe imparato dal mercato e fatto previsioni. I risultati hanno mostrato un'accuratezza molto alta.",

        "about:our_transparency_value": "Il nostro valore di trasparenza",
        "about:our_transparency_value_desc": "I backtest in diretta e le prestazioni in diretta sono stati resi pubblici. Vogliamo che la trasparenza sia un fattore chiave di differenziazione tra noi e altre aziende che non mostrano prove dei loro risultati.",

        "about:infrastructure_revolution": "Rivoluzione dell'Infrastruttura",
        "about:infrastructure_revolution_desc": "Il 2023 è stato un anno di successo con l'adozione della nostra piattaforma da parte di più trader. Abbiamo aumentato il nostro impegno con i trader, cercando di capire cosa tutti avessero veramente bisogno in una piattaforma di bot. Dato questa base utenti in crescita, abbiamo riconosciuto l'importanza di rendere DipSway a prova di fallimento a livello ingegneristico. Prima di introdurre entusiasmanti nuovi aggiornamenti, funzionalità, strumenti e bot, abbiamo dato priorità ad assicurarci che i nostri sistemi funzionassero 24/7 senza problemi e che le prestazioni e la qualità fossero al top.",

        "about:new_exchanges": "Nuovi exchange",
        "about:new_exchanges_desc": "A febbraio, abbiamo significativamente ampliato la nostra piattaforma integrando importanti exchange come Coinbase Advanced, BingX e Bitmart. Abbiamo anche stretto partnership con KuCoin e Bybit, diventando un'applicazione di terze parti ufficiale su entrambi. Queste aggiunte e partnership hanno notevolmente aumentato le opzioni di trading e l'accesso al mercato dei nostri utenti, convalidando l'affidabilità della nostra piattaforma e segnando una pietra miliare importante nella nostra crescita.",

        "about:rotating_proxy": "Proxy rotante e nuove funzionalità interessanti",
        "about:rotating_proxy_desc": "In un significativo aggiornamento della nostra infrastruttura, abbiamo implementato 20 IP aggiuntivi e ampliato le nostre repliche di database da 2 a 7, migliorando la reattività dei bot alle nuove candele su tutti gli exchange. Questo miglioramento posiziona i nostri bot in modo competitivo sul mercato per velocità e affidabilità. Abbiamo anche integrato Crypto(.)com ampliando ulteriormente i nostri exchange supportati e le opzioni di trading per gli utenti. Una nuova caratteristica notevole è la Classifica dei Bot, che permette agli utenti di controllare le prestazioni dei bot e copiare le impostazioni dei bot con le migliori prestazioni. Questi miglioramenti rafforzano collettivamente le capacità della nostra piattaforma, offrendo agli utenti opportunità di trading più robuste, efficienti e diverse.",

        "about:multiple_quote_currencies": "Valute di quotazione multiple",
        "about:multiple_quote_currencies_desc": "Abbiamo introdotto il supporto multi-valuta, permettendo ai bot di fare trading con varie coppie di quotazione oltre l'USDT. Questo miglioramento offre agli utenti maggiore flessibilità, adattandosi a diverse strutture di exchange e regolamenti geografici. Consentendo il trading tra più valute, abbiamo permesso agli utenti di ottimizzare le loro strategie in base a esigenze specifiche e condizioni di mercato, migliorando la loro esperienza di trading complessiva.",

        "about:new_admin_dashboard": "Nuova dashboard di amministrazione",
        "about:new_admin_dashboard_desc": "Il rilascio della nostra nuova piattaforma segna un significativo passo avanti nell'esperienza utente e nella funzionalità. Permette uno sviluppo e una consegna più rapidi di nuove funzionalità, dandoci un maggiore controllo sulla risoluzione dei problemi dei bot per garantire un supporto di livello elite. Abbiamo rafforzato la comunicazione tra interfaccia e backend, garantendo l'accuratezza dei dati e una funzionalità affidabile. Pur mantenendo il nostro impegno per un design minimalista, pulito e facile da navigare, questo ha anche arricchito la piattaforma con dati e analisi completi. Questo equilibrio permette agli utenti di avere approfondimenti dettagliati senza compromettere la semplicità, consentendo un processo decisionale più informato in un ambiente user-friendly.",

        "about:autopilot_tradingview": "Autopilot e automazione dei segnali TradingView",
        "about:autopilot_tradingview_desc": "Il Bot Autopilot, la nostra rivoluzionaria soluzione di trading guidata dall'AI. Questo miglioramento a configurazione zero dei nostri bot AI sfrutta dati storici, calcolo di reti neurali e dati IN DIRETTA da tutti i bot DipSway per ottimizzare continuamente le strategie di trading. Seleziona automaticamente gli asset con le migliori prestazioni e regola le configurazioni basandosi su parametri di mercato in tempo reale, offrendo agli utenti un'esperienza di trading hands-off e guidata dai dati che si adatta istantaneamente alle condizioni di mercato.",

        "about:ai_futures_bot": "Bot AI Futures",
        "about:ai_futures_bot_desc": "Con l'introduzione del sistema SL & TP interno (le operazioni di Trailing saranno presto disponibili), tutti gli utenti hanno ora un tasso di successo del 100% sulle loro impostazioni personalizzate per il loro bot. Il sistema interno ci ha permesso di rilasciare la prima generazione di un autentico bot AI Futures.",

        "about:new_features": "Nuove funzionalità",
        "about:new_features_desc": "Stiamo lavorando su una nuova funzionalità che permetterà agli utenti di creare le proprie strategie e condividerle con la comunità.",

        "proven_to_perform": "Dimostrato di performare",

        "see_how_dipsway_averages": "Guarda come i DipSwayers stanno mediando usando il potere dei bot DipSway.",
        "last": "Ultimi",
        "months_compound_roi": "ROI Composto di Mesi",
        "total_return_based_on_last": "Rendimento totale basato sugli ultimi",
        "show_as_multiplier_values": "Mostra come valori moltiplicatori",
        "show_as_percentage_values": "Mostra come valori percentuali",
        "join": "Unisciti",
        "stars": "Stelle",

        "faq": "Domande Frequenti",
        "faq_desc": "Trova le tue risposte qui. Se hai una domanda che non è coperta nelle FAQ, non esitare a contattarci.",
        "faq_sub_desc": "Non hai trovato la risposta che cercavi?",
        "contact_support": "Contatta il supporto",

        "view_supported_platform": "Visualizza Piattaforme Supportate",

        "simulate_based_on_past_performances": "Simula in base alle prestazioni passate",
        "calculate_your_earnings_desc": "Analisi completa della redditività basata sui dati storici di DipSway, considerando fattori come le commissioni di scambio, le prestazioni delle monete e simulando come i bot DipSway commercerebbero con la tua dimensione di capitale.",

        "go_to_tweet": "Vai al tweet",
        "view_on": "Visualizza su",

        "security": "Sicurezza",
        "safe_and_secure": "Sicuro e protetto",
        "safe_and_secure_desc": "I tuoi fondi rimangono nel tuo exchange, e chiediamo solo permessi minimi per eseguire scambi per te.",

        "follow_dipsway_development": "Segui lo sviluppo di DipSway e incontra la comunità",
        "join_us_on_telegram": "Unisciti a noi su Telegram",
        "here_when_you_need_help": "Qui quando hai bisogno di aiuto",
        "dipsway_customer_care": "Assistenza Clienti DipSway",

        "for_us_top_10_percent": "Per noi il top 10% è lo standard per i DipSwayers. Sei libero di sperimentare con diverse configurazioni, ma quando hai bisogno di aiuto per ottimizzare, siamo qui.",

        "join_the_telegram_community": "Unisciti alla comunità Telegram",

        "made": "ha fatto",
        "profit_on": "di profitto su",
        "a_single_trade": "un singolo scambio",

        "dont_go_back_to_manual_trading": "Non tornare al trading manuale,",
        "get_in_before_the_pump": "entra prima del pump",
        "get_out_before_the_drop": "esci prima del drop",

        "on_autopilot": "In pilota automatico",

        "start_your_dipsway_bot": "Avvia il tuo bot DipSway in 4 clic",

        "safe_for_you": "Sicuro per te, per design",
        "safe_for_you_desc": "Con DipSway i tuoi fondi sono al sicuro nel tuo exchange di criptovalute, e ti chiediamo di fidarti di noi solo quanto necessario per far funzionare i bot.",

        "ip_whitelisting": "Whitelist IP",
        "ip_whitelisting_desc": "Solo gli indirizzi IP controllati da DipSway possono operare sul tuo account, anche se la tua chiave API è stata rubata, i malintenzionati non possono eseguire scambi.",

        "restricted_access": "Accesso limitato",
        "restricted_access_desc": "DipSway richiede solo i permessi più semplici per monitorare ed eseguire scambi per tuo conto.",

        "bright_green_performances": "Prestazioni verde brillante",
        "on_capital": "Sul capitale",
        "win_rate": "tasso di vincita",
        "average_trade": "scambio medio",

        "see_dipsway_live_backtests": "Vedi i backtest in tempo reale e le prestazioni di DipSway",
        "find_and_download": "Trova e scarica dati storici sulle prestazioni e backtest in tempo reale.",

        "available_at_no_extra_cost": "Disponibile senza costi aggiuntivi",

        "full_autopilot_mode": "Modalità Autopilota completa",
        "full_autopilot_mode_desc": "Con l'Autopilota DipSway, non solo i tuoi scambi sono automatizzati, ma DipSway sceglie anche la migliore criptovaluta da scambiare in quel momento in base alle condizioni di mercato.",

        "calculate_your_earnings_with_autopilot": "Calcola i tuoi guadagni con l'Autopilota",
        "high_performance": "Alte prestazioni",
        "live_backtests_historical_performance": "Backtest in tempo reale, prestazioni storiche.",
        "backtests_performance": "Backtest e prestazioni",
        "extendable_via_tradingview": "Estendibile tramite TradingView",
        "use_power_tradingview": "Usa il potere di TradingView per configurare ulteriormente DipSway.",
        "coming_soon": "Prossimamente...",
        "3_green_trades_in_30_days": "3 scambi in verde in 30 giorni o rimborso garantito",
        "need_help_choosing": "Hai bisogno di aiuto per scegliere?",
        "use_dipsway_earnings_calculator": "Usa il calcolatore di guadagni DipSway, scopri quanto puoi guadagnare e ottieni un suggerimento sul piano.",
        "launch_earnings_calculator": "Avvia il calcolatore di guadagni",
        "popular": "Popolare",
        "recommended_for_you": "Raccomandato per te",
        "no_deposit_required": "Nessun deposito richiesto",
        "top_10_of_users": "Top 10% degli utenti",
        "all_users": "Tutti gli utenti",
        "downtrend_page:desc1": "Operazione: Uscita di emergenza dalla posizione. Trend discendente identificato.",
        "downtrend_page:sell_order_fulfilled": "Ordine di vendita per 12 BTC eseguito.",
        "performance_history": "Storia delle Prestazioni",
        "trained_to_recognize_opportunities": "Addestrato a riconoscere le opportunità",
        "live_backtests_performances": "Prestazioni dei backtest in tempo reale",
        "see_how_our_strategies_performed": "Vedi come le nostre strategie hanno performato in passato con backtest in tempo reale di alta qualità.",
        "backtests_from": "Backtest degli ultimi",
        "past_6_months": "6 mesi passati",
        "collapse": "Comprimi",
        "expand": "Espandi",
        "view_report": "Visualizza rapporto",
        "knowledge:title1": "Dati Storici di Alta Qualità",
        "knowledge:description1": "Ci assicuriamo che i candlestick in tempo reale utilizzati siano della massima qualità.",
        "knowledge:title2": "Commissioni e Ritardi inclusi",
        "knowledge:description2": "Le commissioni maker e taker dello 0,1% sono applicate a ogni scambio. Uno slittamento dello 0,1% e un ritardo di pochi minuti sono applicati a ogni scambio.",
        "knowledge:title3": "Prevenzione del Sovradattamento",
        "knowledge:description3": "DipSway monitora passivamente gli account che performano male e raccomanda azioni.",
        "operation": "Operazione",
        "buy_the_dip": "Compra il dip",
        "sell_the_high": "Vendi l'alto",
        "bought": "Comprato",
        "watching_market": "osservando il mercato",
        "sell_order_for": "Ordine di vendita per",
        "fulfilled": "eseguito",
        "profit_made": "Profitto realizzato",
        "calculation_results": "Risultati del calcolo",
        "calculating": "Calcolando",
        "total_expected_profit_at_the_end_of": "Profitto totale previsto alla fine del",
        "period_based_off_of_historical_backtest_performances": "periodo basato sulle prestazioni dei backtest storici",
        "trades": "scambi",
        "day": "giorno",
        "profit": "profitto",
        "recommended_plan_for_you": "Piano raccomandato per te",
        "go_to": "Vai a",
        "free_trial_available": "Prova Gratuita Disponibile",
        "runs_at": "Funziona a",
        "learn_how_we_calculate_your_earnings": "Scopri come calcoliamo i tuoi guadagni",
        "click_to_open_deepdive": "Clicca per aprire l'approfondimento dove puoi scegliere tra gli altri exchange che supportiamo.",
        "your_capital": "Il tuo Capitale",
        "currency": "Valuta",
        "enabled": "Abilitato",
        "set_to": "impostato a",
        "coin_count": "conteggio monete",
        "disabled": "Disabilitato",
        "auto": "Auto",
        "selected_coins": "Monete selezionate",
        "loading_coins": "Caricamento monete",
        "login": "Accedi",
        "trading_with": "Trading con"
    },

    fil: {

        'all_payment_types': 'lahat ng uri ng pagbabayad',
        'all_payment_types_description': 'Makaranas ng wal ang abala na mga transaksyon gamit ang Bitcoin, Ethereum, at higit pa. Magbayad nang ligtas sa pamamagitan ng tiwala Cryptomus gateway para sa agarang access sa mga feature ng DipSway.',
        'extra': 'Extra',

        'no-token:footer.disclaimer': 'Hindi nagbebenta, nag-aalok, nagpapadali, o nagbibigay ng access ang DipSway sa anumang DeFi token o cryptocurrency sa anumang blockchain. Kami ay isang software provider lamang.',

        'coin_swap_description': 'Pinapadali ang iyong {crypto} sa {quote} na mga palitan gamit ang aming Coin Swap Paths finder. Ihambing ang maraming {crypto}/{quote} na ruta ng pagpapalit nang sabay-sabay, ilarawan ang mga kumplikadong swap, at tiyakin ang pinakamagandang palitan para sa iyong {crypto} sa {quote} na mga konbersyon. Perpekto para sa mga baguhan at eksperto na crypto trader na naghahanap ng pinakamagandang {crypto} sa {quote} na mga palitan.',

        'description_calculator_pt_1': 'Ang calculator na ito ay nagbibigay ng rate ng pagpapalit para sa',
        'description_calculator_pt_2': 'hanggang',
        'description_calculator_pt_3': 'Ang rate ng pagpapalit ay batay sa rate ng merkado sa nakaraang 24 na oras.',
        'to': 'hanggang',
        'converter': 'Tagapalit',
        'crypto_prices_and_market_data': 'Mga Presyo ng Crypto at Data ng Merkado',
        'converter_site:desc': 'Magpalit sa pagitan ng mga cryptocurrency at fiat na pera gamit ang aming madaling gamitin na converter tool. Kunin ang pinakabagong palitan at data ng merkado para sa iyong mga palitan.',
        'callout_calculator': 'AI-powered na Crypto Trading Bots',
        'info_on_price': 'Impormasyon sa presyo',
        'live_data': 'Live na data',
        'supported_exchanges': 'Mga Suportadong Exchange',
        'prices_of': 'Mga presyo ng',

        'dropped_of': 'Bumaba ng',
        'respect_yesterday': 'kumpara sa kahapon',
        'close_price': 'Presyo ng pagsara',
        'price_variation': 'Pagbabago ng presyo',
        'vwap': 'Karaniwang presyo',
        'volume_last_24h': 'Volume 24h',

        "exchange": "Exchange",
        "price": "Presyo",
        "volume": "Volume",
        "quote_volume": "Quote Volume",
        "percentage": "Porsyento",

        "asset": "Asset",
        "today_at": "Ngayong araw sa",

        "calculator_title": "Calculator mula {crypto} hanggang {quote}",
        "crypto_label": "Crypto",
        "currency_label": "Quote",
        "enter_amount": "Ilagay ang halaga",
        "conversion_result": "{cryptoAmount} {crypto} = {currencyAmount} {quote}",
        "trade_button": "Mag-trade sa {crypto}",

        "current_price_info": "Ang kasalukuyang presyo ng {crypto} ay {price}, na may {change24h} na pagbabago sa nakaraang 24 na oras at {change7d} sa nakaraang 7 araw.",
        "circulating_supply_info": "Sa umiikot na supply na 21M, ang live market cap ng {crypto} ay {marketCap}.",
        "trading_volume_info": "Ang 24-oras na trading volume ay {volume}.",
        "conversion_rate_calculation": "Ang mga rate ng pagpapalit ay kinakalkula gamit ang Live {crypto} Price Index kasama ang iba pang digital asset price indices.",
        "exchange_rate_update": "Ang {crypto}/{quote} na palitan ay naa-update sa real-time.",
        "follow_dipsway_info": "Sundan ang crypto converter at calculator ng DipSway para sa 24/7 na live updates ng mga palitan sa pagitan ng crypto at fiat na mga pera, kabilang ang {crypto}, {currencies}, at iba pa.",

        'search_for_crypto': 'Maghanap ng crypto currency',
        'see_more': 'Tingnan pa',
        'crypto_prices_title': 'Mga Suportadong Presyo ng Cryptocurrency Huling 24h na Pagbabago',
        'see_current_crypto_market_trend': 'Tingnan ang kasalukuyang trend ng cryptocurrency market',
        'dipsway': 'DipSway',
        'home_page': 'home page',
        'blogs.related_posts': 'Mga Kaugnay na Post',
        'blogs.automate_your_trading': 'I-automate ang iyong trading gamit ang DipSway AI ngayon.',
        'blogs.automate_your_trading_description': 'Ang DipSway ay gumagamit ng kumbinasyon ng 121+ na teknikal na indicators, at 17+ na pattern detectors para bigyan ka ng hindi patas na bentahe.',
        'btn.start_7_day_free_trial': 'Simulan ang 7-araw na libreng pagsubok',
        'blogs.tags': 'Mga Tag',
        'blogs.description_site': 'Mga tutorial sa crypto, mga bagong feature, paghahambing ng mga produkto, at higit pa! Hanapin ang nilalaman mula sa DipSway team dito sa DipSway blog.',
        'blogs.title_site': 'DipSway Blog',
        'blogs.callout': 'Ipinapakilala ang bersyon 4.13',
        'blogs.description_extended': 'Mga tutorial, mga bagong feature, paghahambing ng mga produkto, at higit pa!\nHanapin ang nilalaman mula sa DipSway team dito sa DipSway blog.',
        'blogs.go_to_article': 'Pumunta sa artikulo',
        'skip_to_content': 'Lumaktaw sa nilalaman',
        'calculate_your_earnings_and_start_free_trial': 'Kalkulahin ang iyong kita at Simulan ang 7-araw na libreng pagsubok',

        'privacy_policy': 'Patakaran sa Pagkapribado',
        'terms_of_service': 'Mga Tuntunin ng Serbisyo',
        'refund_policy': 'Patakaran sa Refund',
        'affiliate_policy': 'Patakaran sa Affiliate',
        'blog': 'Blog',

        'pricing': 'Presyo',
        'resources': 'Mga Mapagkukunan',
        'company': 'Kumpanya',
        'support': 'Suporta',
        'affiliate_program': 'Programa sa Affiliate',
        'about_us': 'Tungkol sa Amin',
        'customer_success_agent': 'Ahente ng Tagumpay ng Customer',
        'dipsway_ai_optimization': 'DipSway AI optimization',
        'live_back_tests': 'Live na back tests',
        'live_back_tests_performances': 'DipSway live backtests at performance',
        'historical_performance_data': 'Datos ng nakaraang performance',
        'dipsway_earning_calculator': 'DipSway earnings calculator',
        'performance': 'Performance',
        'common:footer.disclaimer': 'Ang DipSway ay nagbibigay lamang ng software. Anumang mga sanggunian sa trading, exchange, paglilipat, o mga serbisyo ng wallet, atbp. ay mga sanggunian sa mga serbisyong ibinibigay ng mga third-party na service provider.',

        'index:hero.title': 'DipSway - Zero configuration, automated AI crypto trading bots',
        'index:hero.description': 'Maranasan ang walang putol na crypto trading sa aming mga AI bot. Ilunsad ang iyong Crypto Trading AI bot para sa 100% na awtomatikong trades, available 24/7 sa isang simpleng interface.',

        'header:product': 'Produkto',
        'header:product.feature.header': 'Ano ang maaaring kitain ng iyong kapital gamit ang DipSway?',
        'header:product.feature.description': 'Kalkulahin ang iyong kita batay sa kasaysayan ng backtest ng DipSway.',
        'header:product.feature.cta': 'Kalkulahin ang iyong kita',

        'header:product.links.spot_ai_trading_bot': 'Spot AI Trading Bot',
        'header:learn': 'Matuto',
        'header:learn.links.ai_trading_optimization': 'AI + Crypto Trading',

        'welcome:headline': 'Zero configuration, automated AI crypto trading bots',
        'welcome:subheadline': 'Kumonekta sa iyong exchange, buksan ang autopilot at tapos ka na.',
        'start_7_days_no_credit_card': 'Simulan ang 7 araw na libreng pagsubok - Hindi kailangan ng deposito.',

        '404:title_site': '404 - DipSway | AI Powered Crypto Trading Bot',
        '404:description_site': 'Hanapin ang iyong daan pabalik sa crypto trading gamit ang aming AI bot. Ilunsad ang iyong Crypto Trading AI bot para sa 100% na awtomatikong trades, available 24/7 sa isang simpleng interface.',
        '404:page_not_found': 'Hindi nahanap ang pahina',
        '404:page_not_found_message': 'Mukhang nakahanap ka ng ... sirang link! Mangyaring ipaalam sa DipSway support kung anong pahina ang nagdala sa iyo dito!',
        '404:go_home': 'Pumunta sa home',
        '404:open_dashboard': 'Buksan ang dashboard',

        'affiliate_program_policy:title_site': "Patakaran sa Programa ng Affiliate ng DipSway",
        'affiliate_program_policy:description_site': "Patakaran sa Programa ng Affiliate ng DipSway",

        'crypto_bot_calculator:title_site': "Calculator ng kita sa crypto trading bot",
        'crypto_bot_calculator:description_site': "Buong pagsusuri ng kita batay sa datos ng kasaysayan ng DipSway, isinasaalang-alang ang mga bagay tulad ng mga bayarin sa exchange, performance ng coin, at pagsi-simulate kung paano mag-trade ang mga DipSway bot gamit ang laki ng iyong kapital.",
        'crypto_bot_calculator:label': 'Calculator ng kita sa crypto trading bot',
        'crypto_bot_calculator:simulate_based_on': 'I-simulate batay sa nakaraang performance',
        'crypto_bot_calculator:calculate_your_earnings': 'Kalkulahin ang iyong kita',
        'crypto_bot_calculator:description': 'Buong pagsusuri ng kita batay sa datos ng kasaysayan ng DipSway, isinasaalang-alang ang mga bagay tulad ng mga bayarin sa exchange, performance ng coin, at pagsi-simulate kung paano mag-trade ang mga DipSway bot gamit ang laki ng iyong kapital.',

        'featured_on': 'Ipinapakita Sa',
        '4_clicks_to_start': '4 na pag-click para magsimula',
        'no_knowledge_required': 'Walang kailangang kaalaman, walang configuration',
        'split_content_description': 'Ang DipSway ay talagang zero config. ikonekta ang iyong exchange → buksan ang iyong bot → at subaybayan ang performance nito. Ang pinakamahusay na mga configuration ay inilalapat bilang default.',
        'learn_about_dipsway_ai': 'Alamin ang tungkol sa DipSway AI',

        'trained_for_down_trends': 'Sinanay para sa mga pababang trend',
        'be_safe_on_down_trends': 'Maging ligtas, kahit sa mga pababang trend',
        'autopilot_content_headline_1': 'Maaga bago ang pump, labas bago ang pagbagsak,',
        'autopilot_content_headline_2': 'sa autopilot',
        'autopilot_description': 'Sinusuri ng DipSway ang mga merkado at nagsasagawa ng mga trade habang abala ka sa iyong pang-araw-araw na gawain, at pinapanatili kang may alam sa pamamagitan ng mga update sa status.',
        'downtrends_description': 'Ang AI ng DipSway ay sinanay para matukoy ang mga pababang trend at bago lumala ang mga bagay, ilalabas ka nito',

        'see_how_dipsway_works': 'Tingnan kung paano gumagana ang DipSway',

        'free_trial_action:label': 'Handa kapag handa ka na.',

        'dipsway_ai': 'DipSway AI',

        'safety': 'Kaligtasan',
        'safety_description': 'Panatilihing ligtas ang iyong pondo sa iyong exchange, ang iyong kaligtasan ay prayoridad para sa amin.',

        'ai_trading_optimization_description': 'Ang aming proprietary na AI model ay sinanay para makilala ang mga oportunidad.',

        '3months': '3 buwan',
        'total_profits_within_label': 'Kabuuang kita sa lahat ng naka-deploy na DipSway bots sa huling',

        'dipsway_uses_combination_of': 'Ang DipSway ay gumagamit ng kumbinasyon ng',
        'and': 'at',
        'technical_indicators': 'mga teknikal na indicator',
        'pattern_detectors': 'mga detector ng pattern',
        'detect_next_move': 'para matukoy ang susunod na galaw ng merkado.',

        'are_you_influencer': 'Ikaw ba ay isang influencer? Makipagtulungan sa amin',
        'influencer_description': 'Ang mga influencer na nakikipagtulungan sa amin ay nabibigyan ng custom na landing page para sa pag-onboard na iniaangkop sa mga interes ng kanilang user base.',

        'get_in_touch': 'Makipag-ugnayan',

        'spot_ai_bot:title_site': 'Spot AI Crypto Trading Bot | 100% Awtomatikong Trades',
        'spot_ai_bot:description_site': 'Buksan ang iyong Crypto Trading Bot at mag-enjoy sa 100% na awtomatikong trades. Ilunsad ang iyong bot ngayon para sa isang bot na tumatakbo 24/7. Mag-enjoy sa kaginhawaan at palakihin ang iyong kita.',

        'spot_ai_bot:24_7_automated_trading': '24/7 na awtomatikong trading, halos instant na paglalagay ng trade',
        'spot_ai_bot:algo_crypto_by_ml': 'AI Algorithmic Crypto Trading, pinapagana ng Machine Learning',

        'spot_ai_bot:desc': 'Ang pinakamahusay na trading bot sa industriya, gumagamit ng AI para suriin ang merkado at gumawa ng mga desisyon sa trading sa machine-like na bilis.',

        // ... (previous translations)

        'million': 'milyon',

        'avg_monthly_volume_moved_by_bots': 'Karaniwang buwanang volume na inilipat ng mga DipSway bot.',
        'dipsway_has_more_than_350_users': 'Ang DipSway ay may higit sa 350 na mga gumagamit sa buong mundo.',
        'dipsway_supports_315_coins': 'Ang DipSway ay sumusuporta sa trading para sa higit sa 315+ na mga currency.',

        'ai_powered': "Pinapagana ng AI",
        'trading_strategies_powered_by_ai': "Ang mga estratehiya sa trading ay pinapagana ng AI, gumagamit ng kumbinasyon ng teknikal na pagsusuri, pundamental na pagsusuri.",

        'geolocation_advantage': "Bentahe ng Geolocation",
        'geolocation_advantage_desc': "Ang mga server na nag-ho-host sa bot ay matatagpuan sa parehong heograpikong lokasyon ng exchange, para matiyak ang pinakamababang latency na posible (avg. ~932μs na pagkaantala).",

        'high_frequency_trading': 'High Frequency Trading',
        'high_frequency_trading_desc': 'Salamat sa multi-tenancy architecture, ang mga estratehiya sa trading ay isinasagawa sa granularity ng mga candlestick na 1 segundo.',

        'advanced_risk_management': 'Advanced na Pamamahala ng Panganib',
        'advanced_risk_management_desc': 'Ang bot ay may kakayahang pamahalaan ang mga pamumuhunan sa maraming currency, at awtomatikong i-hedge ang panganib ng portfolio.',

        'optimization_overfitting_avoidance': 'Pag-iwas sa Optimization at Overfitting',
        'optimization_overfitting_avoidance_desc': 'Ang bot ay may kakayahang i-optimize ang mga parameter ng mga estratehiya sa trading, at iwasan ang overfitting gamit ang AI-genetic algorithm at custom na Walk-Forward Analysis.',

        'backtesting': 'Backtesting',
        'backtesting_desc': 'Bawat 24 oras, nagpapatakbo kami ng mga backtest ng lahat ng mga estratehiya sa trading, at muli naming kinakalkula ang mga weight na ginagamit ng AI gamit ang approach na "Online Learning".',
        'how_do_dipsways_strategies_work': "Paano gumagana ang mga estratehiya ng DipSway?",
        'no_one_wants_to_invest_in_black_box': "Walang gustong mag-invest sa isang black box. Kaya gumawa kami ng madaling paraan para sundan ang likod ng eksena ng AI Bot ng DipSway - Pinagsasama ng DipSway ang 112+ na indicator at estratehiya para bigyan ka ng bentahe sa mabilis na nagbabagong mundo ng crypto.",
        'learn_more_about_dipsway_ai': 'Alamin pa ang tungkol sa DipSway AI',

        'autopilot_buys_sharp_as_knife': 'Mga autopilot na pagbili, matalim tulad ng kutsilyo',
        'autopilot_buys_sharp_as_knife_desc': 'Karamihan sa mga crypto market ay gumagalaw nang pahalang at ang malalaking pagbabago ng presyo ay hindi nagtatagal. Salamat sa proseso ng paggawa ng desisyon ng Machine Learning, ang AI bot ay ginawa para magsagawa ng matalas na mga order ng pagbili na patuloy na papanatilihin kang kumikita sa mga pahalang na merkado at makinabang kapag nangyayari ang malalaking pagbaba.',
        'other_strategies_dont_work': 'Ang ibang mga estratehiya dyan ay hindi gumagana dahil sila ay hindi nagbabago at hindi nakaka-angkop sa mga pagbabago ng merkado.',

        'autopilot_sells_and_youre_in_profit': 'Awtomatikong nagbebenta, at ikaw ay kumikita.',

        'dont_let_downturns_catch_you_off_guard': 'Huwag hayaang mahuli ka ng mga pagbaba.',
        'dont_let_downturns_catch_you_off_guard_desc': '121+ na mga indicator at bawat sukatan na kailangan mo sa isang platform.',

        'your_bot_runs_in_cloud': 'Ang iyong bot ay tumatakbo sa Cloud, 24/7.',
        'your_bot_runs_in_cloud_desc': 'Ang aming bot ay isang cloud-based na trading bot na tumatakbo 24/7, kaya hindi mo kailangang mag-alala na mawalan ng koneksyon! Palagi itong nakakonekta sa merkado, sinusuri ang pinakamahusay na timing para bumili at magbenta.',

        'unfair_advantage': 'para bigyan ka ng hindi patas na bentahe.',
        'and_many_more': 'At marami pang iba...',

        'pricing:title_site': 'Presyo ng DipSway',
        'pricing:description_site': "Abot-kayang Crypto Trading AI Bot | Ilunsad ang Iyong Bot Ngayon | Mag-enjoy sa 24/7 na Awtomatikong Trading",
        'pricing:supercharge_text': 'Palakasin ang iyong trading gamit ang DipSway AI',

        'performance:title_site': 'Kasaysayan ng Performance ng DipSway at Backtests',
        'performance:description_site': "Maranasan ang live na backtests sa aming Crypto Trading AI Bot. Ilunsad ang iyong bot ngayon para sa isang bot na tumatakbo 24/7. Mag-enjoy sa kaginhawaan at palakihin ang iyong kita.",
        'performance:label': "Kasaysayan ng Performance ng DipSway at Backtests",

        'connect_to_exchange': 'Kumonekta sa iyong exchange, buksan ang autopilot at tapos ka na.',
        'calculate_your_earnings': 'Kalkulahin ang iyong kita',

        'trading_optimization:title_site': "DipSway AI Trading Optimization",
        'trading_optimization:description_site': "Maranasan ang AI Trading Optimization sa aming Crypto Trading AI Bot. Ilunsad ang iyong bot ngayon para sa isang bot na tumatakbo 24/7. Mag-enjoy sa kaginhawaan at palakihin ang iyong kita.",
        'trading_optimization:label': "AI Trading Optimization",
        'trading_optimization:breakdown': 'Breakdown: AI Crypto Trading ng DipSway',

        'trading_optimization:desc': 'Ang makapangyarihang AI bot ng DipSway ay dinamikong umaangkop sa mabilis na pagbabago ng merkado.',
        'trading_optimization:steps': 'Lahat ng ito ay ginagawa sa pamamagitan ng mga serye ng hakbang:',

        'behind_scenes:behind_the_scenes': 'Ano ang nasa likod ng eksena?',

        'behind_scenes:indicators': 'Ang ilang mga indicator ay nagpapakita ng potensyal na signal ng pagbili (berde), habang ang iba ay nagpapakita ng potensyal na signal ng pagbenta (kulay-abo).',
        'behind_scenes:neural_network': 'Ang Neural Network ng DipSway ay isinasaalang-alang ang lahat ng mga indicator na ito at gumagawa ng desisyon batay sa pangkalahatang larawan, na lumilikha ng isang signal ng pagbili o pagbenta.',

        'affiliate_program:title_site': 'Programa ng affiliate para sa cryptocurrency bot',
        'affiliate_program:description_site': "Sumali sa aming Programa ng Affiliate. Mag-enjoy ng 30% na paulit-ulit na komisyon sa mga pag-imbita ng kaibigan.",
        'affiliate_program:label': "Programa ng Affiliate",
        'affiliate_program:desc': "Kumita ng 30% na komisyon sa bawat referral, paulit-ulit",
        'affiliate_program:desc2': "Kumonekta sa iyong exchange, buksan ang autopilot at tapos ka na.",
        'affiliate_program:get_your_affiliate_link': 'Kunin ang iyong affiliate link',

        'conference': 'Kumperensya',

        'about:title_site': "Tungkol sa DipSway",
        'about:our_journey': 'Ang aming paglalakbay',
        'about:our_journey_desc': 'Upang magbigay ng de-kalidad, kumikitang AI crypto trading bots para sa mga trader ng anumang antas na nagpapalago ng kanilang investment wallet at nagpapahusay ng kanilang pang-araw-araw na performance.',
        'about:just_getting_started': 'At nagsisimula pa lang kami.',

        'about:description_site': "Maranasan ang AI Trading Optimization sa aming Crypto Trading AI Bot. Ilunsad ang iyong bot ngayon para sa isang bot na tumatakbo 24/7. Mag-enjoy sa kaginhawaan at palakihin ang iyong kita.",
        'about:label': 'Tungkol sa',
        'about:behind_scenes': 'Sa likod ng eksena',
        'founder': 'Tagapagtatag',

        'about:founder1_msg': 'Ang DipSway ay ipinanganak mula sa isang pananaw na rebolusyonin ang crypto trading sa pamamagitan ng cutting-edge na AI. Bilang mga software engineer at crypto enthusiast, nakita namin ang oportunidad na lumikha ng isang talagang makabago - isang trading bot na nag-iisip tulad ng isang propesyonal na trader ngunit gumagana sa superhuman na bilis.\nSalamat sa aming malakas na teknikal na kaalaman, ang aming breakthrough ay dumating nang nagdevelop kami ng isang AI na hindi lamang sumusunod sa mga preset na panuntunan, kundi umaangkop sa real-time sa mga dinamika ng merkado.',
        'about:founder2_msg': 'Hindi ito ang iyong karaniwang trading bot; ito ay isang sophisticated na sistema na nagsusuri ng malaking dami ng data, kumikilala ng mga kumplikadong pattern, at gumagawa ng mga desisyon sa isang segundo na may kapansin-pansing katumpakan.\nAng nagpapaiba sa DipSway ay ang aming natatanging approach sa AI learning. Parang may isang team ng mga eksperto sa trading na nagtatrabaho para sa iyo 24/7, ngunit mas mabilis at mas episyente.\nAng aming misyon ay gawing accessible ang advanced na crypto trading sa lahat, mula sa mga bihasa hanggang sa mga baguhang curious.',

        'about:join_team': 'Sumali sa DipSway team',
        'about:join_team_desc': 'Naghahanap kami ng mga crypto-enthusiast na may pasyon sa finance, trading at teknolohiya. Handa ka bang baguhin ang paraan ng pag-invest ng mundo sa crypto?',

        'read_post': 'Basahin ang post',

        'about:development_starts': "Nagsimula ang development",
        'about:development_starts_desc': "Isang araw, nakakatanggap kami ng mga notification mula sa Coinbase mobile app tungkol sa kamakailang presyo ng Bitcoin tulad ng 'Ang Bitcoin ay tumaas ng 5% ngayon'. Naisip namin na magiging maganda kung may bot na mag-o-operate BAGO ang mga ganitong notification. Naghanap kami sa web at hindi makahanap ng anumang madaling gamitin, na tumatakbo sa cloud. Kaya nagpasya kaming gumawa nito mismo.",

        'about:first_bot_version': "Unang bersyon ng bot",
        'about:first_bot_version_desc': "Unang simulation ng Algo-Trading strategy para sa Bitcoin at Ethereum, nagsimula kaming magsama ng mas maraming crypto asset at mas maraming estratehiya. Nagsimula naming makita ang potensyal ng aming bot at nagpasya kaming gawin itong pampubliko.",

        'about:simple_strategy_not_enough': 'Ang "simpleng estratehiya" ay hindi sapat',
        'about:simple_strategy_not_enough_desc': "Gumawa at nagsanay kami ng neural network na matututo mula sa merkado at gagawa ng mga prediction. Ang mga resulta ay nagpakita ng napakataas na katumpakan.",

        'about:live_bot_release': "Pag-release ng live bot",
        'about:live_bot_release_desc': "Gumawa at nagsanay kami ng neural network na matututo mula sa merkado at gagawa ng mga prediction. Ang mga resulta ay nagpakita ng napakataas na katumpakan.",

        'about:our_transparency_value': "Ang aming halaga sa transparency",
        'about:our_transparency_value_desc': "Ang mga live na backtest at live na performance ay inilabas sa publiko. Gusto naming maging pangunahing pagkakaiba ang transparency sa pagitan namin at iba pang mga kumpanya na hindi nagpapakita ng ebidensya ng kanilang mga resulta.",

        'about:infrastructure_revolution': "Rebolusyon sa Imprastraktura",
        'about:infrastructure_revolution_desc': "Ang 2023 ay isang matagumpay na taon dahil mas maraming trader ang gumamit ng aming platform. Pinaigting namin ang aming pakikipag-ugnayan sa mga trader, nagsisikap na maunawaan kung ano talaga ang kailangan ng lahat sa isang bot platform. Dahil sa lumalaking user base na ito, nakilala namin ang kahalagahan ng paggawa ng DipSway na hindi nagkakamali sa antas ng engineering. Bago magpakilala ng mga kapana-panabik na bagong update, feature, tool, at bot, binigyan namin ng prayoridad ang pagtiyak na ang aming mga sistema ay tumatakbo 24/7 nang walang problema at ang performance at kalidad ay pinakamahusay.",

        'about:new_exchanges': "Mga bagong exchange",
        'about:new_exchanges_desc': "Noong Pebrero, malaki naming pinalawak ang aming platform sa pamamagitan ng pag-integrate ng mga pangunahing exchange tulad ng Coinbase Advanced, BingX, at Bitmart. Nakipag-partner din kami sa KuCoin at Bybit, nagiging opisyal na third-party application sa pareho. Ang mga karagdagan at partnership na ito ay lubos na nagpataas ng mga opsyon sa trading at access sa merkado ng aming mga user, na nagpapatunay sa pagiging maaasahan ng aming platform at nagmamarka ng isang malaking milestone sa aming paglago.",

        'about:rotating_proxy': "Umiikot na Proxy at mga bagong hot na feature",
        'about:rotating_proxy_desc': "Sa isang makabuluhang pag-upgrade sa aming imprastraktura, nagpatupad kami ng 20 karagdagang IP at pinalawak ang aming mga database replica mula 2 hanggang 7, na nagpapahusay sa pagtugon ng bot sa mga bagong candle sa iba't ibang exchange. Ang mga bagong feature na ito ay nagbigay sa aming mga user ng mas mabilis na pag-access sa merkado at mas mabilis na pagtugon sa mga pagbabago sa merkado.",

        'about:multiple_quote_currencies': "Maramihang quote currencies",
        'about:multiple_quote_currencies_desc': "Nagpakilala kami ng suporta sa multi-currency, na nagbibigay-daan sa mga bot na mag-trade gamit ang iba't ibang quote pairs bukod sa USDT. Ang pagpapahusay na ito ay nag-aalok sa mga user ng mas malaking kakayahang umangkop, na tumutugon sa iba't ibang istraktura ng exchange at geographic na regulasyon. Sa pamamagitan ng pagpapahintulot ng trading sa iba't ibang mga currency, binigyan namin ng kapangyarihan ang mga user na i-optimize ang kanilang mga estratehiya ayon sa mga partikular na pangangailangan at kundisyon ng merkado, na nagpapahusay sa kanilang pangkalahatang karanasan sa trading.",

        'about:new_admin_dashboard': "Bagong admin dashboard",
        'about:new_admin_dashboard_desc': "Ang aming bagong release ng platform ay nagmamarka ng isang mahalagang hakbang pasulong sa karanasan ng user at functionality. Pinapagana nito ang mas mabilis na pag-develop at paghahatid ng mga bagong feature, na nagbibigay sa amin ng mas malaking kontrol sa pag-troubleshoot ng bot para matiyak ang suporta sa elite level. Pinalakas namin ang komunikasyon sa pagitan ng interface at backend, na naggarantiya ng katumpakan ng data at maaasahang functionality. Habang pinapanatili ang aming pangako sa isang minimalist, malinis, at madaling i-navigate na disenyo, pinayaman din nito ang platform ng komprehensibong data at analytics. Ang balanseng ito ay nagbibigay-kapangyarihan sa mga user ng malalim na insight nang hindi nakokompromiso ang simplisidad, na nagbibigay-daan para sa mas maalam na paggawa ng desisyon sa isang user-friendly na kapaligiran.",

        'about:autopilot_tradingview': "Autopilot at TradingView signal automation",
        'about:autopilot_tradingview_desc': "Ang Autopilot Bot, ang aming rebolusyonaryong AI-driven na solusyon sa trading. Ang zero-configuration na pagpapahusay na ito sa aming mga AI bot ay gumagamit ng datos ng kasaysayan, neural network computing, at LIVE na data mula sa lahat ng DipSway bot para patuloy na i-optimize ang mga estratehiya sa trading. Awtomatiko nitong pinipili ang mga pinakamahusay na asset at ina-adjust ang mga configuration batay sa real-time na mga parameter ng merkado, nag-aalok sa mga user ng hands-off, data-driven na karanasan sa trading na agad na umaangkop sa mga kundisyon ng merkado.",

        'about:ai_futures_bot': "AI Futures bot",
        'about:ai_futures_bot_desc': "Sa pagpapakilala ng in-house na SL & TP system (Ang mga Trailing operation ay magiging available sa lalong madaling panahon), lahat ng user ngayon ay may 100% na success rate sa kanilang custom na mga setting para sa kanilang bot. Ang in-house na system ay nagbigay-daan sa amin na ilabas ang unang henerasyon ng tunay na AI Futures bot.",

        'about:new_features': "Mga bagong feature",
        'about:new_features_desc': "Nagtatrabaho kami sa isang bagong feature na magpapahintulot sa mga user na lumikha ng kanilang sariling mga estratehiya at ibahagi ang mga ito sa komunidad.",

        'proven_to_perform': 'Napatunayan na may magandang performance',

        'see_how_dipsway_averages': 'Tingnan kung paano nakakaranas ng average ang mga DipSwayer gamit ang kapangyarihan ng mga DipSway bot.',
        'last': 'Huling',
        'months_compound_roi': 'Buwan na Compound ROI',
        'total_return_based_on_last': 'Kabuuang Return batay sa huling',
        'show_as_multiplier_values': 'Ipakita bilang mga multiplier value',
        'show_as_percentage_values': 'Ipakita bilang mga porsyentong value',
        'join': 'Sumali',
        'stars': 'Bituin',

        'faq': 'Madalas Itanong',
        'faq_desc': 'Hanapin ang iyong mga sagot dito. Kung mayroon kang tanong na hindi nasasagot sa FAQ, huwag mag-atubiling makipag-ugnayan sa amin.',
        'faq_sub_desc': 'Hindi mo ba mahanap ang sagot na hinahanap mo?',
        'contact_support': 'Makipag-ugnayan sa suporta',

        'view_supported_platform': 'Tingnan ang mga Suportadong Platform',

        'simulate_based_on_past_performances': 'I-simulate batay sa nakaraang performance',
        'calculate_your_earnings_desc': 'Buong pagsusuri ng kita batay sa datos ng kasaysayan ng DipSway, isinasaalang-alang ang mga bagay tulad ng mga bayarin sa exchange, performance ng coin, at pagsi-simulate kung paano mag-trade ang mga DipSway bot gamit ang laki ng iyong kapital.',

        'go_to_tweet': 'Pumunta sa tweet',
        'view_on': 'Tingnan sa',

        'security': 'Seguridad',
        'safe_and_secure': 'Ligtas at secure',
        'safe_and_secure_desc': 'Ang iyong mga pondo ay nananatili sa iyong exchange, at humihingi lang kami ng minimal na mga permiso para magsagawa ng mga trade para sa iyo.',

        'follow_dipsway_development': 'Sundan ang development ng DipSway at makilala ang komunidad',
        'join_us_on_telegram': 'Sumali sa amin sa Telegram',
        'here_when_you_need_help': 'Narito kapag kailangan mo ng tulong',
        'dipsway_customer_care': 'DipSway Customer Care',

        'for_us_top_10_percent': 'Para sa amin ang nangunguna 10% ay ang pamantayan para sa mga DipSwayer. Malaya kang mag-eksperimento sa iba t ibang configuration, pero kapag kailangan mo ng tulong sa pag - optimize, nandito kami.',

        'join_the_telegram_community': 'Sumali sa komunidad sa telegram',

        'made': 'kumita ng',
        'profit_on': 'kita sa',
        'a_single_trade': 'isang trade',

        'dont_go_back_to_manual_trading': 'Huwag bumalik sa manual na trading,',

        'get_in_before_the_pump': 'pumasok bago ang pump',
        'get_out_before_the_drop': 'lumabas bago ang pagbagsak',

        'on_autopilot': 'Sa autopilot',

        'start_your_dipsway_bot': 'Simulan ang iyong DipSway bot sa 4 na pag-click',

        'safe_for_you': 'Ligtas para sa iyo, sa disenyo',
        'safe_for_you_desc': 'Sa DipSway, ang iyong mga pondo ay ligtas sa iyong crypto exchange, at hinihiling lang namin na pagkatiwalaan mo kami hanggang sa kinakailangan para tumakbo ang mga bot.',

        'ip_whitelisting': 'IP whitelisting',
        'ip_whitelisting_desc': 'Tanging mga IP address na kontrolado ng DipSway ang maaaring mag-operate sa iyong account, kahit na nanakaw ang iyong API key, hindi makakapagsagawa ng mga trade ang mga masasamang aktor.',

        'restricted_access': 'Limitadong access',
        'restricted_access_desc': 'Ang DipSway ay humihiling lamang ng pinakasimpleng mga permiso para masubaybayan at magsagawa ng mga trade para sa iyo.',

        'bright_green_performances': 'Maliwanag na berdeng performance',
        'on_capital': 'Sa kapital',
        'win_rate': 'win rate',
        'average_trade': 'karaniwang trade',

        'see_dipsway_live_backtests': 'Tingnan ang live na backtests at performance ng DipSway',
        'find_and_download': 'Hanapin at i-download ang datos ng nakaraang performance, at live na backtests.',

        'available_at_no_extra_cost': 'Available nang walang dagdag na bayad',

        'full_autopilot_mode': 'Full Autopilot mode',
        'full_autopilot_mode_desc': 'Sa DipSway Autopilot, hindi lamang awtomatiko ang iyong mga trade, kundi pinipili rin ng DipSway ang pinakamahusay na cryptocurrency para i-trade sa oras na iyon batay sa mga kundisyon ng merkado.',

        'calculate_your_earnings_with_autopilot': 'Kalkulahin ang iyong kita gamit ang Autopilot',
        'high_performance': 'Mataas na performance',
        'live_backtests_historical_performance': 'Live na backtests, nakaraang performance.',
        'backtests_performance': 'Backtests at performance',
        'extendable_via_tradingview': 'Maaaring palawakin gamit ang TradingView',
        'use_power_tradingview': 'Gamitin ang kapangyarihan ng TradingView para mas i-configure ang DipSway.',
        'coming_soon': 'Malapit nang dumating...',
        '3_green_trades_in_30_days': '3 berdeng trade sa loob ng 30 araw o garantisadong ibabalik ang pera',
        'need_help_choosing': 'Kailangan ng tulong sa pagpili?',
        'use_dipsway_earnings_calculator': 'Gamitin ang DipSway earnings calculator, alamin kung magkano ang maaari mong kitain at makakuha ng rekomendasyon sa plano.',
        'launch_earnings_calculator': 'Ilunsad ang earnings calculator',
        'popular': 'Sikat',
        'recommended_for_you': 'Inirerekomenda para sa iyo',
        'no_deposit_required': 'Walang kailangang deposito',
        'top_10_of_users': "Nangunguna 10% ng mga user",
        'all_users': "Lahat ng mga user",
        'downtrend_page:desc1': 'Operasyon: Pang-emergency na paglabas sa posisyon. Natukoy ang pababang trend.',
        'downtrend_page:sell_order_fulfilled': 'Natapos ang sell order para sa 12 BTC.',
        'performance_history': 'Kasaysayan ng Performance',
        'trained_to_recognize_opportunities': 'Sinanay para makilala ang mga oportunidad',
        'live_backtests_performances': 'Live na performance ng Backtests',
        'see_how_our_strategies_performed': 'Tingnan kung paano nag-perform ang aming mga estratehiya sa nakaraan gamit ang de-kalidad na live na backtests.',
        'backtests_from': 'Mga backtest mula sa',
        'past_6_months': 'nakaraang 6 na buwan',
        'collapse': 'I-collapse',
        'expand': 'Palawakin',
        'view_report': 'Tingnan ang ulat',
        'knowledge:title1': 'De-kalidad na Datos ng Kasaysayan',
        'knowledge:description1': 'Tinitiyak namin na ang mga live-candlestick na ginagamit ay may pinakamataas na kalidad.',
        'knowledge:title2': 'Kasama ang mga Bayarin at Pagkaantala',
        'knowledge:description2': 'Ang mga bayarin ng maker at taker na 0.1% ay inilalapat sa bawat trade. Ang slippage na 0.1% at pagkaantala ng ilang minuto ay inilalapat sa bawat trade.',
        'knowledge:title3': 'Pag-iwas sa Overfitting',
        'knowledge:description3': 'Ang DipSway ay pasibong nagmo-monitor ng mga account na hindi maganda ang performance at nagrerekomenda ng mga aksyon.',
        'operation': 'Operasyon',
        'buy_the_dip': 'Bumili sa pagbaba',
        'sell_the_high': 'Magbenta sa pagtaas',
        'bought': 'Bumili',
        'watching_market': 'nagmamasid sa merkado',
        'sell_order_for': 'Sell order para sa',
        'fulfilled': 'natapos',
        'profit_made': 'Kita',
        'calculation_results': 'Mga resulta ng kalkulasyon',
        'calculating': 'Kinakalkula',
        'total_expected_profit_at_the_end_of': 'Kabuuang inaasahang kita sa katapusan ng',
        'period_based_off_of_historical_backtest_performances': 'panahon batay sa mga nakaraang performance ng backtest',
        'trades': 'mga trade',
        'day': 'araw',
        'profit': 'kita',
        'recommended_plan_for_you': 'Inirerekomendang plano para sa iyo',
        'go_to': 'Pumunta sa',
        'free_trial_available': 'May Available na Libreng Pagsubok',
        'runs_at': 'Tumatakbo sa',
        'learn_how_we_calculate_your_earnings': 'Alamin kung paano namin kinakalkula ang iyong kita',

        'click_to_open_deepdive': 'I-click para buksan ang deepdive kung saan maaari kang pumili mula sa iba pang mga exchange na aming sinusuportahan.',
        'your_capital': 'Ang Iyong Kapital',
        'currency': 'Currency',
        'enabled': 'Naka-enable',
        'set_to': 'naka-set sa',
        'coin_count': 'bilang ng coin',
        'disabled': 'Hindi pinagana',
        'auto': 'Auto',
        'selected_coins': 'Mga napiling coin',
        'loading_coins': 'Naglo-load ng mga coin',
        'login': 'Login',
        'trading_with': 'Nag-te-trade gamit ang',
    }
} as const;
export const showDefaultLang = false;